import { CountryType, CountryActionsList } from "../actions/countryActions";
import { Action } from "../actions/types";

const initialValue: Array<CountryType> = []

export const CountryReducer = (state: CountryType[] =initialValue , action: Action) => {

    switch (action.type) {

        case CountryActionsList.ADD_COUNTRY: {

            return [...state, action.data]
        }

        case CountryActionsList.EDIT_COUNTRY: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case CountryActionsList.FETCH_COUNTRY: {

            return action.data

        }

        case CountryActionsList.DELETE_COUNTRY: {
            return state.filter(i => i.id !== action.data.id)

        }
        case CountryActionsList.CLEAR_COUNTRY: {
            return initialValue

        }

        default: return state
    }

}