import { ItemCategoryActionsList, ItemCategoryType } from "../actions/itemcategory";
import { Action } from "../actions/types";

const initialValue: Array<ItemCategoryType> = []

export const ItemCategoryReducer = (state: ItemCategoryType[] = initialValue, action: Action) => {

    switch (action.type) {

        case ItemCategoryActionsList.ADD_ITEMCATEGORY: {

            return [...state, action.data]
        }

        case ItemCategoryActionsList.EDIT_ITEMCATEGORY: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.catid === action.data.catid)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case ItemCategoryActionsList.FETCH_ITEMCATEGORY: {

            return action.data

        }

        case ItemCategoryActionsList.DELETE_ITEMCATEGORY: {
            return state.filter(i => i.catid !== action.data.catid)

        }

        default: return state
    }

}