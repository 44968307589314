import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemSubcategoryForm from "../../components/admin/ItemSubcategoryForm";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { nanoid } from "@reduxjs/toolkit";
import Modal from "../../components/Modal";
import Option from "../../components/Option";
import  {
  Tbody,
  Thead,
} from "../../components/custom/components/AdminTable";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import { 
  fetchDepartment, 
  ItemDepartmentType 
} from "../../redux/actions/itemdepartment";
import {
  ItemCategoryType,
  fetchItemCategory,
} from "../../redux/actions/itemcategory";
import { 
  ItemSubcategoryType,
  deleteItemSubcategory,
  fetchItemSubcategory,
  fetchItemSubcategoryById,
} from "../../redux/actions/itemsubcategory";
import { AppDispatch, StoreState } from "../../redux/store";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";
import { FormInput } from "../../components/custom/components";
import { PortfolioType } from "../../redux/actions/portfolioActions";


type HandleDelete = (value: ItemSubcategoryType) => void;

const ItemSubcategory = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const navigate = useNavigate();
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const departmentList = useSelector<StoreState, Array<ItemDepartmentType>>(
    (state) => state.department
  );
  const ItemCategoryList = useSelector<StoreState, Array<ItemCategoryType>>(
    (state) => state.itemcategory
  );
  const ItemSubcategoryList = useSelector<StoreState, Array<ItemSubcategoryType>>(
    (state) => state.itemsubcategory
  );
  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );
  // const filteredPortfolio = portfolioList.filter((a) => a.businessmodel !== 1);
  const [portfolioId, setportfolioId] = useState<string>("");
  const [portfolioIdError, setportfolioIdError] = useState<string>("");
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<ItemSubcategoryType>();
  const [deleteData, setDeleteData] = useState<ItemSubcategoryType>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [disableDelete, setDisableDelete] = useState<boolean>(false);
  const [id, setId] = useState<number>();
   const [itemSubcategoryData, setItemSubcategoryData] = useState<
    ItemSubcategoryType[]
  >([]);

  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const searchsubCatHandler = (subcat: ItemSubcategoryType, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(subcat.subcategory)
      : true || [];
  };

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!portfolioId) {
      toast("Porfolio required", ToastTypes.ERROR);
      setSearchQuery({ search: "" });
      return;
    }
    setSearchQuery({ search: e.target.value });
  };
  // const [itemDepartmentData, setItemDepartmentData] = useState<ItemDepartmentType[]>([]);
  const limit = 50;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    if (ItemSubcategoryList?.length > 0) {
      setLength(ItemSubcategoryList?.length ? ItemSubcategoryList?.length : 0);
    }
  }, [ItemSubcategoryList]);

  useEffect(() => {
    if (portfolioId) {
      dispatch(fetchDepartment(portfolioId));
      dispatch(fetchItemCategory(portfolioId,));
      dispatch(fetchItemSubcategory(portfolioId));
    }
  }, [portfolioId]);

    useEffect(()=>{
     navigate("/admin/item_sub_category");
  },[])

  const getTableData = () => {
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Department", children: "Department", text: "Department" },
      { id: "Item Category", children: "Item Category", text: "Item Category" },
      { id: "Item Subcategory", children: "Item Subcategory", text: "Item Subcategory" },
      { id: "Status", children: "Status", text: "Status" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];
    if (portfolioId) {
      itemSubcategoryData
      .filter((cr) => searchsubCatHandler(cr, searchTerm))
      .forEach((item, i) => {
        const pageNumber = Math.floor(offset / limit) + 1;
        var tBody: Tbody[] = [];
        let deptIndex = departmentList?.findIndex((a)=>a.depid===item.depid)
        let deptname = departmentList[deptIndex]?.department
        let catIndex = ItemCategoryList?.findIndex((a)=>a.catid===item.catid)
        let catname = ItemCategoryList[catIndex]?.category
        tBody.push(
          {
            id: nanoid(),
            theadId: "S.No",
            children: limit * pageNumber + i - limit + 1,
            text: (limit * pageNumber + i - limit + 1)?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Department",
            children: deptname,
            text: deptname,
            className: "text-truncate",
          },
          {
            id: nanoid(),
            theadId: "Item Category",
            children: catname,
            text: catname,
            className: "text-truncate",
          },
          {
            id: nanoid(),
            theadId: "Item Subcategory",
            children: item.subcategory,
            text: item.subcategory,
            className: "text-truncate",
          },
          {
            id: nanoid(),
            theadId: "Status",
            children: item.active ? "Active" : !item.active ? "InActive" : "",
            text: item.active ? "Active" : !item.active ? "InActive" : "",
          },
          {
            id: nanoid(),
            theadId: "Action",
            children: "action",
            className: "d-none",
            text: item.subcatid?.toString(),
          }
        );
        tableBody.push(tBody);
        // })
      });
    }
    return { tbody: tableBody, thead: tableHead };
  };
  const handleAdd = () => {
    if (!portfolioId) toast("Portfolio required", ToastTypes.ERROR);
    else {
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Create',
          event: `${pageName.pathname.replace("\\", " ")} page | Create Item Subcategory Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = ItemSubcategoryList.find((c) => c.subcatid === id);
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page |  ${obj?.depid} Item Subcategory Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handlePfid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setportfolioId(e.target.value);
    setportfolioIdError("");
  };

  const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = ItemSubcategoryList.find((c) => c.subcatid === id);
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = ItemSubcategoryList.find((c) => c.subcatid === id);
      setEditData(obj);
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | Item Subcategory - ${obj?.subcategory} | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (value.subcatid !== undefined) {
      let pfId = portfolioId
      let subcatid = value.subcatid
      dispatch(deleteItemSubcategory(pfId, subcatid))
        .then(async (text) => {
          dispatch(fetchItemSubcategory(portfolioId));
          toast(text, ToastTypes.SUCCESS);
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
  };

   const handleApply = async (offsetVal = offset) => {
    if (ItemSubcategoryList?.length > 0) {
      const getData = {
        limit: limit?.toString(),
        offset: offsetVal?.toString(),
      };

      if (
        getData !== undefined &&
        getData.limit !== "" &&
        getData.offset !== "" &&
        getData.offset !== undefined &&
        getData.offset !== "NaN"
      ) {
        const filteredItemSubcategoryList: ItemSubcategoryType[] =
          ItemSubcategoryList?.slice(offset, limit + offset);
        if (filteredItemSubcategoryList?.length > 0) {
          setItemSubcategoryData([...filteredItemSubcategoryList]);
        } else {
          setItemSubcategoryData([]);
        }
      }
    }
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, formType]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

    useEffect(() => {
    handleApply();
  }, [ItemSubcategoryList]);

  return (
    <>
      <Modal
        headerText={formType + " ITEM SUBCATEGORY"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <ItemSubcategoryForm
          pfId={portfolioId}
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
        />
      </Modal>
      <Modal
        headerText={formType + " ITEM SUBCATEGORY"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            disableDelete
              ? "Item Subcategory is in use. Deletion not allowed."
              : "Do you really want to delete the records with the Item Subcategory - " +
                deleteData?.subcategory
          }
          onCancel={handleFormClose}
          onDelete={() => (disableDelete ? {} : handleDelete(deleteData!))}
        />
      </Modal>
      <AdminLayout>
      <div className="row">
          <div className="col-6 hstack mt-4">
            <label htmlFor="">Portfolio :</label>
            <FormInput
              name="Portfolio"
              label=""
              labelClassName="required"
              value={portfolioId}
              onChange={handlePfid}
              placeholder="Portfolio"
              errorText={portfolioIdError}
              containerClass="w-50"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {portfolioList.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        </div>
        <AdminTable2
          addBtnText="Create New Item Subcategory"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          search={searchHandler}
          limit={limit}
          setOffset={setOffset}
          offset={offset}
          onPageNoChange={handleApply}
          range={range}
          pageTitle="Item Subcategory Management"
          path="/admin/item_sub_category"
          setId={setId}
          {...getTableData()}
        />
      </AdminLayout>
    </>
  );
};
export default ItemSubcategory;
