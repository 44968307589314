import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "active",
    InActive = "inActive",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

// export enum RoleDropDown {
//     Admin="Admin",
//     Company="Company",
//     Portfolio="Portfolio",
//     Site="Site",
//     Brand="Brand"
//  }
//  export type RoleTypes = RoleDropDown.Admin | RoleDropDown.Company| RoleDropDown.Portfolio| RoleDropDown.Site| RoleDropDown.Brand

export interface UsersType {
    uid?: number
    loginid?: string
    name: string
    password?: string
    status: ActiveTypes | boolean | string
    email: string
    role: string
    roleId: number
    companyId: number
    portfolioId?: string
    siteId?: string
    brandId?: string
    tenantId?:string
    // portfolios?: number[]
    // sites?: number[]
    // brands?: number[]
    createdBy?: number | null,
    createdAt?: string,
    updatedAt?: string
    updatedBy?: string
}

export enum UsersActionsList {
    ADD_USERS = 'ADD_USERS',
    EDIT_USERS = 'EDIT_USERS',
    FETCH_USERS = 'FETCH_USERS',
    DELETE_USERS = 'DELETE_USERS',
    CLEAR_USERS = "CLEAR_USERS",
}

export interface DeleteUsers {
    id: number
}

export interface AddUsersAction {
    type: UsersActionsList.ADD_USERS
    data: UsersType
}

export interface EditUsersAction {
    type: UsersActionsList.EDIT_USERS
    data: UsersType
    id?: number
}

export interface FetchUsersAction {
    type: UsersActionsList.FETCH_USERS
    data: UsersType[]

}

export interface DeleteUsersAction {
    type: UsersActionsList.DELETE_USERS
    data: DeleteUsers
}
export interface ClearUsersAction {
    type: UsersActionsList.CLEAR_USERS;
  }

export type UsersActions = AddUsersAction | EditUsersAction | FetchUsersAction | DeleteUsersAction | ClearUsersAction;

export const clearUsers = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
      return dispatch<ClearUsersAction>({
        type: UsersActionsList.CLEAR_USERS,
      });
    };
  };

export const addUsers = (data: UsersType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        // return dispatch<AddUsersAction>({
        //     type: UsersActionsList.ADD_USERS,
        //     data: {
        //         ...data,
        //         id: nanoid(5)
        //     }
        // })

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<UsersType>>('user/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddUsersAction>({
                    type: UsersActionsList.ADD_USERS,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Users added')
            } else {
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}

export const editUsers = (data: UsersType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        // return dispatch<EditUsersAction>({
        //     type: UsersActionsList.EDIT_USERS,
        //     data: data,
        //     id: id
        // })
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<UsersType>>(`user/profile/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditUsersAction>({
                    type: UsersActionsList.EDIT_USERS,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Users Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchUsers = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        // return dispatch<FetchUsersAction>({
        //     type: UsersActionsList.FETCH_USERS
        // })
        const token = getState().authAdmin?.['auth-token']!
        if(token){
            const config = getAxiosRequestConfig(token)

            return api.get<APIResponse<UsersType[]>>('user/', config).then(response => {
                if (response.status === 200 && response.data.data) {
                    dispatch<FetchUsersAction>({
                        type: UsersActionsList.FETCH_USERS,
                        data: response.data.data
                    })
                    return Promise.resolve(response.data.message
                        ? response.data.message
                        : 'Fetch Successfull')
                } else {
                    throw new Error("Unable to fetch")
                }
            }).catch(error => {
                return Promise.reject(error.response
                    ? error.response.data.message
                        ? error.response.data.message
                        : 'Unable to fetch'
                    : 'Unable to fetch')
            })
        }
    }
}

export const deleteUsers = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        // return dispatch<DeleteUsersAction>({
        //     type: UsersActionsList.DELETE_USERS,
        //     data: { id }
        // })
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<UsersType>>(`user/${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteUsersAction>({
                    type: UsersActionsList.DELETE_USERS,
                    data: { id }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Users Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}