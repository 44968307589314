import { CredentialActionsList, CredentialType } from "../actions/credentialActions";
import { Action } from "../actions/types";

const initialValue: Array<CredentialType> = []

export const CredentialReducer = (state: CredentialType[] = initialValue, action: Action) => {

    switch (action.type) {

        case CredentialActionsList.ADD_CREDENTIAL: {

            return [...state, action.data]
        }

        case CredentialActionsList.EDIT_CREDENTIAL: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.tenantid === action.data.tenantid)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case CredentialActionsList.FETCH_CREDENTIAL: {

            return action.data

        }

        case CredentialActionsList.DELETE_CREDENTIAL: {
            return state.filter(i => i.tenantid !== action.data.id)

        }

        default: return state
    }

}