import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import { ActiveDropDown, ActiveTypes, fetchDepartment } from "../../redux/actions/itemdepartment";
import {
  ItemDepartmentType,
  addItemDepartment,
  editItemDepartment,
} from "../../redux/actions/itemdepartment";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { FormInput } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { useLocation } from "react-router-dom";
import { AdminLog } from "../../config/common";

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

interface Props {
  pfId: string;
  formType: FormType;
  editData?: ItemDepartmentType;
  onSave: () => void;
  showViewForm: boolean;
}

const ItemDepartmentForm = ({
  formType,
  onSave,
  editData,
  showViewForm,
  pfId,
}: Props) => {
  const toast = useToast();

  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const [dept, setDept] = useState<string>("");
  const [deptError, setDeptError] = useState<string>("");
  const [active, setActive] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [activeError, setActiveError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [portfolioId, setportfolioId] = useState<string>("");

  const resetStates = () => {
    setDept("");
    setDeptError("");
    setActive(ActiveDropDown.Active);
    setActiveError("");
    setLoading(false);
  };

  const handleDept: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDept(e.target.value);
    setDeptError("");
  };
  const handleActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setActive(e.target.value as ActiveTypes);
    setActiveError("");
  };

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const data: ItemDepartmentType = {
      portfolioid: portfolioId,
      department: dept,
      active: active,
    };

    if (data.department === "") {
      setDeptError("Department Required");
      error = true;
    } else if (data.department?.length > 60) {
      setDeptError("Department Name should be within 60 letters");
      error = true;
    }

    if (data.active?.toString() === "") {
      setActiveError("Active required");
      error = true;
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addItemDepartment(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
            dispatch(fetchDepartment(portfolioId));
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.depid !== undefined
      ) {
        setLoading(true);
        dispatch(editItemDepartment(data, editData.depid))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
            dispatch(fetchDepartment(portfolioId));
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      setDept(editData.department);
      setActive(
        editData.active === true
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );
    } else {
      resetStates();
    }
  }, [formType, editData]);

  useEffect(() => {
    if (pfId) {
      setportfolioId(pfId);
      dispatch(fetchDepartment(pfId));
    }
  }, [pfId]);


  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Department"
            label="Department"
            labelClassName="required"
            value={dept}
            onChange={handleDept}
            placeholder="Department"
            errorText={deptError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Status"
            label="Status"
            labelClassName="required"
            value={active}
            onChange={handleActive}
            placeholder="Status"
            errorText={activeError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {ACTIVE_DROPS.map((a, i) => {
              return (
                <Option value={a.value?.toString()} key={i}>
                  {a.text}
                </Option>
              );
            })}
          </FormInput>
        </div>
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default ItemDepartmentForm;
