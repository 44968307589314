import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
// import NotificationForm from '../../components/admin/NotificationForm'
import NotificationForm from "../../components/admin/NotificationForm";
import AdminTable, {
  Tbody,
  Thead,
} from "../../components/custom/components/AdminTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import {
  NotificationType,
  deleteNotification,
} from "../../redux/actions/notificationActions";
import { SiteType } from "../../redux/actions/siteActions";
import { TenantCategoryType } from "../../redux/actions/tenantActions";
import { AppDispatch, StoreState } from "../../redux/store";
import { TradeCategoryType } from "../../redux/actions/tradeCategoryActions";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";

// interface TableBody {
//     sno: number
//     Notificationcompanyname?: string
//     name: string
//     url: string
//     logopath: string
//     logourl: string
//     companyid: number
//     active: ActiveTypes | boolean
//     onEdit: MouseEventHandler<HTMLButtonElement>
//     onDelete: MouseEventHandler<HTMLButtonElement>
//     onView: MouseEventHandler<HTMLButtonElement>
// }

// type HandleEdit = (value: NotificationType) => void
type HandleDelete = (value: NotificationType) => void;
// type HandleView = (value: NotificationType) => void

const NotificationAlertManagement = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const NotificationList = useSelector<StoreState, Array<NotificationType>>(
    (state) => state.notification
  );
  const siteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  );
  const tenantList = useSelector<StoreState, Array<TenantCategoryType>>(
    (state) => state.tenant
  );
  const tradeCategoryList = useSelector<StoreState, Array<TradeCategoryType>>(
    (state) => state.tradeCategory
  );
  const [showForm, setShowForm] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<NotificationType>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<NotificationType>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [id, setId] = useState<number>();

  const getTableData = () => {
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Report Type", children: "Report Type", text: "Report Type" },
      { id: "Name", children: "Name", text: "Name" },
      { id: "Center", children: "Center", text: "Center" },
      { id: "Based On", children: "Based On", text: "Based On" },
      {
        id: "Additional Columns",
        children: "Additional Columns",
        text: "Additional Columns",
      },
      { id: "Occurrence", children: "Occurrence", text: "Occurrence" },
      { id: "Month", children: "Month", text: "Month" },
      { id: "Week", children: "Week", text: "Week" },
      { id: "Day", children: "Day", text: "Day" },
      { id: "Hour", children: "Hour", text: "Hour" },
      { id: "Minutes", children: "Minutes", text: "Minutes" },
      { id: "Actions", children: "Actions", text: "Actions" },
      { id: "To List", children: "To List", text: "To List" },
      { id: "Tenant List", children: "Tenant List", text: "Tenant List" },
      { id: "Trade List", children: "Trade List", text: "Trade List" },
      { id: "Status", children: "Status", text: "Status" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];

    NotificationList.forEach((item, i) => {
      let sindex = siteList.findIndex((c, i) => {
        return c.id === item.siteId;
      });
      var sname = siteList[sindex]?.name;

      // let tindex = tenantList.findIndex((c, i) => {
      //     return c.id === item.tenantList
      // })
      const tlist =
        item?.tenantList !== undefined && item?.tenantList !== null
          ? item.tenantList.split(",")
          : [];
      let tlistData = tlist
        ?.map((tl) => {
          return tenantList.filter((f) => f?.id?.toString() === tl?.toString());
        })
        .flat(1);

      var tname = "";
      tlistData.forEach((t, i) => {
        if (i === 0) tname = tname + t.name;
        else tname = tname + "," + t.name;
      });

      const tclist =
        item?.tradeList !== undefined && item?.tradeList !== null
          ? item.tradeList.split(",")
          : [];
      let tclistData = tclist
        ?.map((tcl) => {
          return tradeCategoryList.filter(
            (f) => f?.id?.toString() === tcl?.toString()
          );
        })
        .flat(1);

      var tclname = "";
      tclistData.forEach((tc, i) => {
        if (i === 0) tclname = tclname + tc.name;
        else tclname = tclname + "," + tc.name;
      });

      // var tname = tenantList[tindex]?.name

      var tBody: Tbody[] = [];
      tBody.push(
        {
          id: nanoid(),
          theadId: "S.No",
          children: i + 1,
          text: (i + 1)?.toString(),
        },
        {
          id: nanoid(),
          theadId: "Report Type",
          children: item.reportType,
          text: item.reportType,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Name",
          children: item.name,
          text: item.name,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Center",
          children: sname,
          text: sname,
        },
        {
          id: nanoid(),
          theadId: "Based On",
          children: item.basedOn,
          text: item.basedOn,
        },
        {
          id: nanoid(),
          theadId: "Additional Columns",
          children: item.additionalColumns,
          text: item.additionalColumns,
        },
        {
          id: nanoid(),
          theadId: "Occurrence",
          children: item.occurrence,
          text: item.occurrence,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Month",
          children: item.month,
          text: item.month,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Week",
          children: item.week,
          text: item.week,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Day",
          children: item.day,
          text: item.day,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Hour",
          children: item.hour,
          text: item.hour,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Minutes",
          children: item.minutes,
          text: item.minutes,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Actions",
          children: item.action,
          text: item.action,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "To List",
          children: item.toList?.toString(),
          text: item.toList?.toString(),
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Tenant List",
          children: tname,
          text: tname,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Trade List",
          children: tclname,
          text: tclname,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Status",
          children: item.status ? "Active" : !item.status ? "InActive" : "",
          text: item.status ? "Active" : !item.status ? "InActive" : "",
        },
        {
          id: nanoid(),
          theadId: "Action",
          children: "action",
          className: "d-none",
          text: item.id?.toString(),
        }
      );
      tableBody.push(tBody);
      // })
    });

    return { tbody: tableBody, thead: tableHead };
  };

  const handleAdd = () => {
    setShowForm(true);
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = NotificationList.find((c) => c.id === id);
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
    }
  };

  const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = NotificationList.find((c) => c.id === id);
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = NotificationList.find((c) => c.id === id);
      setEditData(obj);
      setShowForm(true);
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (value.id !== undefined) {
      dispatch(deleteNotification(value.id))
        .then((text) => {
          toast(text, ToastTypes.SUCCESS);
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
  }, [id, formType]);

  return (
    <>
      <Modal
        headerText={formType + " NOTIFICATION"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <NotificationForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
        />
      </Modal>
      <Modal
        headerText={formType + " NOTIFICATION"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            "Do you really want to delete the records with the portfolio - " +
            deleteData?.name
          }
          onCancel={handleFormClose}
          onDelete={() => handleDelete(deleteData!)}
        />
      </Modal>
      <AdminLayout>
        {/* <PageTitle
                title='Notification Management'
                buttonText='Create New Notification'
                onButtonClick={handleAdd}
            /> */}
        <AdminTable
          addBtnText="Create New Notification"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          pageTitle="Notification/Alert Management"
          path="/admin/notification_management"
          // data={data}
          setId={setId}
          {...getTableData()}
        />
      </AdminLayout>
    </>
  );
};

export default NotificationAlertManagement;
