// import { nanoid } from "@reduxjs/toolkit";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { Dispatch } from "redux";
import { GetState } from "../store";

export enum ActiveDropDown {
  Active = "true",
  InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive;

export interface PortfolioType {
  id?: number;
  companyid: number;
  name: string;
  url: string;
  code: string;
  logopath: string;
  logourl: string;
  businessmodel: number;
  reportingcurrency: string;
  currencysymbol: string;
  valuecaption: string;
  active: ActiveTypes | boolean;

  //PORTFOLIO DB

  pfid?: number;
  uid?: string;
  server?: string;
  pwd?: string;
  dbname?: string;
  createdBy?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
}

// export const PortfolioSampleData = [
//     {
//         id: '1',
//         portfolioName:'Portfolio-1',
//         portfolioShortName:'port',
//         websiteUrl:'danvhuehoshdfnlkuou48'

//     },
//     {
//         id: '2',
//         portfolioName:'Portfolio-2',
//         portfolioShortName:'port',
//         websiteUrl:'danvhuehoshdfnlkuou48'
//     },

// ]

export enum PortfolioActionsList {
  ADD_PORTFOLIO = "ADD_PORTFOLIO",
  EDIT_PORTFOLIO = "EDIT_PORTFOLIO",
  FETCH_PORTFOLIO = "FETCH_PORTFOLIO",
  DELETE_PORTFOLIO = "DELETE_PORTFOLIO",
  CLEAR_PORTFOLIO = "CLEAR_PORTFOLIO",
}

export interface DeletePortfolio {
  id: number;
}

export interface AddPortfolioAction {
  type: PortfolioActionsList.ADD_PORTFOLIO;
  data: PortfolioType;
}

export interface EditPortfolioAction {
  type: PortfolioActionsList.EDIT_PORTFOLIO;
  data: PortfolioType;
  id?: number;
}

export interface FetchPortfolioAction {
  type: PortfolioActionsList.FETCH_PORTFOLIO;
  data: PortfolioType[];
}

export interface DeletePortfolioAction {
  type: PortfolioActionsList.DELETE_PORTFOLIO;
  data: DeletePortfolio;
}

export interface ClearPortfolioAction {
  type: PortfolioActionsList.CLEAR_PORTFOLIO;
}

export type PortfolioActions =
  | AddPortfolioAction
  | EditPortfolioAction
  | FetchPortfolioAction
  | DeletePortfolioAction
  | ClearPortfolioAction;

export const addPortfolio = (data: PortfolioType) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);
    return api
      .post<APIResponse<PortfolioType>>("portfolio/", data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<AddPortfolioAction>({
            type: PortfolioActionsList.ADD_PORTFOLIO,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Portfolio added"
          );
        } else {
          // throw { response };
          throw new Error("Unable to create")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to create"
            : "Unable to create"
        );
      });
  };
};
export const clearPortfolio = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    return dispatch<ClearPortfolioAction>({
      type: PortfolioActionsList.CLEAR_PORTFOLIO,
    });
  };
};
export const editPortfolio = (data: PortfolioType, id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .put<APIResponse<PortfolioType>>(`portfolio/${id}`, data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<EditPortfolioAction>({
            type: PortfolioActionsList.EDIT_PORTFOLIO,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Portfolio Updated"
          );
        } else {
          throw new Error("Unable to update")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to update"
            : "Unable to update"
        );
      });
  };
};

export const fetchPortfolio = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<PortfolioType[]>>("portfolio/", config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchPortfolioAction>({
            type: PortfolioActionsList.FETCH_PORTFOLIO,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const deletePortfolio = (id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .delete<APIResponse<PortfolioType>>(`portfolio/${id}`, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch<DeletePortfolioAction>({
            type: PortfolioActionsList.DELETE_PORTFOLIO,
            data: { id },
          });
          return Promise.resolve(
            response.data.message ? response.data.message : " Portfolio Deleted"
          );
        } else {
          throw new Error("Unable to delete")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to delete"
            : "Unable to delete"
        );
      });
  };
};
