import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { Dispatch } from "redux"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface CompanyContactType {
    id?: number
    type:string
    fname: string
    lname: string
    designation: string
    mailid: string
    mobile:string
    pincode: string
    companyid: string
    active: boolean | ActiveTypes
    createdBy?: number | null,
    createdAt?: string,
    updatedAt?: string
    updatedBy?: string
}

export enum CompanyContactActionsList {
    ADD_COMPANYCONTACT = 'ADD_COMPANYCONTACT',
    EDIT_COMPANYCONTACT = 'EDIT_COMPANYCONTACT',
    FETCH_COMPANYCONTACT = 'FETCH_COMPANYCONTACT',
    DELETE_COMPANYCONTACT = 'DELETE_COMPANYCONTACT'
}

export interface DeleteCompanyContact {
    id: number
}

export interface AddCompanyContactAction {
    type: CompanyContactActionsList.ADD_COMPANYCONTACT
    data: CompanyContactType
}

export interface EditCompanyContactAction {
    type: CompanyContactActionsList.EDIT_COMPANYCONTACT
    data: CompanyContactType
    id?: number
}

export interface FetchCompanyContactAction {
    type: CompanyContactActionsList.FETCH_COMPANYCONTACT
    data: CompanyContactType[]

}

export interface DeleteCompanyContactAction {
    type: CompanyContactActionsList.DELETE_COMPANYCONTACT
    data: DeleteCompanyContact
}

export type CompanyContactActions = AddCompanyContactAction | EditCompanyContactAction | FetchCompanyContactAction | DeleteCompanyContactAction

export const addCompanyContact = (data: CompanyContactType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<CompanyContactType>>('companyContact/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddCompanyContactAction>({
                    type: CompanyContactActionsList.ADD_COMPANYCONTACT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'CompanyContact added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}

export const editCompanyContact = (data: CompanyContactType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<CompanyContactType>>(`companyContact/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditCompanyContactAction>({
                    type: CompanyContactActionsList.EDIT_COMPANYCONTACT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'CompanyContact Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchCompanyContact = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<CompanyContactType[]>>('companyContact/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchCompanyContactAction>({
                    type: CompanyContactActionsList.FETCH_COMPANYCONTACT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteCompanyContact = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<CompanyContactType>>(`companyContact/${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteCompanyContactAction>({
                    type: CompanyContactActionsList.DELETE_COMPANYCONTACT,
                    data: { id }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' CompanyContact Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}