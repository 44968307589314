import { CompanyContactType, CompanyContactActionsList } from "../actions/companyContactActions";
import { Action } from "../actions/types";

const initialValue: Array<CompanyContactType> = []

export const CompanyContactReducer = (state: CompanyContactType[] =initialValue , action: Action) => {

    switch (action.type) {

        case CompanyContactActionsList.ADD_COMPANYCONTACT: {

            return [...state, action.data]
        }

        case CompanyContactActionsList.EDIT_COMPANYCONTACT: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case CompanyContactActionsList.FETCH_COMPANYCONTACT: {

            return action.data

        }

        case CompanyContactActionsList.DELETE_COMPANYCONTACT: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}