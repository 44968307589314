import React, { ChangeEventHandler, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BuildingType,
  fetchBuilding,
} from "../../redux/actions/buildingActions";
import { FloorType, fetchFloor } from "../../redux/actions/floorActions";
import { PaxAreaTypes, PaxTypeDropDown } from "../../redux/actions/leaseInfoActions";
import { SiteType } from "../../redux/actions/siteActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import {
  TenantLeaseDetails,
  TenantLeaseDetails2,
  TenantManager2,
  TenantManagerContext,
  TenantManagerContextType,
} from "../context/TenantManagerContext";
import { FormInput, MultiSelect } from "../custom/components";
import {
  testNumber,
  getYesterday,
  getRevenueMaxDate,
  dateranges,
  checkDateRangeOverlap,
} from "../../config/functions";
import { RentType } from "../../redux/actions/commonActions";
import { fetchRent } from "../../redux/actions/commonActions";
import { UOM } from "./SiteForm";
import { FormType, FormTypeList } from "../../config";
import { Tbody, Thead } from "../custom/components/AdminTable2";
import { nanoid } from "@reduxjs/toolkit";
import Modal from "../Modal";
import DeleteConfirmationForm from "./DeleteConfirmationForm";
import AdminGrid from "../custom/components/AdminGrid";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import {
  fetchItemCategory,
  ItemCategoryType,
} from "../../redux/actions/itemcategory";
import { UnitType } from "../../redux/actions/unitActions";
import { DropDownOption } from "../commonWidget";
import TenantCategory from "../../pages/admin/TenantCategory";

type HandleDelete = (value: TenantLeaseDetails) => void;

// const BUILDINGTERMINAL_DROPS = [
//     {
//         text: 'Shopping Center',
//         value: 'Shopping Center'
//     },
//     {
//         text: 'Mall',
//         value: 'Mall'
//     },
// ]

const PAXTYPE_DROPS = [
  {
    text: "Arrival",
    value: "Arrival",
  },
  {
    text: "Departures",
    value: "Departures",
  },
  {
    text: "Others",
    value: "Others",
  },
];

const RENT_STRUCTURE = [
  {
    text: "Base Per Sq.Ft",
    value: "1",
  },
  {
    text: "Base Per Sq.Mtr",
    value: "2",
  },
  {
    text: "Flat Fee",
    value: "3",
  },
];

const RATE_STRUCTURE = [
  {
    text: "Per Month",
    value: "Per Month",
  },
  {
    text: "Per Annum",
    value: "Per Annum",
  },
  {
    text: "365 Days",
    value: "365 Days",
  },
  {
    text: "Flat Fee",
    value: "Flat Fee",
  },
];

const BILLING_CYCLE = [
  {
    text: "One-time",
    value: "One-time",
  },
  {
    text: "Monthly",
    value: "Monthly",
  },
  {
    text: "Quarterly",
    value: "Quarterly",
  },
  {
    text: "Half-Yearly",
    value: "Half-Yearly",
  },
  {
    text: "Annually",
    value: "Annually",
  },
];
interface leaseRow {
  id: number;
  itemCate: string;
  rangeFrom: string;
  rangeTo: string;
  revenueShare: string;
}
const LeaseInfoForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    centerId,
    centerIdError,
    setCenterIdError,
    portfolioId,
    setCenterId,
    leaseid,
    setLeaseId,
    carpetArea,
    setCarpetArea,
    chargeableArea,
    setChargeableArea,
    landmark,
    setLandmark,
    buildingTerminal,
    setBuildingTerminal,
    location,
    setLocation,
    paxType,
    setPaxType,
    unitName,
    setUnitName,
    leaseIdError,
    setLeaseIdError,
    carpetAreaError,
    setCarpetAreaError,
    leasableAreaError,
    setLeasableAreaError,
    landmarkError,
    setLandmarkError,
    buildingTerminalError,
    setBuildingTerminalError,
    locationError,
    setLocationError,
    paxTypeError,
    setPaxTypeError,
    unitNameError,
    setUnitNameError,
    rentType,
    setRentType,
    rentTypeError,
    setRentTypeError,
    maxFromDate,
    setMaxFromDate,
    fromDate,
    setFromDate,
    fromDateError,
    setFromDateError,
    toDate,
    setToDate,
    toDateError,
    setToDateError,
    rentBasis,
    setRentBasis,
    rentBasisError,
    setRentBasisError,
    rentBasisRate,
    setRentBasisRate,
    rentBasisRateError,
    setRentBasisRateError,
    baseRent,
    setBaseRent,
    baseRentError,
    setBaseRentError,
    billingCycle,
    setBillingCycle,
    billingCycleError,
    setBillingCycleError,
    rentEscPer,
    setRentEscPer,
    rentEscPerError,
    setRentEscPerError,
    revenueShare,
    setRevenueShare,
    revenueShareError,
    setRevenueShareError,
    itemCate,
    setItemCate,
    itemCateError,
    setItemCateError,
    rangeFrom,
    setRangeFrom,
    rangeFromError,
    setRangeFromError,
    rangeTo,
    setRangeTo,
    rangeToError,
    setRangeToError,
    tenantLeaseInfo,
    setTenantLeaseInfo,
    tenantLeaseInfo2,
    setTenantLeaseInfo2,
    unitId,
    setUnitId,
    unitIdError,
    setUnitIdError,
    tenantCategory,
    tenantId,
    tenantManager2,
    setTenantManager2
  } = React.useContext<TenantManagerContextType | null>(TenantManagerContext)!;
  const toast = useToast();
  const [tenantLeaseDetails, setTenantLeaseDetails] = useState<
    TenantLeaseDetails[]
  >([]);
  const [tenantLeaseDetails2, setTenantLeaseDetails2] = useState<
    TenantLeaseDetails2[]
  >([]);
  const [tenantManagerDetails2, setTenantManagerDetails2] = useState<
    TenantManager2[]
  >([]);
  // const [tenantLeaseDetails2, setTenantLeaseDetails2] = useState<
  //   TenantLeaseDetails2[]
  // >([]);
  const [leaseRows, setLeaseRows] = useState<leaseRow[]>([
    {
      id: Date.now(),
      itemCate: "",
      rangeFrom: "",
      rangeTo: "",
      revenueShare: "",
    },
  ]);
  const ItemCategoryList = useSelector<StoreState, Array<ItemCategoryType>>(
    (state) => state.itemcategory
  );
  const buildingList = useSelector<StoreState, Array<BuildingType>>(
    (state) => state.building
  );
  const FloorList = useSelector<StoreState, Array<FloorType>>(
    (state) => state.floor
  );
  const siteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  ).filter((s) => s.pfid?.toString() === portfolioId);

  const unitList = useSelector<StoreState, Array<UnitType>>(
    (state) => state.space
  );

  const [units, setUnits] = useState<DropDownOption[]>([]);
  const [unitNoList, setUnitNoList] = useState<string>("");
  const [unitNameList,setUnitNameList] = useState<string>('')

  useEffect(() => {
    if (portfolioId) {
      dispatch(fetchRent());
      dispatch(fetchItemCategory(portfolioId));
    }
  }, [portfolioId]);
  useEffect(() => {
    if (tenantLeaseInfo) {
      setTenantLeaseDetails(tenantLeaseInfo);
    }
  }, [tenantLeaseInfo]);

  useEffect(() => {
    if (tenantLeaseInfo2) {
      setTenantLeaseDetails2(tenantLeaseInfo2);
    }
  }, [tenantLeaseInfo2]);

  useEffect(() => {
    if (tenantManager2) {
      setTenantManagerDetails2(tenantManager2);
    }
  }, [tenantManager2]);

  const rentList = useSelector<StoreState, Array<RentType>>(
    (state) => state.rent
  );
  // const [carpetArea, setCarpetArea] = useState<string>('')
  // const [carpetAreaError, setCarpetAreaError] = useState<string>('')
  // const [leasableArea, setLeasableArea] = useState<string>('')
  // const [leasableAreaError, setLeasableAreaError] = useState<string>('')
  // const [landmark, setLandmark] = useState<string>('')
  // const [landmarkError, setLandmarkError] = useState<string>('')
  // const [buildingTerminal, setBuildingTerminal] = useState<string>('')
  // const [buildingTerminalError, setBuildingTerminalError] = useState<string>('')
  // const [location, setLocation] = useState<string>('')
  // const [locationError, setLocationError] = useState<string>('')
  // const [paxType, setPaxType] = useState<PaxTypeTypes>(PaxTypeDropDown.Arrival)
  // const [paxTypeError, setPaxTypeError] = useState<string>('')
  // const [unitName, setUnitName] = useState<string>('')
  // const [unitNameError, setUnitNameError] = useState<string>('')

  const filteredBuildings = useMemo<BuildingType[]>(() => {
    if (centerId) {
      return buildingList.filter((s) => s.centerid?.toString() === centerId);
    } else return buildingList;
  }, [centerId, buildingList]);

  const filteredFloors = useMemo<FloorType[]>(() => {
    if (buildingTerminal) {
      return FloorList.filter(
        (s) => s.buildingid?.toString() === buildingTerminal
      );
    } else return FloorList;
  }, [buildingTerminal, FloorList]);

  const filteredUnitList = useMemo<UnitType[]>(() => {
    if (
      centerId &&
      buildingTerminal &&
      location &&
      location !== "" &&
      buildingTerminal !== "" &&
      centerId !== ""
    ) {
      return unitList.filter(
        (s) =>
          s.centerid?.toString() === centerId &&
          s?.buildingid?.toString() === buildingTerminal?.toString() &&
          s?.floorid?.toString() === location?.toString()
      );
    } else return [];
  }, [centerId, unitList, location, buildingTerminal]);

  // console.log(filteredUnitList,unitList,'mate')

  const handleLeaseId: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLeaseId!(e.target.value);
    setLeaseIdError!("");
  };

  const handleCarpetArea: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;

    setCarpetArea!(e.target.value);
    setCarpetAreaError!("");
  };
  const handleLeasableArea: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;

    setChargeableArea!(e.target.value);
    setLeasableAreaError!("");
  };
  const handleLandmark: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLandmark!(e.target.value);
    setLandmarkError!("");
  };

  const handleBuildingTerminal: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBuildingTerminal!(e.target.value);
    setBuildingTerminalError!("");
    setLocation!("");
    setLocationError!("");
    setUnits([]);
    setUnitId!("");
    setUnitIdError!("");
  };

  const handleLocation: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLocation!(e.target.value);
    setLocationError!("");
    setUnits([]);
    setUnitId!("");
    setUnitIdError!("");
  };

  const handleCenterId: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCenterId!(e.target.value);
    setCenterIdError!("");
  };

  const handlePaxType: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPaxType!(e.target.value as PaxAreaTypes);
    setPaxTypeError!("");
  };

  const handleUnitName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUnitName!(e.target.value);
    setUnitNameError!("");
  };

  const handleUnitId: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUnitId!(e.target.value);
    setUnitIdError!("");
  };

  const handleRentType: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRentType!(e.target.value as string);
    setRentTypeError!("");
    setLeaseRows([
      {
        id: Date.now(),
        itemCate: "",
        rangeFrom: "",
        rangeTo: "",
        revenueShare: "",
      },
    ]);
  };
  const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setFromDate!(value);
    setFromDateError!("");
  };

  const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    const selectedDate = new Date(e.target.value);
    const fromdateObj = fromDate ? new Date(fromDate) : new Date();
    if (selectedDate <= fromdateObj) {
      setToDateError!("End date must be a future date of Start date.");
    } else {
      setToDate!(e.target.value);
      setToDateError!("");
    }
  };

  const handleRentBasis: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRentBasis!(e.target.value);
    setRentBasisError!("");
  };

  const handleRentBasisRate: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRentBasisRate!(e.target.value);
    setRentBasisRateError!("");
  };

  const handleBillingCycle: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBillingCycle!(e.target.value);
    setBillingCycleError!("");
  };

  const handleBaseRent: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBaseRent!(e.target.value);
    setBaseRentError!("");
  };

  const handleRentEscPer: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRentEscPer!(e.target.value);
    setRentEscPerError!("");
  };

  const handleRevenueShare: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRevenueShare!(e.target.value);
    setRevenueShareError!("");
  };

  const handleItemCate: ChangeEventHandler<HTMLInputElement> = (e) => {
    setItemCate!(e.target.value);
    setItemCateError!("");
  };

  const handleRangeFrom: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRangeFrom!(e.target.value);
    setRangeFromError!("");
  };

  const handleRangeTo: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRangeTo!(e.target.value);
    setRangeToError!("");
  };

  const handleMultiInputChange = (id: number, field: string, value: string) => {
    setLeaseRows(
      leaseRows.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );
  };
  useEffect(() => {
    if (portfolioId) {
      dispatch(fetchFloor(portfolioId));
      dispatch(fetchBuilding(portfolioId));
    }
  }, [portfolioId]);

  const handleSave = () => {
    let error = false;
    const data: TenantLeaseDetails = {
      leaseId: leaseid ? leaseid : "",
      rentType: rentType ? rentType : "",
      leaseStart: fromDate ? fromDate : "",
      leaseEnd: toDate ? toDate : "",
      rentBasis: rentBasis
        ? RENT_STRUCTURE.filter(
            (a) => a?.value?.toString() === rentBasis?.toString()
          )[0].text?.toString()
        : "",
      rentBasisRate: rentBasisRate ? rentBasisRate : "",
      rentBaseAmt: baseRent ? baseRent : "",
      billingCycle: billingCycle ? billingCycle : "",
      rentEscPer: rentEscPer ? rentEscPer : "",
      carpetArea: carpetArea ? carpetArea : "",
      chargeableArea: chargeableArea ? chargeableArea : "",
      /*itemCate: itemCate ? itemCate : '',
        rangeFrom: rangeFrom ? rangeFrom : '',
        reangeTo: rangeTo ? rangeTo : '',
        revenueShare: revenueShare ? revenueShare : '',*/
      leaseMultiRows: leaseRows,
    };
    const data2: TenantLeaseDetails2 = {
      centerid: Number(centerId),
      buildingid: Number(buildingTerminal),
      floorid: Number(location),
      unitno: unitNoList,
      area: data.rentBasis,
      tenantcategory: Number(tenantCategory),
      renttype: Number(data.rentType),
      currenttenant: Number(tenantId),
      leasestart: data.leaseStart,
      leaseend: data.leaseEnd,
      occupancystatus: "",
      unitid: unitId !== "" ? unitId : "",
    };
     const data3: TenantManager2 = {
      floorid: Number(location),
      landmark: landmark??'',
      buildingid: Number(buildingTerminal),
      carpetarea:carpetArea??'',
      chargeablearea: chargeableArea??'',
      unitname: unitNameList,
      paxtype: paxType??'',
      leaseid: leaseid??'',
      unitid:unitId??'',
    };
    let leaseArray = tenantLeaseDetails?.map((t) => t.leaseId);
    // console.log("leaseArray",leaseArray)
    if (data.leaseId === "") {
      setLeaseIdError!("Lease Id Required");
      error = true;
    }
    /*else if(!leaseArray?.includes(data.leaseId)) {
          setLeaseIdError!('LeaseId required')
          error = true
      }*/
    if (data.rentType === "") {
      setRentTypeError!("Rent Type Required");
      error = true;
    }
    if (data.leaseStart === "") {
      setFromDateError!("Lease Start Date Required");
      error = true;
    }
    if (data.leaseEnd === "") {
      setToDateError!("Lease End Date Required");
      error = true;
    }
    if (units?.length === 0) {
      setUnitIdError!("Unit No Required");
      error = true;
    }
    if (data2.unitid === "") {
      setUnitIdError!("Unit No Required");
      error = true;
    }
    const todateobj = toDate ? new Date(toDate) : new Date();
    const fromdateObj = fromDate ? new Date(fromDate) : new Date();
    if (fromDate != "" && toDate != "" && todateobj <= fromdateObj) {
      setToDateError!("End date must be a future date of Start date.");
      error = true;
    }
    if (data.rentBasis === "") {
      setRentBasisError!("Rent Basis-Rent Required");
      error = true;
    }
    if (data.rentBasisRate === "") {
      setRentBasisRateError!("Rent Basis-Rate Required");
      error = true;
    }
    if (data.billingCycle === "") {
      setBillingCycleError!("Billing Cycle Required");
      error = true;
    }
    if (data.carpetArea === "") {
      setCarpetAreaError!("Carpet Area Required");
      error = true;
    }
    if (data.chargeableArea === "") {
      setLeasableAreaError!("Chargeable Area Required");
      error = true;
    }

    if (tenantLeaseDetails !== undefined && tenantLeaseDetails?.length > 0) {
      const existingdateRange: dateranges[] = [];
      tenantLeaseDetails.forEach((a) => {
        let dates = {
          fromdate: a?.leaseStart ?? "",
          todate: a?.leaseEnd ?? "",
        };
        existingdateRange.push(dates);
      });
      const res = checkDateRangeOverlap(
        existingdateRange,
        data?.leaseStart?.toString(),
        data?.leaseEnd?.toString()
      );
      if (res?.status === true) {
        toast(
          `Lease Already Exists for the period ${res?.ExistingFrom} - ${res?.ExistingTo}`,
          ToastTypes.ERROR
        );
        error = true;
      }
    }

    if (
      data?.rentType?.toString() === "2" ||
      data?.rentType?.toString() === "5" ||
      data?.rentType?.toString() === "8"
    ) {
      const tempLeaseRow = data?.leaseMultiRows;
      for (let a of tempLeaseRow) {
        if (a?.revenueShare?.toString() === "" || a?.revenueShare === null) {
          toast(`Revenue Share Required`, ToastTypes.ERROR);
          error = true;
          break;
        }
      }
    }

    if (
      data?.rentType?.toString() === "3" ||
      data?.rentType?.toString() === "6" ||
      data?.rentType?.toString() === "9"
    ) {
      const tempLeaseRow = data?.leaseMultiRows;
      for (let a of tempLeaseRow) {
        if (a?.revenueShare?.toString() === "" || a?.revenueShare === null) {
          toast(`Revenue Share Required`, ToastTypes.ERROR);
          error = true;
          break;
        } else if (a?.rangeFrom?.toString() === "" || a?.rangeFrom === null) {
          toast(`Range From Required`, ToastTypes.ERROR);
          error = true;
          break;
        } else if (a?.rangeTo?.toString() === "" || a?.rangeTo === null) {
          toast(`Range To Required`, ToastTypes.ERROR);
          error = true;
          break;
        }
      }
    }

    if (
      data?.rentType?.toString() === "4" ||
      data?.rentType?.toString() === "7" ||
      data?.rentType?.toString() === "10"
    ) {
      const tempLeaseRow = data?.leaseMultiRows;
      for (let a of tempLeaseRow) {
        if (a?.itemCate?.toString() === "" || a?.itemCate === null) {
          toast(`Item Category Required`, ToastTypes.ERROR);
          error = true;
          break;
        } else if (
          a?.revenueShare?.toString() === "" ||
          a?.revenueShare === null
        ) {
          toast(`Revenue Share Required`, ToastTypes.ERROR);
          error = true;
          break;
        }
      }
    }

    // console.log(data2,'hella')

    if (!error) {
      if (!leaseArray?.includes(data.leaseId)) {
        if (data) {
          setTenantLeaseInfo!((prev) => (prev ? [...prev, data] : [data]));
          setTenantLeaseDetails!((prev) => (prev ? [...prev, data] : [data]));
          setTenantLeaseInfo2!((prev) => (prev ? [...prev, data2] : [data2]));
          setTenantLeaseDetails2!((prev) =>
            prev ? [...prev, data2] : [data2]
          );
          setTenantManager2!((prev) => (prev ? [...prev, data3] : [data3]))
          setTenantManagerDetails2!((prev)=>(prev ? [...prev, data3] : [data3]))
          // setLeaseId!('')
          setRentType!("");
          setFromDate!("");
          setToDate!("");
          setRentBasis!("");
          setRentBasisRate!("");
          setBillingCycle!("");
          setRentEscPer!("");
          setLeaseId!("");
          setUnitId!("");
          setUnits([]);
          setCarpetArea!("");
          setChargeableArea!("");
          // setCarpetArea!('')
          // setChargeableArea!('')
          setLeaseRows([
            {
              id: Date.now(),
              itemCate: "",
              rangeFrom: "",
              rangeTo: "",
              revenueShare: "",
            },
          ]);
          setBaseRent!("");
        }
      } else setLeaseIdError!("Lease Details already exists");
    }
  };

  const addRow = () => {
    setLeaseRows([
      ...leaseRows,
      {
        id: Date.now(),
        itemCate: "",
        rangeFrom: "",
        rangeTo: "",
        revenueShare: "",
      },
    ]);
  };

  const removeRow = (id: number) => {
    setLeaseRows(leaseRows.filter((row) => row.id !== id));
  };

  //   const handleRemoveItem = (value: TenantLeaseDetails) => {

  //     setTenantLeaseInfo!(prev => {
  //       return prev.filter(p => p.leaseId !== value.leaseId)
  //   })
  //     setTenantLeaseDetails!(prev => {
  //         return prev.filter(p => p.leaseId !== value.leaseId)
  //     })
  // }

  const filteredSite = siteList.filter(
    (a) => a?.id?.toString() === centerId?.toString()
  );

  const siteRentStructure = UOM.filter(
    (a) => a?.text?.toString() === filteredSite[0]?.uom?.toString()
  );

  const dynamicRentStructure =
    siteRentStructure?.length > 0
      ? RENT_STRUCTURE.filter(
          (a) =>
            a?.value?.toString() === siteRentStructure[0]?.value?.toString() ||
            a?.value?.toString() === "3"
        )
      : RENT_STRUCTURE;

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  const [deleteData, setDeleteData] = useState<TenantLeaseDetails>();
  const [editData, setEditData] = useState<TenantLeaseDetails2[]>([]);
  const [editData2, setEditData2] = useState<TenantManager2[]>([]);
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);

  const [id, setId] = useState<number>();

  const getTableData = () => {
    if (
      tenantLeaseDetails?.length === 0 ||
      tenantLeaseDetails === undefined ||
      tenantLeaseDetails2?.length === 0
    ) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Lease Id", children: "Lease Id", text: "Lease Id" },
      { id: "Building", children: "Building", text: "Building" },
      { id: "Floor", children: "Floor", text: "Floor" },
      { id: "Unit", children: "Unit", text: "Unit" },
      { id: "Rent Type", children: "Rent Type", text: "Rent Type" },
      {
        id: "Lease Duration",
        children: "Lease Duration",
        text: "Lease Duration",
      },
      { id: "Rent Basis", children: "Rent Basis", text: "Rent Basis" },
      { id: "Billing Cycle", children: "Billing Cycle", text: "Billing Cycle" },
      { id: "Area", children: "Area", text: "Area" },
      { id: "Lease Info", children: "Lease Info", text: "Lease Info" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];

    tenantLeaseDetails.forEach((item, i) => {
      var tBody: Tbody[] = [];
      const pageNumber = Math.floor(offset / limit) + 1;

      let renttypIndex = item.rentType
        ? rentList.findIndex((rt) => rt.id === Number(item.rentType))
        : 0;
      let renttyp =
        renttypIndex > 0 ? rentList[renttypIndex].renttype : item.rentType;

      const filteredLease2 = tenantLeaseDetails2?.filter(
        (a) =>
          a?.leasestart?.toString() === item?.leaseStart?.toString() &&
          a?.leaseend?.toString() === item?.leaseEnd?.toString() &&
          a?.renttype?.toString() === item?.rentType?.toString()
      );

      let floorName =
        FloorList?.filter(
          (a) => a?.id?.toString() === filteredLease2[0]?.floorid?.toString()
        )[0]?.floorname ?? "";
      let buildingName =
        buildingList?.filter(
          (a) => a?.id?.toString() === filteredLease2[0]?.buildingid?.toString()
        )[0]?.buildingname ?? "";

      let filteredUnit = filteredLease2[0]?.unitid?.split(",");
      let filteredUnitName = filteredUnit?.map((a) => {
        const matchedUnit = unitList?.find(
          (b) => a?.toString() === b?.id?.toString()
        );
        return matchedUnit?.unitno;
      });
      let unitNumbers = filteredUnitName?.filter(
        (unitno) => unitno !== undefined
      );
      let unitNumbersWithCommas = unitNumbers?.join(",")?.toString();

      // console.log(unitNumbersWithCommas,'LOLA');

      // console.log(filteredLease2,filteredUnit,'lola')

      tBody.push(
        {
          id: nanoid(),
          theadId: "S.No",
          children: limit * pageNumber + i - limit + 1,
          text: (limit * pageNumber + i - limit + 1)?.toString(),
        },
        {
          id: nanoid(),
          theadId: "Lease Id",
          children: item.leaseId,
          text: item.leaseId,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Building",
          children: buildingName,
          text: buildingName,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Floor",
          children: floorName,
          text: floorName,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Unit",
          children: unitNumbersWithCommas,
          text: unitNumbersWithCommas,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Rent Type",
          children: renttyp,
          text: renttyp,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Lease Duration",
          children: (
            <>
              <span>{item?.leaseStart}</span>
              <br />
              <span
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingRight: "13px",
                }}
              >
                to
              </span>
              <span>{item?.leaseEnd}</span>
            </>
          ),
          text: `${item.leaseStart} to ${item.leaseEnd}`,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Rent Basis",
          children: (
            <>
              <span>
                Rent Based On:{" "}
                {item?.rentBasis !== undefined &&
                item?.rentBasis !== "null" &&
                item?.rentBasis !== ""
                  ? item?.rentBasis
                  : "-"}
              </span>
              <br />
              <span>
                Rate Based On:{" "}
                {item?.rentBasisRate !== undefined &&
                item?.rentBasisRate !== "null" &&
                item?.rentBasisRate !== ""
                  ? item?.rentBasisRate
                  : "-"}
              </span>
            </>
          ),
          text: `Rent Based On: ${
            item?.rentBasis !== undefined &&
            item?.rentBasis !== "null" &&
            item?.rentBasis !== ""
              ? item?.rentBasis
              : "-"
          }\nRate Based On: ${
            item?.rentBasisRate !== undefined &&
            item?.rentBasisRate !== "null" &&
            item?.rentBasisRate !== ""
              ? item?.rentBasisRate
              : "-"
          }`,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Billing Cycle",
          children: item.billingCycle,
          text: item.billingCycle,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Area",
          children: (
            <>
              <span>
                Carpet Area:{" "}
                {item?.carpetArea !== undefined &&
                item?.carpetArea !== "null" &&
                item?.carpetArea !== ""
                  ? item?.carpetArea
                  : "-"}
              </span>
              <br />
              <span>
                Chargeable Area:{" "}
                {item?.chargeableArea !== undefined &&
                item?.chargeableArea !== "null" &&
                item?.chargeableArea !== ""
                  ? item?.chargeableArea
                  : "-"}
              </span>
            </>
          ),
          text: `Carpet Area: ${
            item?.carpetArea !== undefined &&
            item?.carpetArea !== "null" &&
            item?.carpetArea !== ""
              ? item?.carpetArea
              : "-"
          }\nChargeable Area: ${
            item?.chargeableArea !== undefined &&
            item?.chargeableArea !== "null" &&
            item?.chargeableArea !== ""
              ? item?.chargeableArea
              : "-"
          }`,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Lease Info",
          children: (
            <>
              {item.rentBaseAmt &&
              item.rentBaseAmt !== null &&
              item?.rentBaseAmt !== undefined ? (
                <span>Base Rent: {item.rentBaseAmt}</span>
              ) : (
                ""
              )}
              {item?.leaseMultiRows?.map((lr, j) => {
                let itemcatIndex =
                  lr.itemCate != "" &&
                  lr?.itemCate !== null &&
                  lr?.itemCate !== undefined
                    ? ItemCategoryList.findIndex(
                        (ic) => ic.catid === Number(lr.itemCate)
                      )
                    : 0;
                let itemcatname =
                  itemcatIndex > 0
                    ? ItemCategoryList[itemcatIndex].category
                    : lr.itemCate;
                return (
                  <p>
                    {lr.itemCate != "" &&
                    lr?.itemCate !== null &&
                    lr?.itemCate !== undefined ? (
                      <>
                        <span>Item Category: {itemcatname}</span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {lr.rangeFrom != "" &&
                    lr?.rangeFrom !== null &&
                    lr?.rangeFrom !== undefined ? (
                      <>
                        <span>Range From: {lr.rangeFrom}</span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {lr.rangeTo != "" &&
                    lr?.rangeTo !== null &&
                    lr?.rangeTo !== undefined ? (
                      <>
                        <span>Range To: {lr.rangeTo}</span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {lr.revenueShare != "" &&
                    lr?.revenueShare !== null &&
                    lr?.revenueShare !== undefined ? (
                      <span>Revenue Share: {lr.revenueShare}</span>
                    ) : (
                      ""
                    )}
                  </p>
                );
              })}
            </>
          ),
          text: `Base Rent: ${
            item?.rentBaseAmt !== undefined &&
            item?.rentBaseAmt !== null &&
            item?.rentBaseAmt !== ""
              ? item?.rentBaseAmt
              : "-"
          }\n${
            item?.leaseMultiRows?.length > 0
              ? item?.leaseMultiRows?.map(
                  (a) =>
                    `${
                      a?.itemCate !== "" && a?.itemCate !== null
                        ? `Item Category: ${a?.itemCate}`
                        : ""
                    }\n${
                      a?.rangeFrom !== "" && a?.rangeFrom !== null
                        ? `Range From: ${a?.rangeFrom}`
                        : ""
                    }\n${
                      a?.rangeTo !== "" && a?.rangeTo !== null
                        ? `Range To: ${a?.rangeTo}`
                        : ""
                    }\n${
                      a?.revenueShare !== "" && a?.revenueShare !== null
                        ? `Revenue Share: ${a?.revenueShare}`
                        : ""
                    }\n`
                )
              : ""
          }`,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Action",
          children: "action",
          className: "d-none",
          text: i?.toString(),
        }
      );

      tableBody.push(tBody);
      // })
    });

    return { tbody: tableBody, thead: tableHead };

    // if (searchTerm?.length > 0) {
    //   tableBodyFiltered = tableBody.filter((item) =>
    //     searchTenantCatHandler(item, searchTerm)
    //   );
    // } else {
    //   tableBodyFiltered = tableBody;
    // }

    // if (tenCatData !== undefined) {
    //   return { tbody: tableBodyFiltered, thead: tableHead };
    // }
  };

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData([]);
    setEditData2([])
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
    setUnitNameList('')
    setUnitNoList('')
  };

  const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);

    if (
      id !== undefined &&
      tenantLeaseDetails !== undefined &&
      tenantLeaseDetails?.length > 0
    ) {
      var obj = tenantLeaseDetails.find(
        (a, i) => i?.toString() === id?.toString()
      );
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (id !== undefined && deleteData !== undefined) {
      // setBrandContactDetails!(prev => {
      //     return prev.filter((p,i) => i?.toString() !== id?.toString())
      // })

      // console.log(id,'hell')
      // return

      setTenantLeaseInfo!((prev) => {
        return prev.filter((p, i) => i?.toString() !== id?.toString());
      });

      setTenantLeaseInfo2!((prev) => {
        return prev.filter((p, i) => i?.toString() !== id?.toString());
      });

      setTenantLeaseDetails!((prev) => {
        return prev.filter((p, i) => i?.toString() !== id?.toString());
      });

      setTenantLeaseDetails2!((prev) => {
        return prev.filter((p, i) => i?.toString() !== id?.toString());
      });

      handleFormClose();
    }
  };

  useEffect(() => {
    if (tenantLeaseDetails !== undefined && tenantLeaseDetails?.length > 0) {
      setLength(tenantLeaseDetails?.length);
    } else {
      setLength(0);
    }
  }, [tenantLeaseDetails]);

  useEffect(() => {
    // if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, formType]);

  const handleUpdate = () => {
    let error = false;
    const data: TenantLeaseDetails = {
      leaseId: leaseid ? leaseid : "",
      rentType: rentType ? rentType : "",
      leaseStart: fromDate ? fromDate : "",
      leaseEnd: toDate ? toDate : "",
      rentBasis: rentBasis
        ? RENT_STRUCTURE.filter(
            (a) => a?.value?.toString() === rentBasis?.toString()
          )[0].text?.toString()
        : "",
      rentBasisRate: rentBasisRate ? rentBasisRate : "",
      rentBaseAmt: baseRent ? baseRent : "",
      billingCycle: billingCycle ? billingCycle : "",
      rentEscPer: rentEscPer ? rentEscPer : "",
      carpetArea: carpetArea ? carpetArea : "",
      chargeableArea: chargeableArea ? chargeableArea : "",
      /*itemCate: itemCate ? itemCate : '',
        rangeFrom: rangeFrom ? rangeFrom : '',
        reangeTo: rangeTo ? rangeTo : '',
        revenueShare: revenueShare ? revenueShare : '',*/
      leaseMultiRows: leaseRows,
    };
    const data2: TenantLeaseDetails2 = {
      centerid: Number(centerId),
      buildingid: Number(buildingTerminal),
      floorid: Number(location),
      unitno: unitNoList,
      area: data.rentBasis,
      tenantcategory: Number(tenantCategory),
      renttype: Number(data.rentType),
      currenttenant: Number(tenantId),
      leasestart: data.leaseStart,
      leaseend: data.leaseEnd,
      occupancystatus: "",
      unitid: unitId !== "" ? unitId : "",
    };
     const data3: TenantManager2 = {
      floorid: Number(location),
      landmark: landmark??'',
      buildingid: Number(buildingTerminal),
      carpetarea:carpetArea??'',
      chargeablearea: chargeableArea??'',
      unitname: unitNameList,
      paxtype: paxType??'',
      leaseid: leaseid??'',
      unitid:unitId??''
    };
    // let leaseArray = tenantLeaseDetails?.map(t => t.leaseId)
    // console.log("leaseArray",leaseArray)
    if (data.leaseId === "") {
      setLeaseIdError!("Lease Id Required");
      error = true;
    }
    /*else if(!leaseArray?.includes(data.leaseId)) {
          setLeaseIdError!('LeaseId required')
          error = true
      }*/
    if (data.rentType === "") {
      setRentTypeError!("Rent Type Required");
      error = true;
    }
    if (data.leaseStart === "") {
      setFromDateError!("Lease Start Date Required");
      error = true;
    }
    if (data.leaseEnd === "") {
      setToDateError!("Lease End Date Required");
      error = true;
    }
    const todateobj = toDate ? new Date(toDate) : new Date();
    const fromdateObj = fromDate ? new Date(fromDate) : new Date();
    if (fromDate != "" && toDate != "" && todateobj <= fromdateObj) {
      setToDateError!("End date must be a future date of Start date.");
      error = true;
    }
    if (data.rentBasis === "") {
      setRentBasisError!("Rent Basis-Rent Required");
      error = true;
    }
    if (data.rentBasisRate === "") {
      setRentBasisRateError!("Rent Basis-Rate Required");
      error = true;
    }
    if (data.billingCycle === "") {
      setBillingCycleError!("Billing Cycle Required");
      error = true;
    }
    if (data.carpetArea === "") {
      setCarpetAreaError!("Carpet Area Required");
      error = true;
    }
    if (data.chargeableArea === "") {
      setLeasableAreaError!("Chargeable Area Required");
      error = true;
    }
    if (units?.length === 0) {
      setUnitIdError!("Unit No Required");
      error = true;
    }
    if (data2.unitid === "") {
      setUnitIdError!("Unit No Required");
      error = true;
    }

    // console.log(leaseRows,'Hell')

    const tempLeaseList = tenantLeaseDetails?.filter(
      (a, i) => i?.toString() !== id?.toString()
    );

    if (tempLeaseList !== undefined && tempLeaseList?.length > 0) {
      let nameCount = tempLeaseList?.filter(
        (a) => a?.leaseId?.toString() === data?.leaseId?.toString()
      );
      if (nameCount?.length > 0) {
        setLeaseIdError!("Lease Id Already Exists");
        error = true;
      }
    }

    if (tempLeaseList !== undefined && tempLeaseList?.length > 0) {
      const existingdateRange: dateranges[] = [];
      tempLeaseList.forEach((a) => {
        let dates = {
          fromdate: a?.leaseStart ?? "",
          todate: a?.leaseEnd ?? "",
        };
        existingdateRange.push(dates);
      });
      const res = checkDateRangeOverlap(
        existingdateRange,
        data?.leaseStart?.toString(),
        data?.leaseEnd?.toString()
      );
      if (res?.status === true) {
        toast(
          `Lease Already Exists for the period ${res?.ExistingFrom} - ${res?.ExistingTo}`,
          ToastTypes.ERROR
        );
        error = true;
      }
    }

    if (
      data?.rentType?.toString() === "2" ||
      data?.rentType?.toString() === "5" ||
      data?.rentType?.toString() === "8"
    ) {
      const tempLeaseRow = data?.leaseMultiRows;
      for (let a of tempLeaseRow) {
        if (a?.revenueShare?.toString() === "" || a?.revenueShare === null) {
          toast(`Revenue Share Required`, ToastTypes.ERROR);
          error = true;
          break;
        }
      }
    }

    if (
      data?.rentType?.toString() === "3" ||
      data?.rentType?.toString() === "6" ||
      data?.rentType?.toString() === "9"
    ) {
      const tempLeaseRow = data?.leaseMultiRows;
      for (let a of tempLeaseRow) {
        if (a?.revenueShare?.toString() === "" || a?.revenueShare === null) {
          toast(`Revenue Share Required`, ToastTypes.ERROR);
          error = true;
          break;
        } else if (a?.rangeFrom?.toString() === "" || a?.rangeFrom === null) {
          toast(`Range From Required`, ToastTypes.ERROR);
          error = true;
          break;
        } else if (a?.rangeTo?.toString() === "" || a?.rangeTo === null) {
          toast(`Range To Required`, ToastTypes.ERROR);
          error = true;
          break;
        }
      }
    }

    if (
      data?.rentType?.toString() === "4" ||
      data?.rentType?.toString() === "7" ||
      data?.rentType?.toString() === "10"
    ) {
      const tempLeaseRow = data?.leaseMultiRows;
      for (let a of tempLeaseRow) {
        if (a?.itemCate?.toString() === "" || a?.itemCate === null) {
          toast(`Item Category Required`, ToastTypes.ERROR);
          error = true;
          break;
        } else if (
          a?.revenueShare?.toString() === "" ||
          a?.revenueShare === null
        ) {
          toast(`Revenue Share Required`, ToastTypes.ERROR);
          error = true;
          break;
        }
      }
    }

    //  console.log(data,'hella')

    //
    // console.log(leaseRows,'ROWS')
    if (error === false) {
      const updatedLeaseList = tenantLeaseDetails?.filter(
        (a, i) => i?.toString() !== id?.toString()
      );
      const updatedLeaseList2 = tenantLeaseDetails2?.filter(
        (a) => a?.id?.toString() !== editData[0]?.id?.toString()
      );

      const updatedLeaseList3 = tenantManagerDetails2?.filter((a)=>a?.leaseid?.toString()!==editData2[0]?.leaseid?.toString())

      //   console.log(tenantLeaseDetails2,'hecka2')
      //  console.log(updatedLeaseList2,'hecka')
      //  console.log(data2,data,'hecka3')
      //  console.log(editData,'hecka4')

      setTenantLeaseDetails!([...updatedLeaseList!, data]);
      setTenantLeaseInfo!([...updatedLeaseList!, data]);
      setTenantLeaseDetails2!([...updatedLeaseList2!, data2]);
      setTenantLeaseInfo2!([...updatedLeaseList2!, data2]);
      setTenantManager2!([...updatedLeaseList3,data3])
      setTenantManagerDetails2!([...updatedLeaseList3,data3])
    }

    if (error === false) {
      handleCancel();
      setEditData([]);
      setEditData2([])
    }
  };

  const handleCancel = () => {
    setFormType(FormTypeList.ADD);
    setBillingCycle!("");
    setBillingCycleError!("");
    setChargeableArea!("");
    setLeasableAreaError!("");
    setCarpetAreaError!("");
    setCarpetArea!("");
    setLeaseId!("");
    setLeaseIdError!("");
    setFromDate!("");
    setFromDateError!("");
    setToDate!("");
    setToDateError!("");
    setRentBasis!("");
    setRentBasisError!("");
    setRentBasisRate!("");
    setRentBasisRate!("");
    setBaseRent!("");
    setBaseRentError!("");
    setRentEscPer!("");
    setRentEscPerError!("");
    setRentType!("");
    setRentTypeError!("");
    setUnits([]);
    setUnitId!("");
    setUnitIdError!("");
    setUnitNoList("");
     setUnitNameList('')
    setUnitNoList('')
    setBuildingTerminal!('')
    setLocation!('')
    setLandmark!('')
    setPaxType!(PaxTypeDropDown.Arrival)
    setLeaseRows!([
      {
        id: Date.now(),
        itemCate: "",
        rangeFrom: "",
        rangeTo: "",
        revenueShare: "",
      },
    ]);
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (
      id !== undefined &&
      tenantLeaseDetails !== undefined &&
      tenantLeaseDetails?.length > 0
    ) {
      var obj = tenantLeaseDetails.find(
        (a, i) => i?.toString() === id?.toString()
      );
      setBillingCycle!(obj?.billingCycle ? obj?.billingCycle : "");
      setChargeableArea!(obj?.chargeableArea ? obj?.chargeableArea : "");
      setCarpetArea!(obj?.carpetArea ? obj?.carpetArea : "");
      setLeaseId!(obj?.leaseId ? obj?.leaseId : "");
      setFromDate!(obj?.leaseStart ? obj?.leaseStart : "");
      setToDate!(obj?.leaseEnd ? obj?.leaseEnd : "");
      setRentBasis!(
        obj?.rentBasis
          ? RENT_STRUCTURE?.filter(
              (a) => a?.text?.toString() === obj?.rentBasis?.toString()
            )[0]?.value?.toString()
          : ""
      );
      setRentBasisRate!(obj?.rentBasisRate ? obj?.rentBasisRate : "");
      setBaseRent!(obj?.rentBaseAmt ? obj?.rentBaseAmt : "");
      setRentEscPer!(obj?.rentEscPer ? obj?.rentEscPer : "");
      setRentType!(obj?.rentType ? obj?.rentType : "");

      const filteredLease2 = tenantLeaseDetails2?.filter(
        (a) =>
          a?.leasestart?.toString() === obj?.leaseStart?.toString() &&
          a?.leaseend?.toString() === obj?.leaseEnd?.toString() &&
          a?.renttype?.toString() === obj?.rentType?.toString()
      );
      const filteredUnitArray = filteredLease2[0]?.unitid?.split(",");

      let unitEditData = filteredUnitArray
        ?.map((pe) => {
          return unitList.filter((f) => f?.id?.toString() === pe?.toString());
        })
        .flat(1)
        .map((b) => {
          return {
            value: b?.id ? b.id : 0,
            label: b.unitno,
            imgUrl: "",
          };
        });

      setEditData(filteredLease2);
      setUnits(
        unitEditData !== undefined && unitEditData?.length > 0
          ? unitEditData
          : []
      );
      setBuildingTerminal!(filteredLease2[0]?.buildingid?.toString() ?? "");
      setLocation!(filteredLease2[0]?.floorid?.toString() ?? "");
      setLeaseRows!(
        obj?.leaseMultiRows !== undefined && obj?.leaseMultiRows?.length > 0
          ? obj?.leaseMultiRows
          : [
              {
                id: Date.now(),
                itemCate: "",
                rangeFrom: "",
                rangeTo: "",
                revenueShare: "",
              },
            ]
      );

      const requireTenantLease = tenantManagerDetails2?.filter((a)=>a?.leaseid?.toString()===obj?.leaseId?.toString())
      setEditData2(requireTenantLease)
      
      if(requireTenantLease!==undefined&&requireTenantLease[0]?.paxtype!==undefined && requireTenantLease[0]?.paxtype!==''){
      let type1 = requireTenantLease[0]?.paxtype;
      if (type1 === PaxTypeDropDown.Arrival) {
        setPaxType!(PaxTypeDropDown.Arrival);
      } else if (type1 === PaxTypeDropDown.Departures) {
          setPaxType!(PaxTypeDropDown.Departures);
      }else if (type1 === PaxTypeDropDown.Others) {
          setPaxType!(PaxTypeDropDown.Others);
      }

      setLandmark!(requireTenantLease[0]?.landmark??'')

    }


    }
  };

  const selectedUnit = useMemo<number[]>(() => {
    return units.map((p) => {
      return p.value;
    });
  }, [units]);

  useEffect(() => {
    if (selectedUnit?.length > 0) {
      setUnitId!(selectedUnit?.toString());
      setUnitIdError!("");
    }
  }, [units, selectedUnit, unitList, buildingTerminal, location]);

  useEffect(() => {
    if (selectedUnit?.length > 0 && unitId !== "") {
      let filteredUnit = unitId?.split(",");
      let filteredUnitNo = filteredUnit?.map((a) => {
        const matchedUnit = unitList?.find(
          (b) => a?.toString() === b?.id?.toString()
        );
        return matchedUnit?.unitno;
      });

         let filteredUnitName = filteredUnit?.map((a) => {
        const matchedUnit = unitList?.find(
          (b) => a?.toString() === b?.id?.toString()
        );
        return matchedUnit?.unitname;
      });

      let unitNumbers = filteredUnitNo?.filter(
        (unitno) => unitno !== undefined
      );
      let unitNumbersWithCommas = unitNumbers?.join(",")?.toString() ?? "";

      let unitNames = filteredUnitName?.filter(
        (unitname) => unitname !== undefined
      );
      let unitNamesWithCommas = unitNames?.join(",")?.toString() ?? "";

      setUnitNoList(unitNumbersWithCommas);
      setUnitNameList(unitNamesWithCommas)
    }
  }, [unitId, unitList, selectedUnit]);

  // useEffect(()=>{
  //     console.log(tenantLeaseDetails2,'mate1')
  //     console.log(tenantLeaseInfo2,'mate2')
  // },[tenantLeaseInfo2,tenantLeaseDetails2])

  // console.log(unitId,'vice2')

  // console.log(tenantManager2,'hilla')

  return (
    <div className="container">
      <h4>Tenant Lease Information</h4>
      <hr />
      <div className="row">
        {/* <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Select a center"
            label="Center"
            labelClassName="required"
            value={centerId}
            onChange={handleCenterId}
            placeholder="Center"
            errorText={centerIdError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {siteList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.name}
                </Option>
              );
            })}
          </FormInput>
        </div> */}

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Building/Terminal"
            label="Building/Terminal"
            labelClassName="required"
            value={buildingTerminal}
            onChange={handleBuildingTerminal}
            placeholder="Building/Terminal"
            errorText={buildingTerminalError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {filteredBuildings.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.buildingname}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Location"
            label="Location"
            labelClassName="required"
            value={location}
            onChange={handleLocation}
            placeholder="Location"
            errorText={locationError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {filteredFloors.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.floorname}
                </Option>
              );
            })}
          </FormInput>
        </div>

        {/* 
          <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Unit No"
            label="Unit No"
            labelClassName="required"
            value={unitId}
            onChange={handleUnitId}
            placeholder="Unit No"
            errorText={unitIdError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {filteredUnitList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.unitno}
                </Option>
              );
            })}
          </FormInput>
        </div> */}

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="PAX Area"
            label="PAX Area"
            labelClassName="required"
            value={paxType}
            onChange={handlePaxType}
            placeholder="PAX Area"
            errorText={paxTypeError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {PAXTYPE_DROPS.map((ud, i) => {
              return (
                <Option value={ud.value} key={i}>
                  {ud.text}
                </Option>
              );
            })}
          </FormInput>
        </div>

        {/* <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Unit Name"
            label="Unit Name"
            labelClassName="required"
            value={unitName}
            onChange={handleUnitName}
            placeholder="Unit Name"
            errorText={unitNameError}
            containerClass="mb-2"
          />
        </div> */}
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Landmark"
            label="Landmark"
            labelClassName="required"
            value={landmark}
            onChange={handleLandmark}
            placeholder="Landmark"
            errorText={landmarkError}
            containerClass="mb-2"
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Lease Id"
            label="Lease Id"
            labelClassName="required"
            value={leaseid}
            onChange={handleLeaseId}
            placeholder="Lease Id"
            errorText={leaseIdError}
            containerClass="mb-2"
            type="text"
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Rent Type"
            label="Rent Type"
            labelClassName=""
            value={rentType}
            onChange={handleRentType}
            placeholder="Rent Type"
            errorText={rentTypeError}
            containerClass="mb-2"
            // disabled={showViewForm }
            type="select"
          >
            <option value="">Select Rent Type</option>
            {rentList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a?.renttype}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Lease Start Date"
            label="Lease Start Date"
            labelClassName=""
            value={fromDate}
            max={maxFromDate}
            onChange={handleFromDate}
            placeholder="Lease Start Date"
            errorText={fromDateError}
            containerClass="mb-2"
            type="date"
            // disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Lease End Date"
            label="Lease End Date"
            labelClassName=""
            value={toDate}
            max={getRevenueMaxDate()}
            onChange={handleToDate}
            placeholder="Lease End Date"
            errorText={toDateError}
            containerClass="mb-2"
            type="date"
            // disabled={showViewForm}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3 mb-2 tenant_multisel">
          {/* <FormInput
            name="Unit No"
            label="Unit No"
            labelClassName="required"
            value={unitId}
            onChange={handleUnitId}
            placeholder="Unit No"
            errorText={unitIdError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {filteredUnitList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.unitno}
                </Option>
              );
            })}
          </FormInput> */}

          <MultiSelect
            options={filteredUnitList.map((b) => {
              return {
                value: b.id ? b.id : 0,
                label: b?.unitno,
                imgUrl: "",
              };
            })}
            label="Unit No"
            selectedOptions={units}
            setSelectedOptions={setUnits}
            placeHolder="Select Unit No"
            filterText=""
            errorText={unitIdError}
            disabled={showViewForm}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Rent Basis"
            label="Rent Basis"
            labelClassName="required"
            value={rentBasis}
            onChange={handleRentBasis}
            placeholder="Rent Basis"
            errorText={rentBasisError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select Rent Based On</option>
            {dynamicRentStructure.map((ud, i) => {
              return (
                <Option value={ud.value} key={i}>
                  {ud.text}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div
          className="col-sm-6 col-md-4 col-lg-3"
          style={{ paddingTop: "8px" }}
        >
          <FormInput
            name=" "
            label=" "
            labelClassName="required"
            value={rentBasisRate}
            onChange={handleRentBasisRate}
            placeholder="Rate Based On"
            errorText={rentBasisRateError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select Rate Based On</option>
            {RATE_STRUCTURE.map((ud, i) => {
              return (
                <Option value={ud.value} key={i}>
                  {ud.text}
                </Option>
              );
            })}
          </FormInput>
        </div>
        {rentType != "2" && rentType != "3" && rentType != "4" && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Rent Base Amount"
              label="Rent Base Amount"
              labelClassName="required"
              value={baseRent}
              onChange={handleBaseRent}
              placeholder="Rent Base Amount"
              errorText={baseRentError}
              containerClass="mb-2"
              type="text"
            />
          </div>
        )}
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Billing Cycle"
            label="Billing Cycle"
            labelClassName="required"
            value={billingCycle}
            onChange={handleBillingCycle}
            placeholder="Billing Cycle"
            errorText={billingCycleError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select Billing Cycle</option>
            {BILLING_CYCLE.map((ud, i) => {
              return (
                <Option value={ud.value} key={i}>
                  {ud.text}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Rent Escalation"
            label="Rent Escalation"
            labelClassName="required"
            value={rentEscPer}
            onChange={handleRentEscPer}
            placeholder="Rent Escalation"
            errorText={rentEscPerError}
            containerClass="mb-2"
            type="text"
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Carpet Area"
            label="Carpet Area"
            labelClassName="required"
            value={carpetArea}
            onChange={handleCarpetArea}
            placeholder="No. of Carpet Area"
            errorText={carpetAreaError}
            containerClass="mb-2"
            type="text"
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Chargeable Area"
            label="Chargeable Area"
            labelClassName="required"
            value={chargeableArea}
            onChange={handleLeasableArea}
            placeholder="No. of Chargeable Area"
            errorText={leasableAreaError}
            containerClass="mb-2"
            type="text"
          />
        </div>
        {leaseRows.map((row, index) => (
          <div
            key={row.id}
            className="row col-lg-12 m-0 p-0"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            {/* <div>Row {row.id} {row.itemCate} {row.rangeFrom} {row.revenueShare} {index}</div> */}

            {(rentType == "4" || rentType == "7" || rentType == "10") && (
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput
            name="Item Category"
            label="Item Category"
            labelClassName="required"
            // value={itemCate}
            // onChange={handleItemCate}
            value={row.itemCate}
            onChange={(e) => handleMultiInputChange(row.id, 'itemCate', e.target.value)}
            placeholder="Item Category"
            errorText={itemCateError}
            containerClass="mb-2"
            type="text"
          /> */}
                <FormInput
                  name="Item Category"
                  label="Item Category"
                  labelClassName="required"
                  value={row.itemCate}
                  onChange={(e) =>
                    handleMultiInputChange(row.id, "itemCate", e.target.value)
                  }
                  placeholder="Item Category"
                  errorText={itemCateError}
                  containerClass="mb-2"
                  disabled={showViewForm}
                  type="select"
                >
                  <option value="">Select Item Category</option>
                  {ItemCategoryList.map((a, i) => {
                    return (
                      <Option value={a.catid} key={i}>
                        {a.category}
                      </Option>
                    );
                  })}
                </FormInput>
              </div>
            )}
            {(rentType == "3" || rentType == "6" || rentType == "9") && (
              <>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <FormInput
                    name="Range From"
                    label="Range From"
                    labelClassName="required"
                    // value={rangeFrom}
                    // onChange={handleRangeFrom}
                    value={row.rangeFrom}
                    onChange={(e) =>
                      handleMultiInputChange(
                        row.id,
                        "rangeFrom",
                        e.target.value
                      )
                    }
                    placeholder="Range From"
                    errorText={rangeFromError}
                    containerClass="mb-2"
                    type="text"
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <FormInput
                    name="Range To"
                    label="Range To"
                    labelClassName="required"
                    // value={rangeTo}
                    // onChange={handleRangeTo}
                    value={row.rangeTo}
                    onChange={(e) =>
                      handleMultiInputChange(row.id, "rangeTo", e.target.value)
                    }
                    placeholder="Range To"
                    errorText={rangeToError}
                    containerClass="mb-2"
                    type="text"
                  />
                </div>
              </>
            )}
            {rentType != "1" && (
              <>
                <div
                  className={`col-sm-6 col-md-4 col-lg-3 ${
                    rentType != "" &&
                    rentType != "2" &&
                    rentType != "5" &&
                    rentType != "8"
                      ? "rs-container"
                      : ""
                  }`}
                >
                  <FormInput
                    name="Revenue Share"
                    label="Revenue Share"
                    labelClassName="required"
                    // value={revenueShare}
                    // onChange={handleRevenueShare}
                    value={row.revenueShare}
                    onChange={(e) =>
                      handleMultiInputChange(
                        row.id,
                        "revenueShare",
                        e.target.value
                      )
                    }
                    placeholder="Revenue Share"
                    errorText={revenueShareError}
                    containerClass="mb-2"
                    type="text"
                  />
                  {row === leaseRows[leaseRows.length - 1] ? (
                    <span className="plus" onClick={addRow}></span>
                  ) : (
                    <span
                      className="minus"
                      onClick={() => removeRow(row.id)}
                    ></span>
                  )}
                </div>
                {/* <div className="col-sm-6 col-md-4 col-lg-2">
        </div> */}
              </>
            )}
          </div>
        ))}
        <div className="text-end">
          {formType === FormTypeList.ADD && (
            <button
              className="btn btn-sm btn-primary fs-6"
              onClick={handleSave}
            >
              Add
            </button>
          )}
          {formType === FormTypeList.EDIT && id !== undefined && (
            <>
              <div
                className="d-flex flex-row justify-content-end align-items-center"
                style={{ paddingTop: "10px" }}
              >
                <button
                  style={{ marginRight: "10px" }}
                  className="btn btn-sm btn-primary fs-6 "
                  onClick={handleUpdate}
                >
                  Update
                </button>
                <button
                  className="btn btn-sm btn-primary fs-6"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      {tenantLeaseDetails && tenantLeaseDetails?.length > 0 && (
        <>
          <Modal
            headerText={formType + " Brand Contact"}
            visible={showDeleteConfirmation}
            onClose={handleFormClose}
            size="lg"
            centered
            style={{ background: "#00000082", zIndex: "999999" }}
          >
            <DeleteConfirmationForm
              deleteLabel={
                "Do you really want to delete the Lease - " +
                deleteData?.leaseId
              }
              onCancel={handleFormClose}
              onDelete={() => handleDelete(deleteData!)}
            />
          </Modal>

          <AdminGrid
            addBtnText=""
            //   onAdd={handleAdd}
            onEdit={handleEdit}
            onDelete={handleDeleteConfirmation}
            //   onView={handleView}
            //   search={searchHandler}
            limit={limit}
            setOffset={setOffset}
            offset={offset}
            // onPageNoChange={handleApply}
            range={range}
            pageTitle="Lease Details"
            path="/admin/tenant_manager"
            // data={data}
            setId={setId}
            {...getTableData()}
          />
        </>
      )}
    </div>
  );
};
export default LeaseInfoForm;
