import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from '../redux/store'
import Router from './Router'
import ToastProvider from './toast/ToastProvider'
import SessionManager from '../pages/SessionManager'

const App = () => {
    return <Provider store={store}>        
        <PersistGate persistor={persistor}>
            <ToastProvider>                
                    <SessionManager><Router /></SessionManager>                    
            </ToastProvider>
        </PersistGate>        
    </Provider>
}

export default App
