import {
  BrandCompanyType,
  BrandCompanyActionsList,
} from "../actions/brandCompanyActions";
import { Action } from "../actions/types";

const initialValue: Array<BrandCompanyType> = [];

export const BrandCompanyReducer = (
  state: BrandCompanyType[] = initialValue,
  action: Action
) => {
  switch (action.type) {
    case BrandCompanyActionsList.ADD_BRANDCOMPANY: {
      return [...state, action.data];
    }

    case BrandCompanyActionsList.EDIT_BRANDCOMPANY: {
      const updated = [...state].slice();
      const index = updated.findIndex((u) => u.id === action.data.id);

      if (index > -1) {
        updated[index] = {
          ...action.data,
        };
      }

      return updated;
    }

    case BrandCompanyActionsList.FETCH_BRANDCOMPANY: {
      return action.data;
    }
    case BrandCompanyActionsList.CLEAR_BRANDCOMPANY: {
      return initialValue;
    }
    case BrandCompanyActionsList.DELETE_BRANDCOMPANY: {
      return state.filter((i) => i.id !== action.data.id);
    }

    default:
      return state;
  }
};
