import { AdminActionList, AuthAdminType } from "../actions/authAdminActions"
import { Action } from "../actions/types"

type AuthAdminReducer = (state: AuthAdminType | null, action: Action) => AuthAdminType | null

const initialValue: AuthAdminType | null = null

const authAdminReducer: AuthAdminReducer = (state = initialValue, action) => {
    switch (action.type) {

        case AdminActionList.SIGNUP_ADMIN:
            return action.data

        case AdminActionList.LOGIN_ADMIN:
            return action.data

        case AdminActionList.LOGOUT_ADMIN:
            return initialValue

        default:
            return state
    }
}

export default authAdminReducer