import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import {
  ActiveDropDown,
  ActiveTypes,
  BrandCompanyType,
  addBrandCompany,
  editBrandCompany,
} from "../../redux/actions/brandCompanyActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { FormInput } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { CountryType } from "../../redux/actions/countryActions";
import { testNumber } from "../../config/functions";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

interface Props {
  formType: FormType;
  editData?: BrandCompanyType;
  onSave: () => void;
  showViewForm: boolean;
}

const BrandCompanyForm = ({
  formType,
  onSave,
  editData,
  showViewForm,
}: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const countries = useSelector<StoreState, Array<CountryType>>(
    (state) => state.country
  );

  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [urlError, setUrlError] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [streetError, setStreetError] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [cityError, setCityError] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [stateError, setStateError] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [countryError, setCountryError] = useState<string>("");
  const [pincode, setPincode] = useState<string>("");
  const [pincodeError, setPincodeError] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const [active, setActive] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [activeError, setActiveError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const resetStates = () => {
    setName("");
    setNameError("");
    setUrl("");
    setUrlError("");
    setStreet("");
    setStreetError("");
    setCity("");
    setCityError("");
    setState("");
    setStateError("");
    setCountry("");
    setCountryError("");
    setPincode("");
    setPincodeError("");
    setPhone("");
    setPhoneError("");
    setActive(ActiveDropDown.Active);
    setActiveError("");
    setLoading(false);
  };
  const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
    setNameError("");
  };

  const handleUrl: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUrl(e.target.value);
    setUrlError("");
  };

  const handleStreet: ChangeEventHandler<HTMLInputElement> = (e) => {
    setStreet(e.target.value);
    setStreetError("");
  };

  const handleCity: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCity(e.target.value);
    setCityError("");
  };

  const handleState: ChangeEventHandler<HTMLInputElement> = (e) => {
    setState(e.target.value);
    setStateError("");
  };

  const handleCountry: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCountry(e.target.value);
    setCountryError("");
  };

  const handlePincode: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;
    else if (e.target.value?.length >= 7) return;

    setPincode(e.target.value);
    setPincodeError("");
  };

  const handlePhone: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;
    else if (e.target.value?.length >= 12) return;

    setPhone(e.target.value);
    setPhoneError("");
  };

  const handleActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setActive(e.target.value as ActiveTypes);
    setActiveError("");
  };

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const data: BrandCompanyType = {
      name: name,
      url: url,
      street: street,
      city: city,
      state: state,
      country: country,
      pincode: pincode,
      phone: phone,
      active: active,
    };

    if (data.name === "") {
      setNameError("Brand Company Name required");
      error = true;
    } else if (data.name?.length > 60) {
      setNameError("Brand Company Name should be within 60 characters");
      error = true;
    }

    if (data.url === "") {
      setUrlError("Website URL required");
      error = true;
    } else if (data.url?.length > 100) {
      setUrlError("Website URL should be within 100 digits");
      error = true;
    }

    // if (data.street === '') {
    //     setStreetError('Street required')
    //     error = true
    // } else if ((data.street)?.length > 100) {
    //     setStreetError('Street should be within 100 digits')
    //     error = true
    // }

    if (data.city === "") {
      setCityError("City required");
      error = true;
    } else if (data.city?.length > 100) {
      setCityError("City should be within 100 digits");
      error = true;
    }

    // if (data.state === '') {
    //     setStateError('State required')
    //     error = true
    // } else if ((data.state)?.length > 100) {
    //     setStateError('State should be within 100 digits')
    //     error = true
    // }

    if (data.country === "") {
      setCountryError("Country required");
      error = true;
    } else if (data.country?.length > 100) {
      setCountryError("Country should be within 100 digits");
      error = true;
    }

    // if (data.pincode === '') {
    //     setPincodeError('Pincode required')
    //     error = true
    // } else if ((data.pincode)?.length > 100) {
    //     setPincodeError('Pincode should be within 100 digits')
    //     error = true
    // }

    if (data.phone && !testNumber(data.phone)) {
      setPhoneError("Phone Number must be a number");
      error = true;
    }

    if (data.pincode && !testNumber(data.pincode)) {
      setPincodeError("Pincode must be a number");
      error = true;
    }

    // if (data.phone === '') {
    //     setPhoneError('Phone required')
    //     error = true
    // } else if ((data.phone)?.length > 100) {
    //     setPhoneError('Phone should be within 100 digits')
    //     error = true
    // }

    if (data.active?.toString() === "") {
      setActiveError("Active required");
      error = true;
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addBrandCompany(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
              const logProps1 = {
                action:`Save`,
                event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
              };
              AdminLog(logProps1)
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.id !== undefined
      ) {
        setLoading(true);
        dispatch(editBrandCompany(data, editData.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      setName(editData.name);
      setUrl(editData.url);
      setStreet(editData.street);
      setCity(editData.city);
      setState(editData.state);
      setCountry(editData.country);
      setPincode(editData.pincode);
      setPhone(editData.phone);
      setActive(
        editData.active === true
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );
    } else {
      resetStates();
    }
  }, [formType, editData]);


  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Brand Company Name"
            label="Brand Company Name"
            labelClassName="required"
            value={name}
            onChange={handleName}
            placeholder="Brand Company Name"
            errorText={nameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Website URL"
            label="Website URL"
            labelClassName="required"
            value={url}
            onChange={handleUrl}
            placeholder="Website URL"
            errorText={urlError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Street"
            label="Street"
            labelClassName="required"
            value={street}
            onChange={handleStreet}
            placeholder="Street"
            errorText={streetError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="City"
            label="City"
            labelClassName="required"
            value={city}
            onChange={handleCity}
            placeholder="City"
            errorText={cityError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="State"
            label="State"
            labelClassName="required"
            value={state}
            onChange={handleState}
            placeholder="State"
            errorText={stateError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Country"
            label="Country"
            labelClassName="required"
            value={country}
            onChange={handleCountry}
            placeholder="Country"
            errorText={countryError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">select</option>
            {countries.map((country, index) => (
              <option key={index} value={country.countryname}>
                {country.countryname}
              </option>
            ))}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Pincode"
            label="Pincode"
            labelClassName="required"
            value={pincode}
            onChange={handlePincode}
            placeholder="Pincode"
            errorText={pincodeError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Phone"
            label="Phone"
            labelClassName="required"
            value={phone}
            onChange={handlePhone}
            placeholder="Phone"
            errorText={phoneError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Status"
            label="Status"
            labelClassName="required"
            value={active}
            onChange={handleActive}
            placeholder="Status"
            errorText={activeError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {ACTIVE_DROPS.map((a, i) => {
              return (
                <Option value={a.value?.toString()} key={i}>
                  {a.text}
                </Option>
              );
            })}
          </FormInput>
        </div>
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default BrandCompanyForm;
