import { nanoid } from "@reduxjs/toolkit";
import {
  ChangeEventHandler,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import CompanyForm from "../../components/admin/CompanyForm";

import {
  Tbody,
  Thead,
} from "../../components/custom/components/TradeCategoryPerformanceTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList} from "../../config";
import {
  CompanyType,
  deleteCompany,
  fetchCompany,
} from "../../redux/actions/companyActions";
import { fetchCompanyContact } from "../../redux/actions/companyContactActions";
import { AppDispatch, StoreState } from "../../redux/store";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { useLocation } from "react-router-dom";
import { AdminLog} from "../../config/common"
import { AuthAdminType } from "../../redux/actions/authAdminActions";

// interface TableBody {
//   sno: number;
//   name: string;
//   url: string;
//   logopath: string;
//   logourl: string;
//   reportingcurrency: string;
//   active: ActiveTypes | boolean;
//   onEdit: MouseEventHandler<HTMLButtonElement>;
//   onDelete: MouseEventHandler<HTMLButtonElement>;
//   onView: MouseEventHandler<HTMLButtonElement>;
//   permission: Permission;
// }

// type HandleEdit = (value: CompanyType) => void;
type HandleDelete = (value: CompanyType) => void;


const CompanyManagement = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
   const navigate = useNavigate();
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const CompanyList = useSelector<StoreState, Array<CompanyType>>(
    (state) => state.company
  );
  const PortfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );

  const [showForm, setShowForm] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [editData, setEditData] = useState<CompanyType>();
  const [deleteData, setDeleteData] = useState<CompanyType>();
  const [id, setId] = useState<number>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [disableDelete, setDisableDelete] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const [companyData, setCompanyData] = useState<CompanyType[]>([]);

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    if (CompanyList?.length > 0) {
      setLength(CompanyList?.length ? CompanyList?.length : 0);
    }
  }, [CompanyList]);

  useEffect(()=>{
     navigate("/admin/company_management");
  },[])

  const handleApply = async (offsetVal = offset) => {
    if (CompanyList?.length > 0) {
      const getData = {
        limit: limit?.toString(),
        offset: offsetVal?.toString(),
      };

      if (
        getData !== undefined &&
        getData.limit !== "" &&
        getData.offset !== "" &&
        getData.offset !== undefined &&
        getData.offset !== "NaN"
      ) {
        const filteredCompanyList: CompanyType[] = CompanyList?.slice(
          offset,
          limit + offset
        );
        if (filteredCompanyList?.length > 0) {
          setCompanyData([...filteredCompanyList]);
        } else {
          setCompanyData([]);
        }
      }
    }
  };

  useEffect(() => {
    handleApply();
  }, [CompanyList]);

  const searchCompanyHandler = (
    holdingCompany: CompanyType,
    searchTerm: string
  ) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(
          holdingCompany.name
        )
      : true || [];
  };

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery({ search: e.target.value });
  };

  const getTableData = () => {
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Name", children: "Name", text: "Name" },
      { id: "Website URL", children: "Website URL", text: "Website URL" },
      { id: "Logo Path", children: "Logo Path", text: "Logo Path" },
      { id: "Logo URL", children: "Logo URL", text: "Logo URL" },
      {
        id: "Reporting Currency",
        children: "Reporting Currency",
        text: "Reporting Currency",
      },
      { id: "Status", children: "Status", text: "Status" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];

    companyData
      ?.filter((cr) => searchCompanyHandler(cr, searchTerm))
      .forEach((item, i) => {
        const pageNumber = Math.floor(offset / limit) + 1;

        var tBody: Tbody[] = [];
        tBody.push(
          {
            id: nanoid(),
            theadId: "S.No",
            children: limit * pageNumber + i - limit + 1,
            text: (limit * pageNumber + i - limit + 1)?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Name",
            children: item.name,
            text: item.name,
          },
          {
            id: nanoid(),
            theadId: "Website URL",
            children: item.url,
            text: item.url,
          },
          {
            id: nanoid(),
            theadId: "Logo Path",
            children: item.logopath,
            text: item.logopath?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Logo URL",
            children: item.logourl,
            text: item.logourl,
          },
          {
            id: nanoid(),
            theadId: "Reporting Currency",
            children: item.reportingcurrency,
            text: item.reportingcurrency,
          },
          {
            id: nanoid(),
            theadId: "Status",
            children: item.active ? "Active" : !item.active ? "InActive" : "",
            text: item.active ? "Active" : !item.active ? "InActive" : "",
          },
          {
            id: nanoid(),
            theadId: "Action",
            children: "action",
            className: "d-none",
            text: item.id?.toString(),
          }
        );
        tableBody.push(tBody);
        // })
      });

    return { tbody: tableBody, thead: tableHead };
  };

  const handleAdd = () => {
    setShowForm(true);
    if(adminAuthendicated){
      const logProps1 = {
        action: 'Create',
        event: `${pageName.pathname.replace("\\", " ")} page | Create Company Clicked`
      };
      AdminLog(logProps1)
    }
  };

  const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = CompanyList.find((c) => c.id === id);
      setShowDeleteConfirmation(true);
      const portfolios = PortfolioList.filter((pl) => pl.companyid === id);
      setDeleteData(obj);
      setDisableDelete(portfolios?.length > 0 ? true : false);
     
    }
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = CompanyList.find((c) => c.id === id);
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
     
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page | ${obj?.name} Company Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = CompanyList.find((c) => c.id === id);
      setEditData(obj);
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | Company - ${obj?.name} | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (value.id !== undefined) {
      dispatch(deleteCompany(value.id))
        .then(async (text) => {
          dispatch(fetchCompany());
          dispatch(fetchCompanyContact());
          toast(text, ToastTypes.SUCCESS);
          var obj = CompanyList.find((c) => c.id === id);
          if(adminAuthendicated){
            const logProps1 = {
              action: 'Delete',
              event: `${pageName.pathname.replace("\\", " ")} page | Company - ${obj?.name} | Delete Clicked`
            };
            AdminLog(logProps1)
          }
          if (companyData !== undefined && companyData?.length === 1) {
            let newOffset = offset - limit < 0 ? 0 : offset - limit;
            setOffset(newOffset);
            await handleApply(newOffset);
          } else {
            await handleApply(); 
          }
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
  }, [id, formType]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " COMPANY"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            disableDelete
              ? "Company is in use. Deletion not allowed"
              : "Do you really want to delete the records with the company - " +
                deleteData?.name
          }
          onCancel={handleFormClose}
          onDelete={() => (disableDelete ? {} : handleDelete(deleteData!))}
        />
      </Modal>
      <Modal
        headerText={formType + " COMPANY"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <CompanyForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
        />
      </Modal>
      <AdminLayout>
        <AdminTable2
          addBtnText="Create New Company"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          search={searchHandler}
          limit={limit}
          setOffset={setOffset}
          offset={offset}
          onPageNoChange={handleApply}
          range={range}
          pageTitle="Company Management"
          path="/admin/company_management"
          setId={setId}
          {...getTableData()}
        />
      </AdminLayout>
    </>
  );
};

export default CompanyManagement;
