import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"

export enum CaptureModeDropDown {
    PULL = "PULL",
    PUSH = "PUSH",
    UPLOAD = "UPLOAD"
}
export type CaptureModeTypes = CaptureModeDropDown.PULL | CaptureModeDropDown.PUSH | CaptureModeDropDown.UPLOAD

export enum DataTransModeDropDown {
    API = "API",
    FILE = "FILE",
    BOTH = "BOTH"
}
export type DataTransModeTypes = DataTransModeDropDown.API | DataTransModeDropDown.FILE | DataTransModeDropDown.BOTH

export enum DataPullDropDown {
    DBFull = "DB-Full",
    DBRestricted = "DB-Restricted",
    API = "API"
}
export type DataPullTypes = DataPullDropDown.DBFull | DataPullDropDown.DBRestricted | DataPullDropDown.API

export enum FrequncyDropDown {
    DAILY = "DAILY",
    MONTHLY = "MONTHLY",
}
export type FrequncyTypes = FrequncyDropDown.DAILY | FrequncyDropDown.MONTHLY

export interface CredentialType {
    tenantid?: number
    portfolioid?: string
    brandid: number
    brandname: string
    floorid?: number
    buildingid?: number
    displayid: string
    lounge: boolean
    forex: boolean
    posterminals: string
    capturemode: string
    datatransmode: string
    datapullby: string
    dlservicename: string
    version: string
    releasedon: string
    frequency: string
    revenuedata: boolean
    active?: boolean
    status?: string
    deactivationdate?: string
    reason?: string
}

interface Activate {
    portfolioid?: string,
    active: boolean,
    status: string
}

interface DeActivate {
    portfolioid?: string,
    deactivationdate: string,
    reason: string,
    active: boolean,
    status: string
}

export enum CredentialActionsList {
    ADD_CREDENTIAL = 'ADD_CREDENTIAL',
    EDIT_CREDENTIAL = 'EDIT_CREDENTIAL',
    ACTIVATE_CREDENTIAL = 'ACTIVATE_CREDENTIAL',
    DEACTIVATE_CREDENTIAL = 'DEACTIVATE_CREDENTIAL',
    FETCH_CREDENTIAL = 'FETCH_CREDENTIAL',
    DELETE_CREDENTIAL = 'DELETE_CREDENTIAL'
}

export interface DeleteCredential {
    id: number
}

export interface AddCredentialAction {
    type: CredentialActionsList.ADD_CREDENTIAL
    data: CredentialType
}

export interface EditCredentialAction {
    type: CredentialActionsList.EDIT_CREDENTIAL
    data: CredentialType
    id?: number
}
export interface ActivateCredentialAction {
    type: CredentialActionsList.ACTIVATE_CREDENTIAL
    data: Activate
    id?: number
}
export interface DeActivateCredentialAction {
    type: CredentialActionsList.DEACTIVATE_CREDENTIAL
    data: DeActivate
    id?: number
}

export interface FetchCredentialAction {
    type: CredentialActionsList.FETCH_CREDENTIAL
    data: CredentialType[]

}

export interface DeleteCredentialAction {
    type: CredentialActionsList.DELETE_CREDENTIAL
    data: DeleteCredential
}

export type CredentialActions = AddCredentialAction | EditCredentialAction | FetchCredentialAction | DeleteCredentialAction

export const addCredential = (data: CredentialType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<CredentialType>>('credential/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddCredentialAction>({
                    type: CredentialActionsList.ADD_CREDENTIAL,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Credential added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}

export const editCredential = (data: CredentialType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<CredentialType>>(`credential/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditCredentialAction>({
                    type: CredentialActionsList.EDIT_CREDENTIAL,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Credential Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const ActivateCredential = (data: Activate, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<Activate>>(`credential/activate/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<ActivateCredentialAction>({
                    type: CredentialActionsList.ACTIVATE_CREDENTIAL,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Credential Activated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const DeActivateCredential = (data: DeActivate, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<DeActivate>>(`credential/deactivate/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<DeActivateCredentialAction>({
                    type: CredentialActionsList.DEACTIVATE_CREDENTIAL,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Credential DeActivated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchCredential = (portfolioId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<CredentialType[]>>(`credential/${portfolioId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchCredentialAction>({
                    type: CredentialActionsList.FETCH_CREDENTIAL,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteCredential = (id: number, portfolioId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<CredentialType>>(`credential/${portfolioId}/${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteCredentialAction>({
                    type: CredentialActionsList.DELETE_CREDENTIAL,
                    data: { id }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Credential Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}