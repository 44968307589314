import React, { ChangeEventHandler, useMemo } from "react";
import { useSelector } from "react-redux";
import { Permission } from "../../../config";
import { AuthAdminType } from "../../../redux/actions/authAdminActions";
import { StoreState } from "../../../redux/store";
import FormInput from "./FormInput";

export interface Thead {
  id?: React.Key;
  children?: React.ReactNode | React.ReactNode[];
  text?: string;
  style?: React.CSSProperties;
  className?: string;
}

export interface Tbody {
  id?: React.Key;
  theadId?: React.Key;
  children?: React.ReactNode | React.ReactNode[];
  text?: string;
  style?: React.CSSProperties;
  className?: string;
}

interface Props {
  data?: string[][];
  thead?: Thead[];
  tbody?: Tbody[][];
  pageTitle: string;
  path: string;
  addBtnText: string;
  onAdd?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onView?: () => void;
  setId?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setRoleId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  From?: string;
  numArrayForRole?: number[];
  onActivate?: any;
  onDeActivate?: () => void;
  search?: ChangeEventHandler<HTMLInputElement>;
  searchRef?: any;
  searchTerm?: any;
  mainContentRef?: any;
}
const AdminTable: React.FC<Props> = (props: Props) => {
  const authAdminRole = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  )?.role;

  const {
    mainContentRef,
    searchTerm,
    searchRef,
    pageTitle,
    path,
    onAdd,
    onDelete,
    onEdit,
    onView,
    addBtnText,
    setId,
    From,
    setRoleId,
    numArrayForRole,
    onActivate,
    onDeActivate,
    search,
  } = props;

  // const head = [props.thead?.map(h => h.text || '') || []]
  // const body = props.tbody?.map((tr, i) => {
  //     return props.thead?.map((th, j) => {
  //         const col = tr.find(r => r.theadId === th.id);
  //         return col?.text || ''
  //     }) || []
  // }) || []

  const Permissions = useMemo<Permission>(() => {
    let obj = authAdminRole?.find((a) => a.pageurl === path);

    let addAccess = obj?.add!;
    let editAccess = obj?.edit!;
    let viewAccess = obj?.view!;
    let deleteAccess = obj?.delete!;
    if (addAccess || editAccess || viewAccess || deleteAccess) {
      return {
        add: addAccess,
        edit: editAccess,
        view: viewAccess,
        delete: deleteAccess,
      };
    } else
      return {
        add: false,
        edit: false,
        view: false,
        delete: false,
      };
  }, [authAdminRole, path]);

  const handleView = (value: number) => {
    setId!(value);
    onView!();
  };

  const handleEdit = (value: number) => {
    setId!(value);
    onEdit!();
  };

  const handleActivate = (value: number) => {
    setId!(value);
    onActivate(value);
  };

  const handleDeActivate = (value: number) => {
    setId!(value);
    onDeActivate!();
  };

  const handleDelete = (value: number) => {
    setId!(value);
    onDelete!();
  };

  const handleRoleView = (value: string) => {
    setRoleId!(value);
    onView!();
  };

  const handleRoleEdit = (value: string) => {
    setRoleId!(value);
    onEdit!();
  };

  const handleRoleDelete = (value: string) => {
    setRoleId!(value);
    onDelete!();
  };

  return (
    <>
      {/* <div className="text-end mb-3">
                {downloadPDF && <button type="button" onClick={handleDownloadPDF} className={`m-1 btn btn-s btn-blue`}><Feather.Download /> PDF</button>}
                {downloadCSV && <CSVLink data={data} filename={fileName + ".csv"}> <button type="button" className={`m-1 btn btn-s btn-blue`}><Feather.Download /> CSV</button></CSVLink>}
            </div> */}
      <div className="card mt-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h5 className="header-title mb-3 float-start">
                <i className="fe-align-justify me-1 text-primary"></i>
                {pageTitle}
              </h5>
            </div>
            <div className="col-lg-6 col-sm-12 text-end mb-1">
              {Permissions.add && onAdd && (
                <button
                  type="button"
                  onClick={onAdd}
                  className={`me-1 btn btn-xs btn-secondary`}
                >
                  {addBtnText}
                </button>
              )}
            </div>
          </div>
          {search && (
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <FormInput
                  type="text"
                  value={searchTerm}
                  name="search"
                  placeholder="Search..."
                  className="mb-2"
                  itemRef={searchRef}
                  onChange={search}
                  onFocus={() => {
                    mainContentRef?.current?.scrollTo({
                      behavior: "smooth",
                      top: 270,
                    });
                  }}
                />
              </div>
            </div>
          )}
          <div className="table-wrapper">
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  {props.thead?.map((item, i) => {
                    return (
                      <th
                        className={`text-truncate ${item.className || ""}`}
                        key={i}
                        style={item.style}
                      >
                        {item.children}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {From === "role" ? (
                <tbody>
                  {props.tbody?.map((tr, i) => {
                    let sno = i + 1;
                    return (
                      <tr
                        className={`${
                          numArrayForRole?.includes(sno) ? "" : "d-none"
                        }`}
                        key={i}
                      >
                        {props.thead?.map((th, j) => {
                          const col = tr.find((r) => r.theadId === th.id);
                          return (
                            <>
                              <td
                                style={col?.style}
                                className={col?.className}
                                key={col?.id || j}
                              >
                                {col?.children}
                              </td>
                              {col?.children === "action" && (
                                <td>

                                  <div className="hstack gap-1">
                                    {Permissions.edit && onEdit && (
                                      <button
                                        className="btn btn-sm btn-secondary"
                                        onClick={
                                          From === "role"
                                            ? () => handleRoleEdit(col.text!)
                                            : () =>
                                                handleEdit(parseInt(col.text!))
                                        }
                                      >
                                        Edit
                                      </button>
                                    )}
                                    {Permissions.delete && onDelete && (
                                      <button
                                        className="btn btn-sm btn-secondary"
                                        onClick={
                                          From === "role"
                                            ? () => handleRoleDelete(col.text!)
                                            : () =>
                                                handleDelete(
                                                  parseInt(col.text!)
                                                )
                                        }
                                      >
                                        Delete
                                      </button>
                                    )}
                                    {Permissions.view && onView && (
                                      <button
                                        className="btn btn-sm btn-secondary"
                                        onClick={
                                          From === "role"
                                            ? () => handleRoleView(col.text!)
                                            : () =>
                                                handleView(parseInt(col.text!))
                                        }
                                      >
                                        View
                                      </button>
                                    )}
                                  </div>
                                </td>
                              )}
                            </>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  {props.tbody?.map((tr, i) => {
                    return (
                      <tr key={i}>
                        {props.thead?.map((th, j) => {
                          const col = tr.find((r) => r.theadId === th.id);
                          const status = tr.find((r) => r.theadId === "Status");
                          return (
                            <>
                              <td
                                style={col?.style}
                                className={col?.className}
                                key={col?.id || j}
                              >
                                {col?.children}
                              </td>
                              {col?.children === "action" && (
                                <td>

                                  <div className="hstack gap-1">
                                    {Permissions.edit &&
                                      onActivate &&
                                      status?.children === "Deactivated" && (
                                        <button
                                          className="btn btn-sm btn-success"
                                          onClick={() =>
                                            handleActivate(parseInt(col.text!))
                                          }
                                        >
                                          Reactivate
                                        </button>
                                      )}
                                    {Permissions.edit &&
                                      onActivate &&
                                      (status?.children === "Live" ||
                                        status?.children === "Reactivated") && (
                                        <button
                                          className="btn btn-sm btn-danger"
                                          onClick={() =>
                                            handleDeActivate(
                                              parseInt(col.text!)
                                            )
                                          }
                                        >
                                          Deactivate
                                        </button>
                                      )}
                                    {Permissions.edit && onEdit && (
                                      <button
                                        className="btn btn-sm btn-secondary"
                                        onClick={() =>
                                          handleEdit(parseInt(col.text!))
                                        }
                                      >
                                        Edit
                                      </button>
                                    )}
                                    {Permissions.delete && onDelete && (
                                      <button
                                        className="btn btn-sm btn-secondary"
                                        onClick={() =>
                                          handleDelete(parseInt(col.text!))
                                        }
                                      >
                                        Delete
                                      </button>
                                    )}
                                    {Permissions.view && onView && (
                                      <button
                                        className="btn btn-sm btn-secondary"
                                        onClick={() =>
                                          handleView(parseInt(col.text!))
                                        }
                                      >
                                        View
                                      </button>
                                    )}
                                  </div>
                                </td>
                              )}
                            </>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
          {/* <div className='table-wrapper'>
                        <table className="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    {
                                        head.at(0)?.map((col, i) => {
                                            return (
                                                <th className="text-truncate align-middle" key={i}>{col}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    body.map((row, i) => {
                                        return (
                                            <tr key={i}>
                                                {row.map((col, i) => {
                                                    return (
                                                        <td key={i}>{col}</td>
                                                    )
                                                })}
                                                <td>
                                                    <div className='hstack gap-1'>
                                                        <button className='btn btn-sm btn-secondary' onClick={onEdit}>Edit</button>
                                                        <button className='btn btn-sm btn-secondary' onClick={onDelete}>Delete</button>
                                                        <button className='btn btn-sm btn-secondary' onClick={() => handleView(row)}>View</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div> */}
        </div>
      </div>
    </>
  );
};
export default AdminTable;
