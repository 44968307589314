import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import {
  ActiveDropDown,
  ActiveTypes,
  BrandType,
} from "../../redux/actions/brandActions";
import {
  BuildingType,
  fetchBuilding,
} from "../../redux/actions/buildingActions";
import {
  CredentialType,
  addCredential,
  editCredential,
  fetchCredential,
} from "../../redux/actions/credentialActions";
import { FloorType, fetchFloor } from "../../redux/actions/floorActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { FormInput } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { testNumber } from "../../config/functions";

// const ACTIVE_DROPS = [
//   {
//     text: "Active",
//     value: true,
//   },
//   {
//     text: "InActive",
//     value: false,
//   },
// ];

const CAPTUREMODE_DROPS = [
  {
    text: "PULL",
    value: "PULL",
  },
  {
    text: "PUSH",
    value: "PUSH",
  },
  {
    text: "UPLOAD",
    value: "UPLOAD",
  },
];

const TRANSMODE_DROPS = [
  {
    text: "API",
    value: "API",
  },
  {
    text: "FILE",
    value: "FILE",
  },
  {
    text: "BOTH",
    value: "BOTH",
  },
];

const DATAPULL_DROPS = [
  {
    text: "DB-Full",
    value: "DB-Full",
  },
  {
    text: "DB-Restricted",
    value: "DB-Restricted",
  },
  {
    text: "API",
    value: "API",
  },
];

const FREQUENCY_DROPS = [
  {
    text: "DAILY",
    value: "DAILY",
  },
  {
    text: "MONTHLY",
    value: "MONTHLY",
  },
];

interface Props {
  pfId: string;
  formType: FormType;
  editData?: CredentialType;
  onSave: () => void;
  showViewForm: boolean;
}

const CredentialForm = ({
  formType,
  onSave,
  editData,
  showViewForm,
  pfId,
}: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const brandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state.brand
  );
  const [brand, setBrand] = useState<number>();
  const [brandError, setBrandError] = useState<string>("");
  const [brandName, setBrandName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [locationError, setLocationError] = useState<string>("");
  const [tenantId, setTenantId] = useState<string>("");
  const [tenantIdError, setTenantIdError] = useState<string>("");
  const [lounge, setLounge] = useState<boolean>(false);
  const [loungeError, setLoungeError] = useState<string>("");
  const [forex, setForex] = useState<boolean>(false);
  const [forexError, setForexError] = useState<string>("");
  const [POSterminals, setPOSterminals] = useState<string>("");
  const [POSterminalsError, setPOSterminalsError] = useState<string>("");
  const [captureMode, setCaptureMode] = useState<string>("");
  const [captureModeError, setCaptureModeError] = useState<string>("");
  const [dataTransMode, setDataTransMode] = useState<string>("");
  const [dataTransModeError, setDataTransModeError] = useState<string>("");
  const [dataPull, setDataPull] = useState<string>("");
  const [dataPullError, setDataPullError] = useState<string>("");
  const [DIServiceName, setDIServiceName] = useState<string>("");
  const [DIServiceNameError, setDIServiceNameError] = useState<string>("");
  const [version, setVersion] = useState<string>("");
  const [versionError, setVersionError] = useState<string>("");
  const [releasedOn, setReleasedOn] = useState<string>("");
  const [releasedOnError, setReleasedOnError] = useState<string>("");
  const [frequency, setFrequency] = useState<string>("");
  const [frequencyError, setFrequencyError] = useState<string>("");
  const [revenueData, setRevenueData] = useState<boolean>(false);
  const [revenueDataError, setRevenueDataError] = useState<string>("");
  const [status, setStatus] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [statusError, setStatusError] = useState<string>("");
  const buildingList = useSelector<StoreState, Array<BuildingType>>(
    (state) => state.building
  );
  const FloorList = useSelector<StoreState, Array<FloorType>>(
    (state) => state.floor
  );
  const [loading, setLoading] = useState<boolean>(false);

  const filteredBuildings = useMemo<BuildingType[]>(() => {
    // if (centerId) {
    //     return buildingList.filter(s => s.centerid?.toString() === centerId)
    // } else
    return buildingList;
  }, [buildingList]);

  const filteredFloors = useMemo<FloorType[]>(() => {
    // if (bui) {
    //     return FloorList.filter(s => s.buildingid?.toString() === bui)
    // } else
    return FloorList;
  }, [FloorList]);

  const resetStates = () => {
    setBrand(0);
    setBrandError("");
    setLocation("");
    setLocationError("");
    setTenantId("");
    setTenantIdError("");
    setLounge(false);
    setLoungeError("");
    setForex(false);
    setForexError("");
    setPOSterminals("");
    setPOSterminalsError("");
    setCaptureMode("");
    setCaptureModeError("");
    setDataTransMode("");
    setDataTransModeError("");
    setDataPull("");
    setDataPullError("");
    setDIServiceName("");
    setDIServiceNameError("");
    setVersion("");
    setVersionError("");
    setReleasedOn("");
    setReleasedOnError("");
    setFrequency("");
    setFrequencyError("");
    setRevenueData(false);
    setRevenueDataError("");
    setStatus(ActiveDropDown.Active);
    setStatusError("");
    setLoading(false);
  };

  const handleBrand: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBrand(parseInt(e.target.value));
    let index = brandList.findIndex((c, i) => {
      return c.id?.toString() === e.target.value;
    });
    var bcname = brandList[index]?.name;
    setBrandName(bcname);
    setBrandError("");
  };
  const handleLocationChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLocation(e.target.value);
    setLocationError("");
  };
  const handleTenantId: ChangeEventHandler<HTMLInputElement> = (e) => {
    setTenantId(e.target.value);
    setTenantIdError("");
  };
  const handleLounge: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLounge(!lounge);
    setLoungeError("");
  };
  const handleForex: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForex(!forex);
    setForexError("");
  };
  const handlePOSterminals: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;

    setPOSterminals(e.target.value);
    setPOSterminalsError("");
  };
  const handleCaptureMode: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCaptureMode(e.target.value);
    setCaptureModeError("");
  };
  const handleDataTransMode: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDataTransMode(e.target.value);
    setDataTransModeError("");
  };
  const handleDataPull: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDataPull(e.target.value);
    setDataPullError("");
  };
  const handleDIServiceName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDIServiceName(e.target.value);
    setDIServiceNameError("");
  };
  const handleVersion: ChangeEventHandler<HTMLInputElement> = (e) => {
    setVersion(e.target.value);
    setVersionError("");
  };
  const handleReleasedOn: ChangeEventHandler<HTMLInputElement> = (e) => {
    setReleasedOn(e.target.value);
    setReleasedOnError("");
  };
  const handleFrequency: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFrequency(e.target.value);
    setFrequencyError("");
  };
  const handleRevenueData: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRevenueData(!revenueData);
    setRevenueDataError("");
  };
  const handleStatus: ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatus(e.target.value as ActiveTypes);
    setStatusError("");
  };

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const [building, floor] = location.split("-");
    const data: CredentialType = {
      portfolioid: pfId,
      brandid: brand ? brand : 0,
      brandname: brandName,
      buildingid: parseInt(building),
      floorid: parseInt(floor),
      displayid: tenantId,
      lounge: lounge,
      forex: forex,
      posterminals: POSterminals,
      capturemode: captureMode,
      datatransmode: dataTransMode,
      datapullby: dataPull,
      dlservicename: DIServiceName,
      version: version,
      releasedon: releasedOn,
      frequency: frequency,
      revenuedata: revenueData,
      // status: status
    };

    if (isNaN(data.brandid)) {
      setBrandError("Brand required");
      error = true;
    } else if (data.brandid > 60) {
      setBrandError("Brand should be within 60 digits");
      error = true;
    }

    if (data.portfolioid === "") {
      toast("portfolio required", ToastTypes.ERROR);
      error = true;
    }
    if (isNaN(data.buildingid!)) {
      setLocationError("Location required");
      error = true;
    }
    if (isNaN(data.floorid!)) {
      setLocationError("Location required");
      error = true;
    }
    // if (data.status?.toString() === "") {
    //     setStatusError('Status required')
    //     error = true
    // }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addCredential(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            dispatch(fetchCredential(pfId));
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.tenantid !== undefined
      ) {
        setLoading(true);
        dispatch(editCredential(data, editData.tenantid))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            dispatch(fetchCredential(pfId));
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (pfId) {
      dispatch(fetchFloor(pfId));
      dispatch(fetchBuilding(pfId));
    }
  }, [pfId]);

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      setBrand(editData.brandid);
      setBrandName(editData.brandname);
      setLocation("" + editData.buildingid + "-" + editData.floorid + "");
      setTenantId(editData.displayid);
      setLounge(editData.lounge);
      setForex(editData.forex);
      setPOSterminals(editData.posterminals);
      setCaptureMode(editData.capturemode);
      setDataTransMode(editData.datatransmode);
      setDataPull(editData.datapullby);
      setDIServiceName(editData.dlservicename);
      setVersion(editData.version);
      setReleasedOn(editData.releasedon);
      setFrequency(editData.frequency);
      setRevenueData(editData.revenuedata);
      // setStatus(editData.status === true ? ActiveDropDown.Active : ActiveDropDown.InActive)
    } else {
      resetStates();
    }
  }, [formType, editData]);

  return (
    <div className="container">
      <div className="row">
        <div className="hstack gap-3">
          <FormInput
            name="Brand"
            label="Brand"
            labelClassName="required"
            value={brand}
            onChange={handleBrand}
            placeholder="Brand"
            errorText={brandError}
            containerClass="mb-2"
            type="select"
            disabled={showViewForm}
          >
            <option value="">Select</option>
            {brandList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.name}
                </Option>
              );
            })}
          </FormInput>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Location"
              label="Location"
              labelClassName="required"
              value={location}
              onChange={handleLocationChange}
              placeholder="Select Location"
              containerClass="mb-2"
              errorText={locationError}
              type="select"
              disabled={showViewForm}
            >
              <option value="">Select Location</option>
              {filteredBuildings.map((s, i) => {
                const floor = filteredFloors.filter(
                  (f) => f.buildingid === s.id
                );

                if (floor?.length > 0) {
                  return floor.map((bf) => {
                    return (
                      <Option
                        value={`${bf.buildingid}-${bf.id}`}
                        key={`${bf.buildingid}-${bf.id}`}
                      >
                        {s.buildingname}-{bf.floorname}
                      </Option>
                    );
                  });
                } else {
                  return (
                    <Option value={`${s.id}-0`} key={`${s.id}-0`}>
                      {s.buildingname}
                    </Option>
                  );
                }
              })}
            </FormInput>
          </div>

          <FormInput
            name="Display Id"
            label="Display Id"
            labelClassName="required"
            value={tenantId}
            onChange={handleTenantId}
            placeholder="Display Id"
            errorText={tenantIdError}
            containerClass="mb-2"
            disabled={showViewForm || editData ? true : false}
          />
        </div>
        <div>
          <div className="hstack gap-3">
            <FormInput
              name="Lounge"
              label="Lounge"
              labelClassName="required"
              value={lounge?.toString()}
              onChange={handleLounge}
              placeholder="Lounge"
              errorText={loungeError}
              containerClass="mb-2"
              type="checkbox"
              checked={lounge}
              disabled={showViewForm}
            />
            <FormInput
              name="Forex"
              label="Forex"
              labelClassName="required"
              value={forex?.toString()}
              onChange={handleForex}
              placeholder="Forex"
              errorText={forexError}
              containerClass="mb-2"
              type="checkbox"
              checked={forex}
              disabled={showViewForm}
            />
            <FormInput
              name="POS Terminals"
              label="POS Terminals"
              labelClassName="required"
              value={POSterminals}
              onChange={handlePOSterminals}
              placeholder="POS Terminals"
              errorText={POSterminalsError}
              containerClass="mb-2"
              type="text"
              disabled={showViewForm}
            />
          </div>
        </div>
        <div>
          <label htmlFor="" className="mb-2">
            Data Capture Details
          </label>
          <div className="hstack gap-3">
            <FormInput
              name="Capture Mode"
              label="Capture Mode"
              labelClassName="required"
              value={captureMode}
              onChange={handleCaptureMode}
              placeholder="Capture Mode"
              errorText={captureModeError}
              containerClass="mb-2"
              type="select"
              disabled={showViewForm}
            >
              <option value="">Select</option>
              {CAPTUREMODE_DROPS.map((a, i) => {
                return (
                  <Option value={a.value?.toString()} key={i}>
                    {a.text}
                  </Option>
                );
              })}
            </FormInput>
            {captureMode!=='UPLOAD'&&
            <>
            <FormInput
              name="Data Trans Mode"
              label="Data Trans Mode"
              labelClassName="required"
              value={dataTransMode}
              onChange={handleDataTransMode}
              placeholder="Data Trans Mode"
              errorText={dataTransModeError}
              containerClass="mb-2"
              type="select"
              disabled={showViewForm}
            >
              <option value="">Select</option>
              {TRANSMODE_DROPS.map((a, i) => {
                return (
                  <Option value={a.value?.toString()} key={i}>
                    {a.text}
                  </Option>
                );
              })}
            </FormInput>
            <FormInput
              name="Data Pull By"
              label="Data Pull By"
              labelClassName="required"
              value={dataPull}
              onChange={handleDataPull}
              placeholder="Data Pull By"
              errorText={dataPullError}
              containerClass="mb-2"
              type="select"
              disabled={showViewForm}
            >
              <option value="">Select</option>
              {DATAPULL_DROPS.map((a, i) => {
                return (
                  <Option value={a.value?.toString()} key={i}>
                    {a.text}
                  </Option>
                );
              })}
            </FormInput>
            </>
            }
          </div>
        </div>
        {captureMode === "PULL" && (
          <div>
            {/* <label htmlFor="" className="mb-2">If PULL</label> */}
            <div className="hstack gap-3">
              <FormInput
                name="DI Service Name"
                label="DI Service Name"
                labelClassName="required"
                value={DIServiceName}
                onChange={handleDIServiceName}
                placeholder="DI Service Name"
                errorText={DIServiceNameError}
                containerClass="mb-2"
                type="text"
                disabled={showViewForm}
              />
              <FormInput
                name="Version"
                label="Version"
                labelClassName="required"
                value={version}
                onChange={handleVersion}
                placeholder="Version"
                errorText={versionError}
                containerClass="mb-2"
                type="text"
                disabled={showViewForm}
              />
              <FormInput
                name="Released On"
                label="Released On"
                labelClassName="required"
                value={releasedOn}
                onChange={handleReleasedOn}
                placeholder="Released On"
                errorText={releasedOnError}
                containerClass="mb-2"
                type="date"
                disabled={showViewForm}
              />
            </div>
          </div>
        )}
        {captureMode === "UPLOAD" && (
          <div className="hstack gap-5 mt-2 mb-2">
            <div className="hstack gap-2">
              {/* <label htmlFor="">If UPLOAD</label> */}
              <FormInput
                name="Frequency"
                label="Frequency"
                labelClassName="required"
                value={frequency}
                onChange={handleFrequency}
                placeholder="Frequency"
                errorText={frequencyError}
                containerClass="mb-2"
                type="select"
                disabled={showViewForm}
              >
                <option value="">Select</option>
                {FREQUENCY_DROPS.map((a, i) => {
                  return (
                    <Option value={a.value?.toString()} key={i}>
                      {a.text}
                    </Option>
                  );
                })}
              </FormInput>
            </div>
          </div>
        )}

        {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Status'
                    label='Status'
                    labelClassName="required"
                    value={status}
                    onChange={handleStatus}
                    placeholder='Status'
                    errorText={statusError}
                    containerClass="mb-2"
                    type="select"
                >
                    <option value="">Select</option>
                    {ACTIVE_DROPS.map((a, i) => {
                        return <Option value={a.value?.toString()} key={i}>{a.text}</Option>
                    })}
                </FormInput>
            </div> */}
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Revenue Data"
            label="Revenue Data"
            labelClassName="required"
            value={revenueData?.toString()}
            onChange={handleRevenueData}
            placeholder="Revenue Data"
            errorText={revenueDataError}
            containerClass="mt-3"
            type="checkbox"
            checked={revenueData}
            disabled={showViewForm}
          />
        </div>
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default CredentialForm;
