import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"


export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface TradeCategoryType {
    id?: number
    name: string
    active: ActiveTypes | boolean
    createdBy?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null
    updatedBy?: string | null
}

// export const TradeCategorySampleData = [
//     {
//         id: '1',
//         tradeCategoryName: 'Category-1',
//         tradeSubCategoryName:'Sub Category-1',
//     },
//     {
//         id: '2',
//         tradeCategoryName: 'Category-2',
//         tradeSubCategoryName:'Sub Category-1',
//     },
// ]

export enum TradeCategoryActionsList {
    ADD_TRADECATEGORY = 'ADD_TRADECATEGORY',
    EDIT_TRADECATEGORY = 'EDIT_TRADECATEGORY',
    FETCH_TRADECATEGORY = 'FETCH_TRADECATEGORY',
    DELETE_TRADECATEGORY = 'DELETE_TRADECATEGORY',
    CLEAR_TRADECATEGORY = 'CLEAR_TRADECATEGORY'
}

export interface DeleteTradeCategory {
    id: number
}

export interface AddTradeCategoryAction {
    type: TradeCategoryActionsList.ADD_TRADECATEGORY
    data: TradeCategoryType
}

export interface EditTradeCategoryAction {
    type: TradeCategoryActionsList.EDIT_TRADECATEGORY
    data: TradeCategoryType
    id?: number
}

export interface FetchTradeCategoryAction {
    type: TradeCategoryActionsList.FETCH_TRADECATEGORY
    data: TradeCategoryType[]
}

export interface DeleteTradeCategoryAction {
    type: TradeCategoryActionsList.DELETE_TRADECATEGORY
    data: DeleteTradeCategory
}

export interface ClearTradeCategoryAction {
    type: TradeCategoryActionsList.CLEAR_TRADECATEGORY
}

export type TradeCategoryActions = AddTradeCategoryAction | EditTradeCategoryAction | FetchTradeCategoryAction | DeleteTradeCategoryAction | ClearTradeCategoryAction

export const addTradeCategory = (data: TradeCategoryType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<TradeCategoryType>>('category/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddTradeCategoryAction>({
                    type: TradeCategoryActionsList.ADD_TRADECATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Trade Category added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}
export const clearTradeCategory = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return dispatch<ClearTradeCategoryAction>({
            type: TradeCategoryActionsList.CLEAR_TRADECATEGORY

        })
    }
}
export const editTradeCategory = (data: TradeCategoryType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.put<APIResponse<TradeCategoryType>>(`category/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditTradeCategoryAction>({
                    type: TradeCategoryActionsList.EDIT_TRADECATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Trade Category Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchTradeCategory = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.get<APIResponse<TradeCategoryType[]>>('category/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchTradeCategoryAction>({
                    type: TradeCategoryActionsList.FETCH_TRADECATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteTradeCategory = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.delete<APIResponse<TradeCategoryType>>(`category/${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteTradeCategoryAction>({
                    type: TradeCategoryActionsList.DELETE_TRADECATEGORY,
                    data: { id }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Trade Category Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}