import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { Dispatch } from "redux";
import { GetState } from "../store";

export enum ActiveDropDown {
  Active = "true",
  InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive;

export interface BrandCompanyType {
  id?: number;
  name: string;
  url: string;
  street: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  phone: string;
  active: ActiveTypes | boolean;
  createdBy?: number | null;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;
  retailid?: string;
}

export enum BrandCompanyActionsList {
  ADD_BRANDCOMPANY = "ADD_BRANDCOMPANY",
  EDIT_BRANDCOMPANY = "EDIT_BRANDCOMPANY",
  FETCH_BRANDCOMPANY = "FETCH_BRANDCOMPANY",
  DELETE_BRANDCOMPANY = "DELETE_BRANDCOMPANY",
  CLEAR_BRANDCOMPANY = "CLEAR_BRANDCOMPANY",
}

export interface DeleteBrandCompany {
  id: number;
}

export interface AddBrandCompanyAction {
  type: BrandCompanyActionsList.ADD_BRANDCOMPANY;
  data: BrandCompanyType;
}

export interface EditBrandCompanyAction {
  type: BrandCompanyActionsList.EDIT_BRANDCOMPANY;
  data: BrandCompanyType;
  id?: number;
}

export interface FetchBrandCompanyAction {
  type: BrandCompanyActionsList.FETCH_BRANDCOMPANY;
  data: BrandCompanyType[];
}

export interface ClearBrandCompanyAction {
  type: BrandCompanyActionsList.CLEAR_BRANDCOMPANY;
}

export interface DeleteBrandCompanyAction {
  type: BrandCompanyActionsList.DELETE_BRANDCOMPANY;
  data: DeleteBrandCompany;
}

export type BrandCompanyActions =
  | AddBrandCompanyAction
  | EditBrandCompanyAction
  | FetchBrandCompanyAction
  | DeleteBrandCompanyAction
  | ClearBrandCompanyAction;

export const addBrandCompany = (data: BrandCompanyType) => {
  return async (dispatch: Dispatch, getState: GetState) => {

    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);
    return api
      .post<APIResponse<BrandCompanyType>>("brandCompany/", data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<AddBrandCompanyAction>({
            type: BrandCompanyActionsList.ADD_BRANDCOMPANY,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "BrandCompany added"
          );
        } else {
          // throw { response };
          throw new Error("Unable to create")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to create"
            : "Unable to create"
        );
      });
  };
};

export const editBrandCompany = (data: BrandCompanyType, id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .put<APIResponse<BrandCompanyType>>(`brandCompany/${id}`, data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<EditBrandCompanyAction>({
            type: BrandCompanyActionsList.EDIT_BRANDCOMPANY,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message
              ? response.data.message
              : "BrandCompany Updated"
          );
        } else {
          throw new Error("Unable to update")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to update"
            : "Unable to update"
        );
      });
  };
};

export const fetchBrandCompany = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<BrandCompanyType[]>>("brandCompany/", config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchBrandCompanyAction>({
            type: BrandCompanyActionsList.FETCH_BRANDCOMPANY,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};
export const clearBrandCompany = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    return dispatch<ClearBrandCompanyAction>({
      type: BrandCompanyActionsList.CLEAR_BRANDCOMPANY,
    });
  };
};
export const deleteBrandCompany = (id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .delete<APIResponse<BrandCompanyType>>(`brandCompany/${id}`, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch<DeleteBrandCompanyAction>({
            type: BrandCompanyActionsList.DELETE_BRANDCOMPANY,
            data: { id },
          });
          return Promise.resolve(
            response.data.message
              ? response.data.message
              : " BrandCompany Deleted"
          );
        } else {
          throw new Error("Unable to delete")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to delete"
            : "Unable to delete"
        );
      });
  };
};
