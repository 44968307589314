import { Dispatch } from "redux";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { GetState } from "../store";

export interface RolesType {
  id?: string;
  role: string;
  active: number;
}


export enum RolesActionsList {
  FETCH_USER_ROLES = "FETCH_USER_ROLES",
  CLEAR_USER_ROLES = "CLEAR_USER_ROLES",
}

export interface FetchRolesAction {
  type: RolesActionsList.FETCH_USER_ROLES;
  data: RolesType[];
}

export interface ClearRolesAction {
  type: RolesActionsList.CLEAR_USER_ROLES;
}

export type RolesActions =
  | FetchRolesAction
  | ClearRolesAction;

export const clearRoles = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    return dispatch<ClearRolesAction>({
      type: RolesActionsList.CLEAR_USER_ROLES,
    });
  };
};

export const fetchRoles = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<RolesType[]>>("roleslist/?t="+new Date().getTime(), config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchRolesAction>({
            type: RolesActionsList.FETCH_USER_ROLES,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};