import { TenantManagerActionsList, TenantManagerType } from "../actions/tenantManagerActions";
import { Action } from "../actions/types";

const initialValue: Array<TenantManagerType> = []

export const TenantManagerReducer = (state: TenantManagerType[] = initialValue, action: Action) => {

    switch (action.type) {

        case TenantManagerActionsList.ADD_TENANTMANAGER: {

            return [...state, action.data]
        }

        case TenantManagerActionsList.EDIT_TENANTMANAGER: {

            const index = state.findIndex(i => i.tenantid === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    tenantid: action.id,
                    displayid:action.data.tenantManagerName
                }
            }
            return newState
        }

        case TenantManagerActionsList.FETCH_TENANTMANAGER: {

            return action.data
        }

        case TenantManagerActionsList.DELETE_TENANTMANAGER: {
            return state.filter(i => i.tenantid !== action.data.id)

        }

        default: return state
    }

}