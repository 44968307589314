import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FormType, FormTypeList } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import {
  ITDetailsDropDown,
  ITDetailsTypes,
} from "../../redux/actions/ITDetailsActions";
import {
  PaxAreaTypes,
  PaxTypeDropDown,
} from "../../redux/actions/leaseInfoActions";
import {
  TenantManagerType,
  addTenantManager,
  editTenantManager,
  fetchTenantManager,
} from "../../redux/actions/tenantManagerActions";
import { AppDispatch, StoreState } from "../../redux/store";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

export interface TenantLeaseMultiRows {
  id: number;
  itemCate: string;
  rangeFrom: string;
  rangeTo: string;
  revenueShare: string;
}
export interface TenantLeaseDetails {
  leaseId: string;
  rentType: string;
  leaseStart: string;
  leaseEnd: string;
  rentBasis: string;
  rentBasisRate: string;
  rentBaseAmt: string;
  billingCycle: string;
  rentEscPer: string;
  carpetArea: string;
  chargeableArea: string;
  // revenueShare: string;
  // itemCate: string;
  // rangeFrom: string;
  // reangeTo: string;
  leaseMultiRows: {
    id: number;
    itemCate: string;
    rangeFrom: string;
    rangeTo: string;
    revenueShare: string;
  }[];
}
export interface TenantLeaseDetails2 {
  id?: number;
  centerid: number;
  buildingid: number;
  floorid: number;
  unitno: string;
  area: string;
  tenantcategory: number;
  renttype: number;
  currenttenant: number;
  leasestart: string;
  leaseend: string;
  occupancystatus?: string;
  unitid?: string;
}

export interface TenantContactDetails {
  tenantName: string;
  tenantDesignation: string;
  tenantEmail: string;
  tenantContactNo: string;
}

export interface BrandContactDetails {
  brandName: string;
  brandDesignation: string;
  brandEmail: string;
  brandContactNo: string;
}

export interface TenantManager2{
      tenantid?: number;
      displayid?: string;
      brandid?: number;
      companyid?: number;
      tenantcatid?: number;
      floorid?: number;
      landmark: string;
      buildingid: number;
      centerid?: number;
      livefrom?: string;
      lounge?: boolean;
      tradecatid?: number;
      tradesubcatid?: number;
      site_displayid?: null;
      carpetarea: string;
      chargeablearea: string;
      unitname: string;
      paxtype: string ;
      isfranchise?: boolean;
      createdby?: string | null;
      updatedby?: string | null;
      leaseid: string;
      unitid:string;
}

interface editType {
  TenantManager: [
    {
      tenantid: number;
      displayid: string;
      brandid: number;
      brandname: string;
      brandcompanyid: number;
      companyname: string;
      tenantcatid: number;
      floorid: number;
      buildingid: number;
      unitid?: number;
      centerid: number;
      tradecatid: number;
      tradesubcatid: number;
      status: string;
      active: true;
      livefrom: string;
      lounge: boolean;
      franchise: boolean;
      tradecatname: null;
      tradesubcatname: null;
      forex: boolean;
      posterminals: number;
      capturemode: string;
      datapullby: string;
      dIservicename: string;
      version: string;
      frequenc: string;
      revenuedata: true;
      releasedon: string;
      deactivationdate: string;
      reason: string;
      datatransmode: string;
      postype: string;
      posname: string;
      ITteam: string;
    }
  ];
  TenantManager2: [
    { 
      id?:number;
      tenantid: number;
      displayid: string;
      brandid: number;
      companyid: number;
      tenantcatid: number;
      floorid: number;
      landmark: string;
      buildingid: number;
      centerid: number;
      livefrom: string;
      lounge: boolean;
      tradecatid: number;
      tradesubcatid: number;
      site_displayid: null;
      carpetarea: string;
      chargeablearea: string;
      unitname: string | null;
      paxtype: string | null;
      isfranchise: boolean;
      createdby: string | null;
      updatedby: string | null;
      leaseid: string;
    }
  ];
  TenantManagerContact: [
    {
      id: number;
      tenantid: number;
      name: string;
      designation: string;
      email: string;
      mobileNumber: string;
      type: string;
    }
  ];
  TenantManagerLease: [
    {
      id: number;
      centerid: string;
      tenantid: string;
      tenantdisplayid: string;
      leaseid: string;
      leasetype: string;
      leasestart: string;
      leaseend: string;
      rentbasis: string;
      rentbasisrate: string;
      billingcycle: string;
      rentesc: string;
      baseamt: string;
      revenueshare: string;
      carpetarea: string;
      chargeablearea: string;
    }
  ];
  TenantManagerLeaseDetails: [
    {
      id: number;
      leaseinfoid: number;
      baseamt: string;
      revenueshare: string;
      rangefrom: string;
      rangeto: string;
      itemcategory: string;
    }
  ];
  TenantManagerLeaseDetails2: [
    {
      id: number;
      centerid: number;
      buildingid: number;
      floorid: number;
      unitno: string;
      area: string;
      tenantcategory: number;
      renttype: number;
      currenttenant: number;
      leasestart: string;
      leaseend: string;
      occupancystatus: string;
      unitid?: string;
    }
  ];
}

export type TenantManagerContextType = {
  handleSave: () => void;
  resetStates: () => void;
  generalInfoNextErrorHandler: () => boolean;
  leaseInfoNextErrorHandler: () => boolean;
  itDetailsNextErrorHandler: () => boolean;
  formType: FormType;
  id: number | undefined;
  setId: React.Dispatch<React.SetStateAction<number | undefined>>;

  setFormType: React.Dispatch<React.SetStateAction<FormType>>;
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  portfolioId?: string;
  tenantId?: string;
  centerId?: string;
  setportfolioId?: React.Dispatch<React.SetStateAction<string>>;
  setTenantId?: React.Dispatch<React.SetStateAction<string>>;
  setCenterId?: React.Dispatch<React.SetStateAction<string>>;
  portfolioIdError?: string;
  setportfolioIdError?: React.Dispatch<React.SetStateAction<string>>;
  centerIdError?: string;
  setCenterIdError?: React.Dispatch<React.SetStateAction<string>>;
  //general form
  franchise?: boolean;
  company?: string;
  brand?: string;
  tradeCategory?: string;
  tradeSubCategory?: string;
  tenantCategory?: string;
  tenantManagerName?: string;
  franchiseError?: string;
  tradeCategoryError?: string;
  tradeSubCategoryError?: string;
  tenantCategoryError?: string;
  brandError?: string;
  tenantManagerNameError?: string;
  companyName?: string;
  tradeCategoryName?: string;
  tradeSubCategoryName?: string;
  tenantCategoryName?: string;
  brandName?: string;
  setCompanyName?: React.Dispatch<React.SetStateAction<string>>;
  setTradeCategoryName?: React.Dispatch<React.SetStateAction<string>>;
  setTradeSubCategoryName?: React.Dispatch<React.SetStateAction<string>>;
  setTenantCategoryName?: React.Dispatch<React.SetStateAction<string>>;
  setBrandName?: React.Dispatch<React.SetStateAction<string>>;
  setCompany?: React.Dispatch<React.SetStateAction<string>>;
  setFranchise?: React.Dispatch<React.SetStateAction<boolean>>;
  setTradeCategory?: React.Dispatch<React.SetStateAction<string>>;
  setTradeSubCategory?: React.Dispatch<React.SetStateAction<string>>;
  setTenantCategory?: React.Dispatch<React.SetStateAction<string>>;
  setBrand?: React.Dispatch<React.SetStateAction<string>>;
  setTenantManagerName?: React.Dispatch<React.SetStateAction<string>>;
  setFranchiseError?: React.Dispatch<React.SetStateAction<string>>;
  setTradeCategoryError?: React.Dispatch<React.SetStateAction<string>>;
  setTradeSubCategoryError?: React.Dispatch<React.SetStateAction<string>>;
  setTenantCategoryError?: React.Dispatch<React.SetStateAction<string>>;
  setBrandError?: React.Dispatch<React.SetStateAction<string>>;
  setTenantManagerNameError?: React.Dispatch<React.SetStateAction<string>>;
  //lease form
  leaseid?: string;
  setLeaseId?: React.Dispatch<React.SetStateAction<string>>;
  carpetArea?: string;
  setCarpetArea?: React.Dispatch<React.SetStateAction<string>>;
  chargeableArea?: string;
  setChargeableArea?: React.Dispatch<React.SetStateAction<string>>;
  landmark?: string;
  setLandmark?: React.Dispatch<React.SetStateAction<string>>;
  buildingTerminal?: string;
  setBuildingTerminal?: React.Dispatch<React.SetStateAction<string>>;
  location?: string;
  setLocation?: React.Dispatch<React.SetStateAction<string>>;
  paxType?: string;
  setPaxType?: React.Dispatch<React.SetStateAction<PaxAreaTypes>>;
  unitName?: string;
  setUnitName?: React.Dispatch<React.SetStateAction<string>>;
  unitId?: string;
  setUnitId?: React.Dispatch<React.SetStateAction<string>>;
  leaseIdError?: string;
  setLeaseIdError?: React.Dispatch<React.SetStateAction<string>>;
  carpetAreaError?: string;
  setCarpetAreaError?: React.Dispatch<React.SetStateAction<string>>;
  leasableAreaError?: string;
  setLeasableAreaError?: React.Dispatch<React.SetStateAction<string>>;
  landmarkError?: string;
  setLandmarkError?: React.Dispatch<React.SetStateAction<string>>;
  buildingTerminalError?: string;
  setBuildingTerminalError?: React.Dispatch<React.SetStateAction<string>>;
  locationError?: string;
  setLocationError?: React.Dispatch<React.SetStateAction<string>>;
  paxTypeError?: string;
  setPaxTypeError?: React.Dispatch<React.SetStateAction<string>>;
  unitNameError?: string;
  setUnitNameError?: React.Dispatch<React.SetStateAction<string>>;
  unitIdError?: string;
  setUnitIdError?: React.Dispatch<React.SetStateAction<string>>;
  rentType?: string;
  setRentType?: React.Dispatch<React.SetStateAction<string>>;
  rentTypeError?: string;
  setRentTypeError?: React.Dispatch<React.SetStateAction<string>>;
  maxFromDate?: string;
  setMaxFromDate?: React.Dispatch<React.SetStateAction<string>>;
  fromDate?: string;
  setFromDate?: React.Dispatch<React.SetStateAction<string>>;
  fromDateError?: string;
  setFromDateError?: React.Dispatch<React.SetStateAction<string>>;
  toDate?: string;
  setToDate?: React.Dispatch<React.SetStateAction<string>>;
  toDateError?: string;
  setToDateError?: React.Dispatch<React.SetStateAction<string>>;
  baseRent?: string;
  setBaseRent?: React.Dispatch<React.SetStateAction<string>>;
  baseRentError?: string;
  setBaseRentError?: React.Dispatch<React.SetStateAction<string>>;
  rentBasis?: string;
  setRentBasis?: React.Dispatch<React.SetStateAction<string>>;
  rentBasisError?: string;
  setRentBasisError?: React.Dispatch<React.SetStateAction<string>>;
  rentBasisRate?: string;
  setRentBasisRate?: React.Dispatch<React.SetStateAction<string>>;
  rentBasisRateError?: string;
  setRentBasisRateError?: React.Dispatch<React.SetStateAction<string>>;

  billingCycle?: string;
  setBillingCycle?: React.Dispatch<React.SetStateAction<string>>;
  billingCycleError?: string;
  setBillingCycleError?: React.Dispatch<React.SetStateAction<string>>;
  rentEscPer?: string;
  setRentEscPer?: React.Dispatch<React.SetStateAction<string>>;
  rentEscPerError?: string;
  setRentEscPerError?: React.Dispatch<React.SetStateAction<string>>;
  revenueShare?: string;
  setRevenueShare?: React.Dispatch<React.SetStateAction<string>>;
  revenueShareError?: string;
  setRevenueShareError?: React.Dispatch<React.SetStateAction<string>>;
  itemCate?: string;
  setItemCate?: React.Dispatch<React.SetStateAction<string>>;
  itemCateError?: string;
  setItemCateError?: React.Dispatch<React.SetStateAction<string>>;
  rangeFrom?: string;
  setRangeFrom?: React.Dispatch<React.SetStateAction<string>>;
  rangeFromError?: string;
  setRangeFromError?: React.Dispatch<React.SetStateAction<string>>;
  rangeTo?: string;
  setRangeTo?: React.Dispatch<React.SetStateAction<string>>;
  rangeToError?: string;
  setRangeToError?: React.Dispatch<React.SetStateAction<string>>;
  //tenant contact form
  tenantLeaseInfo?: TenantLeaseDetails[];
  setTenantLeaseInfo?: React.Dispatch<
    React.SetStateAction<TenantLeaseDetails[]>
  >;
  tenantLeaseInfo2?: TenantLeaseDetails2[];
  setTenantLeaseInfo2?: React.Dispatch<
    React.SetStateAction<TenantLeaseDetails2[]>
  >;
  tenantManager2?: TenantManager2[];
  setTenantManager2?: React.Dispatch<
    React.SetStateAction<TenantManager2[]>
  >;
  //IT form
  posType?: ITDetailsTypes;
  posName?: string;
  ITTeam?: ITDetailsTypes;
  setPosType?: React.Dispatch<React.SetStateAction<ITDetailsTypes>>;
  setPosName?: React.Dispatch<React.SetStateAction<string>>;
  setITTeam?: React.Dispatch<React.SetStateAction<ITDetailsTypes>>;
  posTypeError?: string;
  setPosTypeError?: React.Dispatch<React.SetStateAction<string>>;
  posNameError?: string;
  setPosNameError?: React.Dispatch<React.SetStateAction<string>>;
  ITTeamError?: string;
  setITTeamError?: React.Dispatch<React.SetStateAction<string>>;
  //tenant contact form
  tenantContactDetails?: TenantContactDetails[];
  setTenantContactDetails?: React.Dispatch<
    React.SetStateAction<TenantContactDetails[]>
  >;
  //brand contact form
  brandContactDetails?: BrandContactDetails[];
  setBrandContactDetails?: React.Dispatch<
    React.SetStateAction<BrandContactDetails[]>
  >;
  loading?: boolean;
} | null;

interface TenantManagerProviderProps {
  children?: React.ReactNode;
}

export const TenantManagerContext =
  React.createContext<TenantManagerContextType>(null);

export const TenantManagerProvider = (props: TenantManagerProviderProps) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const pageName = useLocation();
  const authUserToken = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  )?.["auth-token"]!;
  const config = getAxiosRequestConfig(authUserToken);

  const [showForm, setShowForm] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>();
  const [id, setId] = useState<number>();
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [portfolioId, setportfolioId] = useState<string>("");
  const [tenantId, setTenantId] = useState<string>("");
  const [portfolioIdError, setportfolioIdError] = useState<string>("");
  const [centerId, setCenterId] = useState<string>("");
  const [centerIdError, setCenterIdError] = useState<string>("");

  //general
  const [franchise, setFranchise] = useState<boolean>(false);
  const [company, setCompany] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [tradeCategory, setTradeCategory] = useState<string>("");
  const [tradeCategoryName, setTradeCategoryName] = useState<string>("");
  const [tradeSubCategory, setTradeSubCategory] = useState<string>("");
  const [tradeSubCategoryName, setTradeSubCategoryName] = useState<string>("");
  const [tenantCategory, setTenantCategory] = useState<string>("");
  const [tenantCategoryName, setTenantCategoryName] = useState<string>("");
  const [brand, setBrand] = useState<string>("");
  const [brandName, setBrandName] = useState<string>("");
  const [tenantManagerName, setTenantManagerName] = useState<string>("");
  const [franchiseError, setFranchiseError] = useState<string>("");
  const [tradeCategoryError, setTradeCategoryError] = useState<string>("");
  const [tradeSubCategoryError, setTradeSubCategoryError] =
    useState<string>("");
  const [tenantCategoryError, setTenantCategoryError] = useState<string>("");
  const [brandError, setBrandError] = useState<string>("");
  const [tenantManagerNameError, setTenantManagerNameError] =
    useState<string>("");
  //lease
  const [leaseid, setLeaseId] = useState<string>("");
  const [leaseIdError, setLeaseIdError] = useState<string>("");
  const [carpetArea, setCarpetArea] = useState<string>("");
  const [carpetAreaError, setCarpetAreaError] = useState<string>("");
  const [chargeableArea, setChargeableArea] = useState<string>("");
  const [leasableAreaError, setLeasableAreaError] = useState<string>("");
  const [landmark, setLandmark] = useState<string>("");
  const [landmarkError, setLandmarkError] = useState<string>("");
  const [buildingTerminal, setBuildingTerminal] = useState<string>("");
  const [buildingTerminalError, setBuildingTerminalError] =
    useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [locationError, setLocationError] = useState<string>("");
  const [paxType, setPaxType] = useState<PaxAreaTypes>(PaxTypeDropDown.Arrival);
  const [paxTypeError, setPaxTypeError] = useState<string>("");
  const [unitName, setUnitName] = useState<string>("");
  const [unitNameError, setUnitNameError] = useState<string>("");
  const [unitId, setUnitId] = useState<string>("");
  const [unitIdError, setUnitIdError] = useState<string>("");

  const [rentType, setRentType] = useState<string>("");
  const [rentTypeError, setRentTypeError] = useState<string>("");
  const [maxFromDate, setMaxFromDate] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const [fromDateError, setFromDateError] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [toDateError, setToDateError] = useState<string>("");
  const [baseRent, setBaseRent] = useState<string>("");
  const [baseRentError, setBaseRentError] = useState<string>("");
  const [rentBasis, setRentBasis] = useState<string>("");
  const [rentBasisRate, setRentBasisRate] = useState<string>("");
  const [rentBasisError, setRentBasisError] = useState<string>("");
  const [rentBasisRateError, setRentBasisRateError] = useState<string>("");
  const [billingCycle, setBillingCycle] = useState<string>("");
  const [billingCycleError, setBillingCycleError] = useState<string>("");
  const [rentEscPer, setRentEscPer] = useState<string>("");
  const [rentEscPerError, setRentEscPerError] = useState<string>("");
  const [revenueShare, setRevenueShare] = useState<string>("");
  const [revenueShareError, setRevenueShareError] = useState<string>("");
  const [itemCate, setItemCate] = useState<string>("");
  const [itemCateError, setItemCateError] = useState<string>("");
  const [rangeFrom, setRangeFrom] = useState<string>("");
  const [rangeFromError, setRangeFromError] = useState<string>("");
  const [rangeTo, setRangeTo] = useState<string>("");
  const [rangeToError, setRangeToError] = useState<string>("");
  const [tenantLeaseInfo, setTenantLeaseInfo] = useState<TenantLeaseDetails[]>(
    []
  );
  const [tenantLeaseInfo2, setTenantLeaseInfo2] = useState<
    TenantLeaseDetails2[]
  >([]);
  const [tenantManager2, setTenantManager2] = useState<
    TenantManager2[]
  >([]);
  //IT
  const [posType, setPosType] = useState<ITDetailsTypes>(
    ITDetailsDropDown.In_House
  );
  const [posTypeError, setPosTypeError] = useState<string>("");
  const [posName, setPosName] = useState<string>("");
  const [posNameError, setPosNameError] = useState<string>("");
  const [ITTeam, setITTeam] = useState<ITDetailsTypes>(
    ITDetailsDropDown.In_House
  );
  const [ITTeamError, setITTeamError] = useState<string>("");
  //contact
  const [brandContactDetails, setBrandContactDetails] = useState<
    BrandContactDetails[]
  >([]);
  const [tenantContactDetails, setTenantContactDetails] = useState<
    TenantContactDetails[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);

  const resetStates = () => {
    // setEditData()
    setFormType(FormTypeList.ADD);
    // setportfolioId('')
    // setportfolioIdError('')
    setCenterId("");
    setCenterIdError("");
    setFranchise(false);
    setCompany("");
    setCompanyName("");
    setTradeCategory("");
    setTradeCategoryName("");
    setTradeSubCategory("");
    setTradeSubCategoryName("");
    setTenantCategory("");
    setTenantCategoryName("");
    setBrand("");
    setBrandName("");
    setTenantManagerName("");
    setFranchiseError("");
    setTradeCategoryError("");
    setTradeSubCategoryError("");
    setTenantCategoryError("");
    setBrandError("");
    setTenantManagerNameError("");
    setLeaseId("");
    setLeaseIdError("");
    setCarpetArea("");
    setCarpetAreaError("");
    setChargeableArea("");
    setLeasableAreaError("");
    setLandmark("");
    setLandmarkError("");
    setBuildingTerminal("");
    setBuildingTerminalError("");
    setLocation("");
    setLocationError("");
    setPaxType(PaxTypeDropDown.Arrival);
    setPaxTypeError("");
    setUnitName("");
    setUnitNameError("");
    setUnitId("");
    setUnitIdError("");
    setPosType(ITDetailsDropDown.In_House);
    setPosTypeError("");
    setPosName("");
    setPosNameError("");
    setITTeam(ITDetailsDropDown.In_House);
    setITTeamError("");
    setBrandContactDetails([]);
    setTenantContactDetails([]);
    setTenantLeaseInfo([]);
    setTenantLeaseInfo2([]);
    setTenantManager2([])
    setLoading(true);
  };

  const handleFormClose = () => {
    resetStates();
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setLoading(true);
    dispatch(fetchTenantManager(portfolioId))
    // setTenantManager2([])
    // setTenantLeaseInfo2([])
    // setTenantContactDetails([])
    // setTenantLeaseInfo([])
  };

  const generalInfoNextErrorHandler = () => {
    if (tenantManagerName === "") {
      setTenantManagerNameError("Tenant Displayid required");
      return true;
    } else if (tenantManagerName && tenantManagerName?.length > 15) {
      setTenantManagerNameError("Tenant Displayid should be within 15 letters");
      return true;
    } else if (tradeCategory === "") {
      toast("Trade Category required", ToastTypes.ERROR);
      return true;
    } else if (tradeSubCategory === "") {
      toast("Trade Sub-Category required", ToastTypes.ERROR);
      return true;
    } else if (brand === "") {
      toast("Brand required", ToastTypes.ERROR);
      return true;
    } else if (company === "") {
      toast("Company required", ToastTypes.ERROR);
      return true;
    } else if (centerId === "") {
      // toast("Center required", ToastTypes.ERROR);
      setCenterIdError("Center required");
      return true;
    } else return false;
  };

  const leaseInfoNextErrorHandler = () => {
    // if (leaseid === "") {
    //   toast("Lease Id required", ToastTypes.ERROR);
    //   return true;
    // } else

    // if (carpetArea === "") {
    //   toast("Carpet Area required", ToastTypes.ERROR);
    //   return true;
    // } else if (chargeableArea === "") {
    //   toast("Chargeable Area required", ToastTypes.ERROR);
    //   return true;
    // } else 
      if (tenantLeaseInfo && tenantLeaseInfo?.length <= 0) {
      toast("Lease Details required", ToastTypes.ERROR);
      return true
      } 
    return false;
  };

  const itDetailsNextErrorHandler = () => {
    if (posName === "") {
      toast("Pos Name required", ToastTypes.ERROR);
      return true;
    } else return false;
  };

  const handleSave = () => {
    let error = false;
    const data: TenantManagerType = {
      portfolioid: portfolioId,
      centerid: centerId,
      tenantManagerName: tenantManagerName,
      franchise: franchise,
      company: company,
      companyName: companyName,
      brand: brand,
      brandName: brandName,
      tradeCategory: tradeCategory,
      tradeCategoryName: tradeCategoryName,
      tradeSubCategory: tradeSubCategory,
      tradeSubCategoryName: tradeSubCategoryName,
      tenantCategory: tenantCategory,
      tenantCategoryName: tenantCategoryName,
      //lease form
      buildingTerminal: "", //buildingId
      location: "", //floorid
      unitid: unitId,
      paxType: paxType,
      unitName: unitName,
      landmark: landmark,
      tenantLeaseDetails: tenantLeaseInfo,
      tenantLeaseDetails2: tenantLeaseInfo2,
      tenantManager2:tenantManager2,

      // leaseid: leaseid,
      // carpetArea: carpetArea,
      // chargeableArea: chargeableArea,

      //IT form
      posType: posType,
      posName: posName,
      ITTeam: ITTeam,
      //tenant contact form
      tenantContactDetails: tenantContactDetails,
      //brand contact form
      brandContactDetails: brandContactDetails,
    };
    if (data.portfolioid === "") {
      toast("portfolio required", ToastTypes.ERROR);
      error = true;
    }
    if (data.centerid === "") {
      toast("Center required", ToastTypes.ERROR);
      error = true;
    }
    if (data.brand === "") {
      toast("Brand required", ToastTypes.ERROR);
      error = true;
    }
    if (data.company === "") {
      toast("Company required", ToastTypes.ERROR);
      error = true;
    }
    if (data.tradeCategory === "") {
      toast("TradeCategory required", ToastTypes.ERROR);
      error = true;
    }
    if (data.tradeSubCategory === "") {
      toast("TradeSubCategory required", ToastTypes.ERROR);
      error = true;
    }
    if (data.posName === "") {
      toast("Pos Name required", ToastTypes.ERROR);
      error = true;
    }
    //  if (data.unitid === "") {
    //   toast("Unit required", ToastTypes.ERROR);
    //   error = true;
    // }
    // if (data.leaseid === "") {
    //   toast("Lease Id required", ToastTypes.ERROR);
    //   error = true;
    // }
    // if (data.carpetArea === "") {
    //   toast("Carpet Area required", ToastTypes.ERROR);
    //   error = true;
    // }
    // if (data.chargeableArea === "") {
    //   toast("Chargeable Area required", ToastTypes.ERROR);
    //   error = true;
    // }
    if (data.tenantContactDetails && data.tenantContactDetails?.length <= 0) {
      toast("Tenant Contact Details required", ToastTypes.ERROR);
      error = true;
    }
    if (data.brandContactDetails && data.brandContactDetails?.length <= 0) {
      toast("Brand Contact Details required", ToastTypes.ERROR);
      error = true;
    }
    if (data.tenantLeaseDetails && data.tenantLeaseDetails?.length <= 0) {
      toast("Lease Details required", ToastTypes.ERROR);
      error = true;
    }

    if (data.tenantManagerName === "") {
      setTenantManagerNameError("Tenant Displayid required");
      error = true;
    } else if (
      data?.tenantManagerName &&
      data?.tenantManagerName?.length > 15
    ) {
      setTenantManagerNameError("Tenant Displayid should be within 15 letters");
      error = true;
    }
    if (!error) {
      if (formType === FormTypeList.ADD) {
        dispatch(addTenantManager(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            navigate("/admin/tenant_manager");
            const logProps1 = {
              action: "Add",
              event: `${pageName.pathname.replace(
                "\\",
                " "
              )} page | Save Clicked | Status: Success`,
            };
            AdminLog(logProps1);
            handleFormClose();

            // onSave()
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action: "Add",
              event: `${pageName.pathname.replace(
                "\\",
                " "
              )} page | Save Clicked | Status: Failure | Reason : ${text}`,
            };
            AdminLog(logProps1);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        id !== undefined
      ) {
        dispatch(editTenantManager(data, id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            // dispatch(fetchTenantManager(portfolioId));
            navigate("/admin/tenant_manager");
            const logProps1 = {
              action: `Edit`,
              event: `${pageName.pathname.replace(
                "\\",
                " "
              )} page | Save Clicked | Status : Success`,
            };
            AdminLog(logProps1);
            dispatch(fetchTenantManager(portfolioId));
            handleFormClose();
            // onSave()
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action: `Edit`,
              event: `${pageName.pathname.replace(
                "\\",
                " "
              )} page | Save Clicked | Status : Failure | Reason : ${text}`,
            };
            AdminLog(logProps1);
          });
      }
    }
  };

  useEffect(() => {
    if (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) {
      if (portfolioId && id) {
        api
          .get<APIResponse<editType>>(
            `tenantManager/${portfolioId}/${id}`,
            config
          )
          .then(async (response) => {
            if (response.status === 200 && response.data.data) {
              setLoading(false);
              const result = response.data.data;
              setEditData(result);
              // const tenantLeaseData = result.TenantManagerLease;
              // const tenantLeaseDetailsData = result.TenantManagerLeaseDetails;
              // const leasedetails: TenantLeaseDetails[] = await Promise.all(tenantLeaseData.map(async (tl)=>{
              //   try {
              //   const leasedata = {
              //     leaseId: tl.leaseid,
              //     rentType: tl.leasetype,
              //     leaseStart: tl.leasestart,
              //     leaseEnd: tl.leaseend,
              //     rentBasis: tl.rentbasis,
              //     rentBasisRate: tl.rentbasisrate,
              //     rentBaseAmt: tl.baseamt,
              //     billingCycle: tl.billingcycle,
              //     rentEscPer: tl.rentesc,
              //     carpetArea: tl.carpetarea, //tenant2data[0].carpetarea ? tenant2data[0].carpetarea : "",
              //     chargeableArea: tl.chargeablearea,//tenant2data[0].chargeablearea ? tenant2data[0].chargeablearea: "",
              //   }
              //     let leasede:TenantLeaseMultiRows[] = await tenantLeaseDetailsData
              //     .filter((fltr) => fltr.leaseinfoid === tl.id).map((td)=>{
              //       return {
              //         id: td.id,
              //         itemCate: td.itemcategory,
              //         rangeFrom: td.rangefrom,
              //         rangeTo: td.rangeto,
              //         revenueShare: td.revenueshare
              //     }
              //   })
              //   return {
              //     ...leasedata,
              //     leaseMultiRows:leasede
              //   }
              // } catch (error) {
              //   console.error("Error in map function:", error);
              //   return null as any; // Adjust as necessary based on your logic
              // }
              // }))

              // setPosType(tenant1data[0].brandcompanyid?.toString())
              // setITTeam(tenant1data[0].brandcompanyid?.toString())
            } else {
              setEditData([]);
              setLoading(false);
            }
          })
          .catch((error) => {});
      }
    }
  }, [formType]);

  useEffect(() => {
    const tenant1data =
      editData?.TenantManager !== undefined &&
      editData?.TenantManager?.length > 0
        ? editData?.TenantManager
        : [];
    const tenant2data =
      editData?.TenantManager2 !== undefined &&
      editData?.TenantManager2?.length > 0
        ? editData?.TenantManager2
        : [];
    // const tenantLeaseDetailsData = editData.TenantManagerLeaseDetails;
    const tenantcontactData =
      editData?.TenantManagerContact !== undefined &&
      editData?.TenantManagerContact?.length > 0
        ? editData?.TenantManagerContact
        : [];

    const tenantLeaseData =
      editData?.TenantManagerLease !== undefined &&
      editData?.TenantManagerLease?.length > 0
        ? editData?.TenantManagerLease
        : [];
    const tenantLeaseDetailsData =
      editData?.TenantManagerLeaseDetails !== undefined &&
      editData?.TenantManagerLeaseDetails?.length > 0
        ? editData?.TenantManagerLeaseDetails
        : [];
    const tenantLeaseDetailsData2 =
      editData?.TenantManagerLeaseDetails2 !== undefined &&
      editData?.TenantManagerLeaseDetails2?.length > 0
        ? editData?.TenantManagerLeaseDetails2
        : [];
    // console.log(tenant1data, "hick");

    if (tenant1data?.length > 0) {
      setTenantManagerName(tenant1data[0].displayid?.toString());
      setCenterId(tenant1data[0].centerid?.toString());
      setBrand(tenant1data[0].brandid?.toString());
      // setBuildingTerminal(tenant1data[0].buildingid?.toString());
      // setLocation(tenant1data[0].floorid?.toString());
      // setUnitId(tenant1data[0].unitid?.toString())
      setCompany(tenant1data[0].brandcompanyid?.toString());
      setCompanyName(tenant1data[0].companyname?.toString());
      setTradeCategory(tenant1data[0].tradecatid?.toString());
      setTradeCategoryName(
        tenant1data[0].tradecatname ? tenant1data[0].tradecatname : ""
      );
      setTradeSubCategory(tenant1data[0].tradesubcatid?.toString());
      setTradeSubCategoryName(
        tenant1data[0]?.tradesubcatname ? tenant1data[0]?.tradesubcatname : ""
      );
      setTenantCategory(tenant1data[0].tenantcatid?.toString());
      // setTenantCategoryName(tenant1data[0].t?.toString())
      setFranchise(tenant1data[0].franchise);
      setPosName(tenant1data[0].posname?.toString());

      // console.log(tenant1data[0].postype, ITDetailsDropDown.Customized, "heya");
      let type1 = tenant1data[0].postype;
      if (type1 === ITDetailsDropDown.Customized) {
        setPosType(ITDetailsDropDown.Customized);
      } else if (type1 === ITDetailsDropDown.In_House) {
        setPosType(ITDetailsDropDown.In_House);
      }

      let type2 = tenant1data[0].ITteam;
      if (type2 === ITDetailsDropDown.Customized) {
        setITTeam(ITDetailsDropDown.Customized);
      } else if (type2 === ITDetailsDropDown.In_House) {
        setITTeam(ITDetailsDropDown.In_House);
      }
    }

    if (tenant2data?.length > 0) {
      // setUnitName(tenant2data[0].unitname ? tenant2data[0].unitname : "");
      // // setPaxType(tenant2data[0].paxtype ? tenant2data[0].paxtype===PaxTypeDropDown.Arrival? : '')
      // setLeaseId(tenant2data[0]?.leaseid ? tenant2data[0]?.leaseid : "");
      // setCarpetArea(tenant2data[0].carpetarea ? tenant2data[0].carpetarea : "");
      // setChargeableArea(
      //   tenant2data[0].chargeablearea ? tenant2data[0].chargeablearea : ""
      // );
      // console.log(tenant2data, "heck");
      // setLandmark(tenant2data[0].landmark ? tenant2data[0].landmark : "");
      setTenantManager2(tenant2data)
    }

    if (tenantcontactData?.length > 0) {
      const tcontact: TenantContactDetails[] = tenantcontactData
        .filter((t: any) => t.type === "tenant")
        .map((c: any) => {
          return {
            tenantName: c?.name,
            tenantDesignation: c.designation,
            tenantEmail: c.email,
            tenantContactNo: c.mobileNumber,
          };
        });

      const bcontact: BrandContactDetails[] = tenantcontactData
        .filter((t: any) => t.type === "brand")
        .map((c: any) => {
          return {
            brandName: c?.name,
            brandDesignation: c.designation,
            brandEmail: c.email,
            brandContactNo: c.mobileNumber,
          };
        });

      setBrandContactDetails(bcontact);
      setTenantContactDetails(tcontact);
    }

    const fetchLeaseDetails = async () => {
      if (tenantLeaseData?.length > 0 && tenantLeaseDetailsData?.length > 0) {
        const leasedetails: TenantLeaseDetails[] = await Promise.all(
          tenantLeaseData.map(async (tl: any) => {
            const leasedata = {
              leaseId: tl.leaseid,
              rentType: tl.leasetype,
              leaseStart: tl.leasestart,
              leaseEnd: tl.leaseend,
              rentBasis: tl.rentbasis,
              rentBasisRate: tl.rentbasisrate,
              rentBaseAmt: tl.baseamt,
              billingCycle: tl.billingcycle,
              rentEscPer: tl.rentesc,
              carpetArea: tl.carpetarea,
              chargeableArea: tl.chargeablearea,
            };

            const leasede: TenantLeaseMultiRows[] = tenantLeaseDetailsData
              .filter((fltr: any) => fltr.leaseinfoid === tl.id)
              .map((td: any) => {
                return {
                  id: td.id,
                  itemCate: td.itemcategory,
                  rangeFrom: td.rangefrom,
                  rangeTo: td.rangeto,
                  revenueShare: td.revenueshare,
                };
              });

            return {
              ...leasedata,
              leaseMultiRows: leasede,
            };
          })
        );

        setTenantLeaseInfo(leasedetails);
      }
    };

    fetchLeaseDetails();

    const fetchLeaseDetails2 = async () => {
      if (tenantLeaseData?.length > 0 && tenantLeaseDetailsData2?.length > 0) {
        const leasedetails: TenantLeaseDetails2[] = await Promise.all(
          tenantLeaseDetailsData2.map(async (tl: any) => {
            const leasedata = {
              id: tl.id,
              centerid: tl.centerid,
              buildingid: tl.buildingid,
              floorid: tl.floorid,
              unitno: tl.unitno,
              area: tl.area,
              tenantcategory: tl.tenantcategory,
              renttype: tl.renttype,
              currenttenant: tl.currenttenant,
              leasestart: tl.leasestart,
              leaseend: tl.leaseend,
              occupancystatus: tl.occupancystatus,
              unitid: tl.unitid,
            };

            return {
              ...leasedata,
            };
          })
        );

        setTenantLeaseInfo2(leasedetails);
      }
    };

    fetchLeaseDetails2();

    

  }, [editData]);

  // useEffect(()=>{
  //   console.log(tenantLeaseInfo2,tenantLeaseInfo,'Heck')
  // },[tenantLeaseInfo2,tenantLeaseInfo])

  useEffect(() => {
    if (id !== undefined && id !== null) {
      setTenantId(id?.toString());
    }
  }, [id]);

  return (
    <>
      <TenantManagerContext.Provider
        value={{
          portfolioId,
          setportfolioId,
          centerId,
          setCenterId,
          generalInfoNextErrorHandler,
          leaseInfoNextErrorHandler,
          itDetailsNextErrorHandler,
          brand,
          company,
          franchise,
          tenantCategory,
          tenantManagerName,
          tradeCategory,
          tradeSubCategory,
          handleSave,
          resetStates,
          setBrand,
          ITTeam,
          posName,
          brandContactDetails,
          posType,
          setCompany,
          setFranchise,
          setTenantCategory,
          setTenantManagerName,
          setTradeCategory,
          setTradeSubCategory,
          tenantContactDetails,
          setPosType,
          setPosName,
          setITTeam,
          franchiseError,
          tradeCategoryError,
          tradeSubCategoryError,
          tenantCategoryError,
          brandError,
          tenantManagerNameError,
          setFranchiseError,
          setTradeCategoryError,
          setTradeSubCategoryError,
          setTenantCategoryError,
          setBrandError,
          setTenantManagerNameError,
          setCompanyName,
          setTradeCategoryName,
          setTradeSubCategoryName,
          setTenantCategoryName,
          setBrandName,
          leaseid,
          setLeaseId,
          carpetArea,
          setCarpetArea,
          chargeableArea,
          setChargeableArea,
          landmark,
          setLandmark,
          buildingTerminal,
          setBuildingTerminal,
          location,
          setLocation,
          paxType,
          setPaxType,
          unitName,
          setUnitName,
          unitId,
          setUnitId,
          leaseIdError,
          setLeaseIdError,
          carpetAreaError,
          setCarpetAreaError,
          leasableAreaError,
          setLeasableAreaError,
          landmarkError,
          setLandmarkError,
          buildingTerminalError,
          setBuildingTerminalError,
          locationError,
          setLocationError,
          paxTypeError,
          setPaxTypeError,
          unitNameError,
          setUnitNameError,
          unitIdError,
          setUnitIdError,
          rentType,
          setRentType,
          rentTypeError,
          setRentTypeError,
          maxFromDate,
          setMaxFromDate,
          fromDate,
          setFromDate,
          fromDateError,
          setFromDateError,
          toDate,
          setToDate,
          toDateError,
          setToDateError,
          baseRent,
          setBaseRent,
          baseRentError,
          setBaseRentError,
          rentBasis,
          setRentBasis,
          rentBasisError,
          setRentBasisError,
          rentBasisRate,
          setRentBasisRate,
          rentBasisRateError,
          setRentBasisRateError,
          billingCycle,
          setBillingCycle,
          billingCycleError,
          setBillingCycleError,
          rentEscPer,
          setRentEscPer,
          rentEscPerError,
          setRentEscPerError,
          revenueShare,
          setRevenueShare,
          revenueShareError,
          setRevenueShareError,
          itemCate,
          setItemCate,
          itemCateError,
          setItemCateError,
          rangeFrom,
          setRangeFrom,
          rangeFromError,
          setRangeFromError,
          rangeTo,
          setRangeTo,
          rangeToError,
          setRangeToError,
          tenantLeaseInfo,
          setTenantLeaseInfo,
          tenantLeaseInfo2,
          setTenantLeaseInfo2,
          tenantManager2,
          setTenantManager2,
          setTenantContactDetails,
          setBrandContactDetails,
          posTypeError,
          setPosTypeError,
          posNameError,
          setPosNameError,
          ITTeamError,
          setITTeamError,
          portfolioIdError,
          setportfolioIdError,
          centerIdError,
          setCenterIdError,
          formType,
          setFormType,
          id,
          setId,
          showForm,
          setShowForm,
          loading,
          tenantId,
        }}
      >
        {props.children}
      </TenantManagerContext.Provider>
    </>
  );
};
