import { ItemSubcategoryActionsList, ItemSubcategoryType } from "../actions/itemsubcategory";
import { Action } from "../actions/types";

const initialValue: Array<ItemSubcategoryType> = []

export const ItemSubcategoryReducer = (state: ItemSubcategoryType[] = initialValue, action: Action) => {

    switch (action.type) {

        case ItemSubcategoryActionsList.ADD_ITEMSUBCATEGORY: {

            return [...state, action.data]
        }

        case ItemSubcategoryActionsList.EDIT_ITEMSUBCATEGORY: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.subcatid === action.data.subcatid)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case ItemSubcategoryActionsList.FETCH_ITEMSUBCATEGORY: {

            return action.data

        }

        case ItemSubcategoryActionsList.DELETE_ITEMSUBCATEGORY: {
            return state.filter(i => i.subcatid !== action.data.subcatid)

        }

        default: return state
    }

}