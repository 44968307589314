// import { nanoid } from "@reduxjs/toolkit"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { Dispatch } from "redux"
import { GetState } from "../store"

export interface PortfolioDBType {
    pfid:number
    uid:string
    server:string
    pwd:string
    dbname:string
    createdBy?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null
    updatedBy?: string | null
}

export enum PortfolioDBActionsList {
    ADD_PORTFOLIODB = 'ADD_PORTFOLIODB',
    EDIT_PORTFOLIODB = 'EDIT_PORTFOLIODB',
    FETCH_PORTFOLIODB = 'FETCH_PORTFOLIODB',
    DELETE_PORTFOLIODB = 'DELETE_PORTFOLIODB'
}

export interface DeletePortfolioDB {
    id: number
}

export interface AddPortfolioDBAction {
    type: PortfolioDBActionsList.ADD_PORTFOLIODB
    data: PortfolioDBType
}

export interface EditPortfolioDBAction {
    type: PortfolioDBActionsList.EDIT_PORTFOLIODB
    data: PortfolioDBType
    id?: number
}

export interface FetchPortfolioDBAction {
    type: PortfolioDBActionsList.FETCH_PORTFOLIODB
    data: PortfolioDBType[]
}

export interface DeletePortfolioDBAction {
    type: PortfolioDBActionsList.DELETE_PORTFOLIODB
    data: DeletePortfolioDB
}

export type PortfolioDBActions = AddPortfolioDBAction | EditPortfolioDBAction | FetchPortfolioDBAction | DeletePortfolioDBAction

export const addPortfolioDB = (data: PortfolioDBType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<PortfolioDBType>>('portfolioDB/', data,config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddPortfolioDBAction>({
                    type: PortfolioDBActionsList.ADD_PORTFOLIODB,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'PortfolioDB added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })

    }
}

export const editPortfolioDB = (data: PortfolioDBType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<PortfolioDBType>>(`portfolioDB/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditPortfolioDBAction>({
                    type: PortfolioDBActionsList.EDIT_PORTFOLIODB,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'PortfolioDB Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchPortfolioDB = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<PortfolioDBType[]>>('portfolioDB/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchPortfolioDBAction>({
                    type: PortfolioDBActionsList.FETCH_PORTFOLIODB,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deletePortfolioDB = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<PortfolioDBType>>(`portfolioDB/${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeletePortfolioDBAction>({
                    type: PortfolioDBActionsList.DELETE_PORTFOLIODB,
                    data: { id }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' PortfolioDB Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}