/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse } from "../../config/api"
import { RoleType } from "./roleActions"

export interface LoginDataType {
    loginid: string
    password: string
}

export interface AuthAdminType {
    id?: number
    "auth-token"?: string
    password?: string
    status?: string
    name: string
    email: string
    loginid: string
    mobileNumber?: string
    role: RoleType[]
    companyId?: string
    portfolioId?: string
    siteId?: string
    brandId?: string
    createdAt?: string
    updatedAt?: string
}

export enum AdminActionList {
    LOGIN_ADMIN = 'LOGIN_ADMIN',
    LOGOUT_ADMIN = 'LOGOUT_ADMIN',
    SIGNUP_ADMIN = 'SIGNUP_ADMIN'
}

export interface AdminSignupAction {
    type: AdminActionList.SIGNUP_ADMIN
    data: AuthAdminType
}

export interface AdminLoginAction {
    type: AdminActionList.LOGIN_ADMIN
    data: AuthAdminType
}

export interface AdminLogoutAction {
    type: AdminActionList.LOGOUT_ADMIN
    data: null
}

export type AuthAdminActions = AdminSignupAction | AdminLoginAction | AdminLogoutAction

export const signupAdmin = (data: AuthAdminType) => {
    // api call
}

export const loginAdmin = (data: LoginDataType) => {
    return async (dispatch: Dispatch) => {
        // const data = { id:1,"auth-token":'dddd',password:'666666',status:'active',username: 'admin', name: 'admin', mobileNumber: '9943312345', email: 'test@test.com', role: 'admin' }

        return api.post<APIResponse<AuthAdminType>>('user/login/', data).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AdminLoginAction>({
                    type: AdminActionList.LOGIN_ADMIN,
                    data: response.data.data
                })
                // return Promise.resolve('Successfully logged in')
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Successfully logged in')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to login'
                : 'Unable to login')
        })
    }
}

export const logoutAdmin = () => {
    return (dispatch: Dispatch) => {
        dispatch<AdminLogoutAction>({
            type: AdminActionList.LOGOUT_ADMIN,
            data: null
        })
    }
}