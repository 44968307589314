import { RolesActionsList, RolesType } from "../actions/userRoleActions";
import { Action } from "../actions/types";

const initialValue: Array<RolesType> = []

export const RolesReducer = (state: RolesType[] = initialValue, action: Action) => {

    switch (action.type) {
        case RolesActionsList.FETCH_USER_ROLES: {
            return action.data
        }

        case RolesActionsList.CLEAR_USER_ROLES: {
            return initialValue
        }

        default: return state
    }

}