import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import {
  ActiveDropDown,
  ActiveTypes,
  CompanyType,
  addCompany,
  editCompany,
  fetchCompany,
} from "../../redux/actions/companyActions";
import { CountryType } from "../../redux/actions/countryActions";
import {
  CompanyContactType,
  fetchCompanyContact,
} from "../../redux/actions/companyContactActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { FormInput } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { testEmail, testNumber } from "../../config/functions";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

interface Props {
  formType: FormType;
  editData?: CompanyType;
  onSave: () => void;
  showViewForm: boolean;
}

const CompanyForm = ({ formType, onSave, editData, showViewForm }: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const companyContact = useSelector<StoreState, Array<CompanyContactType>>(
    (state) => state.companyContact
  );
  const CountryList = useSelector<StoreState, Array<CountryType>>(
    (state) => state.country
  );

  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [urlError, setUrlError] = useState<string>("");
  const [logopath, setLogopath] = useState<string>("");
  const [logopathError, setLogopathError] = useState<string>("");
  const [logourl, setLogourl] = useState<string>("");
  const [logourlError, setLogourlError] = useState<string>("");
  const [reportingcurrency, setReportingcurrency] = useState<string>("");
  const [reportingcurrencyError, setReportingcurrencyError] =
    useState<string>("");
  const [currencysymbol, setCurrencysymbol] = useState<string>("");
  const [currencysymbolError, setCurrencysymbolError] = useState<string>("");
  const [valuecaption, setValuecaption] = useState<string>("");
  const [valuecaptionError, setValuecaptionError] = useState<string>("");
  const [active, setActive] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [activeError, setActiveError] = useState<string>("");
  //COMPANY CONTACT
  const [type, setType] = useState<string>("");
  const [typeError, setTypeError] = useState<string>("");
  const [fname, setFName] = useState<string>("");
  const [fnameError, setFNameError] = useState<string>("");
  const [lname, setLName] = useState<string>("");
  const [lnameError, setLNameError] = useState<string>("");
  const [designation, setDesignation] = useState<string>("");
  const [designationError, setDesignationError] = useState<string>("");
  const [mailid, setMailid] = useState<string>("");
  const [mailidError, setMailidError] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [mobileError, setMobileError] = useState<string>("");
  const [pincode, setPincode] = useState<string>("");
  const [pincodeError, setPincodeError] = useState<string>("");
  // const [companyid, setCompanyid] = useState<string>("");
  // const [companyidError, setCompanyidError] = useState<string>("");
  const [contactActive, setContactActive] = useState<ActiveTypes>(
    ActiveDropDown.Active
  );
  const [contactActiveError, setContactActiveError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const resetStates = () => {
    !editData && setName("");
    setNameError("");
    setUrl("");
    setUrlError("");
    setLogopath("");
    setLogopathError("");
    setLogourl("");
    setLogourlError("");
    setActive(ActiveDropDown.Active);
    setActiveError("");
    !editData && setReportingcurrency("");
    setReportingcurrencyError("");
    !editData && setCurrencysymbol("");
    setCurrencysymbolError("");
    setValuecaption("");
    setValuecaptionError("");

    //company contact

    setType("");
    setTypeError("");
    setFName("");
    setFNameError("");
    setLName("");
    setLNameError("");
    setDesignation("");
    setDesignationError("");
    setMailid("");
    setMailidError("");
    setMobile("");
    setMobileError("");
    setPincode("");
    setPincodeError("");
    // setCompanyid("");
    // setCompanyidError("");
    setContactActive(ActiveDropDown.Active);
    setContactActiveError("");
    setLoading(false);
  };
  const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
    setNameError("");
  };

  const handleLogourl: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLogourl(e.target.value);
    setLogourlError("");
  };

  const handleLogopath: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLogopath(e.target.value);
    setLogopathError("");
  };

  const handleReportingcurrency: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value.toUpperCase();
    if (value?.length === 3) {
      const index = CountryList.find(
        (a) => a.shortcode?.toString() === value?.toString()
      );

      const symbol =
        index && index.currencysymbol !== undefined ? index.currencysymbol : "";
      setCurrencysymbol(symbol);
      setCurrencysymbolError("");
    } else {
      setCurrencysymbol("");
    }
    setReportingcurrency(value);
    setReportingcurrencyError("");
  };

  const handleCurrencysymbol: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCurrencysymbol(e.target.value);
    setCurrencysymbolError("");
  };

  const handleValueCaption: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValuecaption(e.target.value);
    setValuecaptionError("");
  };

  const handleActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setActive(e.target.value as ActiveTypes);
    setActiveError("");
  };

  const handleUrl: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUrl(e.target.value);
    setUrlError("");
  };

  //company contact

  const handleType: ChangeEventHandler<HTMLInputElement> = (e) => {
    setType(e.target.value);
    setTypeError("");
  };

  const handleFName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFName(e.target.value);
    setFNameError("");
  };

  const handleLName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLName(e.target.value);
    setLNameError("");
  };

  const handleDesignation: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDesignation(e.target.value);
    setDesignationError("");
  };

  const handleMailid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setMailid(e.target.value);
    setMailidError("");
  };

  const handleMobile: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;
    else if (e.target.value?.length >= 12) return;

    setMobile(e.target.value);
    setMobileError("");
  };

  const handlePincode: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;
    else if (e.target.value?.length >= 7) return;
    setPincode(e.target.value);
    setPincodeError("");
  };

  // const handleCompanyid: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setCompanyid(e.target.value);
  //   setCompanyidError("");
  // };

  const handleContactActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setContactActive(e.target.value as ActiveTypes);
    setContactActiveError("");
  };

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const data: CompanyType = {
      name: name,
      url: url,
      logourl: logourl,
      logopath: logopath,
      reportingcurrency: reportingcurrency,
      currencysymbol: currencysymbol,
      valuecaption: valuecaption,
      active: active,

      //companycontact
      type: type,
      fname: fname,
      lname: lname,
      designation: designation,
      mailid: mailid,
      mobile: mobile,
      pincode: pincode,
      contactActive: contactActive,
    };

    if (data.name === "") {
      setNameError("Company Name required");
      error = true;
    } else if (data.name?.length > 60) {
      setNameError("Company Name should be within 60 characters");
      error = true;
    }

    if (formType === FormTypeList.EDIT) {
      if (data.active?.toString() === "") {
        setActiveError("Active required");
        error = true;
      }
    }

    if (data.reportingcurrency === "") {
      setReportingcurrencyError(" Reporting currency required");
      error = true;
    } else if (data.reportingcurrency?.length > 3) {
      setReportingcurrencyError(
        " Reporting currency should be within 3 characters"
      );
      error = true;
    }

    if (data.currencysymbol === "") {
      setCurrencysymbolError("Currency Symbol required");
      error = true;
    } else if (data.currencysymbol?.length > 5) {
      setCurrencysymbolError("Currency Symbol should be within 5 characters");
      error = true;
    }

    if (data.valuecaption === "") {
      setValuecaptionError("Value Caption required");
      error = true;
    } else if (data.valuecaption?.length > 20) {
      setValuecaptionError("Value Caption should be within 20 characters");
      error = true;
    }

    // if (data.logourl === '') {
    //     setLogourlError('Logo Url required')
    //     error = true
    // } else if ((data.logourl)?.length > 100) {
    //     setLogourlError('Logo Url should be within 100 characters')
    //     error = true
    // }

    // if (data.logopath === '') {
    //     setLogopathError('Logo path required')
    //     error = true
    // } else if ((data.logopath)?.length > 100) {
    //     setLogopathError('Logo path should be within 100 characters')
    //     error = true
    // }

    // if (data.url === '') {
    //     setUrlError('Website URL required')
    //     error = true
    // } else if ((data.url)?.length > 100) {
    //     setUrlError('Website URL should be within 100 characters')
    //     error = true
    // }

    //company contact

    // if (data.type === '') {
    //     setTypeError('Type required')
    //     error = true
    // } else if ((data.type)?.length > 100) {
    //     setTypeError('Type should be within 100 characters')
    //     error = true
    // }

    // if (data.fname === '') {
    //     setFNameError('First Name required')
    //     error = true
    // } else if ((data.fname)?.length > 100) {
    //     setFNameError('First Name should be within 100 characters')
    //     error = true
    // }

    // if (data.designation === '') {
    //     setDesignationError('Designation required')
    //     error = true
    // } else if ((data.designation)?.length > 100) {
    //     setDesignationError('Designation should be within 100 characters')
    //     error = true
    // }

    // if (data.mailid === '') {
    //     setMailidError('Mail id required')
    //     error = true
    // } else if ((data.mailid)?.length > 100) {
    //     setMailidError('Mail id should be within 100 characters')
    //     error = true
    // }

    if (data.mailid && !testEmail(data.mailid)) {
      setMailidError("Email-id is not valid");
      error = true;
    }

    // if (data.mobile === '') {
    //     setMobileError('Mobile required')
    //     error = true
    // } else if ((data.mobile)?.length > 100) {
    //     setMobileError('Mobile should be within 100 characters')
    //     error = true
    // }
    if (data.mobile && !testNumber(data.mobile)) {
      setMobileError("Phone Number must be a number");
      error = true;
    }

    // if (data.pincode === '') {
    //     setPincodeError('Pincode required')
    //     error = true
    // } else if ((data.pincode)?.length > 100) {
    //     setPincodeError('Pincode should be within 100 characters')
    //     error = true
    // }

    if (data.pincode && !testNumber(data.pincode)) {
      setPincodeError("Pincode must be a number");
      error = true;
    }

    if (formType === FormTypeList.EDIT) {
      if (data.contactActive?.toString() === "") {
        setContactActiveError("Active required");
        error = true;
      }
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addCompany(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
            dispatch(fetchCompany());
            dispatch(fetchCompanyContact());
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.id !== undefined
      ) {
        setLoading(true);
        dispatch(editCompany(data, editData.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
            dispatch(fetchCompany());
            dispatch(fetchCompanyContact());
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      setName(editData.name);
      setLogourl(editData.logourl);
      setLogopath(editData.logopath);
      setReportingcurrency(editData.reportingcurrency);
      setCurrencysymbol(editData.currencysymbol);
      setValuecaption(editData.valuecaption);
      setUrl(editData.url);
      setActive(
        editData.active === true
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );

      const index = companyContact.findIndex(
        (p) => p.companyid?.toString() === editData.id?.toString()
      );
      if (editData.companyContacts?.length !== 0) {
        setType(editData.companyContacts?.[0].type || "");
        setFName(editData.companyContacts?.[0].fname || "");
        setLName(editData.companyContacts?.[0].lname || "");
        setDesignation(editData.companyContacts?.[0].designation || "");
        setMailid(editData.companyContacts?.[0].mailid || "");
        setMobile(editData.companyContacts?.[0].mobile || "");
        setPincode(editData.companyContacts?.[0].pincode || "");
        setContactActive(
          editData.companyContacts?.[0].active === true
            ? ActiveDropDown.Active
            : ActiveDropDown.InActive
        );
      }
    } else {
      resetStates();
    }
  }, [formType, editData]);

 

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Company Name"
            label="Company Name"
            labelClassName="required"
            value={name}
            onChange={handleName}
            placeholder="Company Name"
            errorText={nameError}
            containerClass="mb-2"
            disabled={showViewForm || (editData && true)}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Website URL"
            label="Website URL"
            labelClassName="required"
            value={url}
            onChange={handleUrl}
            placeholder="Website URL"
            errorText={urlError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Logo URL"
            label="Logo URL"
            labelClassName="required"
            value={logourl}
            onChange={handleLogourl}
            placeholder="Logo URL"
            errorText={logourlError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Logo Path"
            label="Logo Path"
            labelClassName="required"
            value={logopath}
            onChange={handleLogopath}
            placeholder="Logo Path"
            errorText={logopathError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        {(formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Status"
              label="Status"
              labelClassName="required"
              value={active}
              onChange={handleActive}
              placeholder="Status"
              errorText={activeError}
              containerClass="mb-2"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {ACTIVE_DROPS.map((a, i) => {
                return (
                  <Option value={a.value?.toString()} key={i}>
                    {a.text}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        )}

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Reporting Currency"
            label="Reporting Currency"
            labelClassName="required"
            value={reportingcurrency}
            onChange={handleReportingcurrency}
            placeholder="Reporting Currency"
            errorText={reportingcurrencyError}
            containerClass="mb-2"
            disabled={showViewForm || (editData && true)}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Currency Symbol"
            label="Currency Symbol"
            labelClassName="required"
            value={currencysymbol}
            onChange={handleCurrencysymbol}
            placeholder="Currency Symbol"
            errorText={currencysymbolError}
            containerClass="mb-2"
            disabled={showViewForm || (editData && true)}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Value Caption"
            label="Value Caption"
            labelClassName="required"
            value={valuecaption}
            onChange={handleValueCaption}
            placeholder="Value Caption"
            errorText={valuecaptionError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
      </div>
      {/* COMPANY CONTACT */}
      <hr />
      {formType === FormTypeList.ADD && <h4>ADD COMPANY CONTACT DETAILS</h4>}
      {formType === FormTypeList.EDIT && <h4>EDIT COMPANY CONTACT DETAILS</h4>}
      {formType === FormTypeList.VIEW && <h4>VIEW COMPANY CONTACT DETAILS</h4>}
      <br />
      <br />

      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Type"
            label="Type"
            labelClassName="required"
            value={type}
            onChange={handleType}
            placeholder="Type"
            errorText={typeError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="First Name"
            label="First Name"
            labelClassName="required"
            value={fname}
            onChange={handleFName}
            placeholder="First Name"
            errorText={fnameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Last Name"
            label="Last Name"
            labelClassName="required"
            value={lname}
            onChange={handleLName}
            placeholder="Last Name"
            errorText={lnameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Designation"
            label="Designation"
            labelClassName="required"
            value={designation}
            onChange={handleDesignation}
            placeholder="Designation"
            errorText={designationError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Email"
            label="Email"
            labelClassName="required"
            value={mailid}
            onChange={handleMailid}
            placeholder="Email"
            errorText={mailidError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            type="text"
            name="Mobile"
            label="Mobile"
            labelClassName="required"
            value={mobile}
            onChange={handleMobile}
            placeholder="Mobile"
            errorText={mobileError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            type="text"
            name="Pincode"
            label="Pincode"
            labelClassName="required"
            value={pincode}
            onChange={handlePincode}
            placeholder="Pincode"
            errorText={pincodeError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        {(formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Status"
              label="Status"
              labelClassName="required"
              value={contactActive}
              onChange={handleContactActive}
              placeholder="Status"
              errorText={contactActiveError}
              containerClass="mb-2"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {ACTIVE_DROPS.map((a, i) => {
                return (
                  <Option value={a.value?.toString()} key={i}>
                    {a.text}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        )}
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default CompanyForm;
