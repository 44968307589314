import { outletType, OutletActionsList } from "../actions/outletActions";
import { Action } from "../actions/types";

const initialValue: Array<outletType> = [];

export const OutletReducer = (
  state: outletType[] = initialValue,
  action: Action
) => {
  switch (action.type) {
    case OutletActionsList.ADD_OUTLET_MANAGER: {
      return [...state, action.data];
    }

    case OutletActionsList.EDIT_OUTLET_MANAGER: {
      const updated = [...state].slice();
      const index = updated.findIndex((u) => u.id === action.data.id);

      if (index > -1) {
        updated[index] = {
          ...action.data,
        };
      }

      return updated;
    }

    case OutletActionsList.FETCH_OUTLET_MANAGER: {
      return action.data;
    }

    case OutletActionsList.DELETE_OUTLET_MANAGER: {
      return state.filter((i) => i.id !== action.data.id);
    }

    case OutletActionsList.CLEAR_OUTLET_MANAGER: {
      return initialValue;
    }

    default:
      return state;
  }
};
