import { PortfolioDBType, PortfolioDBActionsList } from "../actions/portfolioDBActions";
import { Action } from "../actions/types";

const initialValue: Array<PortfolioDBType> = []

export const PortfolioDBReducer = (state: PortfolioDBType[]=initialValue, action: Action) => {

    switch (action.type) {

        case PortfolioDBActionsList.ADD_PORTFOLIODB: {

            return [...state, action.data]
        }

        case PortfolioDBActionsList.EDIT_PORTFOLIODB: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.pfid === action.data.pfid)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case PortfolioDBActionsList.FETCH_PORTFOLIODB: {

            return action.data
        }

        case PortfolioDBActionsList.DELETE_PORTFOLIODB: {
            return state.filter(i => i.pfid !== action.data.id)

        }

        default: return state
    }

}