import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface ItemCategoryType {
    catid?: number    
    portfolioid: string
    depid: number
    category: string
    active: ActiveTypes | boolean
    createdon?: string
}

export enum ItemCategoryActionsList {
    ADD_ITEMCATEGORY = 'ADD_ITEMCATEGORY',
    EDIT_ITEMCATEGORY = 'EDIT_ITEMCATEGORY',
    FETCH_ITEMCATEGORY = 'FETCH_ITEMCATEGORY',
    DELETE_ITEMCATEGORY = 'DELETE_ITEMCATEGORY'
}

export interface DeleteItemCategory {
    catid: number
}

export interface AddItemCategoryAction {
    type: ItemCategoryActionsList.ADD_ITEMCATEGORY
    data: ItemCategoryType
}

export interface EditItemCategoryAction {
    type: ItemCategoryActionsList.EDIT_ITEMCATEGORY
    data: ItemCategoryType
    id?: number
}

export interface FetchItemCategoryAction {
    type: ItemCategoryActionsList.FETCH_ITEMCATEGORY
    data: ItemCategoryType[]
}

export interface DeleteItemCategoryAction {
    type: ItemCategoryActionsList.DELETE_ITEMCATEGORY
    data: DeleteItemCategory
}

export type ItemCategoryActions = AddItemCategoryAction | EditItemCategoryAction | FetchItemCategoryAction | DeleteItemCategoryAction

export const addItemCategory = (data: ItemCategoryType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<ItemCategoryType>>('itemcategory/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddItemCategoryAction>({
                    type: ItemCategoryActionsList.ADD_ITEMCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Item Category added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}

export const editItemCategory = (data: ItemCategoryType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<ItemCategoryType>>(`itemcategory/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditItemCategoryAction>({
                    type: ItemCategoryActionsList.EDIT_ITEMCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Item Category Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchItemCategory = (pfId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<ItemCategoryType[]>>(`itemcategory/${pfId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchItemCategoryAction>({
                    type: ItemCategoryActionsList.FETCH_ITEMCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}


export const fetchItemCategoryByDepartment = (pfId: string, deptid: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.get<APIResponse<ItemCategoryType[]>>(`itemcategory/${pfId}/${deptid}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchItemCategoryAction>({
                    type: ItemCategoryActionsList.FETCH_ITEMCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const fetchItemCategoryById = (pfId: string, catid: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.get<APIResponse<ItemCategoryType[]>>(`itemcategory/${pfId}/${catid}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchItemCategoryAction>({
                    type: ItemCategoryActionsList.FETCH_ITEMCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteItemCategory = (pfId: string, catid: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<ItemCategoryType>>(`itemcategory/${pfId}/${catid}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteItemCategoryAction>({
                    type: ItemCategoryActionsList.DELETE_ITEMCATEGORY,
                    data: { catid }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Item Category Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}