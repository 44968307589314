import PageTitle from "../../components/custom/components/PageTitle";
import AdminLayout from "../../components/custom/layouts/AdminLayout";

const ActivateTenant = () => {
    return <AdminLayout>
        <PageTitle
            title='Activate Tenant'
        />
        {/* <div className="card">
       <div className="card-body">
           
       </div>
   </div> */}
    </AdminLayout>
}

export default ActivateTenant;      
