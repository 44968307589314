import { BrandActionsList, BrandType } from "../actions/brandActions";
import { Action } from "../actions/types";

const initialValue: Array<BrandType> = []

export const BrandReducer = (state: BrandType[] = initialValue, action: Action) => {

    switch (action.type) {

        case BrandActionsList.ADD_BRAND: {

            return [...state, action.data]
        }

        case BrandActionsList.EDIT_BRAND: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case BrandActionsList.FETCH_BRAND: {

            return action.data

        }

        case BrandActionsList.DELETE_BRAND: {
            return state.filter(i => i.id !== action.data.id)

        }
        case BrandActionsList.CLEAR_BRAND: {
            return initialValue

        }

        default: return state
    }

}