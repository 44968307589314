import React, { useState, useEffect, ChangeEventHandler } from "react";
import { useToast } from "../../components/toast/ToastProvider";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../redux/store";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { BrandCompanyMappingType } from "../../redux/actions/brandCompanyMappingActions";
import { BrandCompanyType } from "../../redux/actions/brandCompanyActions";
import { BrandType } from "../../redux/actions/brandActions";
import { FormType, FormTypeList } from "../../config";
import AdminTable,{
  Tbody,
  Thead,
} from "../../components/custom/components/AdminTable";
import { nanoid } from "@reduxjs/toolkit";
// import api, { getAxiosRequestConfig } from "../../config/api";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import OutletForm from "../../components/admin/OutletForm";
import Modal from "../../components/Modal";
import { fetchOutlet } from "../../redux/actions/outletActions";
import { useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TradeCategoryType } from "../../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../../redux/actions/tradeSubCategoryActions";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

// type handleDelete = () => void;

const Outlet = () => {
  // const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";

  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );

  const brandmaplist = useSelector<StoreState, Array<BrandCompanyMappingType>>(
    (state) => state.brandCompanyMapping
  );

  // const brandCompanyList = useSelector<StoreState, Array<BrandCompanyType>>(
  //   (state) => state.brandCompany
  // );

  const brandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state.brand
  );

  const catList = useSelector<StoreState, Array<TradeCategoryType>>(
    (state) => state.tradeCategory
  );
  const subcatList = useSelector<StoreState, Array<TradeSubCategoryType>>(
    (state) => state.tradeSubCategory
  );

  const [showForm, setShowForm] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<BrandCompanyMappingType>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [id, setId] = useState<number>();

  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery({ search: e.target.value });
  };

  const searchOutletHandler = (item: any, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(
          item[1]["text"] + " " + item[2]["text"]
        )
      : true || [];
  };

  const [brandMapData, setBrandMapData] = useState<BrandCompanyMappingType[]>(
    []
  );

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    if (brandmaplist?.length > 0) {
      setLength(brandmaplist?.length ? brandmaplist?.length : 0);
    }
  }, [brandmaplist]);

  const handleApply = async (offsetVal = offset) => {
    if (brandmaplist?.length > 0) {
      const getData = {
        limit: limit?.toString(),
        offset: offsetVal?.toString(),
      };

      if (
        getData !== undefined &&
        getData.limit !== "" &&
        getData.offset !== "" &&
        getData.offset !== undefined &&
        getData.offset !== "NaN"
      ) {
        const filteredBrandMapList: BrandCompanyMappingType[] =
          brandmaplist?.slice(offset, limit + offset);
        if (filteredBrandMapList?.length > 0) {
          setBrandMapData([...filteredBrandMapList]);
        } else {
          setBrandMapData([]);
        }
      }
    }
  };

  useEffect(() => {
    handleApply();
  }, [brandmaplist]);

  const getTableData = () => {
    if (brandMapData === undefined) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Portfolio", children: "Portfolio", text: "Portfolio" },
      { id: "Brand", children: "Brand", text: "Brand" },
      {
        id: "Trade Category",
        children: "Trade Category",
        text: "Trade Category",
      },
      {
        id: "Trade Sub Category",
        children: "Trade Sub Category",
        text: "Trade Sub Category",
      },
      { id: "Status", children: "Status", text: "Status" },
      { id: "Action", children: "Action", text: "Action" },
    ];

    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];

    brandMapData.forEach((item, i) => {
      let pindex = portfolioList.findIndex((p) => {
        return p.id === item.pfid;
      });
      var pname = portfolioList[pindex]?.name;

      let bindex = brandList.findIndex((b) => {
        return b.id === item.brandid;
      });

      var bname = brandList[bindex]?.name;

      let ctindex = catList.findIndex((a) => a.id === item.tradecatid);
      var ctname = catList[ctindex]?.name;

      let sctindex = subcatList.findIndex((a) => a.id === item.tradesubcatid);
      var sctname = subcatList[sctindex]?.name;

      const pageNumber = Math.floor(offset / limit) + 1;

      var tBody: Tbody[] = [];
      tBody.push(
        {
          id: nanoid(),
          theadId: "S.No",
          children: limit * pageNumber + i - limit + 1,
          text: (limit * pageNumber + i - limit + 1)?.toString(),
        },
        {
          id: nanoid(),
          theadId: "Portfolio",
          children: pname,
          text: pname,
        },
        {
          id: nanoid(),
          theadId: "Brand",
          children: bname,
          text: bname,
        },
        {
          id: nanoid(),
          theadId: "Trade Category",
          children: ctname,
          text: ctname,
        },
        {
          id: nanoid(),
          theadId: "Trade Sub Category",
          children: sctname,
          text: sctname,
        },
        {
          id: nanoid(),
          theadId: "Status",
          children: item.active ? "Active" : !item.active ? "InActive" : "",
          text: item.active ? "Active" : !item.active ? "InActive" : "",
        },
        {
          id: nanoid(),
          theadId: "Action",
          children: "action",
          className: "d-none",
          text: item.id?.toString(),
        }
      );
      tableBody.push(tBody);
      // })
    });
    if (searchTerm?.length > 0) {
      tableBodyFiltered = tableBody.filter((item) =>
        searchOutletHandler(item, searchTerm)
      );
    } else {
      tableBodyFiltered = tableBody;
    }

    if (brandMapData !== undefined) {
      return { tbody: tableBodyFiltered, thead: tableHead };
    }
  };

  const handleAdd = () => {
    setShowForm(true);
    if(adminAuthendicated){
      const logProps1 = {
        action: 'Create',
        event: `${pageName.pathname.replace("\\", " ")} page | Create Outlet Clicked`
      };
      AdminLog(logProps1)
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = brandmaplist.find((a) => a.id === id);
      if (obj?.pfid) dispatch(fetchOutlet(obj.pfid?.toString(), obj.brandid));
      setEditData(obj);
      setShowForm(true);
      const pobj = portfolioList.filter((a)=>a.id?.toString()===obj?.pfid.toString())
      const bobj = brandList.filter((a)=>a.id?.toString()===obj?.brandid.toString())
      const tobj = catList.filter((a)=>a.id?.toString()===obj?.tradecatid.toString())
      const tsobj = subcatList.filter((a)=>a.id?.toString()===obj?.tradecatid.toString())
      const logProps1 = {
        action:`Edit`,
        event: `${pageName.pathname.replace("\\", " ")} page | Edit Clicked | Details: Portfolio-${pobj[0].name},Brand-${bobj[0].name},TradeCategory-${tobj[0].name},TradeSubCateogry-${tsobj[0]?.name} `
      };
      AdminLog(logProps1)
    }
  };
  

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    navigate("/admin/outlet_management");
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    //else if (id !== undefined && formType === FormTypeList.DELETE)
    //handleDeleteConfirmation();
  }, [id, formType]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <div>
      <>
        <Modal
          headerText={formType + " Outlet Management"}
          visible={showForm}
          onClose={handleFormClose}
          size="xl"
          centered
        >
          <OutletForm
            formType={formType}
            editData={editData}
            showViewForm={showViewForm}
          />
        </Modal>

        <AdminLayout>
          <AdminTable2
            addBtnText="Create New Outlet"
            onAdd={handleAdd}
            onEdit={handleEdit}
            search={searchHandler}
            limit={limit}
            setOffset={setOffset}
            offset={offset}
            onPageNoChange={handleApply}
            range={range}
            pageTitle="Outlet Management"
            path="/admin/outlet_management"
            setId={setId}
            {...getTableData()}
          />
        </AdminLayout>
      </>
    </div>
  );
};

export default Outlet;
