import { nanoid } from "@reduxjs/toolkit";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import PortfolioForm from "../../components/admin/PortfolioForm";
import  {
  Tbody,
  Thead,
} from "../../components/custom/components/AdminTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import { CompanyType } from "../../redux/actions/companyActions";
import {
  PortfolioType,
  deletePortfolio,
  fetchPortfolio,
} from "../../redux/actions/portfolioActions";
import { fetchPortfolioDB } from "../../redux/actions/portfolioDBActions";
import { AppDispatch, StoreState } from "../../redux/store";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
import { SiteType } from "../../redux/actions/siteActions";
import { BUSINESS_MODELS } from "../../components/admin/PortfolioForm";
import {
  BrandCompanyType,
  deleteBrandCompany,
} from "../../redux/actions/brandCompanyActions";
import { BrandType } from "../../redux/actions/brandActions";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { AdminLog} from "../../config/common"
import { AuthAdminType } from "../../redux/actions/authAdminActions";

// interface TableBody {
//     sno: number
//     companyid: number
//     companyname?: string
//     name: string
//     url: string
//     code: string
//     logopath: string
//     logourl: string
//     active: ActiveTypes | boolean
//     onEdit: MouseEventHandler<HTMLButtonElement>
//     onDelete: MouseEventHandler<HTMLButtonElement>
//     onView: MouseEventHandler<HTMLButtonElement>
//     permission: Permission
// }

// type HandleEdit = (value: PortfolioType) => void
type HandleDelete = (value: PortfolioType) => void;
// type HandleView = (value: PortfolioType) => void

const Portfolio = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
   const navigate = useNavigate();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );
  const siteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  );
  const companyList = useSelector<StoreState, Array<CompanyType>>(
    (state) => state.company
  );

  const brandcompanyList = useSelector<StoreState, Array<BrandCompanyType>>(
    (state) => state.brandCompany
  );

  const brandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state.brand
  );

  const [showForm, setShowForm] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<PortfolioType>();
  const [deleteData, setDeleteData] = useState<PortfolioType>();
  const [disableDelete, setDisableDelete] = useState<boolean>(false);
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [id, setId] = useState<number>();

  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const [portfolioData, setPortfolioData] = useState<PortfolioType[]>([]);

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    if (portfolioList?.length > 0) {
      setLength(portfolioList?.length ? portfolioList?.length : 0);
    }
  }, [portfolioList]);

   useEffect(()=>{
     navigate("/admin/porfolio_management");
  },[])

  const handleApply = async (offsetVal = offset) => {
    if (portfolioList?.length > 0) {
      const getData = {
        limit: limit?.toString(),
        offset: offsetVal?.toString(),
      };

      if (
        getData !== undefined &&
        getData.limit !== "" &&
        getData.offset !== "" &&
        getData.offset !== undefined &&
        getData.offset !== "NaN"
      ) {
        const filteredPortfolioList: PortfolioType[] = portfolioList?.slice(
          offset,
          limit + offset
        );
        if (filteredPortfolioList?.length > 0) {
          setPortfolioData([...filteredPortfolioList]);
        } else {
          setPortfolioData([]);
        }
      }
    }
  };

  useEffect(() => {
    handleApply();
  }, [portfolioList]);

  //brandcompanyid
  const [brandCompanyId, setBrandCompanyId] = useState<string>("");

  const searchPortfolioHandler = (
    portfolio: PortfolioType,
    searchTerm: string
  ) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(portfolio.name)
      : true || [];
  };

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery({ search: e.target.value });
  };

  const getTableData = () => {
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Name", children: "Name", text: "Name" },
      { id: "Website URL", children: "Website URL", text: "Website URL" },
      { id: "Code", children: "Code", text: "Code" },
      { id: "Logo Path", children: "Logo Path", text: "Logo Path" },
      { id: "Logo URL", children: "Logo URL", text: "Logo URL" },
      { id: "Company", children: "Company", text: "Company" },
      {
        id: "Business Model",
        children: "Business Model",
        text: "Business Model",
      },
      { id: "Status", children: "Status", text: "Status" },
      { id: "Action", children: "Action", text: "Action" },
    ];

    var tableBody: Tbody[][] = [];

    portfolioData
      .filter((cr) => searchPortfolioHandler(cr, searchTerm))
      .forEach((item, i) => {
        const pageNumber = Math.floor(offset / limit) + 1;
        let index = companyList.findIndex((c, i) => {
          return c.id === item.companyid;
        });
        var cname = companyList[index]?.name;

        let bindex = BUSINESS_MODELS.findIndex((b, i) => {
          return b.value === item.businessmodel;
        });
        var bmodel = BUSINESS_MODELS[bindex]?.text;

        var tBody: Tbody[] = [];
        tBody.push(
          {
            id: nanoid(),
            theadId: "S.No",
            children: limit * pageNumber + i - limit + 1,
            text: (limit * pageNumber + i - limit + 1)?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Name",
            children: item.name,
            text: item.name,
          },
          {
            id: nanoid(),
            theadId: "Website URL",
            children: item.url,
            text: item.url,
          },
          {
            id: nanoid(),
            theadId: "Code",
            children: item.code,
            text: item.code,
          },
          {
            id: nanoid(),
            theadId: "Logo Path",
            children: item.logopath,
            text: item.logopath?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Logo URL",
            children: item.logourl,
            text: item.logourl,
          },
          {
            id: nanoid(),
            theadId: "Company",
            children: cname,
            text: cname,
          },
          {
            id: nanoid(),
            theadId: "Business Model",
            children: bmodel,
            text: bmodel,
          },
          {
            id: nanoid(),
            theadId: "Status",
            children: item.active ? "Active" : !item.active ? "InActive" : "",
            text: item.active ? "Active" : !item.active ? "InActive" : "",
          },
          {
            id: nanoid(),
            theadId: "Action",
            children: "action",
            className: "d-none",
            text: item.id?.toString(),
          }
        );
        tableBody.push(tBody);
        // })
      });

    return { tbody: tableBody, thead: tableHead };
  };

  const handleAdd = () => {
    setShowForm(true);
    if(adminAuthendicated){
      const logProps1 = {
        action: 'Create',
        event: `${pageName.pathname.replace("\\", " ")} page | Create Portfolio Clicked`
      };
      AdminLog(logProps1)
    }
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = portfolioList.find((c) => c.id === id);
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page | ${obj?.name} Portfolio Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = portfolioList.find((c) => c.id === id);
      setEditData(obj);
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | Portfolio - ${obj?.name} Portfolio | Edit Clicked`
        };
        AdminLog(logProps1)
      }
      
    }
  };
  const handleDeleteConfirmation = () => {
    let brand: BrandType[] = [];
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = portfolioList.find((c) => c.id === id);

      if (obj?.businessmodel === 1) {
        var brandCompanyobj: BrandCompanyType[] = brandcompanyList.filter(
          (a) => a.retailid?.toString() === obj?.id?.toString()
        );

        var bcid = brandCompanyobj[0]?.id;

        brand = brandList.filter(
          (a) => a.companyid?.toString() === bcid?.toString()
        );
      }
      setShowDeleteConfirmation(true);
      const portSites = siteList.filter((sl) => sl.pfid === id);
      setDeleteData(obj);
      if (obj?.businessmodel !== 1)
        setDisableDelete(portSites?.length > 0 ? true : false);
      else if (obj.businessmodel === 1)
        setDisableDelete(brand?.length > 0 ? true : false);
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (value.id !== undefined) {
      if (brandCompanyId) {
        dispatch(deleteBrandCompany(parseInt(brandCompanyId)));
      }
      dispatch(deletePortfolio(value.id))
        .then(async (text) => {
          dispatch(fetchPortfolio());
          dispatch(fetchPortfolioDB());
          toast(text, ToastTypes.SUCCESS);
          var obj = portfolioList.find((c) => c.id === id);
          if(adminAuthendicated){
            const logProps1 = {
              action: 'Delete',
              event: `${pageName.pathname.replace("\\", " ")} page | Portfolio - ${obj?.name} | Delete Clicked`
            };
            AdminLog(logProps1)
          }
          if (portfolioData !== undefined && portfolioData?.length === 1) {
            let newOffset = offset - limit < 0 ? 0 : offset - limit;
            setOffset(newOffset);
            await handleApply(newOffset);
          } else {
            await handleApply(); //issue starts from here
          }
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
  }, [id, formType]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " PORTFOLIO"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            disableDelete
              ? "Portfolio is in use. Deletion not allowed."
              : "Do you really want to delete the records with the portfolio - " +
                deleteData?.name
          }
          onCancel={handleFormClose}
          onDelete={() => (disableDelete ? {} : handleDelete(deleteData!))}
        />
      </Modal>
      <Modal
        headerText={formType + " PORTFOLIO"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <PortfolioForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
        />
      </Modal>
      <AdminLayout>
        {/* <PageTitle
                title='Portfolio Management'
                buttonText='Create New Portfolio'
                onButtonClick={handleAdd}
            /> */}
        <AdminTable2
          addBtnText="Create New Portfolio"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          search={searchHandler}
          limit={limit}
          setOffset={setOffset}
          offset={offset}
          onPageNoChange={handleApply}
          range={range}
          pageTitle="Portfolio Management"
          path="/admin/porfolio_management"
          // data={data}
          setId={setId}
          {...getTableData()}
        />
        {/* <div className="card mt-3">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-lg-6 col-sm-12">
                            <h5 className="header-title mb-3 float-start"><i className="fe-align-justify me-1 text-primary"></i>Portfolio Management</h5>
                        </div>
                        <div className="col-lg-6 col-sm-12 text-end mb-1">
                            {Permissions.add && <button type="button" onClick={handleAdd} className={`me-1 btn btn-xs btn-secondary`}>Create New Portfolio</button>}
                        </div>
                    </div>
                    <hr className='hr mt-0' />
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle text-center'>Name</th>
                                    <th className='text-truncate align-middle text-center'>Website URL</th>
                                    <th className='text-truncate align-middle text-center'>Code</th>
                                    <th className='text-truncate align-middle text-center'>Logo Path</th>
                                    <th className='text-truncate align-middle text-center'>Logo URL</th>
                                    <th className='text-truncate align-middle text-center'>Company Name</th>
                                    <th className='text-truncate align-middle text-center'>Status</th>
                                    <th className='text-truncate align-middle text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {portfolioList.map((u, i) => {
                                    let index = companyList.findIndex((c, i) => {
                                        return c.id === u.companyid
                                    })
                                    var cname = companyList[index]?.name
                                    return <PortfolioBody
                                        sno={i + 1}
                                        name={u.name}
                                        url={u.url}
                                        code={u.code}
                                        logopath={u.logopath}
                                        logourl={u.logourl}
                                        companyid={u.companyid}
                                        active={u.active}
                                        companyname={cname}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                        onView={() => handleView(u)}
                                        permission={Permissions}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
      </AdminLayout>
    </>
  );
};

// const PortfolioBody = ({ sno, name, url, code, logopath, logourl, active, companyid, onView, onEdit, onDelete, companyname, permission }: TableBody) => {
//     return <tr>
//         <td className='align-middle text-center'>{sno}</td>
//         <td className='align-middle'>{name}</td>
//         <td className='align-middle'>{url}</td>
//         <td className='align-middle'>{code}</td>
//         <td className='align-middle'>{logopath}</td>
//         <td className='align-middle'>{logourl}</td>
//         <td className='align-middle text-center'>{companyname}</td>
//         <td className='align-middle'>{active ? "Active" : "InActive"}</td>
//         <td>
//             <div className='hstack gap-1'>
//                 {permission.edit && <button className='btn btn-sm btn-secondary' onClick={onEdit}>Edit</button>}
//                 {permission.delete && <button className='btn btn-sm btn-secondary' onClick={onDelete}>Delete</button>}
//                 {permission.view && <button className='btn btn-sm btn-secondary' onClick={onView}>View</button>}
//             </div>
//         </td>
//     </tr>
// }

export default Portfolio;
