import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import { CompanyType } from "../../redux/actions/companyActions";
import {
  ActiveDropDown,
  ActiveTypes,
  PortfolioType,
  addPortfolio,
  editPortfolio,
  fetchPortfolio,
} from "../../redux/actions/portfolioActions";
import {
    fetchPortfolioDB,
} from "../../redux/actions/portfolioDBActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { FormInput } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { testCode } from "../../config/functions";
import { CountryType } from "../../redux/actions/countryActions";
import { fetchBrandCompany } from "../../redux/actions/brandCompanyActions";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

export const BUSINESS_MODELS = [
  {
    text: "RETAIL",
    value: 1,
  },
  {
    text: "MALL",
    value: 2,
  },
  {
    text: "AIRPORT",
    value: 3,
  },
];

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

interface Props {
  formType: FormType;
  editData?: PortfolioType;
  onSave: () => void;
  showViewForm: boolean;
}

const PortfolioForm = ({ formType, onSave, editData, showViewForm }: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const companies = useSelector<StoreState, Array<CompanyType>>(
    (state) => state.company
  );
  // const portfolioDBList = useSelector<StoreState, Array<PortfolioDBType>>(
  //   (state) => state.portfolioDB
  // );

  const CountryList = useSelector<StoreState, Array<CountryType>>(
    (state) => state.country
  );

  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [urlError, setUrlError] = useState<string>("");
  const [logopath, setLogopath] = useState<string>("");
  const [logopathError, setLogopathError] = useState<string>("");
  const [logourl, setLogourl] = useState<string>("");
  const [logourlError, setLogourlError] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [codeError, setCodeError] = useState<string>("");
  const [companyid, setCompanyid] = useState<string>("");
  const [companyidError, setCompanyidError] = useState<string>("");
  const [businessmodel, setBusinessmodel] = useState<string>("2");
  const [businessmodelError, setBusinessmodelError] = useState<string>("");
  const [reportingcurrency, setReportingcurrency] = useState<string>("");
  const [reportingcurrencyError, setReportingcurrencyError] =
    useState<string>("");
  const [currencysymbol, setCurrencysymbol] = useState<string>("");
  const [currencysymbolError, setCurrencysymbolError] = useState<string>("");
  const [valuecaption, setValuecaption] = useState<string>("");
  const [valuecaptionError, setValuecaptionError] = useState<string>("");
  const [active, setActive] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [activeError, setActiveError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  // const [pfid, setPfid] = useState<string>("");
  // const [pfidError, setPfidError] = useState<string>("");
  // const [uid, setUid] = useState<string>('')
  // const [uidError, setUidError] = useState<string>('')
  // const [server, setServer] = useState<string>('')
  // const [serverError, setServerError] = useState<string>('')
  // const [pwd, setPwd] = useState<string>('')
  // const [pwdError, setPwdError] = useState<string>('')
  // const [dbname, setDbname] = useState<string>('')
  // const [dbnameError, setDbnameError] = useState<string>('')

  const resetStates = () => {
    setName("");
    setNameError("");
    setUrl("");
    setUrlError("");
    setCode("");
    setCodeError("");
    setLogopath("");
    setLogopathError("");
    setLogourl("");
    setLogourlError("");
    !editData && setReportingcurrency("");
    setReportingcurrencyError("");
    !editData && setCurrencysymbol("");
    setCurrencysymbolError("");
    setBusinessmodel("2");
    setBusinessmodelError("");
    setValuecaption("");
    setValuecaptionError("");
    setActive(ActiveDropDown.Active);
    setActiveError("");
    setCompanyid("");
    setCompanyidError("");
    setLoading(false);
    // setUid('')
    // setUidError('')
    // setServer('')
    // setServerError('')
    // setPwd('')
    // setPwdError('')
    // setDbname('')
    // setDbnameError('')
  };

  const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
    setNameError("");
  };

  const handleLogourl: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLogourl(e.target.value);
    setLogourlError("");
  };

  const handleLogopath: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLogopath(e.target.value);
    setLogopathError("");
  };

  const handleCode: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCode(e.target.value);
    setCodeError("");
  };

  const handleActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setActive(e.target.value as ActiveTypes);
    setActiveError("");
  };

  const handleCompanyid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCompanyid(e.target.value);
    setCompanyidError("");
  };

  const handleBusinessmodel: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBusinessmodel(e.target.value);
    setBusinessmodelError("");
  };

  const handleUrl: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUrl(e.target.value);
    setUrlError("");
  };

  const handleReportingcurrency: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value.toUpperCase();
    if (value?.length === 3) {
      const index = CountryList.find((a) => a.shortcode === value?.toString());

      const symbol =
        index && index.currencysymbol !== undefined ? index.currencysymbol : "";
      setCurrencysymbol(symbol);
      setCurrencysymbolError("");
    } else {
      setCurrencysymbol("");
    }
    setReportingcurrency(value);
    setReportingcurrencyError("");
  };

  const handleCurrencysymbol: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCurrencysymbol(e.target.value);
    setCurrencysymbolError("");
  };

  const handleValueCaption: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValuecaption(e.target.value);
    setValuecaptionError("");
  };

  //PORTFOLIO DB

  // const handleUid: ChangeEventHandler<HTMLInputElement> = (e) => {
  //     setUid(e.target.value)
  //     setUidError('')
  // }

  // const handleServer: ChangeEventHandler<HTMLInputElement> = (e) => {
  //     setServer(e.target.value)
  //     setServerError('')
  // }

  // const handlePwd: ChangeEventHandler<HTMLInputElement> = (e) => {
  //     setPwd(e.target.value)
  //     setPwdError('')
  // }

  // const handleDbname: ChangeEventHandler<HTMLInputElement> = (e) => {
  //     setDbname(e.target.value)
  //     setDbnameError('')
  // }

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const data: PortfolioType = {
      name: name,
      url: url,
      logourl: logourl,
      logopath: logopath,
      code: code,
      companyid: parseInt(companyid),
      businessmodel: parseInt(businessmodel),
      reportingcurrency: reportingcurrency,
      currencysymbol: currencysymbol,
      valuecaption: valuecaption,
      active: active,

      // uid: uid,
      // server: server,
      // pwd: formType === FormTypeList.ADD ? pwd : '',
      // dbname: dbname
    };

    if (data.name === "") {
      setNameError("Portfolio Name required");
      error = true;
    } else if (data.name?.length > 60) {
      setNameError("Portfolio Name should be within 60 characters");
      error = true;
    }

    if (formType === FormTypeList.EDIT) {
      if (data.active?.toString() === "") {
        setActiveError("Active required");
        error = true;
      }
    }

    if (isNaN(data.companyid)) {
      setCompanyidError("Companyid required");
      error = true;
    }

    if (data.businessmodel ? isNaN(data.businessmodel) : false) {
      setBusinessmodel("Business Model required");
      error = true;
    }

    if (data.logourl === "") {
      setLogourlError("Logo Url required");
      error = true;
    } else if (data.logourl?.length > 100) {
      setLogourlError("Logo Url should be within 100 digits");
      error = true;
    }

    if (data.logopath === "") {
      setLogopathError("Logo path required");
      error = true;
    } else if (data.logopath?.length > 100) {
      setLogopathError("Logo path should be within 100 digits");
      error = true;
    }

    if (data.reportingcurrency === "") {
      setReportingcurrencyError(" Reporting currency required");
      error = true;
    } else if (data.reportingcurrency?.length > 3) {
      setReportingcurrencyError(
        " Reporting currency should be within 3 characters"
      );
      error = true;
    }

    if (data.currencysymbol === "") {
      setCurrencysymbolError("Currency Symbol required");
      error = true;
    } else if (data.currencysymbol?.length > 5) {
      setCurrencysymbolError("Currency Symbol should be within 5 characters");
      error = true;
    }

    if (data.valuecaption === "") {
      setValuecaptionError("Value Caption required");
      error = true;
    } else if (data.valuecaption?.length > 20) {
      setValuecaptionError("Value Caption should be within 20 characters");
      error = true;
    }

    if (data.url === "") {
      setUrlError("Website URL required");
      error = true;
    } else if (data.url?.length > 100) {
      setUrlError("Website URL should be within 100 digits");
      error = true;
    }

    if (data.code === "") {
      setCodeError("Code required");
      error = true;
    } else if (data.code?.length > 15) {
      setCodeError("Code should be within 15 digits");
      error = true;
    } else if (testCode(data.code)) {
      setCodeError("Code should not allow special characters/space");
      error = true;
    }
    //portfolio DB

    // if (data.uid === '') {
    //     setUidError('User required')
    //     error = true
    // } else if ((data.uid !== undefined && data.uid?.length > 100)) {
    //     setUidError('User should be within 100 digits')
    //     error = true
    // }

    // if (formType === FormTypeList.ADD) {
    //     if (data.pwd === '') {
    //         setPwdError('Password required')
    //         error = true
    //     } else if ((data.pwd !== undefined && data.pwd?.length > 100)) {
    //         setPwdError('Password should be within 100 digits')
    //         error = true
    //     }
    // }

    // if (data.server === '') {
    //     setServerError('Server required')
    //     error = true
    // } else if ((data.server !== undefined && data.server?.length > 100)) {
    //     setServerError('Server should be within 100 digits')
    //     error = true
    // }

    // if (data.dbname === '') {
    //     setDbnameError('DB Name required')
    //     error = true
    // } else if ((data.dbname !== undefined && data.dbname?.length > 100)) {
    //     setDbnameError('DB Name should be within 100 digits')
    //     error = true
    // }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addPortfolio(data))
          .then((text) => {
            onSave();
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            dispatch(fetchPortfolio());
            dispatch(fetchPortfolioDB());
            dispatch(fetchBrandCompany());
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.id !== undefined
      ) {
        setLoading(true);
        dispatch(editPortfolio(data, editData.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
            dispatch(fetchPortfolio());
            dispatch(fetchPortfolioDB());
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined &&
      editData.id !== undefined
    ) {
      setName(editData.name);
      setCode(editData.code);
      setLogourl(editData.logourl);
      setLogopath(editData.logopath);
      setCompanyid(editData.companyid?.toString());
      setReportingcurrency(editData.reportingcurrency);
      setBusinessmodel(editData.businessmodel?.toString());
      setCurrencysymbol(editData.currencysymbol);
      setValuecaption(editData.valuecaption);
      setUrl(editData.url);

      setActive(
        editData.active === true
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );

      // const index = portfolioDBList.findIndex(
      //   (p) => p.pfid?.toString() === editData.id?.toString()
      // );
      // setUid(portfolioDBList[index]?.uid)
      // setServer(portfolioDBList[index]?.server)
      // setDbname(portfolioDBList[index]?.dbname)
    } else {
      resetStates();
    }
  }, [formType, editData]);



  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Portfolio Name"
            label="Portfolio Name"
            labelClassName="required"
            value={name}
            onChange={handleName}
            placeholder="Portfolio Name"
            errorText={nameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Website URL"
            label="Website URL"
            labelClassName="required"
            value={url}
            onChange={handleUrl}
            placeholder="Website URL"
            errorText={urlError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Code"
            label="Code"
            labelClassName="required"
            style={{ textTransform: "lowercase" }}
            value={code}
            onChange={handleCode}
            placeholder="Code"
            errorText={codeError}
            containerClass="mb-2"
            disabled={showViewForm || (editData && true)}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Logo URL"
            label="Logo URL"
            labelClassName="required"
            value={logourl}
            onChange={handleLogourl}
            placeholder="Logo URL"
            errorText={logourlError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Logo Path"
            label="Logo Path"
            labelClassName="required"
            value={logopath}
            onChange={handleLogopath}
            placeholder="Logo Path"
            errorText={logopathError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Reporting Currency"
            label="Reporting Currency"
            labelClassName="required"
            value={reportingcurrency}
            onChange={handleReportingcurrency}
            placeholder="Reporting Currency"
            errorText={reportingcurrencyError}
            containerClass="mb-2"
            disabled={showViewForm || (editData && true)}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Currency Symbol"
            label="Currency Symbol"
            labelClassName="required"
            value={currencysymbol}
            onChange={handleCurrencysymbol}
            placeholder="Currency Symbol"
            errorText={currencysymbolError}
            containerClass="mb-2"
            disabled={showViewForm || (editData && true)}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Value Caption"
            label="Value Caption"
            labelClassName="required"
            value={valuecaption}
            onChange={handleValueCaption}
            placeholder="Value Caption"
            errorText={valuecaptionError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        {(formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Status"
              label="Status"
              labelClassName="required"
              value={active}
              onChange={handleActive}
              placeholder="Status"
              errorText={activeError}
              containerClass="mb-2"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {ACTIVE_DROPS.map((a, i) => {
                return (
                  <Option value={a.value?.toString()} key={i}>
                    {a.text}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        )}
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Company Name"
            label="Company Name"
            labelClassName="required"
            value={companyid}
            onChange={handleCompanyid}
            placeholder="Company Name"
            errorText={companyidError}
            containerClass="mb-2"
            disabled={showViewForm || (editData && true)}
            type="select"
          >
            <option value="">Select</option>
            {companies.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a?.name}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Business Model"
            label="Business Model"
            labelClassName="required"
            value={businessmodel}
            onChange={handleBusinessmodel}
            placeholder="Business Model"
            errorText={businessmodelError}
            containerClass="mb-2"
            disabled={showViewForm || (editData && true)}
            type="select"
          >
            <option value="">Select</option>
            {BUSINESS_MODELS.map((a, i) => {
              return (
                <Option value={a.value} key={i}>
                  {a.text}
                </Option>
              );
            })}
          </FormInput>
        </div>
      </div>
      {/* PORTFOLIO DB */}

      {/* <hr />
        {formType === FormTypeList.ADD && <h4>ADD PORTFOLIO DATABASE DETAILS</h4>}
        {formType === FormTypeList.EDIT && <h4>EDIT PORTFOLIO DATABASE DETAILS</h4>}
        {formType === FormTypeList.VIEW && <h4>VIEW PORTFOLIO DATABASE DETAILS</h4>}
        <br />
        <br /> */}
      {/* <br /> */}
      {/* <div className="row">
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='User Name'
                    label='User Name'
                    labelClassName="required"
                    value={uid}
                    onChange={handleUid}
                    placeholder='User Name'
                    errorText={uidError}
                    containerClass="mb-2"
                    disabled={showViewForm}
                />
            </div>
            {formType === FormTypeList.ADD && <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Password'
                    label='Password'
                    labelClassName="required"
                    value={pwd}
                    onChange={handlePwd}
                    placeholder='Password'
                    errorText={pwdError}
                    containerClass="mb-2"
                    disabled={showViewForm}
                />
            </div>}
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Server'
                    label='Server'
                    labelClassName="required"
                    value={server}
                    onChange={handleServer}
                    placeholder='Server'
                    errorText={serverError}
                    containerClass="mb-2"
                    disabled={showViewForm}
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='DB Name'
                    label='DB Name'
                    labelClassName="required"
                    value={dbname}
                    onChange={handleDbname}
                    placeholder='DB Name'
                    errorText={dbnameError}
                    containerClass="mb-2"
                    disabled={showViewForm}
                />
            </div>
        </div> */}

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default PortfolioForm;
