import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface ItemSubcategoryType {
    subcatid?: number
    catid: number
    depid: number
    portfolioid: string
    subcategory: string
    active: ActiveTypes | boolean
    createdon?: string
}

export enum ItemSubcategoryActionsList {
    ADD_ITEMSUBCATEGORY = 'ADD_ITEMSUBCATEGORY',
    EDIT_ITEMSUBCATEGORY = 'EDIT_ITEMSUBCATEGORY',
    FETCH_ITEMSUBCATEGORY = 'FETCH_ITEMSUBCATEGORY',
    DELETE_ITEMSUBCATEGORY = 'DELETE_ITEMSUBCATEGORY'
}

export interface DeleteItemSubcategory {
    subcatid: number
}

export interface AddItemSubcategoryAction {
    type: ItemSubcategoryActionsList.ADD_ITEMSUBCATEGORY
    data: ItemSubcategoryType
}

export interface EditItemSubcategoryAction {
    type: ItemSubcategoryActionsList.EDIT_ITEMSUBCATEGORY
    data: ItemSubcategoryType
    id?: number
}

export interface FetchItemSubcategoryAction {
    type: ItemSubcategoryActionsList.FETCH_ITEMSUBCATEGORY
    data: ItemSubcategoryType[]
}

export interface DeleteItemSubcategoryAction {
    type: ItemSubcategoryActionsList.DELETE_ITEMSUBCATEGORY
    data: DeleteItemSubcategory
}

export type ItemSubcategoryActions = AddItemSubcategoryAction | EditItemSubcategoryAction | FetchItemSubcategoryAction | DeleteItemSubcategoryAction

export const addItemSubcategory = (data: ItemSubcategoryType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<ItemSubcategoryType>>('itemsubcategory/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddItemSubcategoryAction>({
                    type: ItemSubcategoryActionsList.ADD_ITEMSUBCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Item Subcategory added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}

export const editItemSubcategory = (data: ItemSubcategoryType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<ItemSubcategoryType>>(`itemsubcategory/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditItemSubcategoryAction>({
                    type: ItemSubcategoryActionsList.EDIT_ITEMSUBCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Item Subcategory Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchItemSubcategory = (pfId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<ItemSubcategoryType[]>>(`itemsubcategory/${pfId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchItemSubcategoryAction>({
                    type: ItemSubcategoryActionsList.FETCH_ITEMSUBCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const fetchItemSubcategoryById = (pfId: string, subcatid: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<ItemSubcategoryType[]>>(`itemsubcategory/${pfId}/${subcatid}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchItemSubcategoryAction>({
                    type: ItemSubcategoryActionsList.FETCH_ITEMSUBCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const fetchItemSubcategoryByDepartment = (pfId: string, deptid: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.get<APIResponse<ItemSubcategoryType[]>>(`itemsubcategory/${pfId}/${deptid}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchItemSubcategoryAction>({
                    type: ItemSubcategoryActionsList.FETCH_ITEMSUBCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const fetchItemSubcategoryByCategory = (pfId: string, catid: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.get<APIResponse<ItemSubcategoryType[]>>(`itemsubcategory/${pfId}/${catid}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchItemSubcategoryAction>({
                    type: ItemSubcategoryActionsList.FETCH_ITEMSUBCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteItemSubcategory = (pfId: string,subcatid: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<ItemSubcategoryType>>(`itemsubcategory/${pfId}/${subcatid}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteItemSubcategoryAction>({
                    type: ItemSubcategoryActionsList.DELETE_ITEMSUBCATEGORY,
                    data: { subcatid }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Item Category Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}