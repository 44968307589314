import React from 'react'

export interface OptionType {
    text: string
    value: string
}

interface Props extends React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement> { }

const Option = (props: Props) => {
    return <option {...props} />
}

export default Option