import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import {
  ActiveDropDown,
  ActiveTypes,
  BrandType,
  addBrand,
  editBrand,
  fetchBrand,
} from "../../redux/actions/brandActions";
import { BrandCompanyType } from "../../redux/actions/brandCompanyActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { FormInput } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { AdminLog } from "../../config/common";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
// import { text } from "@fortawesome/fontawesome-svg-core";

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

interface Props {
  formType: FormType;
  editData?: BrandType;
  onSave: () => void;
  showViewForm: boolean;
}

const BrandForm = ({ formType, onSave, editData, showViewForm }: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const brandCompanies = [
    ...useSelector<StoreState, Array<BrandCompanyType>>(
      (state) => state.brandCompany
    ),
  ];

  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [urlError, setUrlError] = useState<string>("");
  const [logopath, setLogopath] = useState<string>("");
  const [logopathError, setLogopathError] = useState<string>("");
  const [logourl, setLogourl] = useState<string>("");
  const [logourlError, setLogourlError] = useState<string>("");
  const [companyid, setCompanyid] = useState<string>("");
  const [companyidError, setCompanyidError] = useState<string>("");
  const [active, setActive] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [activeError, setActiveError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const resetStates = () => {
    setName("");
    setNameError("");
    setUrl("");
    setUrlError("");
    setLogopath("");
    setLogopathError("");
    setLogourl("");
    setLogourlError("");
    setActive(ActiveDropDown.Active);
    setActiveError("");
    setCompanyid("");
    setCompanyidError("");
    setLoading(false);
  };
  const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
    setNameError("");
  };

  const handleLogourl: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLogourl(e.target.value);
    setLogourlError("");
  };

  const handleLogopath: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLogopath(e.target.value);
    setLogopathError("");
  };

  const handleCompanyid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCompanyid(e.target.value);
    setCompanyidError("");
  };

  const handleActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setActive(e.target.value as ActiveTypes);
    setActiveError("");
  };

  const handleUrl: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUrl(e.target.value);
    setUrlError("");
  };

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const data: BrandType = {
      name: name,
      url: url,
      logourl: logourl,
      logopath: logopath,
      companyid: parseInt(companyid),
      active: active,
    };

    if (data.name === "") {
      setNameError("Brand Name required");
      error = true;
    } else if (data.name?.length > 60) {
      setNameError("Brand Name should be within 60 characters");
      error = true;
    }

    if (data.active?.toString() === "") {
      setActiveError("Active required");
      error = true;
    }

    if (isNaN(data.companyid)) {
      setCompanyidError("Brand companyid required");
      error = true;
    }
    // else if ((data.companyid) > 15) {
    //     setCompanyidError('Brand companyid should be within 15 digits')
    //     error = true
    // }

    if (data.logourl === "") {
      setLogourlError("Logo Url required");
      error = true;
    } else if (data.logourl?.length > 100) {
      setLogourlError("Logo Url should be within 100 digits");
      error = true;
    }

    if (data.logopath === "") {
      setLogopathError("Logo path required");
      error = true;
    } else if (data.logopath?.length > 100) {
      setLogopathError("Logo path should be within 100 digits");
      error = true;
    }

    if (data.url === "") {
      setUrlError("Website URL required");
      error = true;
    } else if (data.url?.length > 100) {
      setUrlError("Website URL should be within 100 digits");
      error = true;
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addBrand(data))
          .then((text) => {
            dispatch(fetchBrand());
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.id !== undefined
      ) {
        setLoading(true);
        dispatch(editBrand(data, editData.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      setName(editData.name);
      setLogourl(editData.logourl);
      setLogopath(editData.logopath);
      setCompanyid(editData.companyid?.toString());
      setUrl(editData.url);
      setActive(
        editData.active === true
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );
    } else {
      resetStates();
    }
  }, [formType, editData]);




  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Brand Name"
            label="Brand Name"
            labelClassName="required"
            value={name}
            onChange={handleName}
            placeholder="Brand Name"
            errorText={nameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Website URL"
            label="Website URL"
            labelClassName="required"
            value={url}
            onChange={handleUrl}
            placeholder="Website URL"
            errorText={urlError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Logo URL"
            label="Logo URL"
            labelClassName="required"
            value={logourl}
            onChange={handleLogourl}
            placeholder="Logo URL"
            errorText={logourlError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Logo Path"
            label="Logo Path"
            labelClassName="required"
            value={logopath}
            onChange={handleLogopath}
            placeholder="Logo Path"
            errorText={logopathError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Status"
            label="Status"
            labelClassName="required"
            value={active}
            onChange={handleActive}
            placeholder="Status"
            errorText={activeError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {ACTIVE_DROPS.map((a, i) => {
              return (
                <Option value={a.value?.toString()} key={i}>
                  {a.text}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Brand Company"
            label="Brand Company"
            labelClassName="required"
            value={companyid}
            onChange={handleCompanyid}
            placeholder="Brand Company"
            errorText={companyidError}
            containerClass="mb-2"
            disabled={showViewForm || (editData && true)}
            type="select"
          >
            <option value="">Select</option>
            {brandCompanies
              .sort((a: BrandCompanyType, b: BrandCompanyType) => {
                if (a.name < b.name) return -1;
                else if (a.name > b.name) return 1;
                else return 0;
              })
              .filter((brand) => brand.active === true)
              .map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.name}
                  </Option>
                );
              })}
          </FormInput>
        </div>

        {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <div className="form-control border-0">
                    <label className="pb-2">Brand Logo</label>
                    <div className="border rounded border-2 border-secondray logo-lg pt-0 pb-1 m-0 w-75">
                        <img src={LogoDark} alt="" height="22" />
                    </div>
                    <div className="hstack gap-1 mt-1">
                        <button className="btn btn-secondary">Browser</button>
                        <button className="btn btn-secondary">Clear</button>
                    </div>
                </div>
            </div> */}
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default BrandForm;
