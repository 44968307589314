import { nanoid } from "@reduxjs/toolkit";
import React, { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Modal from "../../components/Modal";
import Option from "../../components/Option";
import {
  TenantManagerContext,
  TenantManagerContextType,
} from "../../components/context/TenantManagerContext";
import { FormInput } from "../../components/custom/components";
// import AdminTable from "../../components/custom/components/AdminTable";
import {
  Tbody,
  Thead,
} from "../../components/custom/components/TradeCategoryPerformanceTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormTypeList } from "../../config";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import {
  TenantManagerType,
  deleteTenantManager,
  fetchTenantManager,
} from "../../redux/actions/tenantManagerActions";
import { AppDispatch, StoreState } from "../../redux/store";
import AddTenant from "./AddTenantTab";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";
import { BrandType } from "../../redux/actions/brandActions";
import { fetchUnit } from "../../redux/actions/unitActions";
// interface TableBody {
//     sno: number
//     tenantManagerName: string
//     onEdit: MouseEventHandler<HTMLButtonElement>
//     onDelete: MouseEventHandler<HTMLButtonElement>
//     onView: MouseEventHandler<HTMLButtonElement>
// }

// type HandleEdit = (value: TenantManagerType) => void
type HandleDelete = (value: TenantManagerType) => void;
// type HandleView = (value: TenantManagerType) => void

const TenantManager = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
   const navigate = useNavigate();
  // const navigate = useNavigate();

  const {
    portfolioId,
    resetStates,
    // setCompanyName,
    // setTradeCategoryName,
    // setTradeSubCategoryName,
    // setTenantCategoryName,
    // setBrandName,
    // setCompany,
    // setFranchise,
    // setTradeCategory,
    // setTradeSubCategory,
    // setTenantCategory,
    // setBrand,
    // setTenantManagerName,
    // setCarpetArea,
    // setChargeableArea,
    // setLandmark,
    // setBuildingTerminal,
    // setLocation,
    // setPaxType,
    // setUnitName,
    // setPosType,
    // setPosName,
    // setITTeam,
    // setTenantContactDetails,
    // setBrandContactDetails,
    setportfolioId,
    setportfolioIdError,
    portfolioIdError,
    setFormType,
    formType,
    id,
    setId,
    setShowForm,
    showForm,
  } = React.useContext<TenantManagerContextType | null>(TenantManagerContext)!;

  const TenantManagerList = useSelector<StoreState, Array<TenantManagerType>>(
    (state) => state.tenantmanager
  );
  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );
  const BrandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state.brand
  );
  // const [portfolioId, setportfolioId] = useState<string>('')
  // const [portfolioIdError, setportfolioIdError] = useState<string>('')

  // const [showForm, setShowForm] = useState<boolean>(false)
  // const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<TenantManagerType>();
  const [editData, setEditData] = useState<TenantManagerType>();
  const pageName = useLocation();
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
    typeof authAdmin?.["auth-token"] === "string" &&
    authAdmin?.["auth-token"] !== undefined &&
    authAdmin?.["auth-token"] !== "";
  // const [id, setId] = useState<number>()

  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!portfolioId) {
      toast("Porfolio required", ToastTypes.ERROR);
      setSearchQuery({ search: "" });
      return;
    }
    setSearchQuery({ search: e.target.value });
  };

  const searchTenantManagerHandler = (item: any, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(item[1]["text"])
      : true || [];
  };

  const [tenManData, setTenManData] = useState<TenantManagerType[]>([]);

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

    useEffect(()=>{
     navigate("/admin/tenant_manager");
  },[])

  const handleApply = async (offsetVal = offset) => {
    if (portfolioId) {
      if (TenantManagerList?.length > 0) {
        const getData = {
          limit: limit?.toString(),
          offset: offsetVal?.toString(),
        };

        if (
          getData !== undefined &&
          getData.limit !== "" &&
          getData.offset !== "" &&
          getData.offset !== undefined &&
          getData.offset !== "NaN"
        ) {
          const filteredTenManList: TenantManagerType[] =
            TenantManagerList?.slice(offset, limit + offset);
          if (filteredTenManList?.length > 0) {
            setTenManData([...filteredTenManList]);
          } else {
            setTenManData([]);
          }
        }
      }
    }
  };

  const getTableData = () => {
    if (tenManData === undefined) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      {
        id: "Tenant Displayid",
        children: "Tenant Displayid",
        text: "Tenant Displayid",
      },
      { id: "Brand", children: "Brand", text: "Brand" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];

    if (portfolioId) {
      tenManData.forEach((item, i) => {
        var tBody: Tbody[] = [];
        const pageNumber = Math.floor(offset / limit) + 1;
        var bname =
          BrandList.length > 0 && item.brandid
            ? BrandList.find((b) => b.id === item.brandid)?.name
            : "";
        tBody.push(
          {
            id: nanoid(),
            theadId: "S.No",
            children: limit * pageNumber + i - limit + 1,
            text: (limit * pageNumber + i - limit + 1)?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Tenant Displayid",
            children: item.displayid,
            text: item.displayid,
            className: "text-truncate",
          },
          {
            id: nanoid(),
            theadId: "Brand",
            children: bname,
            text: bname,
            className: "text-truncate",
          },
          {
            id: nanoid(),
            theadId: "Action",
            children: "action",
            className: "d-none",
            text: item.tenantid?.toString(),
          }
        );
        tableBody.push(tBody);
        // })
      });
    }
    if (searchTerm?.length > 0) {
      tableBodyFiltered = tableBody.filter((item) =>
        searchTenantManagerHandler(item, searchTerm)
      );
    } else {
      tableBodyFiltered = tableBody;
    }

    if (tenManData !== undefined) {
      return { tbody: tableBodyFiltered, thead: tableHead };
    }

    return { tbody: tableBody, thead: tableHead };
  };
  const handleAdd = () => {
    if (portfolioId) {
      setShowForm(true);
    } else toast("Select Portfolio", ToastTypes.ERROR);
    // navigate("/admin/Tab");
  };

  const handlePfid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setportfolioId!(e.target.value);
    setportfolioIdError!("");
  };

  const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = TenantManagerList.find((c) => c.tenantid === id);
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
    }
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    // navigate("/admin/Tab");
    if (id !== undefined) {
      var obj = TenantManagerList.find(
        (c) => c.tenantid?.toString() === id?.toString()
      );
      setShowForm(true);
      setEditData(obj);
      if (adminAuthendicated) {
        const logProps1 = {
          action: "View",
          event: `${pageName.pathname.replace("\\", " ")} page |  ${
            obj?.displayid
          } Tenant Manager Viewed | View Clicked`,
        };
        AdminLog(logProps1);
      }
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    // navigate("/admin/Tab");
    if (portfolioId) {
      if (id !== undefined) {
        var obj = TenantManagerList.find(
          (c) => c.tenantid?.toString() === id?.toString()
        );
        setEditData(obj);
        setShowForm(true);
        if (adminAuthendicated) {
          const logProps1 = {
            action: "Edit",
            event: `${pageName.pathname.replace(
              "\\",
              " "
            )} page | Tenant Manager - ${obj?.displayid} | Edit Clicked`,
          };
          AdminLog(logProps1);
        }
      }
    } else toast("Select Portfolio", ToastTypes.ERROR);
  };

  const handleDelete: HandleDelete = (value) => {
    setFormType(FormTypeList.DELETE);
    if (value.tenantid !== undefined && portfolioId) {
      dispatch(deleteTenantManager(value.tenantid, portfolioId))
        .then(async (text) => {
          toast(text, ToastTypes.SUCCESS);
          dispatch(fetchTenantManager(portfolioId));
          var obj = TenantManagerList.find(
            (c) => c.tenantid?.toString() === id?.toString()
          );
          if (adminAuthendicated) {
            const logProps1 = {
              action: "Delete",
              event: `${pageName.pathname.replace(
                "\\",
                " "
              )} page | Tenant Manager - ${obj?.displayid} | Delete Clicked`,
            };
            AdminLog(logProps1);
          }
          if (tenManData !== undefined && tenManData?.length === 1) {
            let newOffset = offset - limit < 0 ? 0 : offset - limit;
            setOffset(newOffset);
            await handleApply(newOffset);
          } else {
            await handleApply(); //issue starts from here
          }
          handleFormClose();
        })
        .catch((text) => {
          toast(text, ToastTypes.ERROR);
        });
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    resetStates();
    setShowDeleteConfirmation(false);
    // if (portfolioId) {
    //   dispatch(fetchTenantManager(portfolioId));
    // }
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, formType]);

  useEffect(() => {
    if (portfolioId) {
      dispatch(fetchTenantManager(portfolioId));
      dispatch(fetchUnit(portfolioId));
    }
  }, [portfolioId]);

  useEffect(() => {
    if (portfolioId) {
      if (TenantManagerList?.length > 0) {
        setLength(TenantManagerList?.length ? TenantManagerList?.length : 0);
      }
    }
  }, [TenantManagerList, portfolioId]);

  useEffect(() => {
    if (portfolioId) handleApply();
  }, [TenantManagerList, portfolioId]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(() => {
    if (adminAuthendicated) {
      const logProps1 = {
        action: `Visit`,
        event: `${pageName.pathname.replace("\\", " ")} page | Visited `,
      };
      AdminLog(logProps1);
    }
  }, []);

  return (
    <>
      <Modal
        headerText={formType + " TENANT MANAGER"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <AddTenant
          frmType={formType}
          // formType={formType}
          // editData={editData}
          // onSave={handleFormClose}
        />
      </Modal>
      <Modal
        headerText={formType + " TENANT MANAGER"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            "Do you really want to delete the records with the Tenant manager - " +
            deleteData?.displayid
          }
          onCancel={handleFormClose}
          onDelete={() => handleDelete(deleteData!)}
        />
      </Modal>
      <AdminLayout>
        <div className="row">
          <div className="col-6 hstack mt-4">
            <label htmlFor="">Portfolio :</label>
            <FormInput
              name="Portfolio"
              label=""
              labelClassName="required"
              value={portfolioId}
              onChange={handlePfid}
              placeholder="Portfolio"
              errorText={portfolioIdError}
              containerClass="w-50"
              // disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {portfolioList.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        </div>
        <AdminTable2
          addBtnText="Create New Tenant"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          search={
            portfolioId !== "" && portfolioId !== undefined
              ? searchHandler
              : undefined
          }
          limit={limit}
          setOffset={setOffset}
          offset={offset}
          onPageNoChange={handleApply}
          range={range}
          pageTitle="Tenant Manager"
          path="/admin/tenant_manager"
          // data={data}
          setId={setId}
          {...getTableData()}
        />
        {/* <div className="card mt-3">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-lg-6 col-sm-12">
                            <h5 className="header-title mb-3 float-start"><i className="fe-align-justify me-1 text-primary"></i>Tenant Manager</h5>
                        </div>
                        <div className="col-lg-6 col-sm-12 text-end mb-1">
                            <button type="button" onClick={handleAdd} className={`me-1 btn btn-xs btn-secondary`}>Create New Manager</button>
                        </div>
                    </div>
                    <hr className='hr mt-0' />
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Tenant Manager Name</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {TenantManagerList.map((u, i) => {
                                    return <TenantManagerBody
                                        sno={i + 1}
                                        tenantManagerName={u.tenantManagerName}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                        onView={() => handleView(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
      </AdminLayout>
    </>
  );
};

// const TenantManagerBody = ({ sno, tenantManagerName, onView, onEdit, onDelete }: TableBody) => {
//     return <tr>
//         <td className='align-middle'>{sno}</td>
//         <td className='align-middle'>{tenantManagerName}</td>
//         <td>
//             <div className='hstack gap-1'>
//                 <button className='btn btn-sm btn-secondary' onClick={onEdit}>Edit</button>
//                 <button className='btn btn-sm btn-secondary' onClick={onDelete}>Delete</button>
//                 <button className='btn btn-sm btn-secondary' onClick={onView}>View</button>
//             </div>
//         </td>
//     </tr>
// }

export default TenantManager;
