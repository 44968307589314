import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"

export enum ReportDropDown {
    DaywiseSalesNotification = "Daywise sales notification",
    WeekwiseSalesNotification = "Weekwise sales notification",
    MonthwiseSalesNotification = "Monthwise sales notification"
}
export type ReportTypes = ReportDropDown.DaywiseSalesNotification | ReportDropDown.WeekwiseSalesNotification | ReportDropDown.MonthwiseSalesNotification

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export enum BasedOnDropDown {
    Invoice = "Invoice",
    InvoiceLessReturn = "Invoice Less Return",
    NetSale = "Net Sale"
}
export type BasedOnTypes = BasedOnDropDown.Invoice | BasedOnDropDown.InvoiceLessReturn | BasedOnDropDown.NetSale

export enum ResultBasedOnDropDown {
    AllTenants = "All Tenants",
    SelectedTenants = "Selected Tenants",
    TradeCategory = "Trade Category"
}
export type ResultBasedOnTypes = ResultBasedOnDropDown.AllTenants | ResultBasedOnDropDown.SelectedTenants | ResultBasedOnDropDown.TradeCategory

export enum AdditionalColumnsDropDown {
    ReceiptCount = "Receipt Count",
    ItemQty = "Item Qty",
    Growth = "Growth",
    MTD = "MTD",
    HighlightGrowthVariation = "Highlight Growth Variation +/- 25",
    ContractNo = "ContractNo",
    DataCaptureType = "DataCaptureType"
}
export type AdditionalColumnsTypes = AdditionalColumnsDropDown.ReceiptCount | AdditionalColumnsDropDown.ItemQty | AdditionalColumnsDropDown.Growth | AdditionalColumnsDropDown.MTD | AdditionalColumnsDropDown.HighlightGrowthVariation | AdditionalColumnsDropDown.ContractNo | AdditionalColumnsDropDown.DataCaptureType

export enum DayDropDown {
    Sunday = "Sunday",
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday",
}
export type DayTypes = DayDropDown.Sunday | DayDropDown.Monday | DayDropDown.Tuesday | DayDropDown.Wednesday | DayDropDown.Thursday | DayDropDown.Friday | DayDropDown.Saturday

export enum OccurenceDropDown {
    Day = "Day",
    Week = "Week",
    Month = "Month",
    Year = "Year"
}
export type OccurenceTypes = OccurenceDropDown.Day | OccurenceDropDown.Week | OccurenceDropDown.Month | OccurenceDropDown.Year

export enum MonthDropDown {
    January = "January",
    Febraury = "Febraury",
    March = "March",
    April = "April",
    May = "May",
    June = "June",
    July = "July",
    August = "August",
    September = "September",
    October = "October",
    November = "November",
    December = "December",

}
export type MonthTypes = MonthDropDown.January | MonthDropDown.Febraury | MonthDropDown.March | MonthDropDown.April | MonthDropDown.May | MonthDropDown.June | MonthDropDown.July | MonthDropDown.August | MonthDropDown.September | MonthDropDown.October | MonthDropDown.November | MonthDropDown.December

export enum DateDropDown {
    One = "1",
    Two = "2",
    Three = "3",
    Four = "4",
    Five = "5",
    Six = "6",
    Seven = "7",
    Eight = "8",
    Nine = "9",
    Ten = "10",
    One1 = "11",
    One2 = "12",
    One3 = "13",
    One4 = "14",
    One5 = "15",
    One6 = "16",
    One7 = "17",
    One8 = "18",
    One9 = "19",
    Two0 = "20",
    Two1 = "21",
    Two2 = "22",
    Two3 = "23",
    Two4 = "24",
    Two5 = "25",
    Two6 = "26",
    Two7 = "27",
    Two8 = "28",
    Two9 = "29",
    Three0 = "30",
    Three1 = "31",

}
export type DateTypes = DateDropDown.One | DateDropDown.Two | DateDropDown.Three | DateDropDown.Four | DateDropDown.Five | DateDropDown.Six | DateDropDown.Seven | DateDropDown.Eight | DateDropDown.Nine | DateDropDown.Ten | DateDropDown.One1 | DateDropDown.One2 | DateDropDown.One3 | DateDropDown.One4 | DateDropDown.One5 | DateDropDown.One6 | DateDropDown.One7 | DateDropDown.One8 | DateDropDown.One9 | DateDropDown.Two0 | DateDropDown.Two1 | DateDropDown.Two2 | DateDropDown.Two3 | DateDropDown.Two4 | DateDropDown.Two5 | DateDropDown.Two6 | DateDropDown.Two7 | DateDropDown.Two8 | DateDropDown.Two9 | DateDropDown.Three0 | DateDropDown.Three1

export interface NotificationType {
    id?: number
    reportType: string
    name: string
    siteId: number
    basedOn: BasedOnTypes
    resultBasedOn: ResultBasedOnTypes
    additionalColumns: string
    occurrence: string
    month: string
    week: string
    day: string
    hour: string
    minutes: string
    action: string
    toList: string
    tenantList: string
    tradeList:string
    status: ActiveTypes | boolean
}

export enum NotificationActionsList {
    ADD_NOTIFICATION = 'ADD_NOTIFICATION',
    EDIT_NOTIFICATION = 'EDIT_NOTIFICATION',
    FETCH_NOTIFICATION = 'FETCH_NOTIFICATION',
    DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
}

export interface DeleteNotification {
    id: number
}

export interface AddNotificationAction {
    type: NotificationActionsList.ADD_NOTIFICATION
    data: NotificationType
}

export interface EditNotificationAction {
    type: NotificationActionsList.EDIT_NOTIFICATION
    data: NotificationType
    id?: number
}

export interface FetchNotificationAction {
    type: NotificationActionsList.FETCH_NOTIFICATION
    data: NotificationType[]

}

export interface DeleteNotificationAction {
    type: NotificationActionsList.DELETE_NOTIFICATION
    data: DeleteNotification
}

export type NotificationActions = AddNotificationAction | EditNotificationAction | FetchNotificationAction | DeleteNotificationAction

export const addNotification = (data: NotificationType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<NotificationType>>('Notification/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddNotificationAction>({
                    type: NotificationActionsList.ADD_NOTIFICATION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Notification added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}

export const editNotification = (data: NotificationType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<NotificationType>>(`Notification/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditNotificationAction>({
                    type: NotificationActionsList.EDIT_NOTIFICATION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Notification Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchNotification = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<NotificationType[]>>('Notification/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchNotificationAction>({
                    type: NotificationActionsList.FETCH_NOTIFICATION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteNotification = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<NotificationType>>(`Notification/${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteNotificationAction>({
                    type: NotificationActionsList.DELETE_NOTIFICATION,
                    data: { id }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Notification Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}