import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"


export interface LogUserType {
    name?: string,
    role?: string,
    loginid: string,
    portfolioid?: string,
    action?: string,
    apptype?: string,
    ipaddr: string,
    event?:string,
    eventtime: string,
    eventdate:string
  }

  export enum LogUserActionsList {
    ADD_LOGUSER = "ADD_LOGUSER",
    CLEAR_LOGUSER = "CLEAR_LOGUSER"
  }

  export interface AddLogUserAction {
    type: LogUserActionsList.ADD_LOGUSER;
    data: LogUserType;
  }

  export interface ClearLogUserAction {
    type: LogUserActionsList.CLEAR_LOGUSER;
  }

  export type LogUserActions =
  | AddLogUserAction
  | ClearLogUserAction;
  

  export const addLogUser = (data: LogUserType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
     
      const token =
        getState().authAdmin?.["auth-token"]!;
      const config = getAxiosRequestConfig(token);
      return api
        .post<APIResponse<LogUserType>>("user/log/", data, config)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            dispatch<AddLogUserAction>({
              type: LogUserActionsList.ADD_LOGUSER,
              data: response.data.data,
            });
            return Promise.resolve(
              response.data.message ? response.data.message : "Center added"
            );
          } else {
            throw { response };
          }
        })
        .catch((error) => {
          return Promise.reject(
            error.response
              ? error.response.data.message
                ? error.response.data.message
                : "Unable to create"
              : "Unable to create"
          );
        });
    };
  };

  export const clearLogUser = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
      return dispatch<ClearLogUserAction>({
        type: LogUserActionsList.CLEAR_LOGUSER,
      });
    };
  };