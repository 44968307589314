import React, { ChangeEventHandler, useEffect, useState } from "react"
import { BrandContactDetails, TenantManagerContext, TenantManagerContextType } from "../context/TenantManagerContext"
import { FormInput } from "../custom/components"
import { testEmail, testNumber } from "../../config/functions"
import AdminTable2 from "../custom/components/AdminTable2"
import { Tbody, Thead } from "../custom/components/AdminTable"
import { nanoid } from "@reduxjs/toolkit"
import { FormType, FormTypeList } from "../../config"
import Modal from "../Modal"
import DeleteConfirmationForm from "./DeleteConfirmationForm"
import Brand from "../../pages/admin/BrandManagement"
import AdminGrid from "../custom/components/AdminGrid"


type HandleDelete = (value: BrandContactDetails) => void;

const BrandContactForm = () => {
    const { brandContactDetails, setBrandContactDetails
    } = React.useContext<TenantManagerContextType | null>(TenantManagerContext)!

    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')

    // const [lastName, setLastName] = useState<string>('')
    // const [lastNameError, setLastNameError] = useState<string>('')
    const [designation, setDesignation] = useState<string>('')
    const [designationError, setDesignationError] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<string>('')
    const [contactNo, setContactNo] = useState<string>('')
    const [contactNoError, setContactNoError] = useState<string>('')

    const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

    const [deleteData, setDeleteData] = useState<BrandContactDetails>();
    const [editData, setEditData] = useState<BrandContactDetails>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
   const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);

    const [id, setId] = useState<number>();

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }
    // const handleLastName: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setLastName(e.target.value)
    //     setLastNameError('')
    // }
    const handleDesignation: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDesignation(e.target.value)
        setDesignationError('')
    }
    const handleEmail: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }
    const handleContactNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        if((!testNumber(e.target.value)&&e.target.value))
        return
        else if(e.target.value?.length>=12)
        return
    
        setContactNo(e.target.value)
        setContactNoError('')
    }

    const handleRemoveItem = (value: BrandContactDetails) => {
        setBrandContactDetails!(prev => {
            return prev.filter(p => p.brandName !== value.brandName)
        })
        // setToListError('')
    }

    const handleSave = () => {
        let error = false
        const data: BrandContactDetails = {
            brandName: name,
            brandDesignation: designation,
            brandEmail: email,
            brandContactNo: contactNo
        }

        if (data.brandName === '') {
            setNameError('Name required')
            error = true
        } else if ((data.brandName)?.length > 50) {
            setNameError('Name should be within 50 digits')
            error = true
        }

        if (data.brandDesignation === '') {
            setDesignationError('Designation required')
            error = true
        } else if ((data.brandDesignation)?.length > 50) {
            setDesignationError('Designation should be within 50 digits')
            error = true
        }

        if (data.brandEmail === '') {
            setEmailError('Email required')
            error = true
        } else if ((data.brandEmail)?.length > 35) {
            setEmailError('Email should be within 30 characters')
            error = true
        }
        else if(data.brandEmail&&!testEmail(data.brandEmail)) {
            setEmailError('Email-id is not valid')
            error = true
        }


        if (data.brandContactNo === '') {
            setContactNoError('Contact No required')
            error = true
        } else if ((data.brandContactNo)?.length > 15) {
            setContactNoError('Contact No should be within 15 characters')
            error = true
        }else if (data.brandContactNo&&!testNumber(data.brandContactNo)) {
            setContactNoError('Phone Number must be a number')
            error = true
        }

        let namesArray = brandContactDetails?.map(t => t.brandName)
        if (!error) {
            // const handleToList = (value: string) => {
            if (!namesArray?.includes(data.brandName)) {
                if (data) {
                    setBrandContactDetails!(prev => prev ? [...prev, data] : [data])
                    setEmail('')
                    setEmailError('')
                    setName('')
                    setNameError('')
                    setDesignation('')
                    setDesignationError('')
                    setContactNo('')
                    setContactNoError('')
                }
            } else setNameError('Name already exists')
            // setToListError('')
            // }
        }
    }

    const getTableData = () => {
    if (brandContactDetails?.length===0||brandContactDetails===undefined) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Name", children: "Name", text: "Name" },
      { id: "Designation", children: "Designation", text: "Designation" },
      { id: "Email", children: "Email", text: "Email" },
      { id: "Contact No", children: "Contact No", text: "Contact No" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];

    brandContactDetails.forEach((item, i) => {
      var tBody: Tbody[] = [];
      const pageNumber = Math.floor(offset / limit) + 1;

      

      tBody.push(
        {
          id: nanoid(),
          theadId: "S.No",
          children: limit * pageNumber + i - limit + 1,
          text: (limit * pageNumber + i - limit + 1)?.toString(),
        },
        {
          id: nanoid(),
          theadId: "Name",
          children: item.brandName,
          text: item.brandName,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Designation",
          children: item.brandDesignation,
          text: item.brandDesignation,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Email",
          children: item.brandEmail,
          text: item.brandEmail,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Contact No",
          children: item.brandContactNo,
          text: item.brandContactNo,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Action",
          children: "action",
          className: "d-none",
          text: i?.toString(),
        }
      );
     
      tableBody.push(tBody);
      // })
    });

    return { tbody: tableBody, thead: tableHead };

    // if (searchTerm?.length > 0) {
    //   tableBodyFiltered = tableBody.filter((item) =>
    //     searchTenantCatHandler(item, searchTerm)
    //   );
    // } else {
    //   tableBodyFiltered = tableBody;
    // }

    // if (tenCatData !== undefined) {
    //   return { tbody: tableBodyFiltered, thead: tableHead };
    // }
  };

    const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);
  
    if (id !== undefined&&brandContactDetails!==undefined&&brandContactDetails?.length>0) {
      var obj = brandContactDetails.find((a,i)=>i?.toString()===id?.toString())
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
    }
  };

   const handleDelete: HandleDelete = (value) => {
    if (id !== undefined&&deleteData!==undefined) {
        setBrandContactDetails!(prev => {
            return prev.filter((p,i) => i?.toString() !== id?.toString())
        })
        handleFormClose();
    }
  };


  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
  };
  
  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  const handleEdit = () =>{
    setFormType(FormTypeList.EDIT);
    if(id!==undefined&&brandContactDetails!==undefined&&brandContactDetails?.length>0){
      var obj = brandContactDetails.find((a,i)=>i?.toString()===id?.toString())
      setName(obj?.brandName?obj?.brandName:'')
      setDesignation(obj?.brandDesignation?obj?.brandDesignation:'')
      setEmail(obj?.brandEmail?obj?.brandEmail:'')
      setContactNo(obj?.brandContactNo?obj?.brandContactNo:'')
    }
  }

  const handleCancel = () =>{
    setFormType(FormTypeList.ADD);
      setName('')
      setDesignation('')
      setEmail('')
      setContactNo('')
      setContactNoError('')
      setDesignationError('')
      setNameError('')
      setEmailError('')
      setId(undefined);
  }

  const handleUpdate = () =>{
    //  const updatedContact = {
    //         brandName: name,
    //         brandDesignation: designation,
    //         brandEmail: email,
    //         brandContactNo: contactNo
    //  }
      let error = false
        const data : BrandContactDetails = {
            brandName: name,
            brandDesignation: designation,
            brandEmail: email,
            brandContactNo: contactNo
        }

        if (data.brandName === '') {
            setNameError('Name required')
            error = true
        } else if ((data.brandName)?.length > 50) {
            setNameError('Name should be within 50 digits')
            error = true
        }

        if (data.brandDesignation === '') {
            setDesignationError('Designation required')
            error = true
        } else if ((data.brandDesignation)?.length > 50) {
            setDesignationError('Designation should be within 50 digits')
            error = true
        }

        if (data.brandEmail === '') {
            setEmailError('Email required')
            error = true
        } else if ((data.brandEmail)?.length > 35) {
            setEmailError('Email should be within 30 characters')
            error = true
        }
        else if(data.brandEmail&&!testEmail(data.brandEmail)) {
            setEmailError('Email-id is not valid')
            error = true
        }


        if (data.brandContactNo === '') {
            setContactNoError('Contact No required')
            error = true
        } else if ((data.brandContactNo)?.length > 15) {
            setContactNoError('Contact No should be within 15 characters')
            error = true
        }else if (data.brandContactNo&&!testNumber(data.brandContactNo)) {
            setContactNoError('Phone Number must be a number')
            error = true
        }

        if(brandContactDetails!==undefined&&brandContactDetails?.length>0){
            let updatedList = brandContactDetails?.filter((a,i)=>i?.toString()!==id?.toString())
            let nameCount = updatedList?.filter((a)=>a?.brandName?.toString()===data?.brandName?.toString())
        if(nameCount?.length>0){
            setNameError('Name Already Exists')
            error = true
        }
        }

        


     if(error===false){
        const updatedContactList = brandContactDetails?.filter((a,i)=>i?.toString()!==id?.toString())
        setBrandContactDetails!([...updatedContactList!,data])
     }

     if(error===false){
     handleCancel()
     }
     
  }

    useEffect(() => {
    // if (id !== undefined && formType === FormTypeList.VIEW) handleView();
     if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, formType]);

   useEffect(() => {
    if (brandContactDetails!==undefined&&brandContactDetails?.length > 0) {
      setLength(brandContactDetails?.length);
      
    }else{
        setLength(0)
    }
  }, [brandContactDetails]);



 

    return (<div className='container'>
        <h4>Brand Contact Information</h4>
        <hr />
        <div className="row">
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Name'
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder=' Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>

            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Last Name'
                    label='Last Name'
                    labelClassName="required"
                    value={lastName}
                    onChange={handleLastName}
                    placeholder='Last Name'
                    errorText={lastNameError}
                    containerClass="mb-2"
                />
            </div> */}
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Designation'
                    label='Designation'
                    labelClassName="required"
                    value={designation}
                    onChange={handleDesignation}
                    placeholder=' Designation'
                    errorText={designationError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Email'
                    label='Email'
                    labelClassName="required"
                    value={email}
                    onChange={handleEmail}
                    placeholder=' Email'
                    errorText={emailError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Contact No'
                    label='Contact No'
                    labelClassName="required"
                    value={contactNo}
                    onChange={handleContactNo}
                    placeholder='Contact No'
                    errorText={contactNoError}
                    containerClass="mb-2"
                    type="text"
                />
            </div>
            <div className="text-end">
                {formType===FormTypeList.ADD&&<button className="btn btn-sm btn-primary fs-6" onClick={handleSave} >Add</button>}
                {formType===FormTypeList.EDIT&&id!==undefined&&
                <>
                  <div className="d-flex flex-row justify-content-end align-items-center" style={{paddingTop:'10px'}} >
                    <button style={{marginRight:'10px'}} className="btn btn-sm btn-primary fs-6 " onClick={handleUpdate} >Update</button>
                    <button className="btn btn-sm btn-primary fs-6" onClick={handleCancel} >Cancel</button>
                  </div>
                </> 
                }
            </div>
        </div>

        {/* {brandContactDetails && brandContactDetails?.length > 0 && <div className="mt-1">
            <table className="table colored">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Email</th>
                        <th>Contact No</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {brandContactDetails?.map((tc, i) => {
                        return <tr>
                            <td>{tc.brandName}</td>
                            <td>{tc.brandDesignation}</td>
                            <td>{tc.brandEmail}</td>
                            <td>{tc.brandContactNo}</td>
                            <td> <button className="btn btn-sm btn-primary fs-6" onClick={() => handleRemoveItem(tc)}>-</button></td>
                        </tr>
                    })
                    }
                </tbody>
            </table>

        </div>
        } */}


        {brandContactDetails && brandContactDetails?.length > 0 && 
        // <div className="mt-1">
        //     <table className="table colored">
        //         <thead>
        //             <tr>
        //                 <th>Name</th>
        //                 <th>Designation</th>
        //                 <th>Email</th>
        //                 <th>Contact No</th>
        //                 <th>Action</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             {brandContactDetails?.map((tc, i) => {
        //                 return <tr>
        //                     <td>{tc.brandName}</td>
        //                     <td>{tc.brandDesignation}</td>
        //                     <td>{tc.brandEmail}</td>
        //                     <td>{tc.brandContactNo}</td>
        //                     <td> <button className="btn btn-sm btn-primary fs-6" onClick={() => handleRemoveItem(tc)}>-</button></td>
        //                 </tr>
        //             })
        //             }
        //         </tbody>
        //     </table>

        // </div>
            <>
         <Modal
        headerText={formType + " Brand Contact"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
        style={{ background: "#00000082", zIndex: "999999" }}
      >
        <DeleteConfirmationForm
          deleteLabel={
            "Do you really want to delete the Contact - " +
            deleteData?.brandName
          }
          onCancel={handleFormClose}
          onDelete={() => handleDelete(deleteData!)}
        />
      </Modal>



         <AdminGrid
          addBtnText=""
        //   onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
        //   onView={handleView}
        //   search={searchHandler}
          limit={limit}
          setOffset={setOffset}
          offset={offset}
        //   onPageNoChange={handleApply}
          range={range}
          pageTitle="Brand Contacts"
          path="/admin/tenant_manager"
          // data={data}
          setId={setId}
          {...getTableData()}
        />

        </>


        }
    </div >)
}
export default BrandContactForm