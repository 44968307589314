import { PortfolioType, PortfolioActionsList } from "../actions/portfolioActions";
import { Action } from "../actions/types";

const initialValue: Array<PortfolioType> = []

export const PortfolioReducer = (state: PortfolioType[]=initialValue, action: Action) => {

    switch (action.type) {

        case PortfolioActionsList.ADD_PORTFOLIO: {

            return [...state, action.data]
        }

        case PortfolioActionsList.EDIT_PORTFOLIO: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case PortfolioActionsList.FETCH_PORTFOLIO: {

            return action.data
        }

        case PortfolioActionsList.DELETE_PORTFOLIO: {
            return state.filter(i => i.id !== action.data.id)

        }

        case PortfolioActionsList.CLEAR_PORTFOLIO: {
            return initialValue

        }

        default: return state
    }

}