import { TradeCategoryActionsList, TradeCategoryType } from "../actions/tradeCategoryActions";
import { Action } from "../actions/types";

const initialValue: Array<TradeCategoryType> = []


export const TradeCategoryReducer = (state: TradeCategoryType[] = initialValue, action: Action) => {

    switch (action.type) {

        case TradeCategoryActionsList.ADD_TRADECATEGORY: {

            return [...state, action.data]
        }

        case TradeCategoryActionsList.EDIT_TRADECATEGORY: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case TradeCategoryActionsList.FETCH_TRADECATEGORY: {
            return action.data
        }

        case TradeCategoryActionsList.DELETE_TRADECATEGORY: {
            return state.filter(i => i.id !== action.data.id)

        }

        case TradeCategoryActionsList.CLEAR_TRADECATEGORY: {
            return initialValue

        }
        default: return state
    }

}