import { today, today1, timestamp } from "./functions"
import { OptionsTypes } from "../redux/actions/selectedOptionsActions";
import { addLogUser } from "../redux/actions/logUserActions";
import { store } from "../redux/store";
import { AuthAdminType } from "../redux/actions/authAdminActions";


interface Props {
    action: string;
    event: string;
}

export const UserLog = async (props: Props) => {
    const state = store.getState();
    const authUser:AuthAdminType = state.authAdmin;
    const selectedOption:OptionsTypes = state.selectedOption;
    const date = today()
    const date2 = today1()
    const time = timestamp()
    
    const data = {
        name:authUser?.name,
        role:authUser?.role[0]?.role,
        loginid:authUser?.loginid ? authUser?.loginid : "",
        portfolioid:authUser?.portfolioId,
        action: props.action,
        apptype:'Mall-App',
        ipaddr:selectedOption.ip,
        event: props.event,
        eventtime:date2 + " "+time,
        eventdate:date
    }
    await store.dispatch(addLogUser(data))
}

export const AdminLog = async (props: Props) => {
    const state = store.getState();
    const authAdmin:AuthAdminType = state.authAdmin;
    const selectedOption:OptionsTypes = state.selectedOption;
    const date = today()
    const date2 = today1()
    const time = timestamp()
    
    const data = {
        name:authAdmin?.name,
        role:authAdmin?.role[0]?.role,
        loginid:authAdmin?.loginid ? authAdmin?.loginid : "",
        portfolioid:'2',
        action: props.action,
        apptype:'Admin',
        ipaddr:selectedOption.ip,
        event: props.event,
        eventtime:date2 + " "+time,
        eventdate:date
    }
    await store.dispatch(addLogUser(data))
}