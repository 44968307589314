import React, { ChangeEventHandler, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { BrandType } from "../../redux/actions/brandActions";
import { BrandCompanyType } from "../../redux/actions/brandCompanyActions";
import { TenantCategoryType } from "../../redux/actions/tenantActions";
import { TradeCategoryType } from "../../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../../redux/actions/tradeSubCategoryActions";
import { StoreState } from "../../redux/store";
import Option from "../Option";
import {
  TenantManagerContext,
  TenantManagerContextType,
} from "../context/TenantManagerContext";
import { FormInput } from "../custom/components";
import { SiteType } from "../../redux/actions/siteActions";
import { fetchRent } from "../../redux/actions/commonActions";
import { TenantManagerType } from "../../redux/actions/tenantManagerActions";
import { extractNumber } from "../../config/functions";
import { FormTypeList } from "../../config";

// const TRADECATEGORY_DROPS = [
//     {
//         text: 'Category-1',
//         value: 'Category-1'
//     },
//     {
//         text: 'Category-2',
//         value: 'Category-2'
//     },
// ]
// const bRAND_DROPS = [
//     {
//         text: 'Brand-1',
//         value: 'Brand-1'
//     },
//     {
//         text: 'Brand-2',
//         value: 'Brand-2'
//     },
//     {
//         text: 'Brand-3',
//         value: 'Brand-3'
//     },
//     {
//         text: 'Brand-4',
//         value: 'Brand-4'
//     },
//     {
//         text: 'Brand-5',
//         value: 'Brand-5'
//     },
//     {
//         text: 'Brand-6',
//         value: 'Brand-6'
//     },
// ]

const GeneralInfoForm = () => {
  const {
    brand,
    company,
    franchise,
    tenantCategory,
    tenantManagerName,
    tradeCategory,
    tradeSubCategory,
    setCompany,
    setFranchise,
    setTenantCategory,
    setTenantManagerName,
    setTradeCategory,
    setBrand,
    setFranchiseError,
    // setTradeCategoryError,
    // setTradeSubCategoryError,
    setTenantCategoryError,
    setBrandError,
    setTenantManagerNameError,
    franchiseError,
    tradeCategoryError,
    tradeSubCategoryError,
    tenantCategoryError,
    brandError,
    tenantManagerNameError,
    setTradeSubCategory,
    setBrandName,
    setCompanyName,
    setTradeCategoryName,
    setTradeSubCategoryName,
    setTenantCategoryName,
    portfolioId,
    centerId,
    setCenterId,
    centerIdError,
    setCenterIdError,
    formType
  } = React.useContext<TenantManagerContextType | null>(TenantManagerContext)!;

  const categoryOption = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state.tradeCategory
  );

  const subCategoryOption = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state.tradeSubCategory
  );
  const brandOption = useSelector<StoreState, BrandType[]>(
    (state) => state.brand
  );
  const brandCompanyOption = useSelector<StoreState, BrandCompanyType[]>(
    (state) => state.brandCompany
  );
  const tenantCategoryList = useSelector<StoreState, Array<TenantCategoryType>>(
    (state) => state.tenant
  );

  const siteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  ).filter((s) => s.pfid?.toString() === portfolioId);

  const tenantManagerList = useSelector<StoreState, Array<TenantManagerType>>(
    (state) => state.tenantmanager
  );

  // const [brands, setBrands] = useState<string>('')
  // const [company, setCompany] = useState<string>('')
  // const [categories, setCategories] = useState<string>('')
  // const [subCategories, setSubCategories] = useState<string>('')

  // const [franchise, setFranchise] = useState<boolean>(false)
  // const [franchiseError, setFranchiseError] = useState<string>('')
  // const [tradeCategory, setTradeCategory] = useState<string>('')
  // const [tradeCategoryError, setTradeCategoryError] = useState<string>('')
  // const [tradeSubCategory, setTradeSubCategory] = useState<string>('')
  // const [tradeSubCategoryError, setTradeSubCategoryError] = useState<string>('')
  // const [tenantCategory, setTenantCategory] = useState<string>('')
  // const [tenantCategoryError, setTenantCategoryError] = useState<string>('')
  // const [tenantLogo, settenantLogo] = useState<string>('')
  // const [brand, setBrand] = useState<string>('')
  // const [brandError, setBrandError] = useState<string>('')
  // const [tenantManagerName, setTenantManagerName] = useState<string>('')
  // const [tenantManagerNameError, setTenantManagerNameError] = useState<string>('')

  const subCategoryFilteredByCategory = useMemo<TradeSubCategoryType[]>(() => {
    if (tradeCategory !== "") {
      return subCategoryOption.filter((sc) => {
        return sc.catid?.toString() === tradeCategory?.toString();
      });
    } else return [];
  }, [tradeCategory, subCategoryOption]);

  const BrandFilteredByCompany = useMemo<BrandType[]>(() => {
    if (company !== "") {
      return brandOption.filter((s) => {
        return s.companyid?.toString() === company?.toString();
      });
    } else return brandOption;
  }, [company, brandOption]);

  const handleCategoryChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setTradeCategory!(e.target.value);
    if (e.target.value) {
      let obj = categoryOption.find((c) => c.id?.toString() === e.target.value);
      setTradeCategoryName!(obj?.name ? obj?.name : "");
    }
    setTradeSubCategory!("");
  };
  const handleSubCategoryChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setTradeSubCategory!(e.target.value);
    if (e.target.value) {
      let obj = subCategoryOption.find(
        (c) => c.id?.toString() === e.target.value
      );
      setTradeSubCategoryName!(obj?.name ? obj?.name : "");
    }
  };
  const handleCompanyChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCompany!(e.target.value);
    if (e.target.value) {
      let obj = brandCompanyOption.find(
        (c) => c.id?.toString() === e.target.value
      );
      setCompanyName!(obj?.name ? obj?.name : "");
    }
    setBrand!("");
  };
  const handleBrandChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBrand!(e.target.value);
    if (e.target.value) {
      let obj = brandOption.find((c) => c.id?.toString() === e.target.value);
      setBrandName!(obj?.name ? obj?.name : "");
    }
    setBrandError!("");
  };
  const handleTenantCategory: ChangeEventHandler<HTMLInputElement> = (e) => {
    setTenantCategory!(e.target.value);
    if (e.target.value) {
      let obj = tenantCategoryList.find(
        (c) => c.id?.toString() === e.target.value
      );
      setTenantCategoryName!(obj?.name ? obj?.name : "");
    }
    setTenantCategoryError!("");
  };

  const handleFranchise: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFranchise!(!franchise);
    setFranchiseError!("");
  };
  const handleTenantManagerName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setTenantManagerName!(e.target.value);
    setTenantManagerNameError!("");
  };

  const handleCenterId: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCenterId!(e.target.value);
    setCenterIdError!("");
  };

  useEffect(()=>{
    if(tenantManagerList?.length>0&&siteList?.length>0&&centerId!==''&&formType===FormTypeList.ADD){
      const filteredSite = siteList.filter((a)=>a?.id?.toString()===centerId?.toString())
      let numberArray:number[] = []
      tenantManagerList.forEach((a)=>{
        if(a?.displayid!==undefined&&a?.displayid?.toString()!==''&&a?.centerid?.toString()===centerId?.toString()){
            const num = extractNumber(a.displayid)
            if(num!==''){
                numberArray.push(Number(num))
            }
        }
      })
      if(numberArray?.length>0){
        const max = Math.max(...numberArray)
        // console.log(max,'Hey')
        setTenantManagerName!(`${filteredSite[0]?.tenant_prefix?.toString()}0000${max+1}`)
      }else{
        setTenantManagerName!(`${filteredSite[0]?.tenant_prefix?.toString()}0000${1}`)
      }
    }
    
  },[tenantManagerList,siteList,centerId,formType])

  // console.log(tenantCategoryList,'hell')


  return (
    <div className="container">
      <h4>Tenant General Information</h4>
      <hr />
      <div className="row">
      <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Select a center"
            label="Center"
            labelClassName="required"
            value={centerId}
            onChange={handleCenterId}
            placeholder="Center"
            errorText={centerIdError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {siteList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.name}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Tenant Displayid"
            label="Tenant Displayid"
            labelClassName="required"
            value={tenantManagerName}
            onChange={handleTenantManagerName}
            placeholder="Tenant Displayid"
            errorText={tenantManagerNameError}
            containerClass="mb-2"
            disabled = {true}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Trade Category"
            label="Trade Category"
            labelClassName="required"
            value={tradeCategory}
            onChange={handleCategoryChange}
            placeholder="Trade Category"
            errorText={tradeCategoryError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select Trade Category</option>
            {categoryOption.map((s, i) => {
              return (
                <Option value={s.id} key={i}>
                  {s.name}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Trade Sub-Category"
            label="Trade Sub-Category"
            labelClassName="required"
            value={tradeSubCategory}
            onChange={handleSubCategoryChange}
            placeholder="Trade Sub-Category"
            errorText={tradeSubCategoryError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select Sub Category</option>
            {subCategoryFilteredByCategory.map((s, i) => {
              return (
                <Option value={s.id} key={i}>
                  {s.name}
                </Option>
              );
            })}
          </FormInput>
        </div>

        {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <div className="form-control border-0">
                    <label className="pb-2">Site Logo</label>
                    <div className="border rounded border-2 border-secondray logo-lg pt-0 pb-1 m-0 w-75">
                        <img src={LogoDark} alt="" height="22" />
                    </div>
                    <div className="hstack gap-1 mt-1">
                        <button className="btn btn-secondary">Browser</button>
                        <button className="btn btn-secondary">Clear</button>
                    </div>
                </div>
            </div> */}
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Company"
            label="Company"
            labelClassName="required"
            value={company}
            onChange={handleCompanyChange}
            placeholder="Select Company"
            containerClass="mb-1"
            type="select"
          >
            <option value="">Select Company</option>
            {brandCompanyOption.map((s, i) => {
              return (
                <Option value={s.id} key={i}>
                  {s.name}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Brand"
            label="Brand"
            labelClassName="required"
            value={brand}
            onChange={handleBrandChange}
            placeholder="Select Brand"
            errorText={brandError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select Brand</option>
            {BrandFilteredByCompany.map((s, i) => {
              return (
                <Option value={s.id} key={i}>
                  {s.name}
                </Option>
              );
            })}
          </FormInput>
        </div>
        {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Tenant Category'
                    label='Tenant Category'
                    labelClassName="required"
                    value={tenantCategory}
                    onChange={handleTenantCategory}
                    placeholder='Tenant Category'
                    errorText={tenantCategoryError}
                    containerClass="mb-2"
                />
            </div> */}
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Tenant Category"
            label="Tenant Category"
            labelClassName="required"
            value={tenantCategory}
            onChange={handleTenantCategory}
            placeholder="Tenant Category"
            errorText={tenantCategoryError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {tenantCategoryList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.name}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="form-check col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Franchise"
            label="Franchise"
            labelClassName="required"
            value={franchise?.toString()}
            onChange={handleFranchise}
            containerClass="mt-4"
            type="checkbox"
            errorText={franchiseError}
            checked={franchise}
          />
          {/* <label className='form-check-label'>Franchise: <input type='checkbox' /> </label> */}
        </div>
      </div>
    </div>
  );
};
export default GeneralInfoForm;
