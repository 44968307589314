import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { Dispatch } from "redux"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface TradeSubCategoryType {
    id?: number
    name:string
    catid:number
    active: ActiveTypes | boolean
    createdBy?: number | null,
    createdAt?: string,
    updatedAt?: string
    updatedBy?: string
}

export enum TradeSubCategoryActionsList {
    ADD_TRADESUBCATEGORY = 'ADD_TRADESUBCATEGORY',
    EDIT_TRADESUBCATEGORY = 'EDIT_TRADESUBCATEGORY',
    FETCH_TRADESUBCATEGORY = 'FETCH_TRADESUBCATEGORY',
    DELETE_TRADESUBCATEGORY = 'DELETE_TRADESUBCATEGORY',
    CLEAR_TRADESUBCATEGORY = 'CLEAR_TRADESUBCATEGORY'
}

export interface DeleteTradeSubCategory {
    id: number
}

export interface AddTradeSubCategoryAction {
    type: TradeSubCategoryActionsList.ADD_TRADESUBCATEGORY
    data: TradeSubCategoryType
}

export interface EditTradeSubCategoryAction {
    type: TradeSubCategoryActionsList.EDIT_TRADESUBCATEGORY
    data: TradeSubCategoryType
    id?: number
}

export interface FetchTradeSubCategoryAction {
    type: TradeSubCategoryActionsList.FETCH_TRADESUBCATEGORY
    data: TradeSubCategoryType[]

}

export interface DeleteTradeSubCategoryAction {
    type: TradeSubCategoryActionsList.DELETE_TRADESUBCATEGORY
    data: DeleteTradeSubCategory
}
export interface ClearTradeSubCategoryAction {
    type: TradeSubCategoryActionsList.CLEAR_TRADESUBCATEGORY
}

export type TradeSubCategoryActions = AddTradeSubCategoryAction | EditTradeSubCategoryAction | FetchTradeSubCategoryAction | DeleteTradeSubCategoryAction | ClearTradeSubCategoryAction

export const addTradeSubCategory = (data: TradeSubCategoryType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<TradeSubCategoryType>>('subCategory/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddTradeSubCategoryAction>({
                    type: TradeSubCategoryActionsList.ADD_TRADESUBCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Trade Sub-Category added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}
export const clearsubTradeCategory = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return dispatch<ClearTradeSubCategoryAction>({
            type: TradeSubCategoryActionsList.CLEAR_TRADESUBCATEGORY

        })
    }
}
export const editTradeSubCategory = (data: TradeSubCategoryType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<TradeSubCategoryType>>(`subCategory/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditTradeSubCategoryAction>({
                    type: TradeSubCategoryActionsList.EDIT_TRADESUBCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Trade Sub-Category Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchTradeSubCategory = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<TradeSubCategoryType[]>>('subCategory/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchTradeSubCategoryAction>({
                    type: TradeSubCategoryActionsList.FETCH_TRADESUBCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteTradeSubCategory = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<TradeSubCategoryType>>(`subCategory/${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteTradeSubCategoryAction>({
                    type: TradeSubCategoryActionsList.DELETE_TRADESUBCATEGORY,
                    data: { id }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Trade Sub-Category Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}