import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface UnitType {
    id?: number
    portfolioid: string
    centerid: number
    buildingid: number
    floorid: number
    unitname: string
    unitno: string
    active: ActiveTypes | number
    createdby?: string
    updatedby?: string
    createdon?: string
    updatedon?: string
}

export enum UnitActionsList {
    ADD_UNIT = 'ADD_UNIT',
    EDIT_UNIT = 'EDIT_UNIT',
    FETCH_UNIT = 'FETCH_UNIT',
    DELETE_UNIT = 'DELETE_UNIT'
}

export interface DeleteUnit {
    id: number
}

export interface AddUnitAction {
    type: UnitActionsList.ADD_UNIT
    data: UnitType
}

export interface EditUnitAction {
    type: UnitActionsList.EDIT_UNIT
    data: UnitType
    id?: number
}

export interface FetchUnitAction {
    type: UnitActionsList.FETCH_UNIT
    data: UnitType[]
}

export interface DeleteUnitAction {
    type: UnitActionsList.DELETE_UNIT
    data: DeleteUnit
}

export type UnitActions = AddUnitAction | EditUnitAction | FetchUnitAction | DeleteUnitAction

export const addUnit = (data: UnitType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<UnitType>>('unit/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddUnitAction>({
                    type: UnitActionsList.ADD_UNIT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Unit added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}

export const editUnit = (data: UnitType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<UnitType>>(`unit/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditUnitAction>({
                    type: UnitActionsList.EDIT_UNIT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Unit Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchUnit = (pfId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<UnitType[]>>(`unit/${pfId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchUnitAction>({
                    type: UnitActionsList.FETCH_UNIT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const fetchUnitById = (pfId: string,id: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<UnitType[]>>(`unit/${pfId}/${id}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchUnitAction>({
                    type: UnitActionsList.FETCH_UNIT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const fetchUnitByCenter = (pfId: string,centerid: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<UnitType[]>>(`unit/${pfId}/${centerid}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchUnitAction>({
                    type: UnitActionsList.FETCH_UNIT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const fetchUnitByBuilding = (pfId: string,buildingid: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<UnitType[]>>(`unit/${pfId}/${buildingid}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchUnitAction>({
                    type: UnitActionsList.FETCH_UNIT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const fetchUnitByFloor = (pfId: string,floorid: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<UnitType[]>>(`unit/${pfId}/${floorid}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchUnitAction>({
                    type: UnitActionsList.FETCH_UNIT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteUnit = (id: number, portfolioId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<UnitType>>(`unit/${portfolioId}/${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteUnitAction>({
                    type: UnitActionsList.DELETE_UNIT,
                    data: { id }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Unit Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}