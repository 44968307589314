import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutAdmin } from "../../redux/actions/authAdminActions";
import { AppDispatch } from "../../redux/store";
import { addToSelectedOption } from "../../redux/actions/selectedOptionsActions";
import { clearRegion, clearState, clearCity } from "../../redux/actions/commonActions";
import { AdminLog } from "../../config/common";
import {clearPortfolio} from "../../redux/actions/portfolioActions";
import { clearSite } from "../../redux/actions/siteActions";
import { clearBrand } from "../../redux/actions/brandActions";
import { clearBrandCompany } from "../../redux/actions/brandCompanyActions";
import { clearTradeCategory } from "../../redux/actions/tradeCategoryActions";
import { clearsubTradeCategory } from "../../redux/actions/tradeSubCategoryActions";
import { clearCountry } from "../../redux/actions/countryActions";
import { clearRole } from "../../redux/actions/roleActions";
import { clearBrandCompanyMapping } from "../../redux/actions/brandCompanyMappingActions";
import { clearCompany } from "../../redux/actions/companyActions";
import { clearTenantCategory } from "../../redux/actions/tenantActions";
import { clearUsers } from "../../redux/actions/usersActions";


const Logout = () => {
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const logProps1 = {
            action:`Logout`,
            event: `Admin LoggedOut`
          };
          AdminLog(logProps1)
        dispatch(logoutAdmin())
        dispatch(clearCompany())
        dispatch(clearPortfolio());
        dispatch(clearSite());
        dispatch(clearBrandCompany());
        dispatch(clearBrand());
        dispatch(clearTradeCategory());
        dispatch(clearsubTradeCategory());
        dispatch(clearCountry());
        dispatch(clearRole());
        dispatch(clearBrandCompanyMapping());
        dispatch(clearRegion());
        dispatch(clearState());
        dispatch(clearCity());
        dispatch(clearUsers())
        dispatch(clearTenantCategory());
        dispatch(addToSelectedOption({
            portfolio: "",
            site: "",
            brand: "",
            company: "",
            location: "",
            category: "",  
            portfolios: [],
            sites: [],
            companies: [],
            brands: [],
            subCategory: "",
            buildings: [],
            floors: [],
            categories: [],
            subCategories: [],
            country: "",
            region: "",
            state: "",
            city: "",
            ip:"",
            loginday:"",
            loginevent:"",
            email:"",
            isLogged:false,
            loader1:true,
            loader2:true,
            loader3:true,
            loader4:true,
        }));
    }, [])
    return <>
    </>
}
export default Logout