import { TenantCategoryType, TenantCategoryActionsList } from "../actions/tenantActions";
import { Action } from "../actions/types";

const initialValue: Array<TenantCategoryType> = []


export const TenantCategoryReducer = (state: TenantCategoryType[] = initialValue, action: Action) => {

    switch (action.type) {

        case TenantCategoryActionsList.ADD_TENANTCATEGORY: {

            return [...state, action.data]
        }

        case TenantCategoryActionsList.EDIT_TENANTCATEGORY: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case TenantCategoryActionsList.FETCH_TENANTCATEGORY: {
            return action.data
        }

        case TenantCategoryActionsList.DELETE_TENANTCATEGORY: {
            return state.filter(i => i.id !== action.data.id)

        }
        case TenantCategoryActionsList.CLEAR_TENANTCATEGORY: {
            return initialValue

        }

        default: return state
    }

}