// import { nanoid } from "@reduxjs/toolkit"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { Dispatch } from "redux"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface TenantCategoryType {
    id?: number
    name: string
    active: ActiveTypes|boolean|string
    createdBy?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null
    updatedBy?: string | null
}

// export const TenantCategorySampleData = [
//     {
//         id: '1',
//         tenantCategoryName: 'Category-1',      
//     },
//     {
//         id: '2',
//         tenantCategoryName: 'Category-2',
       
//     },
// ]

export enum TenantCategoryActionsList {
    ADD_TENANTCATEGORY = 'ADD_TENANTCATEGORY',
    EDIT_TENANTCATEGORY = 'EDIT_TENANTCATEGORY',
    FETCH_TENANTCATEGORY = 'FETCH_TENANTCATEGORY',
    DELETE_TENANTCATEGORY = 'DELETE_TENANTCATEGORY',
    CLEAR_TENANTCATEGORY = 'CLEAR_TENANTCATEGORY'
}

export interface DeleteTenantCategory {
    id: number
}

export interface AddTenantCategoryAction {
    type: TenantCategoryActionsList.ADD_TENANTCATEGORY
    data: TenantCategoryType
}

export interface EditTenantCategoryAction {
    type: TenantCategoryActionsList.EDIT_TENANTCATEGORY
    data: TenantCategoryType
    id?: number
}

export interface FetchTenantCategoryAction {
    type: TenantCategoryActionsList.FETCH_TENANTCATEGORY
    data: TenantCategoryType[]
}

export interface DeleteTenantCategoryAction {
    type: TenantCategoryActionsList.DELETE_TENANTCATEGORY
    data: DeleteTenantCategory
}
export interface ClearTenantCategoryAction {
    type: TenantCategoryActionsList.CLEAR_TENANTCATEGORY
}

export type TenantCategoryActions = AddTenantCategoryAction | EditTenantCategoryAction | FetchTenantCategoryAction | DeleteTenantCategoryAction | ClearTenantCategoryAction

export const addTenantCategory = (data: TenantCategoryType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<TenantCategoryType>>('tenantCategory/', data,config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddTenantCategoryAction>({
                    type: TenantCategoryActionsList.ADD_TENANTCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Tenant Category added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}
export const clearTenantCategory = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return dispatch<ClearTenantCategoryAction>({
            type: TenantCategoryActionsList.CLEAR_TENANTCATEGORY

        })
    }
}
export const editTenantCategory = (data: TenantCategoryType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.put<APIResponse<TenantCategoryType>>(`tenantCategory/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditTenantCategoryAction>({
                    type: TenantCategoryActionsList.EDIT_TENANTCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Tenant Category Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchTenantCategory = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.get<APIResponse<TenantCategoryType[]>>('tenantCategory/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchTenantCategoryAction>({
                    type: TenantCategoryActionsList.FETCH_TENANTCATEGORY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteTenantCategory = (id:number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.delete<APIResponse<TenantCategoryType>>(`tenantCategory/${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteTenantCategoryAction>({
                    type: TenantCategoryActionsList.DELETE_TENANTCATEGORY,
                    data: { id }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Tenant Category Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}