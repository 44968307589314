import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { defaultBrand, defaultPortfolio, DropDownOption1, FormType, FormTypeList } from "../../config";
import { DropDownOption } from "../commonWidget";
import { BrandType } from "../../redux/actions/brandActions";
import { CompanyType } from "../../redux/actions/companyActions";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { RoleType } from "../../redux/actions/roleActions";
import { SiteType } from "../../redux/actions/siteActions";
import {
  ActiveDropDown,
  ActiveTypes,
  UsersType,
  addUsers,
  editUsers,
} from "../../redux/actions/usersActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { onlyUnique } from "../commonWidget";
import { FormInput, MultiSelect } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { FormLabel } from "react-bootstrap";
import { testEmail } from "../../config/functions";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import {
  outletType,
  fetchOutletByBrands,
} from "../../redux/actions/outletActions";
import { useLocation } from "react-router-dom";
import { AdminLog } from "../../config/common";
import SingleSelect from "../custom/components/SingleSelect";
import { CredentialType, fetchCredential } from "../../redux/actions/credentialActions";
import { RolesType } from "../../redux/actions/userRoleActions";

const STATUS_DROPS = [
  {
    text: "Active",
    value: "active",
  },
  {
    text: "InActive",
    value: "inActive",
  },
];

// const ROLE_DROPS = [
//     {
//         text: 'Admin',
//         value: 'admin'
//     },
//     {
//         text: 'Company',
//         value: 'company'
//     },
//     {
//         text: 'Portfolio',
//         value: 'portfolio'
//     },
//     {
//         text: 'Site',
//         value: 'site'
//     },
//     {
//         text: 'Brand',
//         value: 'brand'
//     },
// ]

interface Props {
  formType: FormType;
  editData?: UsersType;
  onSave: () => void;
  showViewForm: boolean;
}

interface SiteNote {
  value: number;
  label: string;
  imgUrl: string;
}

const UsersForm = ({ formType, onSave, editData, showViewForm }: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const authAdminToken = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  )?.["auth-token"]!;

  const config = getAxiosRequestConfig(authAdminToken);

  const brandOption = useSelector<StoreState, BrandType[]>(
    (state) => state.brand
  );

  const BrandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state.brand
  );
  const PortfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );
  const SiteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  );
  const CompanyList = useSelector<StoreState, Array<CompanyType>>(
    (state) => state.company
  );
  const rolesList = useSelector<StoreState, Array<RolesType>>(
    (state) => state.roles
  );
  const roleList = useSelector<StoreState, Array<RoleType>>(
    (state) => state.role
  );
  const OutletList = useSelector<StoreState, Array<outletType>>(
    (state) => state.outlet
  );

  const CredentialList = useSelector<StoreState, Array<CredentialType>>(
    (state) => state.credential
  );

  const [loginid, setLoginid] = useState<string>("");
  const [loginidError, setLoginidError] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [status, setStatus] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [statusError, setStatusError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [roleName, setRoleName] = useState<string>("");
  const [roleError, setRoleError] = useState<string>("");
  const [companyId, setCompanyId] = useState<string>("");
  const [companyIdError, setCompanyIdError] = useState<string>("");
  // const [portfolioId, setPortfolioId] = useState<string>("");
  const [portfolioIdError, setPortfolioIdError] = useState<string>("");
  const [siteId, setSiteId] = useState<string>("");
  const [siteIdError, setSiteIdError] = useState<string>("");
  const [brandId, setBrandId] = useState<string>("");
  const [brandIdError, setBrandIdError] = useState<string>("");

  const [portfolios, setPortfolios] = useState<DropDownOption[]>([]);
  const [sites, setSites] = useState<DropDownOption[]>([]);
  const [brands, setBrands] = useState<DropDownOption[]>([]);
  const [tenants, setTenants] = useState<DropDownOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [showSite, setShowSite] = useState<boolean>(false);
  const [showOutlet, setShowOutlet] = useState<boolean>(false);
  const [outlets, setOutlets] = useState<DropDownOption[]>([]);

  const [outletIdError, setOutletIdError] = useState<string>("");
  const [tenantIdError,setTenantIdError] = useState<string>('')


  const [showCred,setShowCred] = useState<boolean>(false)

  const [buserPortfolio,setbuserPortfolio] = useState<string>('')
  const [buserportfolioIdError, setbuserPortfolioIdError] = useState<string>("");
  const [portfoliosOption,setPortfoliosOption] = useState<DropDownOption1>(defaultPortfolio)

  // const [buserBrand,setbuserBrand] = useState<string>('')
  // const [buserBrandIdError, setbuserBrandIdError] = useState<string>("");
  // const [brandsOption,setBrandsOption] = useState<DropDownOption1>(defaultBrand)


  const resetStates = () => {
    setLoginid("");
    setLoginidError("");
    setName("");
    setNameError("");
    setPassword("");
    setPasswordError("");
    setStatus(ActiveDropDown.Active);
    setStatusError("");
    setEmail("");
    setEmailError("");
    setRole("");
    setRoleError("");
    setCompanyId("");
    setCompanyIdError("");
    // setPortfolioId("");
    // setPortfolioIdError("");
    setSiteId("");
    setSiteIdError("");
    setOutletIdError("");
    setBrandId("");
    setBrands([]);
    setBrandIdError("");
    setTenantIdError('')
    setTenants([])
    setLoading(false);
    setbuserPortfolio('')
    setbuserPortfolioIdError("")
  };

  // const filteredPortfolios = useMemo<PortfolioType[]>(() => {
  //     return PortfolioList.filter(p => p.companyid?.toString() === companyId?.toString())
  // }, [companyId])

  // const filteredSites = useMemo<SiteType[]>(() => {
  //     return SiteList.filter(p => p.pfid?.toString() === portfolioId?.toString())
  // }, [portfolioId])

  const roles = useMemo<string[]>(() => {
    return roleList.map((r) => r.role).filter(onlyUnique);
  }, [roleList]);


  const portfolioFilteredByCompany = useMemo<PortfolioType[]>(() => {
    if (companyId !== "") {
      return PortfolioList.filter(
        (p) => p.companyid?.toString() === companyId?.toString()
      );
    } else {
      setPortfolios([]);
      return [];
    }
  }, [companyId, PortfolioList]);


  const SiteFilteredByPortfolio = useMemo<SiteType[]>(() => {
    
    if(showCred===false&&buserPortfolio===''){
    if (portfolios?.length === 1) {
      return SiteList.filter((s) => {
        return s.pfid?.toString() === portfolios[0].value?.toString();
      });
    } else if (portfolios?.length > 1) {
      return portfolios
        .map((p) => {
          return SiteList.filter((s) => {
            return s.pfid?.toString() === p.value?.toString();
          });
        })
        .flat(1);
    } else {
      setSites([]);
      return [];
    }
  }else{
    return SiteList.filter((a)=>a?.pfid?.toString()===buserPortfolio?.toString())
  }
  }, [portfolios, SiteList,buserPortfolio,showCred]);

  const tenantFilteration = useMemo<CredentialType[]>(() => {
    if (!showCred) {
      return [];
    }
  
    if (brands && brands.length === 1 && buserPortfolio !== '') {
      return CredentialList.filter(a => a?.brandid?.toString() === brands[0]?.value?.toString());
    }
  
    if (buserPortfolio !== '') {
      return CredentialList;
    }
  
    return [];
  }, [showCred, brands, CredentialList, buserPortfolio]);
  

  const handleLoginid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLoginid(e.target.value);
    setLoginidError("");
  };

  const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
    setNameError("");
  };

  const handlePassword: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleStatus: ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatus(e.target.value as ActiveTypes);
    setStatusError("");
  };

  const handleEmail: ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleRole: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRole(e.target.value);
    setRoleError("");
    if(e?.target?.value?.toString() === '4'){
      setShowCred(true)
    }else{
      setShowCred(false)
    }
  };

  const handleCompanyId: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCompanyId(e.target.value);
    setCompanyIdError("");
  };

  const handlebuserPortfolioChange = (e:DropDownOption1) => {
          setbuserPortfolioIdError('')
          setbuserPortfolio(e?.value?.toString())
          setPortfoliosOption(e)
  };

  // const handlePortfolioId: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setPortfolioId(e.target.value);
  //   setPortfolioIdError("");
  // };

  // const handleSiteId: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setSiteId(e.target.value);
  //   setSiteIdError("");
  // };

  // const handleBrandId: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setBrandId(e.target.value);
  //   setBrandIdError("");
  // };

  const handleClear = () => {
    resetStates();
  };

  const portfolio = useMemo<number[]>(() => {
    setPortfolioIdError("");
    if (formType === FormTypeList.ADD) setSites([]);
    // setSites([])
    return portfolios.map((p) => {
      return p.value;
    });
  }, [portfolios]);


  const site = useMemo<number[]>(() => {
    setSiteIdError("");
    return sites.map((p) => {
      return p.value;
    });
  }, [sites]);


  const outlet = useMemo<number[]>(() => {
    setOutletIdError("");
    return outlets.map((p) => {
      return p?.value;
    });
  }, [outlets]);

  const tenant = useMemo<number[]>(() => {
    setTenantIdError("");
    return tenants.map((p) => {
      return p?.value;
    });
  }, [tenants]);


  const brand = useMemo<number[]>(() => {
    setBrandIdError("");
    return brands.map((p) => {
      return p.value;
    });
  }, [brands]);

  

  

  const handleSave = () => {
    let error = false;
    const addData: UsersType = {
      loginid: loginid,
      name: name,
      password: password,
      status: status,
      email: email,
      role: roleName,
      roleId: parseInt(role),
      companyId: parseInt(companyId),
      portfolioId: showCred?buserPortfolio:portfolio?.toString(),
      siteId: showOutlet ? outlet?.toString() : site?.toString(),
      brandId: brand?.toString(),
      tenantId:tenant?.toString()
      // portfolioId: parseInt(portfolioId),
      // siteId: parseInt(siteId),
      // brandId: parseInt(brandId),
    };

    const editdata: UsersType = {
      loginid: loginid,
      name: name,
      status: status,
      email: email,
      role: roleName,
      roleId: parseInt(role),
      companyId: parseInt(companyId),
      portfolioId: showCred?buserPortfolio:portfolio?.toString(),
      siteId: showOutlet ? outlet?.toString() : site?.toString(),
      brandId: brand?.toString(),
      tenantId:tenant?.toString()
      // portfolioId: parseInt(portfolioId),
      // siteId: parseInt(siteId),
      // brandId: parseInt(brandId),
    };

    if (addData.loginid === "") {
      setLoginidError("Loginid required");
      error = true;
    } else if (addData.loginid!?.length > 50) {
      setLoginidError("Loginid should be within 50 characters");
      error = true;
    }

    if (addData.name === "") {
      setNameError("Name required");
      error = true;
    } else if (addData.name?.length > 30) {
      setNameError("Name should be within 30 characters");
      error = true;
    }

    if (formType === FormTypeList.ADD) {
      if (addData.password === "") {
        setPasswordError("Password required");
        error = true;
      } else if (addData.password!?.length > 15) {
        setPasswordError("Password should be within 15 characters");
        error = true;
      }
    }

    if (addData.status?.toString() === "") {
      setStatusError("Status required");
      error = true;
    }

    if (addData.email === "") {
      setEmailError("Email required");
      error = true;
    } else if (addData.email?.length > 100) {
      setEmailError("Email should be within 100 characters");
      error = true;
    } else if (addData.email && !testEmail(addData.email)) {
      setEmailError("Email-id is not valid");
      error = true;
    }

    if (addData.role?.toString() === "") {
      setRoleError("Role required");
      error = true;
    }

    if (isNaN(addData.companyId)) {
      setCompanyIdError("companyId required");
      error = true;
    }
    // else if ((addData.companyId) > 15) {
    //     setCompanyIdError('companyId should be within 15 digits')
    //     error = true
    // }

    // if (isNaN(addData.portfolioId!)) {
    //     setPortfolioIdError('PortfolioId required')
    //     error = true
    // } else if ((addData.portfolioId!) > 15) {
    //     setPortfolioIdError('PortfolioId should be within 15 digits')
    //     error = true
    // }

    // if (isNaN(addData.siteId!)) {
    //     setSiteIdError('SiteId required')
    //     error = true
    // } else if ((addData.siteId!) > 15) {
    //     setSiteIdError('SiteId should be within 15 digits')
    //     error = true
    // }

    // if (isNaN(addData.brandId!)) {
    //     setBrandIdError('BrandId required')
    //     error = true
    // } else if ((addData.brandId!) > 15) {
    //     setBrandIdError('BrandId should be within 15 digits')
    //     error = true
    // }

    if (addData.portfolioId === ""&&showCred===false) {
      setPortfolioIdError("Portfolio required");
      error = true;
    }

    if (addData.portfolioId === ""&&showCred===true) {
      setbuserPortfolioIdError("Portfolio required");
      error = true;
    }

    if (addData.siteId === "") {
      showOutlet
        ? setOutletIdError("Outlet required")
        : setSiteIdError("Center required");
      error = true;
    }

    if (addData.brandId === "") {
      setBrandIdError("Brand required");
      error = true;
    }

    if (addData.tenantId === ""&&showCred===true) {
      setTenantIdError("Tenant required");
      error = true;
    }
    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);

        dispatch(addUsers(addData))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.uid !== undefined
      ) {
        setLoading(true);
        dispatch(editUsers(editdata, editData.uid))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      const port =
        editData?.portfolioId !== undefined && editData?.portfolioId !== null
          ? editData?.portfolioId.split(",")
          : [];

      let portfolioEditData = port
        ?.map((pe) => {
          return PortfolioList.filter(
            (f) => f?.id?.toString() === pe?.toString()
          );
        })
        .flat(1)
        .map((p) => {
          return {
            value: p?.id ? p.id : 0,
            label: p?.name,
            imgUrl: p.logourl,
          };
        });

      const st =
        editData?.siteId !== undefined && editData?.siteId !== null
          ? editData?.siteId.split(",")
          : [];

      let ss = st
        ?.map((pe) => {
          return SiteList.filter((f) => f?.id?.toString() === pe?.toString());
        })
        .flat(1);

      let siteEditData = ss.map((s) => {
        return {
          value: s?.id ? s?.id : 0,
          label: s?.name,
          imgUrl: s.logourl,
        };
      });
      setSites(siteEditData);
      let outletEditData = ss.map((s) => {
        return {
          value: s?.id ? s?.id : 0,
          label: s?.name,
          imgUrl: "",
        };
      });
      setOutlets(outletEditData);
      const bnd =
        editData?.brandId !== undefined && editData?.brandId !== null
          ? editData?.brandId.split(",")
          : [];
      let brandEditData = bnd
        ?.map((pe) => {
          return BrandList.filter((f) => f?.id?.toString() === pe?.toString());
        })
        .flat(1)
        .map((b) => {
          return {
            value: b?.id ? b.id : 0,
            label: b?.name,
            imgUrl: b?.logourl,
          };
        });

        const at =
        editData?.tenantId !== undefined && editData?.tenantId !== null
          ? editData?.tenantId.split(",")
          : [];

      let aa = at
        ?.map((pe) => {
          return CredentialList.filter((f) => f?.tenantid?.toString() === pe?.toString());
        })
        .flat(1);

      let tenantEditData = aa.map((s) => {
        return {
          value: s?.tenantid ? s?.tenantid : 0,
          label: s?.displayid,
          imgUrl: '',
        };
      });
      setTenants(tenantEditData);

      setbuserPortfolio(editData?.portfolioId!==undefined && editData?.portfolioId!==null?editData.portfolioId.toString():'')


      setPortfolios(portfolioEditData);
      setBrands(brandEditData);

      setLoginid(editData?.loginid !== undefined ? editData?.loginid : "");
      setName(editData?.name);
      setPassword(editData?.password !== undefined ? editData?.password : "");
      setStatus(
        editData.status === "active"
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );
      setEmail(editData?.email);
      setRole(editData?.roleId?.toString());
      
      setCompanyId(
        editData?.companyId !== null ? editData?.companyId?.toString() : ""
      );
      // setPortfolioId(editData?.portfolioId !== undefined && editData?.portfolioId !== null ? editData.portfolioId?.toString() : '')
      // setSiteId(editData?.siteId !== undefined && editData?.siteId !== null ? editData.siteId?.toString() : '')
      // setBrandId(editData?.brandId !== undefined && editData?.brandId !== null ? editData.brandId?.toString() : '')
    } else {
      resetStates();
    }
  }, [formType, editData]);

  const handleApply = async () => {
    let error = false;

    if (portfolio[0] === undefined) {
      setPortfolioIdError("Select a Portfolio");
      error = true;
    }

    if (portfolio?.length > 1) {
      setPortfolioIdError("Select a Single Portfolio");
      error = true;
    }

    if (error === false && portfolio?.length === 1) {
      const id = portfolio[0]?.toString();
      await api
        .get<
          APIResponse<{
            map(
              arg0: (s: SiteType) => {
                value: number;
                label: string;
                imgUrl: string;
              }
            ): SiteNote[];
            records: SiteType[];
          }>
        >(`site/business/${id}`, config)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            const result = response.data.data;
            const retailSites = result.map((s) => ({
              value: s.id ? s.id : 0,
              label: s?.name,
              imgUrl: s.logourl,
            }));
            setSites([...retailSites]);
          }
        })
        .catch((error) => {});
    } else {
      setSites([]);
    }
  };

  useEffect(() => {
    const hasBusinessModelPortfolio = portfolios.some((selectedPortfolio) => {
      const matchingPortfolio = PortfolioList.find(
        (portfolio) => portfolio.id === selectedPortfolio.value
      );

      return matchingPortfolio && matchingPortfolio.businessmodel === 1;
    });
    if (hasBusinessModelPortfolio === true) {
      handleApply();
      setShowSite(false);
      setShowOutlet(true);
    } else if (hasBusinessModelPortfolio === false) {
      setShowSite(true);
      setShowOutlet(false);
    }
  }, [portfolios]);

  useEffect(() => {
    if (role !== ''){
      const obj = rolesList.find(
        (r) => r.id?.toString() === role?.toString()
      );
      setRoleName(obj ? obj?.role : "");
    }
  }, [role, rolesList]);

  useEffect(() => {
    if (portfolio?.length === 1 && (!showSite || !showOutlet))
      setPortfolioIdError("");
  }, [portfolios]);

  useEffect(() => {
    const isRetailPortfolio = portfolios.some((selectedPortfolio) => {
      const matchingPortfolio = PortfolioList.find(
        (portfolio) => portfolio.id === selectedPortfolio.value
      );

      return matchingPortfolio && matchingPortfolio.businessmodel === 1;
    });
    if (portfolios && portfolios[0]?.value && isRetailPortfolio) {
      let brandids: string[] = [];
      brands.map((b) => {
        brandids.push(b?.value?.toString());
      });
      dispatch(fetchOutletByBrands(portfolios[0]?.value?.toString(), brandids));
    }
  }, [portfolios, brands]);


  useEffect(()=>{
    if(buserPortfolio!==''&&showCred){
      dispatch(fetchCredential(buserPortfolio))
    }
  },[buserPortfolio,showCred])

  useEffect(()=>{
      if(role?.toString()==='4'&&formType===FormTypeList.EDIT){
        setShowCred(true)
      }
  },[role,formType])

  useEffect(()=>{
    if(buserPortfolio!==''&&formType===FormTypeList.EDIT){
      const b = PortfolioList.filter((a)=>a.id?.toString()===buserPortfolio?.toString()).flat(1)
      if (b.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption1 = {
          value: b[0].id?.toString(), 
          label: b[0].name,
          imgUrl: b[0].logourl, 
        };
        setPortfoliosOption(val);
      }
    }
  },[formType,buserPortfolio])

 

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Login Id"
            label="Login Id"
            labelClassName="required"
            value={loginid}
            style={{ textTransform: "lowercase" }}
            onChange={handleLoginid}
            placeholder="Login Id"
            errorText={loginidError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Name"
            label="Name"
            labelClassName="required"
            value={name}
            onChange={handleName}
            placeholder="Name"
            errorText={nameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        {formType === FormTypeList.ADD && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Password"
              label="Password"
              labelClassName="required"
              value={password}
              onChange={handlePassword}
              placeholder="Password"
              errorText={passwordError}
              containerClass="mb-2"
              disabled={showViewForm}
            />
          </div>
        )}
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Status"
            label="Status"
            labelClassName="required"
            value={status}
            onChange={handleStatus}
            placeholder="Status"
            errorText={statusError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {STATUS_DROPS.map((a, i) => {
              return (
                <Option value={a.value?.toString()} key={i}>
                  {a.text}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Email"
            label="Email"
            labelClassName="required"
            value={email}
            onChange={handleEmail}
            placeholder="Email"
            errorText={emailError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Role"
            label="Role"
            labelClassName="required"
            value={role}
            onChange={handleRole}
            placeholder="Role"
            errorText={roleError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {rolesList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.role}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Company Name"
            label="Company Name"
            labelClassName="required"
            value={companyId}
            onChange={handleCompanyId}
            placeholder="Company Name"
            errorText={companyIdError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {CompanyList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a?.name}
                </Option>
              );
            })}
          </FormInput>
        </div>
        {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Portfolio Name'
                    label='Portfolio Name'
                    labelClassName="required"
                    value={portfolioId}
                    onChange={handlePortfolioId}
                    placeholder='Portfolio Name'
                    errorText={portfolioIdError}
                    containerClass="mb-2"
                    disabled={showViewForm}
                    type="select"
                >
                    <option value="">Select</option>
                    {filteredPortfolios.map((a, i) => {
                        return <Option value={a.id} key={i}>{a.name}</Option>
                    })}
                </FormInput>
            </div> */}
        {showCred===false&&<div className="col-sm-6 col-md-4 col-lg-3">
          {showViewForm ? (
            <>
              <FormLabel>Portfolio</FormLabel>
              <p>
                {portfolioFilteredByCompany
                  .filter((pf) => {
                    for (let port of portfolios) {
                      if (port.value === pf.id) return pf;
                    }
                  })
                  .map((pf: any) => pf?.name)
                  .join(", ")}
              </p>
            </>
          ) : (
            <MultiSelect
              options={portfolioFilteredByCompany.map((p) => {
                return {
                  value: p.id ? p.id : 0,
                  label: p?.name,
                  imgUrl: p.logourl,
                };
              })}
              label="Portfolio"
              selectedOptions={portfolios}
              setSelectedOptions={setPortfolios}
              placeHolder="Select Portfolio"
              filterText=""
              errorText={portfolioIdError}
              disabled={showViewForm}
            />
          )}
        </div>}

        {showCred&&
          <div className="col-sm-6 col-md-4 col-lg-3">
               <SingleSelect
                  options={[
                    defaultPortfolio,
                    ...portfolioFilteredByCompany?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s.id ? s.id?.toString() : '',
                          label: s.name,
                          imgUrl: s.logourl,
                        }
                      );
                    })
                  ]}
                  label="Portfolio"
                  selectedOptions={portfoliosOption}
                  setSelectedOptions={handlebuserPortfolioChange}
                  // disabled={disableSite}
                  placeHolder="Select Portfolio"
                  // filterText=""
                />
            </div>
        }

        {showSite && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            {showViewForm ? (
              <>
                <FormLabel>Center</FormLabel>
                <p>
                  {SiteFilteredByPortfolio.filter((sp) => {
                    for (let site of sites) {
                      if (site.value === sp.id) return sp;
                    }
                  })
                    .map((sp: any) => sp?.name)
                    .join(", ")}
                </p>
              </>
            ) : (
              <MultiSelect
                options={SiteFilteredByPortfolio.map((s) => {
                  return {
                    value: s.id ? s.id : 0,
                    label: s?.name,
                    imgUrl: s.logourl,
                  };
                })}
                label="Center"
                selectedOptions={sites}
                setSelectedOptions={setSites}
                placeHolder="Select Center"
                filterText=""
                errorText={siteIdError}
                disabled={showViewForm}
              />
            )}
          </div>
        )}

        <div className="col-sm-6 col-md-4 col-lg-3">
          {showViewForm ? (
            <>
              <FormLabel>Brand</FormLabel>
              <p>
                {brandOption
                  .filter((bo) => {
                    for (let brand of brands) {
                      if (brand.value === bo.id) return bo;
                    }
                  })
                  .map((brand: any) => brand?.name)
                  .join(", ")}
              </p>
            </>
          ) : (
            <MultiSelect
              options={brandOption.map((b) => {
                return {
                  value: b.id ? b.id : 0,
                  label: b?.name,
                  imgUrl: b.logourl,
                };
              })}
              label="Brand"
              selectedOptions={brands}
              setSelectedOptions={setBrands}
              placeHolder="Select Brand"
              filterText=""
              errorText={brandIdError}
              disabled={showViewForm}
            />
          )}
        </div>

       

        {showOutlet && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            {showViewForm ? (
              <>
                <FormLabel>Outlet</FormLabel>
                <p>
                  {OutletList.filter((sp) => {
                    for (let outlet of outlets) {
                      if (outlet?.value === sp?.centerid) return sp;
                    }
                  })
                    .map((sp: any) => sp?.outletcode)
                    .join(", ")}
                </p>
              </>
            ) : (
              <MultiSelect
                options={OutletList.map((o) => {
                  return {
                    value: o?.centerid ? o.centerid : 0,
                    label: o?.outletcode,
                    imgUrl: "",
                  };
                })}
                label="Outlet"
                selectedOptions={outlets}
                setSelectedOptions={setOutlets}
                placeHolder="Select Outlet"
                filterText=""
                errorText={outletIdError}
                disabled={showViewForm}
              />
            )}
          </div>
        )}

        {showCred&&
          <div className="col-sm-6 col-md-4 col-lg-3">
              <MultiSelect
              options={tenantFilteration.map((b) => {
                return {
                  value: b.tenantid ? b.tenantid : 0,
                  label: b?.displayid,
                  imgUrl: '',
                };
              })}
              label="Tenants"
              selectedOptions={tenants}
              setSelectedOptions={setTenants}
              placeHolder="Select Tenants"
              filterText=""
              errorText={tenantIdError}
              // disabled={showViewForm}
            />
            </div>
        }
        {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Site Name'
                    label='Site Name'
                    labelClassName="required"
                    value={siteId}
                    onChange={handleSiteId}
                    placeholder='Site Name'
                    errorText={siteIdError}
                    containerClass="mb-2"
                    disabled={showViewForm}
                    type="select"
                >
                    <option value="">Select</option>
                    {filteredSites.map((a, i) => {
                        return <Option value={a.id} key={i}>{a.name}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Brand Name'
                    label='Brand Name'
                    labelClassName="required"
                    value={brandId}
                    onChange={handleBrandId}
                    placeholder='Brand Name'
                    errorText={brandIdError}
                    containerClass="mb-2"
                    disabled={showViewForm}
                    type="select"
                >
                    <option value="">Select</option>
                    {BrandList.map((a, i) => {
                        return <Option value={a.id} key={i}>{a.name}</Option>
                    })}
                </FormInput>
            </div> */}
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default UsersForm;
