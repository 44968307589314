import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface ItemDepartmentType {
    depid?: number
    portfolioid: string
    department: string
    active: ActiveTypes | boolean
    createdon?: string
}

export enum ItemDepartmentActionsList {
    ADD_DEPARTMENT = 'ADD_DEPARTMENT',
    EDIT_DEPARTMENT = 'EDIT_DEPARTMENT',
    FETCH_DEPARTMENT = 'FETCH_DEPARTMENT',
    DELETE_DEPARTMENT = 'DELETE_DEPARTMENT'
}

export interface DeleteDepartment {
    depid: number
}

export interface AddDepartmentAction {
    type: ItemDepartmentActionsList.ADD_DEPARTMENT
    data: ItemDepartmentType
}

export interface EditDepartmentAction {
    type: ItemDepartmentActionsList.EDIT_DEPARTMENT
    data: ItemDepartmentType
    id?: number
}

export interface FetchDepartmentAction {
    type: ItemDepartmentActionsList.FETCH_DEPARTMENT
    data: ItemDepartmentType[]
}

export interface DeleteDepartmentAction {
    type: ItemDepartmentActionsList.DELETE_DEPARTMENT
    data: DeleteDepartment
}

export type ItemDepartmentActions = AddDepartmentAction | EditDepartmentAction | FetchDepartmentAction | DeleteDepartmentAction

export const addItemDepartment = (data: ItemDepartmentType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<ItemDepartmentType>>('itemdepartment/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddDepartmentAction>({
                    type: ItemDepartmentActionsList.ADD_DEPARTMENT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Item Department added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}

export const editItemDepartment = (data: ItemDepartmentType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<ItemDepartmentType>>(`itemdepartment/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditDepartmentAction>({
                    type: ItemDepartmentActionsList.EDIT_DEPARTMENT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Item Department Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchDepartment = (pfId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<ItemDepartmentType[]>>(`itemdepartment/${pfId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchDepartmentAction>({
                    type: ItemDepartmentActionsList.FETCH_DEPARTMENT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteItemDepartment = (pfId: string, depid: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<ItemDepartmentType>>(`itemdepartment/${pfId}/${depid}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteDepartmentAction>({
                    type: ItemDepartmentActionsList.DELETE_DEPARTMENT,
                    data: { depid }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Item Department Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}