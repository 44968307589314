import { MouseEventHandler, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../../components/custom/components/PageTitle'
import AdminLayout from '../../components/custom/layouts/AdminLayout'
import { ContactType } from '../../redux/actions/contactActions'
import { AppDispatch, StoreState } from '../../redux/store'
import { deleteContact } from "../../redux/actions/contactActions"
import Modal from '../../components/Modal'
import ContactForm from '../../components/admin/ContactForm'

interface TableBody {
    sno: number
    name: string
    designation: String
    email: string
    contactNo: number
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    onView: MouseEventHandler<HTMLButtonElement>
}

export enum FormTypeList {
    ADD = 'ADD',
    EDIT = 'EDIT',
    VIEW = 'VIEW',
}

export type FormType = FormTypeList.ADD | FormTypeList.EDIT | FormTypeList.VIEW;

type HandleEdit = (value: ContactType) => void
type HandleDelete = (value: ContactType) => void
type HandleView = (value: ContactType) => void

const Contact = () => {
    const dispatch = useDispatch<AppDispatch>()

    const ContactList = useSelector<StoreState, Array<ContactType>>(state => (state.contact))

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<ContactType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleView: HandleView = (value) => {
        setFormType(FormTypeList.VIEW)
        setShowForm(true)
        setEditData(value)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteContact(value.id)).then(text => {

            }).catch(text => {

            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }
    return <>
        <Modal
            headerText={formType + ' CONTACT'}
            visible={showForm}
            onClose={handleFormClose}
            size='xl'
            centered
        >
            <ContactForm
            />

        </Modal>
        <AdminLayout>
            <PageTitle
                title='Contact'
                buttonText='Create New Contact'
                onButtonClick={handleAdd}
            />
            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Name</th>
                                    <th className='text-truncate align-middle'>Designation</th>
                                    <th className='text-truncate align-middle'>Email</th>
                                    <th className='text-truncate align-middle'>Contact No</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ContactList.map((u, i) => {
                                    return <ContactBody
                                        sno={i + 1}
                                        name={u.name}
                                        designation={u.designation}
                                        email={u.email}
                                        contactNo={u.contactNo}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                        onView={() => handleView(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </AdminLayout>
    </>
}

const ContactBody = ({ sno,name,designation,email
    ,contactNo, onView, onEdit, onDelete }: TableBody) => {
    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='align-middle'>{name}</td>
        <td className='align-middle'>{designation}</td>
        <td className='align-middle'>{email}</td>
        <td className='align-middle'>{contactNo}</td>
        <td>
            <div className='hstack gap-1'>
                <button className='btn btn-sm btn-secondary' onClick={onEdit}>Edit</button>
                <button className='btn btn-sm btn-secondary' onClick={onDelete}>Delete</button>
                <button className='btn btn-sm btn-secondary' onClick={onView}>View</button>
            </div>
        </td>
    </tr>
}

export default Contact
