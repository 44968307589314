import { Dispatch } from "redux";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { GetState } from "../store";

// export enum RoleActiveDropDown {
//     Active = "true",
//     InActive = "false",
// }
// export type RoleActiveTypes = RoleActiveDropDown.Active | RoleActiveDropDown.InActive

export interface RoleType {
  id?: string;
  role: string;
  roleId: string;
  pagetitle: string;
  pageurl: string;
  add: boolean;
  view: boolean;
  edit: boolean;
  delete: boolean;
  createdBy?: number | null;
  createdAt?: string;
  updatedAt?: string;
}

export interface PermissionType {
  // role: string
  pagetitle: string;
  pageurl: string;
  add: boolean;
  view: boolean;
  edit: boolean;
  delete: boolean;
}

export interface AddRoleType {
  role: string;
  roleId: string;
  permission: PermissionType[];
}

export enum RoleActionsList {
  ADD_ROLE = "ADD_ROLE",
  EDIT_ROLE = "EDIT_ROLE",
  FETCH_ROLE = "FETCH_ROLE",
  DELETE_ROLE = "DELETE_ROLE",
  CLEAR_ROLE = "CLEAR_ROLE",
}

export interface DeleteRole {
  role: string;
}

export interface AddRoleAction {
  type: RoleActionsList.ADD_ROLE;
  data: RoleType[];
}

export interface EditRoleAction {
  type: RoleActionsList.EDIT_ROLE;
  data: RoleType;
  role?: string;
}

export interface FetchRoleAction {
  type: RoleActionsList.FETCH_ROLE;
  data: RoleType[];
}

export interface DeleteRoleAction {
  type: RoleActionsList.DELETE_ROLE;
  data: DeleteRole;
}

export interface ClearRoleAction {
  type: RoleActionsList.CLEAR_ROLE;
}

export type RoleActions =
  | AddRoleAction
  | EditRoleAction
  | FetchRoleAction
  | DeleteRoleAction
  | ClearRoleAction;

export const addRole = (data: AddRoleType) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .post<APIResponse<RoleType[]>>("role/", data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<AddRoleAction>({
            type: RoleActionsList.ADD_ROLE,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Role Added"
          );
        } else {
          // throw { response };
          throw new Error("Unable to create")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to create"
            : "Unable to create"
        );
      });
  };
};

export const clearRole = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    return dispatch<ClearRoleAction>({
      type: RoleActionsList.CLEAR_ROLE,
    });
  };
};

export const editRole = (data: AddRoleType, role: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .put<APIResponse<RoleType>>(`role/${role}`, data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<EditRoleAction>({
            type: RoleActionsList.EDIT_ROLE,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Role Updated"
          );
        } else {
          throw new Error("Unable to update")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to update"
            : "Unable to update"
        );
      });
  };
};

export const fetchRole = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<RoleType[]>>("role/", config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchRoleAction>({
            type: RoleActionsList.FETCH_ROLE,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const deleteRole = (role: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .delete<APIResponse<DeleteRole>>(`role/${role}`, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<DeleteRoleAction>({
            type: RoleActionsList.DELETE_ROLE,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : " Role Deleted"
          );
        } else {
          throw new Error("Unable to delete")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to delete"
            : "Unable to delete"
        );
      });
  };
};
