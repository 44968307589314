import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export enum ITDetailsDropDown {
    In_House = 'In-House',
    Customized = 'Customized',
}
export type ITDetailsTypes = ITDetailsDropDown.In_House | ITDetailsDropDown.Customized

export interface ITDetailsType {
    id?: string
    posType: ITDetailsTypes
    posName: string
    ITTeam: ITDetailsTypes
}

export const ITDetailsSampleData = [
    {
        id: '1',
        posApplication: ITDetailsDropDown.In_House,
        ITManagement: 'staff-1'
    },
    {
        id: '2',
        posApplication: ITDetailsDropDown.In_House,
        ITManagement: 'staff-2'
    },

]

export enum ITDetailsActionsList {
    ADD_ITDETAILS = 'ADD_ITDETAILS',
    EDIT_ITDETAILS = 'EDIT_ITDETAILS',
    FETCH_ITDETAILS = 'FETCH_ITDETAILS',
    DELETE_ITDETAILS = 'DELETE_ITDETAILS'
}

export interface DeleteITDetails {
    id: string
}

export interface AddITDetailsAction {
    type: ITDetailsActionsList.ADD_ITDETAILS
    data: ITDetailsType
}

export interface EditITDetailsAction {
    type: ITDetailsActionsList.EDIT_ITDETAILS
    data: ITDetailsType
    id: string
}

export interface FetchITDetailsAction {
    type: ITDetailsActionsList.FETCH_ITDETAILS

}

export interface DeleteITDetailsAction {
    type: ITDetailsActionsList.DELETE_ITDETAILS
    data: DeleteITDetails
}

export type ITDetailsActions = AddITDetailsAction | EditITDetailsAction | FetchITDetailsAction | DeleteITDetailsAction

export const addITDetails = (data: ITDetailsType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddITDetailsAction>({
            type: ITDetailsActionsList.ADD_ITDETAILS,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editITDetails = (data: ITDetailsType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditITDetailsAction>({
            type: ITDetailsActionsList.EDIT_ITDETAILS,
            data: data,
            id: id
        })
    }
}

export const fetchITDetails = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchITDetailsAction>({
            type: ITDetailsActionsList.FETCH_ITDETAILS

        })
    }
}

export const deleteITDetails = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteITDetailsAction>({
            type: ITDetailsActionsList.DELETE_ITDETAILS,
            data: { id }
        })
    }
}