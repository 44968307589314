import { ContactType, ContactActionsList, ContactSampleData } from "../actions/contactActions";
import { Action } from "../actions/types";
const initialValue: Array<ContactType> = []

export const ContactReducer = (state: ContactType[] = [], action: Action) => {

    switch (action.type) {

        case ContactActionsList.ADD_CONTACT: {

            return [...state, action.data]
        }

        case ContactActionsList.EDIT_CONTACT: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case ContactActionsList.FETCH_CONTACT: {

            return state
        }

        case ContactActionsList.DELETE_CONTACT: {
            return state.filter(i => i.id !== action.data.id)

        }

        case ContactActionsList.CLEAR_CONTACT: {
            return initialValue

        }

        default: return state
    }

}