import React from 'react'
import PageTitle from '../../components/custom/components/PageTitle'
import AdminLayout from '../../components/custom/layouts/AdminLayout'

const Home = () => {
    return <AdminLayout>
        <PageTitle
            title='Page title'
        />
        <div className="card">
            <div className="card-body">
                Admin Home
            </div>
        </div>
    </AdminLayout>
}

export default Home
