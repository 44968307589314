import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import Option from "../../components/Option";
import { FormInput } from "../../components/custom/components";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AppDispatch, StoreState } from "../../redux/store";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { SiteType, fetchUsersSite } from "../../redux/actions/siteActions";
import { BuildingType, fetchBuilding } from "../../redux/actions/buildingActions";
import { FloorType, fetchFloor } from "../../redux/actions/floorActions";
import { useLocation, useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { UnitType, fetchUnit } from "../../redux/actions/unitActions";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import UnitUploadIndex from "../../components/admin/UnitUploadIndex";
import template from "../../components/custom/assets/templates/unit-template.xls"
import ExcelJS from 'exceljs';
import { testNumber } from "../../config/functions";


interface Props {}

const UnitUpload = () => {
    const toast = useToast();
    const dispatch = useDispatch<AppDispatch>();
    const pageName = useLocation()
    const authAdmin = useSelector<StoreState, AuthAdminType | null>(
        (state) => state.authAdmin
    );
    const adminAuthendicated = typeof authAdmin?.["auth-token"] === "string" &&
    authAdmin?.["auth-token"] !== undefined &&
    authAdmin?.["auth-token"] !== "";  
    const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
        (state) => state.portfolio
    );  
    // const filteredPortfolio = portfolioList.filter((a) => a.businessmodel !== 1);
  
    const unitList = useSelector<StoreState, Array<UnitType>>(
      (state) => state.space
    );
    const siteList = useSelector<StoreState, Array<SiteType>>(
      (state) => state.site
    );
    const buildingList = useSelector<StoreState, Array<BuildingType>>(
      (state) => state.building
    );
    const floorList = useSelector<StoreState, Array<FloorType>>(
      (state) => state.floor
    );

    const [portfolio, setPortfolio] = useState<string>("");
    const [portfolioIdError, setportfolioIdError] = useState<string>("");
    const [centerid, setCenterid] = useState<string>("");
    const [centeridError, setCenteridError] = useState<string>("");
    const [buildingid, setBuildingid] = useState<string>("");
    const [buildingidError, setBuildingidError] = useState<string>("");
    const [floorid, setFloorid] = useState<string>("");
    const [flooridError, setFlooridError] = useState<string>("");
    const [unitname, setUnitname] = useState<string>("");
    const [unitnameError, setUnitnameError] = useState<string>("");
    const [unitno, setUnitno] = useState<string>("");
    const [unitnoError, setUnitnoError] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);
    const [unitCount,setUnitCount] = useState<string>('')
    const [unitCountError,setUnitCountError] = useState<string>('')

    const [availableUnit,setAvailableUnit] = useState<string>('')

    const [totalUnits,setTotalUnits] = useState<string>("")

    
   

    const fetchRequiredData = async(portfolio:string) =>{
      await dispatch(fetchUnit(portfolio))
      await dispatch(fetchUsersSite());
      await dispatch(fetchBuilding(portfolio));
      await dispatch(fetchFloor(portfolio))
    }

    useEffect(()=>{
      if(portfolio){
      fetchRequiredData(portfolio)
      }
    },[portfolio])

    const filteredSites = useMemo<SiteType[]>(() => {
      if (portfolio) {
        return siteList.filter((s) => s.pfid?.toString() === portfolio);
      } else return siteList;
    }, [portfolio, siteList]);
  
    const filteredBuildings = useMemo<BuildingType[]>(() => {
      if (centerid) {
        const centerBuilding = buildingList.filter(
          (s) => s.centerid?.toString() === centerid
        );
        if (centerBuilding?.length === 0) setBuildingid("");
        return centerBuilding;
      } else return buildingList;
    }, [centerid, buildingList]);
  
    const filteredFloors = useMemo<FloorType[]>(() => {
      // if (centerid) {
      //   const centerFloors = floorList.filter(
      //     (s) => s.centerid?.toString() === centerid
      //   );
      //   if (centerFloors?.length === 0) setFloorid("");
      //   return centerFloors;
      // } else 
      if (buildingid) {
        const buildingFloors = floorList.filter(
          (s) => s.buildingid?.toString() === buildingid
        );
        if (buildingFloors?.length === 0) setFloorid("");
        return buildingFloors;
      } else return floorList;
    }, [buildingid, floorList]);//centerid, 
    
    const handlePfid: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPortfolio(e.target.value);
        setportfolioIdError("");
        setCenterid('')
        setCenteridError('')
        setBuildingid('')
        setBuildingidError("");
        setFloorid('')
        setFlooridError("");
    };
    const handleCenterid: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCenterid(e.target.value);
        setCenteridError("");
        setBuildingid('')
        setBuildingidError("");
        setFloorid('')
        setFlooridError("");
        setAvailableUnit('')
        setTotalUnits('')
    };
    const handleBuildingid: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBuildingid(e.target.value);
        setBuildingidError("");
        setFloorid('')
        setFlooridError("");
    };
    const handleFloorid: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFloorid(e.target.value);
        setFlooridError("");
    };

     const handleUnitCount: ChangeEventHandler<HTMLInputElement> = (e) => {
        if((!testNumber(e.target.value)&&e.target.value))
        return
        else if(e.target.value?.length>=4)
        return
    
        setUnitCount(e.target.value)
        setUnitCountError('')
    }


    const handleDownloadXls = async () => {

      if(portfolio===''){
            toast("Portfolio Required", ToastTypes.ERROR);
            return
        } 
        else if(centerid===''){
            toast("Center Required", ToastTypes.ERROR);
            return
        } 
        else if(buildingid===''){
            toast("Building Required", ToastTypes.ERROR);
            return
        } 
        else if(floorid===''){
            toast("Floor Required", ToastTypes.ERROR);
            return
        }  
        else if(unitCount===''){
            // toast("Please Enter a valid Number Of Units", ToastTypes.ERROR);
            setUnitCountError('Please Enter a Valid Number Of Units')
            return
        }
        else if(totalUnits?.toString()===''){
            toast("No units are available for this center", ToastTypes.ERROR);
            return
        }
        else if(availableUnit?.toString()==''){
            toast("All units for this center have already been added", ToastTypes.ERROR);
            return
        }
        else if(Number(unitCount)>Number(availableUnit) || Number(unitCount)<=0){
            if(Number(availableUnit)===1){
                toast(`Please enter a valid number of units to upload (Available Units: ${availableUnit})`, ToastTypes.ERROR);
                return
            }else{
                 toast(`Please enter a valid number of units to upload (Available Units: ${availableUnit})`, ToastTypes.ERROR);
                return
            }
        }


        if(centerid!==''&&buildingid!==''&&floorid!==''&&portfolio!==''&&unitCount!==''&&totalUnits!==''&&availableUnit!==''){
          

         const portfolioName = portfolioList.filter((a)=>a?.id?.toString()===portfolio?.toString())[0]?.name
         const centerName = siteList.filter((a)=>a?.id?.toString()===centerid?.toString())[0]?.name
         const buildingName = buildingList.filter((a)=>a?.id?.toString()===buildingid?.toString())[0]?.buildingname
         const floorName = floorList.filter((a)=>a?.id?.toString()===floorid?.toString())[0]?.floorname

        //  let count = 100
         let excelData:any = [];

         let count = Number(unitCount)+1


      for (let index = 1; index < Number(count); index++) {
        let data = {
          // 'S.No':index,
          "Portfolio":portfolioName,
          "Center": centerName,
          "Building":buildingName,
          "Floor":floorName,
          "Unit Number": '',
          "Unit Name":'',
        }
        excelData.push(data)
    }

     generateExcel(excelData)

        }
    }


     const generateExcel = async (data: any[]) => {
    
      

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(`Sheet_1`);
  
    
      worksheet.columns = [
        // { header: 'S.No', key: 'S.No',width:15},
        { header: 'Portfolio', key: 'Portfolio',width:15},
        { header: 'Center', key: 'Center',width:15},
        { header: 'Building', key: 'Building',width:15},
        { header: 'Floor', key: 'Floor',width:15},
        { header: 'Unit Number', key: 'Unit Number',width:15},
        { header: 'Unit Name', key: 'Unit Name',width:15},
      ];
  
      data.map((row)=>{
        worksheet.addRow({ Portfolio:row.Portfolio,Center:row.Center,Building:row.Building,
          Floor:row.Floor
         });
      })

    
      worksheet.getRow(1).eachCell((cell:any) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF007ACC'} 
        };
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFFFF' } 
        };
      });


      const rowLength = data.length


      for (let i = 2; i <= rowLength+1; i++) {
        worksheet.getRow(i).eachCell({ includeEmpty: true }, (cell, colNumber) => {
          if (colNumber <= 5) { 
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFE0DCC4' } 
            };
            cell.font = {
              bold: false,
              color: { argb: 'FF000000' } 
            };
          }
        });
      }
  

        worksheet.eachRow((row) => {
          row.eachCell((cell) => {
            cell.protection = {
              locked: false
            };
          });
        });

        const columnsToLock = ['A','B','C','D'];

        columnsToLock.forEach(colLetter => {
          worksheet.getColumn(colLetter).eachCell({ includeEmpty: true }, (cell) => {
            cell.protection = {
              locked: true
            };
          });
        });

       
        const columnsToUnlock = ['E','F'];

        columnsToUnlock.forEach(colLetter => {
          worksheet.getColumn(colLetter).eachCell({ includeEmpty: true }, (cell) => {
            // if (colLetter === 'F' || colLetter === 'M' || colLetter ==='N' || colLetter==='O'||colLetter==='P'||colLetter==='Q'||colLetter==='R') { 
            //   cell.dataValidation = {
            //     type: 'whole',
            //     operator: 'greaterThanOrEqual',
            //     allowBlank: true,
            //     showInputMessage: true, 
            //     formulae: [0], 
            //     promptTitle: 'Whole Value',
            //     showErrorMessage: true,
            //     errorTitle: 'Invalid input',
            //     error: 'Please enter a valid Whole number.'
            //   };
            // }
            // if (colLetter === 'G' || colLetter==='H' || colLetter==='I' || colLetter==='J' || colLetter==='K' || colLetter==='L') { 
            //   cell.dataValidation = {
            //     type: 'decimal',
            //     operator: 'greaterThanOrEqual',
            //     allowBlank: true,
            //     showInputMessage: true, 
            //     formulae: [0], 
            //     promptTitle: 'Decimal Value',
            //     showErrorMessage: true,
            //     errorTitle: 'Invalid input',
            //     error: 'Please enter a valid number.'
            //   };
            // }
            cell.protection = {
              locked: false
            };
          });
        });

        worksheet.getRow(1).eachCell((cell) => {
        cell.protection = {
          locked: true,
        };
        
      });
    
     
      await worksheet.protect('', { selectLockedCells: true, selectUnlockedCells: true, })
      
  
    
  
    
      workbook.xlsx.writeBuffer().then((buffer:any) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Unit Upload`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      });

      // setUnitCount('')

  }


  useEffect(()=>{
    if(unitList?.length>0&&portfolio&&centerid&&siteList){
        const filteredUnitList = unitList.filter((a)=>a?.centerid?.toString()===centerid?.toString())
        const filteredSiteList = siteList.filter((a)=>a?.id?.toString()===centerid?.toString())
        // console.log(filteredUnitList,'hella')
        if(filteredSiteList[0].unitcount){
        const remainingUnit = Number(filteredSiteList[0].unitcount)-Number(filteredUnitList?.length)
        setTotalUnits(filteredSiteList[0].unitcount?.toString()!=='undefined'&&!Number.isNaN(filteredSiteList[0].unitcount)?filteredSiteList[0].unitcount?.toString():'')
        // console.log(remainingUnit,'hell')
        if(remainingUnit>0){
        setAvailableUnit(remainingUnit!==undefined&&!Number.isNaN(remainingUnit)&&remainingUnit!==undefined&&remainingUnit?.toString()!==''?remainingUnit?.toString():'')
        }else{
          setAvailableUnit('')
        }
      }else{
          setTotalUnits('')
          setAvailableUnit('')
        }
    }
  },[unitList,portfolio,centerid,siteList])




  return (
    <AdminLayout>
        <div className="row">
          <div className="col-6 hstack mt-4">
            <label htmlFor="">Portfolio :</label>
            <FormInput
              name="Portfolio"
              label=""
              labelClassName="required"
              value={portfolio}
              onChange={handlePfid}
              placeholder="Portfolio"
              errorText={portfolioIdError}
              containerClass="w-50"
            //   disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {portfolioList.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        </div>
        <div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <h5 className="header-title mb-3 float-start">
                            <i className="fe-align-justify me-1 text-primary"></i>
                            Unit Upload
                        </h5>
                    </div>
                    <div className="col-lg-6 col-sm-12 text-end mb-1">
                        <button
                        type="button"
                        className={`me-1 btn btn-xs btn-secondary`} 
                        onClick={handleDownloadXls}
                        >Download Template
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <FormInput
                        name="Center Name"
                        label="Center Name"
                        labelClassName="required"
                        value={centerid || ""}
                        onChange={handleCenterid}
                        placeholder="Center Name"
                        errorText={centeridError}
                        containerClass="mb-2"
                        // disabled={showViewForm}
                        type="select"
                        >
                            <option value="">Select</option>
                            {filteredSites.map((a, i) => {
                                return (
                                    <Option value={a.id} key={i}>
                                    {a.name}
                                    </Option>
                                );
                            })}
                        </FormInput>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <FormInput
                        name="Building Name"
                        label="Building Name"
                        labelClassName="required"
                        value={buildingid || ""}
                        onChange={handleBuildingid}
                        placeholder="Building Name"
                        errorText={buildingidError}
                        containerClass="mb-2"
                        // disabled={showViewForm}
                        type="select"
                        >
                            <option value="">Select</option>
                            {filteredBuildings.map((a, i) => {
                            return (
                            <Option value={a.id} key={i}>
                            {a.buildingname}
                            </Option>
                            );
                            })}
                        </FormInput>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <FormInput
                        name="Floor Name"
                        label="Floor Name"
                        labelClassName="required"
                        value={floorid || ""}
                        onChange={handleFloorid}
                        placeholder="Floor Name"
                        errorText={flooridError}
                        containerClass="mb-2"
                        // disabled={showViewForm}
                        type="select"
                        >
                            <option value="">Select</option>
                            {filteredFloors.map((a, i) => {
                            return (
                            <Option value={a.id} key={i}>
                            {a.floorname}
                            </Option>
                            );
                            })}
                        </FormInput>
                    </div>

              <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Number Of Units'
                    label='Number Of Units'
                    labelClassName="required"
                    value={unitCount}
                    onChange={handleUnitCount}
                    placeholder='Number Of Units'
                    errorText={unitCountError}
                    containerClass="mb-2"
                    type="text"
                />
            </div>


                </div>


                <div className="row col-sm-12 col-lg-12 upload-container  mt-3">
                <UnitUploadIndex portfolioid={portfolio} centerid={centerid} buildingid={buildingid} floorid={floorid} />
                </div>
            </div>
        </div>
    </AdminLayout>
  );
};
export default UnitUpload;
