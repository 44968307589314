import { RoleActionsList, RoleType } from "../actions/roleActions";
import { Action } from "../actions/types";

const initialValue: Array<RoleType> = []

export const RoleReducer = (state: RoleType[] = initialValue, action: Action) => {

    switch (action.type) {

        case RoleActionsList.ADD_ROLE: {

            return [...state, ...action.data]
        }

        case RoleActionsList.EDIT_ROLE: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.role === action.data.role)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case RoleActionsList.FETCH_ROLE: {

            return action.data

        }

        case RoleActionsList.DELETE_ROLE: {
            return state.filter(i => i.role !== action.data.role)

        }

        case RoleActionsList.CLEAR_ROLE: {
            return initialValue

        }

        default: return state
    }

}