import PageTitle from "../../components/custom/components/PageTitle";
import AdminLayout from "../../components/custom/layouts/AdminLayout";

const AgentAuthentication = () => {
    return <AdminLayout>
        <PageTitle
            title='Agent Authentication'
        />
        {/* <div className="card">
       <div className="card-body">
           
       </div>
   </div> */}
    </AdminLayout>
}
export default AgentAuthentication;      
