import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { Dispatch } from "redux";
import { GetState } from "../store";
import { ActiveTypes } from "./brandActions";

export interface outletDetails {
  outletcode?: string;
  country?: string;
  region?: string;
  state?: string;
  city?: string;
  area?: string;
}

export interface outletType {
  id?: number;
  portfolioid: number;
  brandid: number;
  brandcompanyid: number;
  countryid: number;
  regionid: number;
  stateid: number;
  cityid: number;
  area: string;
  address1: string;
  address2: string;
  pincode: string;
  active: ActiveTypes | boolean;
  franchise: boolean;
  outletcode: string;
  outlettype: number;
  outletlocation: number;
  carpetarea: number;
  chargeablearea: number;
  tradecatid: number;
  tradesubcatid: number;
  leaseid: string;
  centerid?: number;
  renttype: number;
  revenueshare: string;
  fromdate: string;
  todate: string;
}

export enum OutletActionsList {
  ADD_OUTLET_MANAGER = "ADD_OUTLET_MANAGER",
  EDIT_OUTLET_MANAGER = "EDIT_OUTLET_MANAGER",
  FETCH_OUTLET_MANAGER = "FETCH_OUTLET_MANAGER",
  DELETE_OUTLET_MANAGER = "DELETE_OUTLET_MANAGER",
  CLEAR_OUTLET_MANAGER = "CLEAR_OUTLET_MANAGER",
}

export interface DELETE_OUTLET_MANAGER {
  id: number;
}

export interface AddOutletAction {
  type: OutletActionsList.ADD_OUTLET_MANAGER;
  data: outletType;
}

export interface EditOutletAction {
  type: OutletActionsList.EDIT_OUTLET_MANAGER;
  data: outletType;
  id?: number;
}

export interface DeleteOutletAction {
  type: OutletActionsList.DELETE_OUTLET_MANAGER;
  data: DELETE_OUTLET_MANAGER;
}

export interface FetchOutletAction {
  type: OutletActionsList.FETCH_OUTLET_MANAGER;
  data: outletType[];
}

export interface ClearOutletAction {
  type: OutletActionsList.CLEAR_OUTLET_MANAGER;
}

export type OutletActions =
  | AddOutletAction
  | EditOutletAction
  | DeleteOutletAction
  | ClearOutletAction
  | FetchOutletAction;

export const addOutlet = (data: outletType) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);
    return api
      .post<APIResponse<outletType>>("outlet/", data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<AddOutletAction>({
            type: OutletActionsList.ADD_OUTLET_MANAGER,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Outlet added"
          );
        } else {
          // throw { response };
          throw new Error("Unable to create")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to create"
            : "Unable to create"
        );
      });
  };
};

export const editOutlet = (data: outletType, id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .put<APIResponse<outletType>>(`outlet/${id}`, data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<EditOutletAction>({
            type: OutletActionsList.EDIT_OUTLET_MANAGER,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Outlet Updated"
          );
        } else {
          throw new Error("Unable to update")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to update"
            : "Unable to update"
        );
      });
  };
};

export const fetchOutlet = (portfolioId: string, id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<outletType[]>>(`outlet/${portfolioId}/${id}`, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchOutletAction>({
            type: OutletActionsList.FETCH_OUTLET_MANAGER,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const fetchOutletByBrands = (portfolioId: string, brands: string[]) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<outletType[]>>(`outlet/outletbyBrands/${portfolioId}/${brands}`, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchOutletAction>({
            type: OutletActionsList.FETCH_OUTLET_MANAGER,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const fetchOutletByPortfolios = (portfolioId: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<outletType[]>>(`outlet/outletbyPortfolios/${portfolioId}`, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchOutletAction>({
            type: OutletActionsList.FETCH_OUTLET_MANAGER,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const deleteOutlet = (id: number, portfolioId: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .delete<APIResponse<outletType>>(`outlet/${portfolioId}/${id}`, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch<DeleteOutletAction>({
            type: OutletActionsList.DELETE_OUTLET_MANAGER,
            data: { id },
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Outlet Deleted"
          );
        } else {
          throw new Error("Unable to delete")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to delete"
            : "Unable to delete"
        );
      });
  };
};

export const clearOutlet = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    return dispatch<ClearOutletAction>({
      type: OutletActionsList.CLEAR_OUTLET_MANAGER,
    });
  };
};
