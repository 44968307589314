import React from "react";
import { Form } from "react-bootstrap";
import { components, default as ReactSelect } from "react-select";

const Option = (props: any) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                // className="form-select2"
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

interface OptProps {
    value: number
    label: string
    imgUrl?: string
}
interface MutiSelectProps {
    options: OptProps[]
    label?: string
    errorText?: string
    filterText?: string
    selectedOptions: any
    setSelectedOptions: React.Dispatch<any>
    disabled?: boolean
    onChange?: () => void
    placeHolder?: string

}
const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        // background: '#fff',
        // borderColor: '#9e9e9e',
        minHeight: '30px',
        height: '33px',
        // boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided: any, state: any) => ({
        ...provided,
        height: '30px',
        padding: '0 6px',
        overflowY: 'hidden',
        // backgroundColor: 'red'
        // margin: '2px 0'
    }),

    input: (provided: any, state: any) => ({
        ...provided,
        margin: '2px',
    }),
    // indicatorSeparator: state => ({
    //     display: 'none',
    // }),
    indicatorsContainer: (provided: any, state: any) => ({
        ...provided,
        height: '30px',
    }),
};

const MultiSelect: React.FC<MutiSelectProps> = (props: MutiSelectProps) => {
    // const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const { options, label, errorText, selectedOptions, setSelectedOptions, disabled, onChange, placeHolder, filterText } = props;
    const handleChange = (selected: any) => {
        setSelectedOptions(selected)
        if (filterText === 'Portfolio') {
            localStorage.removeItem('portfolioFilter')
            localStorage.setItem('portfolioFilter', JSON.stringify(selected));
        }

        if (filterText === 'Center') {
            localStorage.removeItem('centerFilter')
            localStorage.setItem('centerFilter', JSON.stringify(selected));
        }
        onChange?.()
    }
    return (
        <>
            <Form.Group>
                {label ? (
                    <>
                        {' '}
                        <Form.Label className="label">{label}</Form.Label>
                    </>
                ) : null}
                <ReactSelect
                    options={options} isMulti components={{ Option }}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={selectedOptions}
                    onChange={handleChange}
                    isDisabled={disabled}
                    placeholder={placeHolder}
                    // className={'form-select2'}
                    styles={customStyles}
                    classNames={{
                        // control: (state) => {
                        //     return state.isFocused ? 'p-0 border-red-600' : 'p-0 border-grey-300'
                        // }
                        control: (props) => {
                            return 'form-multi-select2 p-0'
                        },
                        // indicatorsContainer: (state) =>
                        //     state.children ? 'p-0' : 'p-0',
                    }}
                />

                {errorText && <Form.Control.Feedback type="invalid" className="d-block">
                    {errorText}
                </Form.Control.Feedback>}
            </Form.Group>
        </>
    )
}

export default MultiSelect