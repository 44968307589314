import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import {
  CountryType,
  addCountry,
  editCountry,
} from "../../redux/actions/countryActions";
import { AppDispatch, StoreState } from "../../redux/store";
import { FormInput } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { AdminLog } from "../../config/common";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";

interface Props {
  formType: FormType;
  editData?: CountryType;
  onSave: () => void;
  showViewForm: boolean;
}

const CountryForm = ({ formType, onSave, editData, showViewForm }: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const [countrycode, setCountrycode] = useState<string>("");
  const [countrycodeError, setCountrycodeError] = useState<string>("");
  const [countryname, setCountryname] = useState<string>("");
  const [countrynameError, setCountrynameError] = useState<string>("");
  const [currencyname, setCurrencyname] = useState<string>("");
  const [currencynameError, setCurrencynameError] = useState<string>("");
  const [shortcode, setShortcode] = useState<string>("");
  const [shortcodeError, setShortcodeError] = useState<string>("");
  const [noofdecimal, setNoofdecimal] = useState<string>("");
  const [noofdecimalError, setNoofdecimalError] = useState<string>("");
  const [decimalshortname, setDecimalshortname] = useState<string>("");
  const [decimalshortnameError, setDecimalshortnameError] =
    useState<string>("");
  const [currencysymbol, setCurrencysymbol] = useState<string>("");
  const [currencysymbolError, setCurrencysymbolError] = useState<string>("");
  const [divisionvalue, setDivisionvalue] = useState<string>("");
  const [divisionvalueError, setDivisionvalueError] = useState<string>("");
  const [valuecaption, setValuecaption] = useState<string>("");
  const [valuecaptionError, setValuecaptionError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const resetStates = () => {
    setCountrycode("");
    setCountrycodeError("");
    setCountryname("");
    setCountrynameError("");
    setCurrencyname("");
    setCurrencynameError("");
    setShortcode("");
    setShortcodeError("");
    setNoofdecimal("");
    setNoofdecimalError("");
    setDecimalshortname("");
    setDecimalshortnameError("");
    setCurrencysymbol("");
    setCurrencysymbolError("");
    setDivisionvalue("");
    setDivisionvalueError("");
    setValuecaption("");
    setValuecaptionError("");
    setLoading(false);
  };

  const handleCountrycode: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCountrycode(e.target.value);
    setCountrycodeError("");
  };

  const handleCountryname: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCountryname(e.target.value);
    setCountrynameError("");
  };

  const handleCurrencyname: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCurrencyname(e.target.value);
    setCurrencynameError("");
  };

  const handleShortcode: ChangeEventHandler<HTMLInputElement> = (e) => {
    setShortcode(e.target.value);
    setShortcodeError("");
  };

  const handleNoofdecimal: ChangeEventHandler<HTMLInputElement> = (e) => {
    setNoofdecimal(e.target.value);
    setNoofdecimalError("");
  };

  const handleDecimalshortname: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDecimalshortname(e.target.value);
    setDecimalshortnameError("");
  };

  const handleCurrencysymbol: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCurrencysymbol(e.target.value);
    setCurrencysymbolError("");
  };

  const handleDivisionvalue: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDivisionvalue(e.target.value);
    setDivisionvalueError("");
  };

  const handleValueCaption: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValuecaption(e.target.value);
    setValuecaptionError("");
  };

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const data: CountryType = {
      countrycode: countrycode,
      countryname: countryname,
      currencyname: currencyname,
      shortcode: shortcode,
      noofdecimal: parseInt(noofdecimal),
      decimalshortname: decimalshortname,
      currencysymbol: currencysymbol,
      divisionvalue: divisionvalue,
      valuecaption: valuecaption,
    };

    if (data.countrycode === "") {
      setCountrycodeError("Country Code required");
      error = true;
    } else if (data.countrycode?.length > 100) {
      setCountrycodeError("Country Code should be within 100 digits");
      error = true;
    }

    if (data.countryname === "") {
      setCountrynameError("Country name required");
      error = true;
    } else if (data.countryname?.length > 100) {
      setCountrynameError("Country name should be within 100 characters");
      error = true;
    }

    if (data.currencyname === "") {
      setCurrencynameError("Currency name required");
      error = true;
    } else if (data.currencyname?.length > 50) {
      setCurrencynameError("Currency name should be within 50 characters");
      error = true;
    }

    if (data.shortcode === "") {
      setShortcodeError("Short code required");
      error = true;
    } else if (data.shortcode?.length > 15) {
      setShortcodeError("Short code should be within 30 characters");
      error = true;
    }

    if (isNaN(data.noofdecimal)) {
      setNoofdecimalError("Number of decimal required");
      error = true;
    } else if (data.noofdecimal > 15) {
      setNoofdecimalError("Number of decimal should be within 30 characters");
      error = true;
    }

    if (data.decimalshortname === "") {
      setDecimalshortnameError("Decimal Short Name required");
      error = true;
    } else if (data.decimalshortname?.length > 15) {
      setDecimalshortnameError(
        "Decimal short name should be within 30 characters"
      );
      error = true;
    }

    if (data.currencysymbol === "") {
      setCurrencysymbolError("Currency Symbol required");
      error = true;
    } else if (data.currencysymbol?.length > 15) {
      setCurrencysymbolError("Currency Symbol should be within 30 characters");
      error = true;
    }

    if (data.divisionvalue === "") {
      setDivisionvalueError("Division Value required");
      error = true;
    } else if (data.divisionvalue?.length > 15) {
      setDivisionvalueError("Division Value should be within 30 characters");
      error = true;
    }

    if (data.valuecaption === "") {
      setValuecaptionError("Division Value required");
      error = true;
    } else if (data.valuecaption?.length > 30) {
      setValuecaptionError("Division Value should be within 30 characters");
      error = true;
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addCountry(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.id !== undefined
      ) {
        setLoading(true);
        dispatch(editCountry(data, editData.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      setCountrycode(editData.countrycode);
      setCountryname(editData.countryname);
      setCurrencyname(editData.currencyname);
      setShortcode(editData.shortcode);
      setNoofdecimal(editData.noofdecimal?.toString());
      setDecimalshortname(editData.decimalshortname);
      setCurrencysymbol(editData.currencysymbol);
      setDivisionvalue(editData.divisionvalue);
      setValuecaption(editData.valuecaption);
    } else {
      resetStates();
    }
  }, [formType, editData]);


  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Country Name"
            label="Country Name"
            labelClassName="required"
            value={countryname}
            onChange={handleCountryname}
            placeholder="Country Name"
            errorText={countrynameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Country Code"
            label="Country Code"
            labelClassName="required"
            value={countrycode}
            onChange={handleCountrycode}
            placeholder="Country Code"
            errorText={countrycodeError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Currency Name"
            label="Currency Name"
            labelClassName="required"
            value={currencyname}
            onChange={handleCurrencyname}
            placeholder="Currency Name"
            errorText={currencynameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Short Name"
            label="Short Name"
            labelClassName="required"
            value={shortcode}
            onChange={handleShortcode}
            placeholder="Short Name"
            errorText={shortcodeError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Number of decimal"
            label="Number of decimal"
            labelClassName="required"
            value={noofdecimal}
            onChange={handleNoofdecimal}
            placeholder="Number of decimal"
            errorText={noofdecimalError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Decimal Short Name"
            label="Decimal Short Name"
            labelClassName="required"
            value={decimalshortname}
            onChange={handleDecimalshortname}
            placeholder="Decimal Short Name"
            errorText={decimalshortnameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Currency Symbol"
            label="Currency Symbol"
            labelClassName="required"
            value={currencysymbol}
            onChange={handleCurrencysymbol}
            placeholder="Currency Symbol"
            errorText={currencysymbolError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Division Value"
            label="Division Value"
            labelClassName="required"
            value={divisionvalue}
            onChange={handleDivisionvalue}
            placeholder="Division Value"
            errorText={divisionvalueError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Value Caption"
            label="Value Caption"
            labelClassName="required"
            value={valuecaption}
            onChange={handleValueCaption}
            placeholder="Value Caption"
            errorText={valuecaptionError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default CountryForm;
