// import { RoleType } from "../../redux/actions/roleActions"

interface Props {
    deleteLabel: string
    // deleteData: RoleType
    onCancel: () => void
    onDelete: () => void
}


const DeleteConfirmationForm = ({ deleteLabel, onCancel, onDelete }: Props) => {

    return <div className="mt-0">
        <h4 className="text-black ">{deleteLabel}</h4>
        <div className="hstack gap-1 float-end">
            <button className="btn btn-sm btn-secondary" onClick={onDelete}>Delete</button>
            <button className="btn btn-sm btn-secondary" onClick={onCancel}>Cancel</button>
        </div>
    </div>
}
export default DeleteConfirmationForm