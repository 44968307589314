import React, { useState } from "react";
import Tabs from "../../components/admin/Tabs";
import "../../styles/styles.css";
// Tabs Components
import { Link } from "react-router-dom";
import BrandContactForm from "../../components/admin/BrandContactForm";
import ContactForm from "../../components/admin/ContactForm";
import GeneralInfoForm from "../../components/admin/GeneralInfoForm";
import ITDetailsForm from "../../components/admin/ITDetailsForm";
import LeaseInfoForm from "../../components/admin/LeaseInfoForm";
import { TenantManagerContext, TenantManagerContextType } from "../../components/context/TenantManagerContext";
import { TenantManagerType } from "../../redux/actions/tenantManagerActions";
import { FormType, FormTypeList } from "./Contact";
import Loader from "../../components/custom/components/Loader";
import FileLoader from "../../components/custom/components/FileLoader";

type TabsType = {
    label: string;
    index: number;
    Component: React.FC<{}>;
    // Component: JSX.Element
}[];

export enum TabList {
    GENERAL_INFO = "General Info",
    LEASE_INFO = "Lease Info",
    IT_DETAILS = "IT Details",
    TENANT_CONTACTS = "Tenant Contacts",
    BRAND_CONTACTS = "Brand Contacts"
}

// Tabs Array
const tabs: TabsType = [
    {
        label: "General Info",
        index: 1,
        Component: GeneralInfoForm
    },
    {
        label: "Lease Info",
        index: 2,
        Component: LeaseInfoForm
    },
    {
        label: "IT Details",
        index: 3,
        Component: ITDetailsForm
    },
    {
        label: "Tenant Contacts",
        index: 4,
        Component: ContactForm
    },
    {
        label: "Brand Contacts",
        index: 5,
        Component: BrandContactForm
    }
];

interface Props {
    formType?: FormType
    editData?: TenantManagerType
    onSave?: () => void
    frmType?: string
}

const AddTenant = (props: Props) => {

    //     return (
    //         <>
    //             {/* <AdminLayout> */}
    //             {/* <PageTitle
    //                 title='Add Tenant Information'
    //             /> */}
    //             {/* <TenantManagerProvider> */}
    //             <Body />
    //             {/* </TenantManagerProvider> */}
    //             {/* </AdminLayout> */}
    //         </>
    //     );
    // }

    // const Body = () => {
    const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
    const { handleSave,generalInfoNextErrorHandler,leaseInfoNextErrorHandler,itDetailsNextErrorHandler,loading} = React.useContext<TenantManagerContextType | null>(TenantManagerContext)!

    const handlePrevious = () => {
        const currentTab = selectedTab;
        setSelectedTab(currentTab - 1)
    }

    const handleNext = () => {
        let error = false
        if(selectedTab===1)
        error = generalInfoNextErrorHandler()
        else if(selectedTab===2)
        error = leaseInfoNextErrorHandler()
        else if(selectedTab===3)
        error = itDetailsNextErrorHandler()

        if(!error)
        {
            const currentTab = selectedTab;
            setSelectedTab(currentTab + 1)
        }
    }
    return <>
        <div className="App">
            {props.frmType===FormTypeList.ADD&&<Tabs selectedTab={selectedTab} onClick={setSelectedTab} tabs={tabs} frmType={props.frmType}/>}
            {
  (props.frmType === FormTypeList.EDIT || props.frmType === FormTypeList.VIEW) && !loading ? (
    <Tabs selectedTab={selectedTab} onClick={setSelectedTab} tabs={tabs} frmType={props.frmType} />
  ) : (
    props.frmType !== FormTypeList.ADD && (
      <div style={{minHeight:'250px',display:'flex',justifyContent:'center',alignItems:'center',paddingBottom:'20px'}}>
      <div className="spinner-border" role="status"></div>
      </div>
    )
  )
}

            <div className="d-flex justify-content-end hstack gap-1 mt-2">
                {(selectedTab === 2 || selectedTab === 3 || selectedTab === 4 || selectedTab === 5) &&  <button className="btn btn-secondary" onClick={handlePrevious}>Previous</button>}
                {(selectedTab === 1 || selectedTab === 2 || selectedTab === 3 || selectedTab === 4) && props.frmType===FormTypeList.ADD&&<button className="btn btn-secondary" onClick={handleNext}>Next</button>}
                {(selectedTab === 1 || selectedTab === 2 || selectedTab === 3 || selectedTab === 4) && ((props.frmType === FormTypeList.EDIT || props.frmType === FormTypeList.VIEW) && !loading)&&
                <button className="btn btn-secondary" onClick={handleNext}>Next</button>}

                {selectedTab === 5 && props.frmType!==FormTypeList.VIEW&& <button className="btn btn-secondary"><Link to="/admin/tenant_manager" className="text-light">Cancel</Link></button>}
                {selectedTab === 5 && props.frmType!==FormTypeList.VIEW && <button className="btn btn-secondary"><Link to="/admin/tenant_manager" className="text-light" onClick={handleSave}>Save</Link></button>}
            </div>
        </div>
    </>
}
export default AddTenant;
