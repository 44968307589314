import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

// export enum budildingTerminalDropDown {
//     ShoppingCenter = 'Shopping Center',
//     Mall = 'Mall',
// }
// export type budildingTerminalTypes = budildingTerminalDropDown.ShoppingCenter | budildingTerminalDropDown.Mall
export enum PaxTypeDropDown {
    Arrival = 'Arrival',
    Departures = 'Departures',
    Others = 'Others',
}
export type PaxAreaTypes = PaxTypeDropDown.Arrival | PaxTypeDropDown.Departures | PaxTypeDropDown.Others

export interface LeaseInfoType {
    id?: string
    carpetArea: String
    leasableArea: string
    landmark: string
    buildingTerminal: string
    location: string
    paxType: string
    unitName: string
}

export const LeaseInfoSampleData = [
    {
        id: '1',
        carpetArea: 'Hall',
        leasableArea: 'Mall',
        landmark: 'Temple',
        budildingTerminal: 'Mall',
        location: 'Tirchy',
        paxType: 'Pax Type-1',
        unitName: 'sector-1',
    },
    {
        id: '2',
        carpetArea: 'Hall',
        leasableArea: 'Mall',
        landmark: 'Temple',
        budildingTerminal: 'ShoppingCenter',
        location: 'Tirchy',
        paxType: 'Pax Type-1',
        unitName: 'sector-1',
    },

]

export enum LeaseInfoActionsList {
    ADD_LEASEINFO = 'ADD_LEASEINFO',
    EDIT_LEASEINFO = 'EDIT_LEASEINFO',
    FETCH_LEASEINFO = 'FETCH_LEASEINFO',
    DELETE_LEASEINFO = 'DELETE_LEASEINFO'
}

export interface DeleteLeaseInfo {
    id: string
}

export interface AddLeaseInfoAction {
    type: LeaseInfoActionsList.ADD_LEASEINFO
    data: LeaseInfoType
}

export interface EditLeaseInfoAction {
    type: LeaseInfoActionsList.EDIT_LEASEINFO
    data: LeaseInfoType
    id: string
}

export interface FetchLeaseInfoAction {
    type: LeaseInfoActionsList.FETCH_LEASEINFO

}

export interface DeleteLeaseInfoAction {
    type: LeaseInfoActionsList.DELETE_LEASEINFO
    data: DeleteLeaseInfo
}

export type LeaseInfoActions = AddLeaseInfoAction | EditLeaseInfoAction | FetchLeaseInfoAction | DeleteLeaseInfoAction

export const addLeaseInfo = (data: LeaseInfoType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddLeaseInfoAction>({
            type: LeaseInfoActionsList.ADD_LEASEINFO,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editLeaseInfo = (data: LeaseInfoType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditLeaseInfoAction>({
            type: LeaseInfoActionsList.EDIT_LEASEINFO,
            data: data,
            id: id
        })
    }
}

export const fetchLeaseInfo = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchLeaseInfoAction>({
            type: LeaseInfoActionsList.FETCH_LEASEINFO

        })
    }
}

export const deleteLeaseInfo = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteLeaseInfoAction>({
            type: LeaseInfoActionsList.DELETE_LEASEINFO,
            data: { id }
        })
    }
}