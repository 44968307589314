import { Dispatch } from "redux";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { GetState } from "../store";

export enum ActiveDropDown {
  Active = "true",
  InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive;

export interface SiteType {
  id?: number;
  name: string;
  code: string;
  url: string;
  pfid: number;
  centertype: string;
  gla: number;
  uom: string;
  salesby: string;
  country: number;
  region: number;
  siteRegion?: {
    regionname?: string;
  };
  state: number;
  siteState?: {
    statename?: string;
  };
  city: number;
  siteCity?: {
    cityname?: string;
  };
  street: string;
  pincode: string;
  logopath: string;
  logourl: string;
  active: ActiveTypes | boolean;
  livefrom: string;
  createdBy?: number | null;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;
  currencycode?: string;
  currencysymbol?: string;
  divisionvalue?: number;
  valuecaption?: string;
  tenant_prefix:string;
  unitcount?:number
}

// export const SiteSampleData = [
//     {
//         id: '1',
//         siteName: 'Site-1',
//         portfolioId: '1',
//         portfolioName: 'Portfolio-1',
//         status: StatusDropDown.Active,
//         buildingTerminal: 'Mall',
//         siteType: 'Land',
//         measureIn: 'Centimeter',
//         crossArea: 'Land',
//         liveOnDate: '2023-01-08',
//         leasableArea: 'Chennai',
//         saleBaseOn: 'Rate',
//     },
//     {
//         id: '2',
//         siteName: 'Site-2',
//         portfolioId: '2',
//         portfolioName: 'Portfolio-2',
//         status: StatusDropDown.De_Active,
//         buildingTerminal: 'SuperMarket',
//         siteType: 'Land',
//         measureIn: 'Centimeter',
//         crossArea: 'Land',
//         liveOnDate: '2023-01-08',
//         leasableArea: 'Chennai',
//         saleBaseOn: 'Rate',
//     },
// ]

export enum SiteActionsList {
  ADD_SITE = "ADD_SITE",
  EDIT_SITE = "EDIT_SITE",
  FETCH_SITE = "FETCH_SITE",
  DELETE_SITE = "DELETE_SITE",
  CLEAR_SITE = "CLEAR_SITE",
}

export interface DeleteSite {
  id: number;
}

export interface AddSiteAction {
  type: SiteActionsList.ADD_SITE;
  data: SiteType;
}

export interface EditSiteAction {
  type: SiteActionsList.EDIT_SITE;
  data: SiteType;
  id?: number;
}

export interface FetchSiteAction {
  type: SiteActionsList.FETCH_SITE;
  data: SiteType[];
}

export interface ClearSiteAction {
  type: SiteActionsList.CLEAR_SITE;
}

export interface DeleteSiteAction {
  type: SiteActionsList.DELETE_SITE;
  data: DeleteSite;
}

export type SiteActions =
  | AddSiteAction
  | EditSiteAction
  | FetchSiteAction
  | DeleteSiteAction
  | ClearSiteAction;

export const addSite = (data: SiteType) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);
    return api
      .post<APIResponse<SiteType>>("site/", data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<AddSiteAction>({
            type: SiteActionsList.ADD_SITE,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Center added"
          );
        } else {
          // throw { response };
          throw new Error("Unable to create")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to create"
            : "Unable to create"
        );
      });
  };
};
export const clearSite = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    return dispatch<ClearSiteAction>({
      type: SiteActionsList.CLEAR_SITE,
    });
  };
};
export const editSite = (data: SiteType, id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .put<APIResponse<SiteType>>(`site/${id}`, data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<EditSiteAction>({
            type: SiteActionsList.EDIT_SITE,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Center Updated"
          );
        } else {
          throw new Error("Unable to update")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to update"
            : "Unable to update"
        );
      });
  };
};

export const fetchSite = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<SiteType[]>>("site/", config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchSiteAction>({
            type: SiteActionsList.FETCH_SITE,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const fetchUsersSite = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<SiteType[]>>("users-site/", config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchSiteAction>({
            type: SiteActionsList.FETCH_SITE,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const deleteSite = (id: number, portfolioId: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .delete<APIResponse<SiteType>>(`site/${portfolioId}/${id}`, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch<DeleteSiteAction>({
            type: SiteActionsList.DELETE_SITE,
            data: { id },
          });
          return Promise.resolve(
            response.data.message ? response.data.message : " Center Deleted"
          );
        } else {
          throw new Error("Unable to delete")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to delete"
            : "Unable to delete"
        );
      });
  };
};
