import React, { ChangeEventHandler } from "react"
import { ITDetailsTypes } from "../../redux/actions/ITDetailsActions"
import Option from "../Option"
import { TenantManagerContext, TenantManagerContextType } from "../context/TenantManagerContext"
import { FormInput } from "../custom/components"

const ITDETAILS_DROPS = [
    {
        text: 'In-House',
        value: 'In-House'
    },
    {
        text: 'Customized',
        value: 'Customized'
    },
]

const ITDetailsForm = () => {
    const { posName, posNameError, posType, posTypeError, ITTeam, ITTeamError, setITTeam, setITTeamError, setPosName, setPosNameError, setPosType, setPosTypeError
    } = React.useContext<TenantManagerContextType | null>(TenantManagerContext)!


    // const [posType, setPosType] = useState<ITDetailsTypes>(ITDetailsDropDown.In_House)
    // const [posTypeError, setPosTypeError] = useState<string>('')
    // const [posName, setPosName] = useState<string>('')
    // const [posNameError, setPosNameError] = useState<string>('')
    // const [ITTeam, setITTeam] = useState<ITDetailsTypes>(ITDetailsDropDown.In_House)
    // const [ITTeamError, setITTeamError] = useState<string>('')

    const handlePosType: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPosType!(e.target.value as ITDetailsTypes)
        setPosTypeError!('')
    }
    const handlePosName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPosName!(e.target.value as ITDetailsTypes)
        setPosNameError!('')
    }
    const handleITTeam: ChangeEventHandler<HTMLInputElement> = (e) => {
        setITTeam!(e.target.value as ITDetailsTypes)
        setITTeamError!('')
    }

    return (
        <div className='container'>
            <h4>Tenant IT Details</h4>
            <hr />
            <div className="row">
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        name='POS Type'
                        label='POS Type'
                        labelClassName="required"
                        value={posType}
                        onChange={handlePosType}
                        placeholder='POS Type'
                        errorText={posTypeError}
                        containerClass="mb-2"
                        type="select"
                    >
                        <option value=''>Select</option>
                        {ITDETAILS_DROPS.map(ud => {
                            return <Option value={ud.value}>{ud.text}</Option>
                        })}
                    </FormInput>
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        name='POS Name'
                        label='POS Name'
                        labelClassName="required"
                        value={posName}
                        onChange={handlePosName}
                        placeholder='POS Name'
                        errorText={posNameError}
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        name='IT Management'
                        label='IT Management'
                        labelClassName="required"
                        value={ITTeam}
                        onChange={handleITTeam}
                        placeholder='IT Management'
                        errorText={ITTeamError}
                        containerClass="mb-2"
                        type="select"
                    >
                        <option value=''>Select</option>
                        {ITDETAILS_DROPS.map(ud => {
                            return <Option value={ud.value}>{ud.text}</Option>
                        })}
                    </FormInput>
                </div>
            </div>
        </div >
    )
}
export default ITDetailsForm
