import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { FormType, FormTypeList } from "../../config";
import {
  BrandCompanyMappingType,
  addBrandCompanyMapping,
  editBrandCompanyMapping,
} from "../../redux/actions/brandCompanyMappingActions";
import { FormInput } from "../custom/components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../redux/store";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import {
  ActiveDropDown,
  ActiveTypes,
  PortfolioType,
} from "../../redux/actions/portfolioActions";
import { BrandCompanyType } from "../../redux/actions/brandCompanyActions";
import { BrandType } from "../../redux/actions/brandActions";
import Option from "../Option";
import { TradeCategoryType } from "../../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../../redux/actions/tradeSubCategoryActions";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";


interface Props {
  formType: FormType;
  editData?: BrandCompanyMappingType;
  onSave: () => void;
  showViewForm: boolean;
}

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

const BrandCompanyMappingForm = ({
  formType,
  onSave,
  editData,
  showViewForm,
}: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );

  const filteredPortfolioList = portfolioList.filter(
    (a) => a.businessmodel === 1
  );

  const brandcompanyList = useSelector<StoreState, Array<BrandCompanyType>>(
    (state) => state.brandCompany
  );

  const brandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state.brand
  );

  const TradeCategoryList = useSelector<StoreState, Array<TradeCategoryType>>(
    (state) => state.tradeCategory
  );

  const TradeSubCategoryList = useSelector<
    StoreState,
    Array<TradeSubCategoryType>
  >((state) => state.tradeSubCategory);

  const [pfid, setpfid] = useState<string>("");
  const [pfidError, setpfidError] = useState<string>("");
  const [Companyid, setCompanyid] = useState<string>("");
  const [brandid, setBrandid] = useState<string>("");
  const [brandidError, setBrandidError] = useState<string>("");
  const [catid, setCatid] = useState<string>("");
  const [catidError, setCatidError] = useState<string>("");
  const [subcatid, setsubcatid] = useState<string>("");
  const [subcatidError, setsubcatidError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [active, setActive] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [activeError, setActiveError] = useState<string>("");

  const brandFilteredByCompany = useMemo<BrandType[]>(() => {
    if (pfid !== "") {
      const filteredBrandCompany = brandcompanyList.filter(
        (a) => a.retailid?.toString() === pfid?.toString()
      );
      const filteredBrandList = brandList.filter(
        (a) =>
          a.companyid?.toString() === filteredBrandCompany[0].id?.toString()
      );
      return filteredBrandList.flat(1);
    } else {
      return [];
    }
  }, [pfid, brandList, brandcompanyList]);

  const filteredList = useMemo<TradeSubCategoryType[]>(() => {
    if (catid !== "") {
      const newList = TradeSubCategoryList.filter(
        (a) => a.catid?.toString() === catid?.toString()
      );
      return newList;
    } else return [];
  }, [catid, TradeSubCategoryList]);

  const resetStates = () => {
    !editData && setpfid("");
    setpfidError("");
    !editData && setBrandid("");
    setBrandidError("");
    setLoading(false);
    setActive(ActiveDropDown.Active);
    setActiveError("");
  };

  const handlePortfolioid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setpfid(e.target.value);
    setpfidError("");
    const company = brandcompanyList.filter(
      (a) => a.retailid?.toString() === e.target.value?.toString()
    );
    if (company[0].id) {
      setCompanyid(company[0].id?.toString());
    }
  };

  const handleBrandid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBrandid(e.target.value);
    setBrandidError("");
  };

  const handleTradeCategory: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCatid(e.target.value);
    setCatidError("");
  };

  const handleTradeSubCategory: ChangeEventHandler<HTMLInputElement> = (e) => {
    setsubcatid(e.target.value);
    setsubcatidError("");
  };

  const handleActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setActive(e.target.value as ActiveTypes);
    setActiveError("");
  };

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const data: BrandCompanyMappingType = {
      pfid: parseInt(pfid),
      brandid: parseInt(brandid),
      companyid: parseInt(Companyid),
      tradecatid: parseInt(catid),
      tradesubcatid: parseInt(subcatid),
      active: active,
    };

    if (isNaN(data.pfid)) {
      setpfidError("Portfolio required");
      error = true;
    }

    if (isNaN(data.brandid)) {
      setBrandidError("Brand required");
      error = true;
    }

    if (data.companyid !== undefined) {
      if (isNaN(data.companyid)) {
        setCompanyid("Brand Company Required");
        error = true;
      }
    }

    if (isNaN(data.tradecatid)) {
      setCatidError("Trade Category Rrequired");
      error = true;
    }

    if (isNaN(data.tradesubcatid)) {
      setsubcatidError("Trade Sub Category Required");
      error = true;
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addBrandCompanyMapping(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.id !== undefined
      ) {
        setLoading(true);
        dispatch(editBrandCompanyMapping(data, editData.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      editData !== undefined &&
      editData?.id !== undefined &&
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW)
    ) {
      setpfid(editData.pfid?.toString());
      setBrandid(editData.brandid?.toString());
      setCatid(editData.tradecatid?.toString());
      setsubcatid(editData.tradesubcatid?.toString());
      setCompanyid(editData.companyid?.toString());
      setActive(
        editData.active === true
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );
    } else {
      resetStates();
    }
  }, [editData, formType]);

  
 

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Portfolio Name"
              label="Portfolio Name"
              labelClassName="required"
              value={pfid}
              onChange={handlePortfolioid}
              placeholder="Portfolio Name"
              errorText={pfidError}
              containerClass="mb-2"
              disabled={showViewForm || (editData && true)}
              type="select"
            >
              <option value="">Select</option>
              {filteredPortfolioList.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Brand"
              label="Brand"
              labelClassName="required"
              value={brandid}
              onChange={handleBrandid}
              placeholder="Brand Name"
              errorText={brandidError}
              containerClass="mb-2"
              disabled={showViewForm || (editData && true)}
              type="select"
            >
              <option value="">Select</option>
              {brandFilteredByCompany.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Trade Category"
              label="Trade Category"
              labelClassName="required"
              value={catid}
              onChange={handleTradeCategory}
              placeholder="Trade Category"
              errorText={catidError}
              containerClass="mb-2"
              disabled={showViewForm || (editData && true)}
              type="select"
            >
              <option value="">Select Trade Category</option>
              {TradeCategoryList.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Trade Sub Category"
              label="Trade Sub Category"
              labelClassName="required"
              value={subcatid}
              onChange={handleTradeSubCategory}
              placeholder="Trade Sub Category"
              errorText={subcatidError}
              containerClass="mb-2"
              disabled={showViewForm || (editData && true)}
              type="select"
            >
              <option value="">Select Trade Sub Category</option>
              {filteredList.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>
          {formType === FormTypeList.EDIT && (
            <div className="col-sm-6 col-md-4 col-lg-3">
              <FormInput
                name="Status"
                label="Status"
                labelClassName="required"
                value={active}
                onChange={handleActive}
                placeholder="Status"
                errorText={activeError}
                containerClass="mb-2"
                disabled={showViewForm}
                type="select"
              >
                <option value="">Select</option>
                {ACTIVE_DROPS.map((a, i) => {
                  return (
                    <Option value={a.value?.toString()} key={i}>
                      {a.text}
                    </Option>
                  );
                })}
              </FormInput>
            </div>
          )}
        </div>

        {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
          <div className="d-flex justify-content-end hstack gap-1 mt-2">
            <button className="btn btn-secondary" onClick={handleClear}>
              Clear
            </button>
            {!loading ? (
              <button className="btn btn-secondary" onClick={handleSave}>
                Save
              </button>
            ) : (
              <button className="btn btn-secondary" disabled>
                <i className="fa fa-spinner fa-spin"></i> Saving...
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BrandCompanyMappingForm;
