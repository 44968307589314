import { configureStore } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { Action } from './actions/types'
import reducers from './reducers'

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['authUser','selectedOption','authAdmin']
}

const persistedReducer = persistReducer(persistConfig, reducers)

const thunkMiddleware: ThunkMiddleware<StoreState, Action> = thunk

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
        const middlewares = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })

        middlewares.push(thunkMiddleware)

        return middlewares
    },
})

export type StoreState = ReturnType<typeof reducers>
export type AppDispatch = typeof store.dispatch
export type GetState = () => StoreState

export const persistor = persistStore(store)
