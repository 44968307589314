import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import {
  ActiveDropDown,
  ActiveTypes,
  TenantCategoryType,
  addTenantCategory,
  editTenantCategory,
} from "../../redux/actions/tenantActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { FormInput } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

interface Props {
  formType: FormType;
  editData?: TenantCategoryType;
  onSave: () => void;
  showViewForm: boolean;
}

const TenantCategoryForm = ({
  formType,
  onSave,
  editData,
  showViewForm,
}: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [active, setActive] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [activeError, setActiveError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const resetStates = () => {
    setName("");
    setNameError("");
    setActive(ActiveDropDown.Active);
    setActiveError("");
    setLoading(false);
  };

  const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
    setNameError("");
  };
  const handleActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setActive(e.target.value as ActiveTypes);
    setActiveError("");
  };

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const data: TenantCategoryType = {
      name: name,
      active: active,
    };

    if (data.name === "") {
      setNameError("Tenant Category Name required");
      error = true;
    } else if (data.name?.length > 60) {
      setNameError("Tenant Category Name should be within 60 letters");
      error = true;
    }

    if (data.active?.toString() === "") {
      setActiveError("Active required");
      error = true;
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addTenantCategory(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.id !== undefined
      ) {
        setLoading(true);
        dispatch(editTenantCategory(data, editData.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      setName(editData.name);
      setActive(
        editData.active ? ActiveDropDown.Active : ActiveDropDown.InActive
      );
    } else {
      resetStates();
    }
  }, [formType, editData]);

 

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Tenant Category Name"
            label="Tenant Category Name"
            labelClassName="required"
            value={name}
            onChange={handleName}
            placeholder="Tenant Category Name"
            errorText={nameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Status"
            label="Status"
            labelClassName="required"
            value={active}
            onChange={handleActive}
            placeholder="Status"
            errorText={activeError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {ACTIVE_DROPS.map((a, i) => {
              return (
                <Option value={a.value?.toString()} key={i}>
                  {a.text}
                </Option>
              );
            })}
          </FormInput>
        </div>
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default TenantCategoryForm;
