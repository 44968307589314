import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { Dispatch } from "redux";
import { GetState } from "../store";
import { CompanyContactType } from "./companyContactActions";

export enum ActiveDropDown {
  Active = "true",
  InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive;

export interface CompanyType {
  id?: number;
  name: string;
  url: string;
  logopath: string;
  logourl: string;
  reportingcurrency: string;
  currencysymbol: string;
  valuecaption: string;
  active: ActiveTypes | boolean;
  createdBy?: number | null;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;

  //company contacts
  companyContacts?: CompanyContactType[];
  type: string;
  fname: string;
  lname: string;
  designation: string;
  mailid: string;
  mobile: string;
  pincode: string;
  companyid?: string;
  contactActive: ActiveTypes | boolean;
}

export enum CompanyActionsList {
  ADD_COMPANY = "ADD_COMPANY",
  EDIT_COMPANY = "EDIT_COMPANY",
  FETCH_COMPANY = "FETCH_COMPANY",
  DELETE_COMPANY = "DELETE_COMPANY",
  CLEAR_COMPANY = "CLEAR_COMPANY",
}

export interface DeleteCompany {
  id: number;
}

export interface AddCompanyAction {
  type: CompanyActionsList.ADD_COMPANY;
  data: CompanyType;
}

export interface EditCompanyAction {
  type: CompanyActionsList.EDIT_COMPANY;
  data: CompanyType;
  id?: number;
}

export interface FetchCompanyAction {
  type: CompanyActionsList.FETCH_COMPANY;
  data: CompanyType[];
}

export interface DeleteCompanyAction {
  type: CompanyActionsList.DELETE_COMPANY;
  data: DeleteCompany;
}

export interface ClearCompanyAction {
  type: CompanyActionsList.CLEAR_COMPANY;
}

export type CompanyActions =
  | AddCompanyAction
  | EditCompanyAction
  | FetchCompanyAction
  | DeleteCompanyAction
  | ClearCompanyAction;
export const clearCompany = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    return dispatch<ClearCompanyAction>({
      type: CompanyActionsList.CLEAR_COMPANY,
    });
  };
};
export const addCompany = (data: CompanyType) => {
  return async (dispatch: Dispatch, getState: GetState) => {

    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);
    return api
      .post<APIResponse<CompanyType>>("holdingCompany/", data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<AddCompanyAction>({
            type: CompanyActionsList.ADD_COMPANY,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Company added"
          );
        } else {
          // throw { response };
          throw new Error("Unable to create")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to create"
            : "Unable to create"
        );
      });
  };
};

export const editCompany = (data: CompanyType, id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .put<APIResponse<CompanyType>>(`holdingCompany/${id}`, data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<EditCompanyAction>({
            type: CompanyActionsList.EDIT_COMPANY,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Company Updated"
          );
        } else {
          throw new Error("Unable to update")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to update"
            : "Unable to update"
        );
      });
  };
};

export const fetchCompany = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<CompanyType[]>>("holdingCompany/", config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchCompanyAction>({
            type: CompanyActionsList.FETCH_COMPANY,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const deleteCompany = (id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .delete<APIResponse<CompanyType>>(`holdingCompany/${id}`, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch<DeleteCompanyAction>({
            type: CompanyActionsList.DELETE_COMPANY,
            data: { id },
          });
          return Promise.resolve(
            response.data.message ? response.data.message : " Company Deleted"
          );
        } else {
          throw new Error("Unable to delete")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to delete"
            : "Unable to delete"
        );
      });
  };
};
