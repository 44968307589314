import { nanoid } from "@reduxjs/toolkit";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import {
  AddRoleType,
  RoleType,
  addRole,
  editRole,
  fetchRole,
} from "../../redux/actions/roleActions";
import { AppDispatch, StoreState } from "../../redux/store";
import { FormInput } from "../custom/components";
import { getAdminMenuItems, getUserMenuItems } from "../custom/helpers/menu";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { AdminLog } from "../../config/common";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { RolesType } from "../../redux/actions/userRoleActions";
import Option from "../Option";

interface Props {
  formType: FormType;
  editData?: RoleType;
  onSave: () => void;
  showViewForm: boolean;
}

interface Child {
  pageTitle: string;
  pageUrl: string;
  add: boolean;
  view: boolean;
  edit: boolean;
  delete: boolean;
  parentLabel?: string;
}

interface ViewFormData {
  label?: string;
  // role?: string
  child: Child[];
  key: string;
}

type HandleAdd = (value: Child) => void;
type HandleEdit = (value: Child) => void;
type HandleView = (value: Child) => void;
type HandleDelete = (value: Child) => void;

const RoleForm = ({ formType, onSave, editData, showViewForm }: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const menuItems = getUserMenuItems();
  const adminMenuItems = getAdminMenuItems();

  const rolesList = useSelector<StoreState, Array<RolesType>>(
    (state) => state.roles
  );

  const roleList = useSelector<StoreState, Array<RoleType>>(
    (state) => state.role
  );

  const [role, setRole] = useState<string>("");
  const [roleError, setRoleError] = useState<string>("");
  const [roletype, setRoletype] = useState<string>("");
  const [roletypeError, setRoletypeError] = useState<string>("");
  const [pageTitle, setPageTitle] = useState<string>("");
  const [pageTitleError, setPageTitleError] = useState<string>("");
  const [pageUrl, setPageUrl] = useState<string>("");
  const [pageUrlError, setPageUrlError] = useState<string>("");
  const [add, setAdd] = useState<boolean>(false);
  const [addError, setAddError] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [editError, setEditError] = useState<string>("");
  const [view, setView] = useState<boolean>(false);
  const [viewError, setViewError] = useState<string>("");
  const [deleteAccess, setDelete] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ViewFormData[]>([]);

  const resetStates = () => {
    setRole("");
    setRoleError("");
    setPageTitle("");
    setPageTitleError("");
    setPageUrl("");
    setPageUrlError("");
    setData([]);
    setLoading(false);
  };
  const handleRole: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRole(e.target.value);
    setRoleError("");
  };
  const handleRoles: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRoletype(e.target.value);
    setRoletypeError("");
  };

  const handlepageUrl: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPageUrl(e.target.value);
    setPageUrlError("");
  };

  const handlepageTitle: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPageTitle(e.target.value);
    setPageTitleError("");
  };

  const handleAdd: HandleAdd = (value) => {
    data.forEach((d) => {
      const parentIndex = data.findIndex((d) => d.label === value.parentLabel);

      if (parentIndex > -1) {
        // value.child.forEach((ev) => {
        const childIndex = d.child.findIndex(
          (ci) =>
            ci.pageTitle === value.pageTitle && ci.pageUrl === value.pageUrl
        );
        if (childIndex > -1) {

          const updated = [...data].slice();
          updated[parentIndex].child[childIndex] = {
            parentLabel: value.parentLabel,
            pageTitle: value.pageTitle,
            pageUrl: value.pageUrl,
            add: !value.add,
            view: value.view,
            edit: value.edit,
            delete: value.delete,
          };
          setData(updated);
          setAddError("");
        }

        // })
      }
    });
  };

  const handleEdit: HandleEdit = (value) => {
    data.forEach((d) => {
      const parentIndex = data.findIndex((d) => d.label === value.parentLabel);

      if (parentIndex > -1) {
        // value.child.forEach((ev) => {
        const childIndex = d.child.findIndex(
          (ci) =>
            ci.pageTitle === value.pageTitle && ci.pageUrl === value.pageUrl
        );
        if (childIndex > -1) {

          const updated = [...data].slice();
          updated[parentIndex].child[childIndex] = {
            parentLabel: value.parentLabel,
            pageTitle: value.pageTitle,
            pageUrl: value.pageUrl,
            add: value.add,
            view: value.view,
            edit: !value.edit,
            delete: value.delete,
          };
          setData(updated);
          setAddError("");
        }

        // })
      }
    });
  };

  const handleView: HandleView = (value) => {
    data.forEach((d) => {
      const parentIndex = data.findIndex((d) => d.label === value.parentLabel);

      if (parentIndex > -1) {
        // value.child.forEach((ev) => {
        const childIndex = d.child.findIndex(
          (ci) =>
            ci.pageTitle === value.pageTitle && ci.pageUrl === value.pageUrl
        );
        if (childIndex > -1) {

          const updated = [...data].slice();
          updated[parentIndex].child[childIndex] = {
            parentLabel: value.parentLabel,
            pageTitle: value.pageTitle,
            pageUrl: value.pageUrl,
            // add: value.add,
            // view: !(value.view),
            // edit: value.edit,
            // delete: value.delete
            add: add,
            view: !value.view,
            edit: edit,
            delete: deleteAccess,
          };
          setData(updated);
          setAddError("");
        }

        // })
      }
    });
  };

  const handleDelete: HandleDelete = (value) => {
    data.forEach((d) => {
      const parentIndex = data.findIndex((d) => d.label === value.parentLabel);

      if (parentIndex > -1) {
        // value.child.forEach((ev) => {
        const childIndex = d.child.findIndex(
          (ci) =>
            ci.pageTitle === value.pageTitle && ci.pageUrl === value.pageUrl
        );
        if (childIndex > -1) {

          const updated = [...data].slice();
          updated[parentIndex].child[childIndex] = {
            parentLabel: value.parentLabel,
            pageTitle: value.pageTitle,
            pageUrl: value.pageUrl,
            add: value.add,
            view: value.view,
            edit: value.edit,
            delete: !value.delete,
          };
          setData(updated);
          setAddError("");
        }

        // })
      }
    });
  };

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;

    // const data2: RoleType = data.map(dt => {
    //     return dt.child.map(c => {
    //         return {
    //             role: role,
    //             pageurl: c.pageUrl,
    //             pagetitle: c.pageTitle,
    //             add: c.add,
    //             edit: c.edit,
    //             view: c.view,
    //             delete: c.delete
    //         }
    //     })
    // }).flat(1)

    const data2: AddRoleType = {
      role: role,
      roleId: roletype,
      permission: data
        .map((dt) => {
          return dt.child.map((c) => {
            if (c.pageUrl === "") {
              setPageUrlError("Page Url required");
              error = true;
            } else if (c.pageUrl?.length > 50) {
              setPageUrlError("Page Url should be within 50 digits");
              error = true;
            }

            if (c.pageTitle === "") {
              setPageTitleError("Page title required");
              error = true;
            } else if (c.pageTitle?.length > 50) {
              setPageTitleError("Page title should be within 50 digits");
              error = true;
            }
            return {
              pageurl: c.pageUrl,
              pagetitle: c.pageTitle,
              add: c.add,
              edit: c.edit,
              view: c.view,
              delete: c.delete,
            };
          });
        })
        .flat(1),
    };

    if (data2.role === "") {
      setRoleError("Display Name required");
      error = true;
    } else if (data2.role?.length > 30) {
      setRoleError("Display Name should be within 30 characters");
      error = true;
    }
    if (data2.role === "") {
      setRoletypeError("Role required");
      error = true;
    } 

    if (data2.permission?.length <= 0) {
      toast("Invalid Data", ToastTypes.ERROR);
      error = true;
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addRole(data2))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
            // dispatch(fetchRole())
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.role !== undefined
      ) {
        setLoading(true);
        dispatch(editRole(data2, editData.role))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            onSave();
            dispatch(fetchRole());
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  // useEffect(() => {
  //     if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== undefined) {
  //         setRole(editData.name)
  //         setPageUrl(editData.pageUrl)
  //         setPageTitle(editData.pageTitle)
  //         setEdit(editData.companyid?.toString())
  //         setView(editData.url)
  //         setAdd(editData.active === true ? RoleActiveDropDown.Active : RoleActiveDropDown.InActive)
  //     } else {
  //         resetStates()
  //     }
  // }, [formType, editData])

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined &&
      editData.role !== undefined
    ) {
      setRole(editData.role);
      setRoletype(editData.roleId);

      const edata = roleList.filter(
        (r) => r.role?.toString() === editData.role?.toString()
      );
      let ed: ViewFormData[] = [];
      //User
      menuItems.forEach((m) => {
        var plabel = m.label;
        var childArray: Child[] = [];

        if (m.children && m.children?.length > 0) {
          edata.forEach((ed) => {
            m.children
              ?.filter((f) => f.label === ed.pagetitle && f.url === ed.pageurl)
              .forEach((c) => {
                var pTitle = c?.label;
                var pUrl = c?.url;

                childArray.push({
                  parentLabel: plabel,
                  pageTitle: pTitle,
                  pageUrl: pUrl!,
                  add: ed.add,
                  view: ed.view,
                  edit: ed.edit,
                  delete: ed.delete,
                });
              });
          });
        }

        m.children?.forEach((mc) => {
          var pTitle = mc?.label;
          var pUrl = mc?.url;
          // const newPathExists = childArray.forEach(c => {
          //     if (c.pageTitle === pTitle && c.pageUrl === pUrl) return false
          //     else if (c.pageTitle !== pTitle && c.pageUrl !== pUrl) return true
          // })
          const index = childArray.findIndex(
            (c) => c.pageTitle === pTitle && c.pageUrl === pUrl
          );

          if (index < 0) {
            childArray.push({
              parentLabel: plabel,
              pageTitle: pTitle,
              pageUrl: pUrl!,
              add: add,
              view: view,
              edit: edit,
              delete: deleteAccess,
            });
          }
        });

        ed.push({
          label: plabel,
          key: "user",
          child: m.children && m.children?.length > 0 ? childArray : [],
        });
      });

      //Admin
      adminMenuItems.forEach((m) => {
        var plabel = m.label;
        var childArray: Child[] = [];

        if (m.children && m.children?.length > 0) {
          edata.forEach((ed) => {
            m.children
              ?.filter((f) => f.label === ed.pagetitle && f.url === ed.pageurl)
              .forEach((c) => {
                var pTitle = c?.label;
                var pUrl = c?.url;

                childArray.push({
                  parentLabel: plabel,
                  pageTitle: pTitle,
                  pageUrl: pUrl!,
                  add: ed.add,
                  view: ed.view,
                  edit: ed.edit,
                  delete: ed.delete,
                });
              });
          });
        } else {
          edata
            .filter((f) => f.pagetitle === m.label && f.pageurl === m.url)
            .forEach((ed) => {
              childArray.push({
                parentLabel: m.label,
                pageTitle: m.label,
                pageUrl: m.url!,
                add: ed.add,
                view: ed.view,
                edit: ed.edit,
                delete: ed.delete,
              });
            });
        }

        if (m.children && m.children?.length > 0) {
          m.children?.forEach((mc) => {
            var pTitle = mc?.label;
            var pUrl = mc?.url;
            // const newPathExists = childArray.forEach(c => {
            //     if (c.pageTitle === pTitle && c.pageUrl === pUrl) return false
            //     else if (c.pageTitle !== pTitle && c.pageUrl !== pUrl) return true
            // })
            const index = childArray.findIndex(
              (c) => c.pageTitle === pTitle && c.pageUrl === pUrl
            );

            if (index < 0) {
              childArray.push({
                parentLabel: plabel,
                pageTitle: pTitle,
                pageUrl: pUrl!,
                add: add,
                view: view,
                edit: edit,
                delete: deleteAccess,
              });
            }
          });
        } else {
          const index = childArray.findIndex(
            (c) => c.pageTitle === m.label && c.pageUrl === m.url
          );

          if (index < 0) {
            childArray.push({
              parentLabel: plabel,
              pageTitle: m.label,
              pageUrl: m.url!,
              add: add,
              view: view,
              edit: edit,
              delete: deleteAccess,
            });
          }
        }

        ed.push({
          label: plabel,
          key: "admin",
          child: m.children && m.children?.length > 0 ? childArray : childArray,
        });
      });

      setData(ed);
    }
  }, [formType, editData, roleList, menuItems, adminMenuItems]);

  useEffect(() => {
    if (formType === FormTypeList.ADD) {
      let d: ViewFormData[] = [];
      menuItems.forEach((m) => {
        var plabel = m.label;
        d.push({
          label: plabel,
          key: "user",
          child:
            m.children && m.children?.length > 0
              ? m.children.map((c) => {
                  var pTitle = c?.label;
                  var pUrl = c?.url;

                  return {
                    parentLabel: plabel,
                    pageTitle: pTitle,
                    pageUrl: pUrl!,
                    add: add,
                    view: view,
                    edit: edit,
                    delete: deleteAccess,
                  };
                })
              : [],
        });
      });

      adminMenuItems.forEach((m) => {
        if (m.children && m.children?.length > 0) {
          var plabel = m.label;
          d.push({
            label: plabel,
            key: "admin",
            child:
              m.children && m.children?.length > 0
                ? m.children.map((c) => {
                    var pTitle = c?.label;
                    var pUrl = c?.url;

                    return {
                      parentLabel: plabel,
                      pageTitle: pTitle,
                      pageUrl: pUrl!,
                      add: add,
                      view: view,
                      edit: edit,
                      delete: deleteAccess,
                    };
                  })
                : [],
          });
        } else {
          d.push({
            label: m.label,
            key: "admin",
            child: [
              {
                parentLabel: m.label,
                pageTitle: m.label,
                pageUrl: m.url!,
                add: add,
                view: view,
                edit: edit,
                delete: deleteAccess,
              },
            ],
          });
        }
      });

      setData(d);
    }
  }, [formType, menuItems, adminMenuItems]);


  
 

  return (
    <div className="container">
      <div className="row">
        {(formType === FormTypeList.ADD || formType === FormTypeList.VIEW) && (
          <><div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Role"
              label="Display Name"
              labelClassName="required"
              value={role}
              onChange={handleRole}
              placeholder="Display Name"
              errorText={roleError}
              containerClass="mb-2"
              disabled={showViewForm}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
            name="RoleId"
            label="Role"
            labelClassName="required"
            value={roletype}
            onChange={handleRoles}
            placeholder="Role"
            errorText={roletypeError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {rolesList.map((row, i) => (
              <Option value={row.id} key={i+`${row.id}`}>
                {row.role}
              </Option>
            ))}
          </FormInput>
          </div></>
        )}
        {formType === FormTypeList.EDIT && (
          <><div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Role"
              label="Display Name"
              labelClassName="required"
              value={role}
              onChange={handleRole}
              placeholder="Display Name"
              errorText={roleError}
              containerClass="mb-2"
              disabled
            />
          </div><div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
            name="RoleId"
            label="Role"
            labelClassName="required"
            value={roletype}
            onChange={handleRoles}
            placeholder="Role"
            errorText={roletypeError}
            containerClass="mb-2"
            disabled
            type="select"
          >
            <option value="">Select</option>
            {rolesList.map((row, i) => (
              <Option value={row.id} key={i+`${row.id}`}>
                {row.role}
              </Option>
            ))}
          </FormInput>
          </div></>
        )}
        <div>
          {data
            .filter((d) => d.key === "user")
            .map((m, i) => {
              return (
                <div key={nanoid()}>
                  <h5 className="text-decoration-underline text-primary">
                    {m.label}
                  </h5>
                  {m.child && m.child?.length > 0 && (
                    <>
                      {m.child.map((c, j) => {
                        return (
                          <div className="hstack" key={nanoid()}>
                            {/* <div className='col-sm-6 col-md-3 col-lg-3 ms-1'>
                                    <h5>{c.pageTitle}</h5>
                                </div> */}
                            {/* <div className='col-sm-6 col-md-2 col-lg-2'>
                                    <FormInput
                                        name='ADD'
                                        label='ADD'
                                        labelClassName="required"
                                        value={c.add ? 'true' : 'false'}
                                        onChange={() => handleAdd(c)}
                                        placeholder='ADD'
                                        errorText={addError}
                                        containerClass=""
                                        type="checkbox"
                                        checked={c.add}
                                        disabled={showViewForm}
                                    />
                                </div>
                                <div className='col-sm-6 col-md-2 col-lg-2'>
                                    <FormInput
                                        name='EDIT'
                                        label='EDIT'
                                        labelClassName="required"
                                        value={c.edit ? 'true' : 'false'}
                                        onChange={() => handleEdit(c)}
                                        placeholder='EDIT'
                                        errorText={editError}
                                        containerClass=""
                                        type="checkbox"
                                        checked={c.edit}
                                        disabled={showViewForm}
                                    />
                                </div> */}
                            <div className="col-sm-6 col-md-4 col-lg-4 ms-2">
                              <FormInput
                                name={"APP_" + i + "_" + j}
                                label={c.pageTitle}
                                labelClassName="required"
                                value={c.view ? "true" : "false"}
                                onChange={() => handleView(c)}
                                placeholder="VIEW"
                                errorText={viewError}
                                containerClass=""
                                type="checkbox"
                                checked={c.view}
                                disabled={showViewForm}
                              />
                            </div>
                            {/* <div className='col-sm-6 col-md-2 col-lg-2'>
                                    <FormInput
                                        name='DELETE'
                                        label='DELETE'
                                        labelClassName="required"
                                        value={c.delete ? 'true' : 'false'}
                                        onChange={() => handleDelete(c)}
                                        placeholder='DELETE'
                                        errorText={deleteError}
                                        containerClass=""
                                        type="checkbox"
                                        checked={c.delete}
                                        disabled={showViewForm}
                                    />
                                </div> */}

                            <div className="col-sm-6 col-md-2 col-lg-2 d-none">
                              <FormInput
                                name="Page URL"
                                label="Page URL"
                                labelClassName="required"
                                value={c.pageUrl}
                                onChange={handlepageUrl}
                                placeholder="Page URL"
                                errorText={pageUrlError}
                                containerClass="mb-2"
                                disabled={showViewForm}
                              />
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-2 d-none">
                              <FormInput
                                name="Page Title"
                                label="Page Title"
                                labelClassName="required"
                                value={c.pageTitle}
                                onChange={handlepageTitle}
                                placeholder="Page Title"
                                errorText={pageTitleError}
                                containerClass="mb-2"
                                disabled={showViewForm}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              );
            })}
        </div>

        <div>
          <h4 className="mt-4 mb-0">
            <span className="badge bg-dark">ADMIN</span>
          </h4>
          {data
            .filter((d) => d.key === "admin")
            .map((m, i) => {
              return (
                <div key={nanoid()}>
                  <h5 className="text-decoration-underline text-primary">
                    {m.label}
                  </h5>
                  {m.child && m.child?.length > 0 && (
                    <>
                      {m.child.map((c, j) => {
                        return (
                          <div className="hstack" key={nanoid()}>
                            <div className="col-sm-6 col-md-3 col-lg-3 ms-1">
                              <h5>{c.pageTitle}</h5>
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-2 ms-2">
                              <FormInput
                                name={"VIEW_" + i + "_" + j}
                                label="VIEW"
                                labelClassName="required"
                                value={c.view ? "true" : "false"}
                                onChange={() => handleView(c)}
                                placeholder="VIEW"
                                errorText={viewError}
                                containerClass=""
                                type="checkbox"
                                checked={c.view}
                                disabled={showViewForm}
                              />
                            </div>
                            {c.view && (
                              <>
                                <div className="col-sm-6 col-md-2 col-lg-2">
                                  <FormInput
                                    name={"ADD_" + i + "_" + j}
                                    label="ADD"
                                    labelClassName="required"
                                    value={c.add ? "true" : "false"}
                                    onChange={() => handleAdd(c)}
                                    placeholder="ADD"
                                    errorText={addError}
                                    containerClass=""
                                    type="checkbox"
                                    checked={c.add}
                                    disabled={showViewForm}
                                  />
                                </div>
                                <div className="col-sm-6 col-md-2 col-lg-2">
                                  <FormInput
                                    name={"EDIT_" + i + "_" + j}
                                    label="EDIT"
                                    labelClassName="required"
                                    value={c.edit ? "true" : "false"}
                                    onChange={() => handleEdit(c)}
                                    placeholder="EDIT"
                                    errorText={editError}
                                    containerClass=""
                                    type="checkbox"
                                    checked={c.edit}
                                    disabled={showViewForm}
                                  />
                                </div>

                                <div className="col-sm-6 col-md-2 col-lg-2">
                                  <FormInput
                                    name={"DELETE_" + i + "_" + j}
                                    label="DELETE"
                                    labelClassName="required"
                                    value={c.delete ? "true" : "false"}
                                    onChange={() => handleDelete(c)}
                                    placeholder="DELETE"
                                    errorText={deleteError}
                                    containerClass=""
                                    type="checkbox"
                                    checked={c.delete}
                                    disabled={showViewForm}
                                  />
                                </div>
                              </>
                            )}

                            <div className="col-sm-6 col-md-2 col-lg-2 d-none">
                              <FormInput
                                name="Page URL"
                                label="Page URL"
                                labelClassName="required"
                                value={c.pageUrl}
                                onChange={handlepageUrl}
                                placeholder="Page URL"
                                errorText={pageUrlError}
                                containerClass="mb-2"
                                disabled={showViewForm}
                              />
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-2 d-none">
                              <FormInput
                                name="Page Title"
                                label="Page Title"
                                labelClassName="required"
                                value={c.pageTitle}
                                onChange={handlepageTitle}
                                placeholder="Page Title"
                                errorText={pageTitleError}
                                containerClass="mb-2"
                                disabled={showViewForm}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              );
            })}
        </div>
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-primary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default RoleForm;
