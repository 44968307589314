import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface FloorType {
    id?: number
    portfolioid: string
    centerid: number
    centertype: string
    buildingid: number
    floorname: string
    floorcode: string
    active: ActiveTypes | boolean
    createdby?: string
    createdat?: string
    updatedat?: string
}

export enum FloorActionsList {
    ADD_FLOOR = 'ADD_FLOOR',
    EDIT_FLOOR = 'EDIT_FLOOR',
    FETCH_FLOOR = 'FETCH_FLOOR',
    DELETE_FLOOR = 'DELETE_FLOOR'
}

export interface DeleteFloor {
    id: number
}

export interface AddFloorAction {
    type: FloorActionsList.ADD_FLOOR
    data: FloorType
}

export interface EditFloorAction {
    type: FloorActionsList.EDIT_FLOOR
    data: FloorType
    id?: number
}

export interface FetchFloorAction {
    type: FloorActionsList.FETCH_FLOOR
    data: FloorType[]

}

export interface DeleteFloorAction {
    type: FloorActionsList.DELETE_FLOOR
    data: DeleteFloor
}

export type FloorActions = AddFloorAction | EditFloorAction | FetchFloorAction | DeleteFloorAction

export const addFloor = (data: FloorType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<FloorType>>('floor/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddFloorAction>({
                    type: FloorActionsList.ADD_FLOOR,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Floor added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}

export const editFloor = (data: FloorType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<FloorType>>(`floor/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditFloorAction>({
                    type: FloorActionsList.EDIT_FLOOR,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Floor Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchFloor = (portfolioId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<FloorType[]>>(`floor/${portfolioId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchFloorAction>({
                    type: FloorActionsList.FETCH_FLOOR,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteFloor = (id: number, portfolioId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<FloorType>>(`floor/${portfolioId}/${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteFloorAction>({
                    type: FloorActionsList.DELETE_FLOOR,
                    data: { id }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Floor Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}