import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import {
  ActiveDropDown,
  ActiveTypes,
  BuildingType,
  addBuilding,
  editBuilding,
  fetchBuilding,
} from "../../redux/actions/buildingActions";
import { SiteType } from "../../redux/actions/siteActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { FormInput } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

interface Props {
  pfId: string;
  formType: FormType;
  editData?: BuildingType;
  onSave: () => void;
  showViewForm: boolean;
}

const BuildingForm = ({
  formType,
  onSave,
  editData,
  showViewForm,
  pfId,
}: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const siteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  );

  const [portfolioId, setportfolioId] = useState<string>("");
  // const [portfolioIdError, setportfolioIdError] = useState<string>("");
  const [centerid, setCenterid] = useState<string>("");
  const [centeridError, setCenteridError] = useState<string>("");
  const [centertype, setCentertype] = useState<string>("");
  // const [centertypeError, setCentertypeError] = useState<string>("");
  const [buildingname, setBuildingname] = useState<string>("");
  const [buildingnameError, setBuildingnameError] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [codeError, setCodeError] = useState<string>("");
  const [active, setActive] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [activeError, setActiveError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const filteredSites = useMemo<SiteType[]>(() => {
    if (portfolioId) {
      return siteList.filter((s) => s.pfid?.toString() === portfolioId);
    } else return siteList;
  }, [portfolioId, siteList]);

  const resetStates = () => {
    setportfolioId("");
    // setportfolioIdError("");
    setCenterid("");
    setCenteridError("");
    setCentertype("");
    // setCentertypeError("");
    setBuildingname("");
    setBuildingnameError("");
    setCode("");
    setCodeError("");
    setActive(ActiveDropDown.Active);
    setActiveError("");
    setLoading(false);
  };
  // const handlePfid: ChangeEventHandler<HTMLInputElement> = (e) => {
  //     setportfolioId(e.target.value)
  //     setportfolioIdError('')
  // }

  const handleCenterid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCenterid(e.target.value);
    setCenteridError("");
    if (e.target.value) {
      let obj = siteList.find((s) => s.id?.toString() === e.target.value);
      setCentertype(obj?.centertype ? obj?.centertype : "");
    }
  };

  // const handleCentertype: ChangeEventHandler<HTMLInputElement> = (e) => {
  //     setCentertype(e.target.value)
  //     setCentertypeError('')
  // }

  const handleBuildingname: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBuildingname(e.target.value);
    setBuildingnameError("");
  };

  const handleActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setActive(e.target.value as ActiveTypes);
    setActiveError("");
  };

  const handleCode: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCode(e.target.value);
    setCodeError("");
  };
  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const data: BuildingType = {
      portfolioid: portfolioId,
      centerid: parseInt(centerid),
      centertype: centertype,
      buildingname: buildingname,
      code: code,
      active: active,
    };
    if (data.portfolioid === "") {
      toast("portfolio required", ToastTypes.ERROR);
      error = true;
    }

    // if (data.centertype === '') {
    //     setCentertypeError('Centertype required')
    //     error = true
    // } else if ((data.centertype)?.length > 30) {
    //     setCentertypeError('Centertype should be within 30 characters')
    //     error = true
    // }

    if (data.active?.toString() === "") {
      setActiveError("Active required");
      error = true;
    }

    if (isNaN(data.centerid)) {
      setCenteridError("Center required");
      error = true;
    }

    if (data.buildingname === "") {
      setBuildingnameError("Building Name required");
      error = true;
    } else if (data.buildingname?.length > 50) {
      setBuildingnameError("Building Name should be within 50 digits");
      error = true;
    }

    if (data.code === "") {
      setCodeError("code required");
      error = true;
    } else if (data.code?.length > 10) {
      setCodeError("code should be within 10 digits");
      error = true;
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addBuilding(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
            dispatch(fetchBuilding(portfolioId));
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.id !== undefined
      ) {
        setLoading(true);
        dispatch(editBuilding(data, editData.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
            dispatch(fetchBuilding(portfolioId));
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      setCenterid(editData.centerid?.toString());
      setCentertype(editData.centertype);
      setBuildingname(editData.buildingname);
      setCode(editData.code);
      setActive(
        editData.active === true
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );
    } else {
      resetStates();
    }
  }, [formType, editData]);

  useEffect(() => {
    if (pfId) setportfolioId(pfId);
  }, [pfId]);

  
  useEffect(()=>{
    if(adminAuthendicated){
      const logProps1 = {
        action: 'Create',
        event: `${pageName.pathname.replace("\\", " ")} page | Create Building Clicked`
      };
      AdminLog(logProps1)
    }
  },[adminAuthendicated])

  return (
    <div className="container">
      <div className="row">
        {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Portfolio'
                    label='Portfolio'
                    labelClassName="required"
                    value={portfolioId}
                    onChange={handlePfid}
                    placeholder='Portfolio'
                    errorText={portfolioIdError}
                    containerClass="mb-2"
                    disabled={showViewForm}
                    type="select"
                >
                    <option value="">Select</option>
                    {portfolioList.map((a, i) => {
                        return <Option value={a.id} key={i}>{a.name}</Option>
                    })}
                </FormInput>
            </div> */}
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Center Name"
            label="Center Name"
            labelClassName="required"
            value={centerid}
            onChange={handleCenterid}
            placeholder="Center Name"
            errorText={centeridError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {filteredSites.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.name}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Building Name"
            label="Building Name"
            labelClassName="required"
            value={buildingname}
            onChange={handleBuildingname}
            placeholder="Building Name"
            errorText={buildingnameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Center Type'
                    label='Center Type'
                    labelClassName="required"
                    value={centertype}
                    onChange={handleCentertype}
                    placeholder='Center Type'
                    errorText={centertypeError}
                    containerClass="mb-2"
                    disabled={showViewForm}
                />
            </div> */}
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Code"
            label="Code"
            labelClassName="required"
            value={code}
            onChange={handleCode}
            placeholder="Code"
            errorText={codeError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        {formType !== FormTypeList.ADD && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Status"
              label="Status"
              labelClassName="required"
              value={active}
              onChange={handleActive}
              placeholder="Status"
              errorText={activeError}
              containerClass="mb-2"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {ACTIVE_DROPS.map((a, i) => {
                return (
                  <Option value={a.value?.toString()} key={i}>
                    {a.text}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        )}
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default BuildingForm;
