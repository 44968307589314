import React, { ChangeEventHandler, useEffect, useState } from "react"
import { TenantContactDetails, TenantManagerContext, TenantManagerContextType } from "../context/TenantManagerContext"
import { FormInput } from "../custom/components"
import { testEmail, testNumber } from "../../config/functions"
import AdminTable2 from "../custom/components/AdminTable2"
import { Tbody, Thead } from "../custom/components/AdminTable"
import { nanoid } from "@reduxjs/toolkit"
import { FormType, FormTypeList } from "../../config"
import Modal from "../Modal"
import DeleteConfirmationForm from "./DeleteConfirmationForm"
import AdminGrid from "../custom/components/AdminGrid"

type HandleDelete = (value: TenantContactDetails) => void;

const ContactForm = () => {
    const { tenantContactDetails, setTenantContactDetails
    } = React.useContext<TenantManagerContextType | null>(TenantManagerContext)!

    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    // const [lastName, setLastName] = useState<string>('')
    // const [lastNameError, setLastNameError] = useState<string>('')
    const [designation, setDesignation] = useState<string>('')
    const [designationError, setDesignationError] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<string>('')
    const [contactNo, setContactNo] = useState<string>('')
    const [contactNoError, setContactNoError] = useState<string>('')

      const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

    const [deleteData, setDeleteData] = useState<TenantContactDetails>();
    const [editData, setEditData] = useState<TenantContactDetails>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
   const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);

    const [id, setId] = useState<number>();

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }
    // const handleLastName: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setLastName(e.target.value)
    //     setLastNameError('')
    // }
    const handleDesignation: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDesignation(e.target.value)
        setDesignationError('')
    }
    const handleEmail: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }
    const handleContactNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        if((!testNumber(e.target.value)&&e.target.value))
        return
        else if(e.target.value?.length>=12)
        return
    
        setContactNo(e.target.value)
        setContactNoError('')
    }

    const handleRemoveItem = (value: TenantContactDetails) => {
        setTenantContactDetails!(prev => {
            return prev.filter(p => p.tenantName !== value.tenantName)
        })
        // setToListError('')
    }

    const handleSave = () => {
        let error = false
        const data: TenantContactDetails = {
            tenantName: name,
            tenantDesignation: designation,
            tenantEmail: email,
            tenantContactNo: contactNo
        }

        if (data.tenantName === '') {
            setNameError('Name required')
            error = true
        } else if ((data.tenantName)?.length > 50) {
            setNameError('Name should be within 50 digits')
            error = true
        }

        if (data.tenantDesignation === '') {
            setDesignationError('Designation required')
            error = true
        } else if ((data.tenantDesignation)?.length > 50) {
            setDesignationError('Designation should be within 50 digits')
            error = true
        }

        if (data.tenantEmail === '') {
            setEmailError('Email required')
            error = true
        } else if ((data.tenantEmail)?.length > 35) {
            setEmailError('Email should be within 30 characters')
            error = true
        }
        else if(data.tenantEmail&&!testEmail(data.tenantEmail)) {
            setEmailError('Email-id is not valid')
            error = true
        }

        if (data.tenantContactNo === '') {
            setContactNoError('Contact No required')
            error = true
        } else if ((data.tenantContactNo)?.length > 15) {
            setContactNoError('Contact No should be within 15 characters')
            error = true
        }else if (data.tenantContactNo&&!testNumber(data.tenantContactNo)) {
            setContactNoError('Phone Number must be a number')
            error = true
        }

        let namesArray = tenantContactDetails?.map(t => t.tenantName)
        if (!error) {
            // const handleToList = (value: string) => {
            if (!namesArray?.includes(data.tenantName)) {
                if (data) {
                    setTenantContactDetails!(prev => prev ? [...prev, data] : [data])
                    setEmail('')
                    setEmailError('')
                    setName('')
                    setNameError('')
                    setDesignation('')
                    setDesignationError('')
                    setContactNo('')
                    setContactNoError('')
                }
            } else setNameError('Name already exists')
            // setToListError('')
            // }
        }
    }

      const getTableData = () => {
    if (tenantContactDetails?.length===0||tenantContactDetails===undefined) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Name", children: "Name", text: "Name" },
      { id: "Designation", children: "Designation", text: "Designation" },
      { id: "Email", children: "Email", text: "Email" },
      { id: "Contact No", children: "Contact No", text: "Contact No" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];

    tenantContactDetails.forEach((item, i) => {
      var tBody: Tbody[] = [];
      const pageNumber = Math.floor(offset / limit) + 1;

      

      tBody.push(
        {
          id: nanoid(),
          theadId: "S.No",
          children: limit * pageNumber + i - limit + 1,
          text: (limit * pageNumber + i - limit + 1)?.toString(),
        },
        {
          id: nanoid(),
          theadId: "Name",
          children: item.tenantName,
          text: item.tenantName,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Designation",
          children: item.tenantDesignation,
          text: item.tenantDesignation,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Email",
          children: item.tenantEmail,
          text: item.tenantEmail,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Contact No",
          children: item.tenantContactNo,
          text: item.tenantContactNo,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Action",
          children: "action",
          className: "d-none",
          text: i?.toString(),
        }
      );
     
      tableBody.push(tBody);
      // })
    });

    return { tbody: tableBody, thead: tableHead };

    // if (searchTerm?.length > 0) {
    //   tableBodyFiltered = tableBody.filter((item) =>
    //     searchTenantCatHandler(item, searchTerm)
    //   );
    // } else {
    //   tableBodyFiltered = tableBody;
    // }

    // if (tenCatData !== undefined) {
    //   return { tbody: tableBodyFiltered, thead: tableHead };
    // }
  };

   const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);
  
    if (id !== undefined&&tenantContactDetails!==undefined&&tenantContactDetails?.length>0) {
      var obj = tenantContactDetails.find((a,i)=>i?.toString()===id?.toString())
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (id !== undefined&&deleteData!==undefined) {
        setTenantContactDetails!(prev => {
            return prev.filter((p,i) => i?.toString() !== id?.toString())
        })
        handleFormClose();
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    // setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  const handleEdit = () =>{
    setFormType(FormTypeList.EDIT);
    if(id!==undefined&&tenantContactDetails!==undefined&&tenantContactDetails?.length>0){
      var obj = tenantContactDetails.find((a,i)=>i?.toString()===id?.toString())
      setName(obj?.tenantName?obj?.tenantName:'')
      setDesignation(obj?.tenantDesignation?obj?.tenantDesignation:'')
      setEmail(obj?.tenantEmail?obj?.tenantEmail:'')
      setContactNo(obj?.tenantContactNo?obj?.tenantContactNo:'')
    }
  }

  const handleCancel = () =>{
    setFormType(FormTypeList.ADD);
      setName('')
      setDesignation('')
      setEmail('')
      setContactNo('')
      setContactNoError('')
      setDesignationError('')
      setNameError('')
      setEmailError('')
      setId(undefined);
  }

   const handleUpdate = () =>{
    //  const updatedContact = {
    //         brandName: name,
    //         brandDesignation: designation,
    //         brandEmail: email,
    //         brandContactNo: contactNo
    //  }
      let error = false
        const data : TenantContactDetails = {
            tenantName: name,
            tenantDesignation: designation,
            tenantEmail: email,
            tenantContactNo: contactNo
        }

        if (data.tenantName === '') {
            setNameError('Name required')
            error = true
        } else if ((data.tenantName)?.length > 50) {
            setNameError('Name should be within 50 digits')
            error = true
        }

        if (data.tenantDesignation === '') {
            setDesignationError('Designation required')
            error = true
        } else if ((data.tenantDesignation)?.length > 50) {
            setDesignationError('Designation should be within 50 digits')
            error = true
        }

        if (data.tenantEmail === '') {
            setEmailError('Email required')
            error = true
        } else if ((data.tenantEmail)?.length > 35) {
            setEmailError('Email should be within 30 characters')
            error = true
        }
        else if(data.tenantEmail&&!testEmail(data.tenantEmail)) {
            setEmailError('Email-id is not valid')
            error = true
        }


        if (data.tenantContactNo === '') {
            setContactNoError('Contact No required')
            error = true
        } else if ((data.tenantContactNo)?.length > 15) {
            setContactNoError('Contact No should be within 15 characters')
            error = true
        }else if (data.tenantContactNo&&!testNumber(data.tenantContactNo)) {
            setContactNoError('Phone Number must be a number')
            error = true
        }

        if(tenantContactDetails!==undefined&&tenantContactDetails?.length>0){
            let updatedList = tenantContactDetails?.filter((a,i)=>i?.toString()!==id?.toString())
            let nameCount = updatedList?.filter((a)=>a?.tenantName?.toString()===data?.tenantName?.toString())
        if(nameCount?.length>0){
            setNameError('Name Already Exists')
            error = true
        }
        }

        


     if(error===false){
        const updatedContactList = tenantContactDetails?.filter((a,i)=>i?.toString()!==id?.toString())
        setTenantContactDetails!([...updatedContactList!,data])
     }

     if(error===false){
     handleCancel()
     }
     
  }

    useEffect(() => {
    // if (id !== undefined && formType === FormTypeList.VIEW) handleView();
     if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, formType]);

   useEffect(() => {
    if (tenantContactDetails!==undefined&&tenantContactDetails?.length > 0) {
      setLength(tenantContactDetails?.length);
      
    }else{
        setLength(0)
    }
  }, [tenantContactDetails]);

    return (<div className='container'>
        <h4>Tenant Contact Information</h4>
        <hr />
        <div className="row">
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Name'
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder=' Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>

            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Last Name'
                    label='Last Name'
                    labelClassName="required"
                    value={lastName}
                    onChange={handleLastName}
                    placeholder='Last Name'
                    errorText={lastNameError}
                    containerClass="mb-2"
                />
            </div> */}
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Designation'
                    label='Designation'
                    labelClassName="required"
                    value={designation}
                    onChange={handleDesignation}
                    placeholder=' Designation'
                    errorText={designationError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Email'
                    label='Email'
                    labelClassName="required"
                    value={email}
                    onChange={handleEmail}
                    placeholder=' Email'
                    errorText={emailError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Contact No'
                    label='Contact No'
                    labelClassName="required"
                    value={contactNo}
                    onChange={handleContactNo}
                    placeholder='Contact No'
                    errorText={contactNoError}
                    containerClass="mb-2"
                    type="text"
                />
            </div>
             <div className="text-end">
                {formType===FormTypeList.ADD&&<button className="btn btn-sm btn-primary fs-6" onClick={handleSave} >Add</button>}
                {formType===FormTypeList.EDIT&&id!==undefined&&
                <>
                  <div className="d-flex flex-row justify-content-end align-items-center" style={{paddingTop:'10px'}}>
                    <button style={{marginRight:'10px'}} className="btn btn-sm btn-primary fs-6 " onClick={handleUpdate} >Update</button>
                    <button className="btn btn-sm btn-primary fs-6" onClick={handleCancel} >Cancel</button>
                  </div>
                </> 
                }
            </div>
        </div>


        {/* {tenantContactDetails && tenantContactDetails?.length > 0 && <div className="mt-1">
            <table className="table colored">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Email</th>
                        <th>Contact No</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tenantContactDetails?.map((tc, i) => {
                        return <tr>
                            <td>{tc.tenantName}</td>
                            <td>{tc.tenantDesignation}</td>
                            <td>{tc.tenantEmail}</td>
                            <td>{tc.tenantContactNo}</td>
                            <td> <button className="btn btn-sm btn-primary fs-6" onClick={() => handleRemoveItem(tc)}>-</button></td>
                        </tr>
                    })
                    }
                </tbody>
            </table>

        </div>
        } */}

        
        {tenantContactDetails && tenantContactDetails?.length > 0 && 
        // <div className="mt-1">
        //     <table className="table colored">
        //         <thead>
        //             <tr>
        //                 <th>Name</th>
        //                 <th>Designation</th>
        //                 <th>Email</th>
        //                 <th>Contact No</th>
        //                 <th>Action</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             {brandContactDetails?.map((tc, i) => {
        //                 return <tr>
        //                     <td>{tc.brandName}</td>
        //                     <td>{tc.brandDesignation}</td>
        //                     <td>{tc.brandEmail}</td>
        //                     <td>{tc.brandContactNo}</td>
        //                     <td> <button className="btn btn-sm btn-primary fs-6" onClick={() => handleRemoveItem(tc)}>-</button></td>
        //                 </tr>
        //             })
        //             }
        //         </tbody>
        //     </table>

        // </div>
            <>
         <Modal
        headerText={formType + " Brand Contact"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
        style={{ background: "#00000082", zIndex: "999999" }}
      >
        <DeleteConfirmationForm
          deleteLabel={
            "Do you really want to delete the Contact - " +
            deleteData?.tenantName
          }
          onCancel={handleFormClose}
          onDelete={() => handleDelete(deleteData!)}
        />
      </Modal>



         <AdminGrid
          addBtnText=""
        //   onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
        //   onView={handleView}
        //   search={searchHandler}
          limit={limit}
          setOffset={setOffset}
          offset={offset}
        //   onPageNoChange={handleApply}
          range={range}
          pageTitle="Brand Contacts"
          path="/admin/tenant_manager"
          // data={data}
          setId={setId}
          {...getTableData()}
        />

        </>


        }


    </div >)
}
export default ContactForm