import { nanoid } from "@reduxjs/toolkit";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import BrandForm from "../../components/admin/BrandForm";
import  {
  Tbody,
  Thead,
} from "../../components/custom/components/AdminTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import {
  BrandType,
  deleteBrand,
  fetchBrand,
} from "../../redux/actions/brandActions";
import { BrandCompanyType } from "../../redux/actions/brandCompanyActions";
import { AppDispatch, StoreState } from "../../redux/store";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

// interface TableBody {
//     sno: number
//     brandcompanyname?: string
//     name: string
//     url: string
//     logopath: string
//     logourl: string
//     companyid: number
//     active: ActiveTypes | boolean
//     onEdit: MouseEventHandler<HTMLButtonElement>
//     onDelete: MouseEventHandler<HTMLButtonElement>
//     onView: MouseEventHandler<HTMLButtonElement>
// }

// type HandleEdit = (value: BrandType) => void
type HandleDelete = (value: BrandType) => void;
// type HandleView = (value: BrandType) => void

const Brand = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
   const navigate = useNavigate();
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const BrandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state.brand
  );
  const brandCompanyList = useSelector<StoreState, Array<BrandCompanyType>>(
    (state) => state.brandCompany
  );

  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<BrandType>();
  const [deleteData, setDeleteData] = useState<BrandType>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [id, setId] = useState<number>();

  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const searchBrandHandler = (item: any, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(
          item[1]["text"] + " " + item[5]["text"]
        )
      : true || [];
  };

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery({ search: e.target.value });
  };

  const [brandData, setBrandData] = useState<BrandType[]>([]);

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    if (BrandList?.length > 0) {
      setLength(BrandList?.length ? BrandList?.length : 0);
    }
  }, [BrandList]);

    useEffect(()=>{
     navigate("/admin/brand_management");
  },[])

  const handleApply = async (offsetVal = offset) => {
    if (BrandList?.length > 0) {
      const getData = {
        limit: limit?.toString(),
        offset: offsetVal?.toString(),
      };

      if (
        getData !== undefined &&
        getData.limit !== "" &&
        getData.offset !== "" &&
        getData.offset !== undefined &&
        getData.offset !== "NaN"
      ) {
        const filteredBrandList: BrandType[] = BrandList?.slice(
          offset,
          limit + offset
        );
        if (filteredBrandList?.length > 0) {
          setBrandData([...filteredBrandList]);
        } else {
          setBrandData([]);
        }
      }
    }
  };

  useEffect(() => {
    handleApply();
  }, [BrandList]);

  const getTableData = () => {
    if (brandData === undefined) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Brand Name", children: "Brand Name", text: "Brand Name" },
      { id: "Website URL", children: "Website URL", text: "Website URL" },
      { id: "Logo Path", children: "Logo Path", text: "Logo Path" },
      { id: "Logo URL", children: "Logo URL", text: "Logo URL" },
      {
        id: "Brand Company Name",
        children: "Brand Company Name",
        text: "Brand Company Name",
      },
      { id: "Status", children: "Status", text: "Status" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];

    brandData.forEach((item, i) => {
      let index = brandCompanyList.findIndex((c, i) => {
        return c.id === item.companyid;
      });
      var bcname = brandCompanyList[index]?.name;
      const pageNumber = Math.floor(offset / limit) + 1;

      var tBody: Tbody[] = [];
      tBody.push(
        {
          id: nanoid(),
          theadId: "S.No",
          children: limit * pageNumber + i - limit + 1,
          text: (limit * pageNumber + i - limit + 1)?.toString(),
        },
        {
          id: nanoid(),
          theadId: "Brand Name",
          children: item.name,
          text: item.name,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Website URL",
          children: item.url,
          text: item.url,
        },
        {
          id: nanoid(),
          theadId: "Logo Path",
          children: item.logopath,
          text: item.logopath?.toString(),
        },
        {
          id: nanoid(),
          theadId: "Logo URL",
          children: item.logourl,
          text: item.logourl,
        },
        {
          id: nanoid(),
          theadId: "Brand Company Name",
          children: bcname,
          text: bcname,
        },
        {
          id: nanoid(),
          theadId: "Status",
          children: item.active ? "Active" : !item.active ? "InActive" : "",
          text: item.active ? "Active" : !item.active ? "InActive" : "",
        },
        {
          id: nanoid(),
          theadId: "Action",
          children: "action",
          className: "d-none",
          text: item.id?.toString(),
        }
      );
      tableBody.push(tBody);
      // })
    });
    if (searchTerm?.length > 0) {
      tableBodyFiltered = tableBody.filter((item) =>
        searchBrandHandler(item, searchTerm)
      );
    } else {
      tableBodyFiltered = tableBody;
    }

    if (brandData !== undefined) {
      return { tbody: tableBodyFiltered, thead: tableHead };
    }
  };

  const handleAdd = () => {
    setShowForm(true);
    if(adminAuthendicated){
      const logProps1 = {
        action: 'Create',
        event: `${pageName.pathname.replace("\\", " ")} page | Create Brand Clicked`
      };
      AdminLog(logProps1)
    }
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = BrandList.find((c) => c.id === id);
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page |  ${obj?.name} Brand Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = BrandList.find((c) => c.id === id);
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = BrandList.find((c) => c.id === id);
      setEditData(obj);
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | Brand - ${obj?.name} Brand  | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (value.id !== undefined) {
      dispatch(deleteBrand(value.id))
        .then(async (text) => {
          dispatch(fetchBrand());
          toast(text, ToastTypes.SUCCESS);
          var obj = BrandList.find((c) => c.id === id);
          if(adminAuthendicated){
            const logProps1 = {
              action: 'Delete',
              event: `${pageName.pathname.replace("\\", " ")} page |  ${obj?.name} Brand | Delete Clicked`
            };
            AdminLog(logProps1)
          }
          if (brandData !== undefined && brandData?.length === 1) {
            let newOffset = offset - limit < 0 ? 0 : offset - limit;
            setOffset(newOffset);
            await handleApply(newOffset);
          } else {
            await handleApply(); //issue starts from here
          }
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
  }, [id, formType]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " BRAND"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <BrandForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
        />
      </Modal>
      <Modal
        headerText={formType + " BRAND"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            "Do you really want to delete the records with the brand - " +
            deleteData?.name
          }
          onCancel={handleFormClose}
          onDelete={() => handleDelete(deleteData!)}
        />
      </Modal>
      <AdminLayout>
        {/* <PageTitle
                title='Brand Management'
                buttonText='Create New Brand'
                onButtonClick={handleAdd}
            /> */}
        <AdminTable2
          addBtnText="Create New Brand"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          search={searchHandler}
          limit={limit}
          setOffset={setOffset}
          offset={offset}
          onPageNoChange={handleApply}
          range={range}
          pageTitle="Brand Management"
          path="/admin/brand_management"
          // data={data}
          setId={setId}
          {...getTableData()}
        />
        {/* <div className="card mt-3">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-lg-6 col-sm-12">
                            <h5 className="header-title mb-3 float-start"><i className="fe-align-justify me-1 text-primary"></i>Brand  Management</h5>
                        </div>
                        <div className="col-lg-6 col-sm-12 text-end mb-1">
                            <button type="button" onClick={handleAdd} className={`me-1 btn btn-xs btn-secondary`}>Create New Brand</button>
                        </div>
                    </div>
                    <hr className='hr mt-0' />
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle text-center'>Brand Name</th>
                                    <th className='text-truncate align-middle text-center'>Website URL</th>
                                    <th className='text-truncate align-middle text-center'>Logo Path</th>
                                    <th className='text-truncate align-middle text-center'>Logo URL</th>
                                    <th className='text-truncate align-middle text-center'>Brand Company Name</th>
                                    <th className='text-truncate align-middle text-center'>Status</th>
                                    <th className='text-truncate align-middle text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {BrandList.map((u, i) => {
                                    let index = brandCompanyList.findIndex((c, i) => {
                                        return c.id === u.companyid
                                    })
                                    var cname = brandCompanyList[index]?.name
                                    return <BrandBody
                                        sno={i + 1}
                                        name={u.name}
                                        url={u.url}
                                        logopath={u.logopath}
                                        logourl={u.logourl}
                                        companyid={u.companyid}
                                        brandcompanyname={cname}
                                        active={u.active}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                        onView={() => handleView(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
      </AdminLayout>
    </>
  );
};

// const BrandBody = ({ sno, name, url, logopath, logourl, active, companyid, onView, onEdit, onDelete, brandcompanyname }: TableBody) => {
//     return <tr>
//         <td className='align-middle text-center'>{sno}</td>
//         <td className='align-middle'>{name}</td>
//         <td className='align-middle'>{url}</td>
//         <td className='align-middle'>{logopath}</td>
//         <td className='align-middle'>{logourl}</td>
//         <td className='align-middle text-center'>{brandcompanyname}</td>
//         <td className='align-middle'>{active ? "Active" : "InActive"}</td>
//         <td>
//             <div className='hstack gap-1'>
//                 <button className='btn btn-sm btn-secondary' onClick={onEdit}>Edit</button>
//                 <button className='btn btn-sm btn-secondary' onClick={onDelete}>Delete</button>
//                 <button className='btn btn-sm btn-secondary' onClick={onView}>View</button>
//             </div>
//         </td>
//     </tr>
// }

export default Brand;
