import errorPic from "../../components/custom/assets/images/errors/error-img.png";

const Page404 = () => {
    return (
        <>
        <div className="errorPage my-5 pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center my-5">
                            <h1 className="fw-boldtext-error">4 <span className="error-text">0<img src={errorPic!} alt="404" className="error-img" /></span> 4</h1>
                            <h3 className="text-uppercase">Sorry, page not found</h3>
                            <div className="mt-5 text-center">
                                <a className="btn btn-primary waves-effect waves-light" href="/">Back to Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Page404