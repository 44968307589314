import React, { ChangeEventHandler, useEffect, useState } from "react";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../redux/store";
import { PortfolioType } from "../../redux/actions/portfolioActions";
//import { BrandCompanyType } from "../../redux/actions/brandCompanyActions";
import { FormType, FormTypeList } from "../../config";
import {
  Tbody,
  Thead,
} from "../../components/custom/components/AdminTable";
import {
  BrandCompanyMappingType,
  deleteBrandCompanyMapping,
  fetchBrandCompanyMapping,
} from "../../redux/actions/brandCompanyMappingActions";
import { nanoid } from "@reduxjs/toolkit";
import Modal from "../../components/Modal";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
import BrandCompanyMappingForm from "../../components/admin/BrandCompanyMappingForm";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { BrandType } from "../../redux/actions/brandActions";
import { TradeCategoryType } from "../../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../../redux/actions/tradeSubCategoryActions";
import { outletDataType } from "../../components/admin/OutletForm";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

type HandleDelete = (value: BrandCompanyMappingType) => void;

const BrandCompanyMapping = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const authAdminToken = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  )?.["auth-token"]!;

  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );

  const brandmaplist = useSelector<StoreState, Array<BrandCompanyMappingType>>(
    (state) => state.brandCompanyMapping
  );

  // const brandCompanyList = useSelector<StoreState, Array<BrandCompanyType>>(
  //   (state) => state.brandCompany
  // );

  const brandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state.brand
  );

  const catList = useSelector<StoreState, Array<TradeCategoryType>>(
    (state) => state.tradeCategory
  );
  const subcatList = useSelector<StoreState, Array<TradeSubCategoryType>>(
    (state) => state.tradeSubCategory
  );

  const [showForm, setShowForm] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<BrandCompanyMappingType>();
  const [deleteData, setDeleteData] = useState<BrandCompanyMappingType>();
  const [disableDelete, setDisableDelete] = useState<boolean>(false);
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [id, setId] = useState<number>();

  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const limitDel = 1;
  const [offsetDel] = useState<number>(0);

  const searchBrandCompanyMappingHandler = (item: any, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(
          item[1]["text"] + " " + item[2]["text"]
        )
      : true || [];
  };

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery({ search: e.target.value });
  };

  const [brandMapData, setBrandMapData] = useState<BrandCompanyMappingType[]>(
    []
  );

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    if (brandmaplist?.length > 0) {
      setLength(brandmaplist?.length ? brandmaplist?.length : 0);
    }
  }, [brandmaplist]);


  
    useEffect(()=>{
     navigate("/admin/brand_mapping");
  },[])

  const handleApply = async (offsetVal = offset) => {
    if (brandmaplist?.length > 0) {
      const getData = {
        limit: limit?.toString(),
        offset: offsetVal?.toString(),
      };

      if (
        getData !== undefined &&
        getData.limit !== "" &&
        getData.offset !== "" &&
        getData.offset !== undefined &&
        getData.offset !== "NaN"
      ) {
        const filteredBrandMapList: BrandCompanyMappingType[] =
          brandmaplist?.slice(offset, limit + offset);
        if (filteredBrandMapList?.length > 0) {
          setBrandMapData([...filteredBrandMapList]);
        } else {
          setBrandMapData([]);
        }
      }
    }
  };

  useEffect(() => {
    handleApply();
  }, [brandmaplist]);

  const getTableData = () => {
    if (brandMapData === undefined) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Portfolio", children: "Portfolio", text: "Portfolio" },
      { id: "Brand", children: "Brand", text: "Brand" },
      {
        id: "Trade Category",
        children: "Trade Category",
        text: "Trade Category",
      },
      {
        id: "Trade Sub Category",
        children: "Trade Sub Category",
        text: "Trade Sub Category",
      },
      { id: "Status", children: "Status", text: "Status" },
      { id: "Action", children: "Action", text: "Action" },
    ];

    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];

    brandMapData.forEach((item, i) => {
      let pindex = portfolioList.findIndex((p) => {
        return p.id === item.pfid;
      });
      var pname = portfolioList[pindex]?.name;

      let bindex = brandList.findIndex((b) => {
        return b.id === item.brandid;
      });

      var bname = brandList[bindex]?.name;

      let ctindex = catList.findIndex((a) => a.id === item.tradecatid);
      var ctname = catList[ctindex]?.name;

      let sctindex = subcatList.findIndex((a) => a.id === item.tradesubcatid);
      var sctname = subcatList[sctindex]?.name;

      const pageNumber = Math.floor(offset / limit) + 1;

      var tBody: Tbody[] = [];
      tBody.push(
        {
          id: nanoid(),
          theadId: "S.No",
          children: limit * pageNumber + i - limit + 1,
          text: (limit * pageNumber + i - limit + 1)?.toString(),
        },
        {
          id: nanoid(),
          theadId: "Portfolio",
          children: pname,
          text: pname,
        },
        {
          id: nanoid(),
          theadId: "Brand",
          children: bname,
          text: bname,
        },
        {
          id: nanoid(),
          theadId: "Trade Category",
          children: ctname,
          text: ctname,
        },
        {
          id: nanoid(),
          theadId: "Trade Sub Category",
          children: sctname,
          text: sctname,
        },
        {
          id: nanoid(),
          theadId: "Status",
          children: item.active ? "Active" : !item.active ? "InActive" : "",
          text: item.active ? "Active" : !item.active ? "InActive" : "",
        },
        {
          id: nanoid(),
          theadId: "Action",
          children: "action",
          className: "d-none",
          text: item.id?.toString(),
        }
      );
      tableBody.push(tBody);
      // })
    });
    if (searchTerm?.length > 0) {
      tableBodyFiltered = tableBody.filter((item) =>
        searchBrandCompanyMappingHandler(item, searchTerm)
      );
    } else {
      tableBodyFiltered = tableBody;
    }

    if (brandMapData !== undefined) {
      return { tbody: tableBodyFiltered, thead: tableHead };
    }
  };

  const handleAdd = () => {
    setShowForm(true);
    if(adminAuthendicated){
      const logProps1 = {
        action: 'Create',
        event: `${pageName.pathname.replace("\\", " ")} page | Create Brand Company Mapping Clicked`
      };
      AdminLog(logProps1)
    }
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = brandmaplist.find((c) => c.id === id);
      var brandObj = brandList.find((c)=>c.id===obj?.brandid)
      var brandCompanyObj = portfolioList.find((c)=>c.id===obj?.pfid)
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page | Portfolio - ${brandCompanyObj?.name} , Brand - ${brandObj?.name} BrandCompany Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = brandmaplist.find((a) => a.id === id);
      setEditData(obj);
      setShowForm(true);
      
      if(adminAuthendicated&&formType!==FormTypeList.VIEW){
        var brandObj = brandList.find((c)=>c.id===obj?.brandid)
      var brandCompanyObj = portfolioList.find((c)=>c.id===obj?.pfid)
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | Portfolio - ${brandCompanyObj?.name} , Brand - ${brandObj?.name} BrandCompany Viewed | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDeleteConfirmation = async () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = brandmaplist.find((c) => c.id === id);
      // const portfolioId = obj?.pfid;
      // const brandcompanyId = obj?.companyid;
      setShowDeleteConfirmation(true);

      setDeleteData(obj);
      // const token = authAdminToken;
      // const config = getAxiosRequestConfig(token);
      const getData = {
        pfid: obj?.pfid,
        bcid: obj?.brandid,
        limit: limitDel?.toString(),
        offset: offsetDel?.toString(),
      };

      try {
        const apiconfig = getAxiosRequestConfig(authAdminToken);
        const response = await api.post<
          APIResponse<{ records: outletDataType[] }>
        >(`outlet/pagination`, getData, apiconfig);

        if (response.status === 200 && response.data.data) {
          const result = response.data.data.records;

          setDisableDelete(
            result && result[0].record?.length > 0 ? true : false
          );
        } else {
          setDisableDelete(true);
        }
      } catch (error) {
        // Handle errors
      } finally {
      }
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (value.id !== undefined) {
      dispatch(deleteBrandCompanyMapping(value.id, value.pfid?.toString()))
        .then(async (text) => {
          dispatch(fetchBrandCompanyMapping());
          toast(text, ToastTypes.SUCCESS);
          var obj = brandmaplist.find((c) => c.id === value.id);
      var brandObj = brandList.find((c)=>c.id===obj?.brandid)
      var brandCompanyObj = portfolioList.find((c)=>c.id===obj?.pfid)
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Delete',
          event: `${pageName.pathname.replace("\\", " ")} page | Portfolio - ${brandCompanyObj?.name} , Brand - ${brandObj?.name} BrandCompany | Delete Clicked`
        };
        AdminLog(logProps1)
      }
          if (brandMapData !== undefined && brandMapData?.length === 1) {
            let newOffset = offset - limit < 0 ? 0 : offset - limit;
            setOffset(newOffset);
            await handleApply(newOffset);
          } else {
            await handleApply(); //issue starts from here
          }
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
  }, [id, formType]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " BRAND MAPPING"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            disableDelete
              ? "Brand Mapping is in use. Deletion not allowed."
              : "Do you really want to delete the records with brand mapping "
          }
          onCancel={handleFormClose}
          onDelete={() => (disableDelete ? {} : handleDelete(deleteData!))}
        />
      </Modal>

      <Modal
        headerText={formType + " BRAND MAPPING"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <BrandCompanyMappingForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
        />
      </Modal>

      <AdminLayout>
        <AdminTable2
          addBtnText="Create New Brand Mapping"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          search={searchHandler}
          limit={limit}
          setOffset={setOffset}
          offset={offset}
          onPageNoChange={handleApply}
          range={range}
          pageTitle="Brand Mapping"
          path="/admin/brand_mapping"
          setId={setId}
          {...getTableData()}
        />
      </AdminLayout>
    </>
  );
};

export default BrandCompanyMapping;
