import { MouseEventHandler } from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


interface BreadcrumbItems {
    label: string;
    path: string;
    active?: boolean;
}

interface PageTitleProps {
    to?: string
    isLink?: boolean
    breadCrumbItems?: Array<BreadcrumbItems>;
    title: string;
    buttonText?: string;
    onButtonClick?: MouseEventHandler<HTMLButtonElement>;

}

/**
 * PageTitle
 */
const PageTitle = (props: PageTitleProps) => {
    return (
        <Row>
            <Col>
                <div className="page-title-box">
                    {props.buttonText && <div className="page-title-right">
                        {props.isLink && props.to && props.to !== ''
                            ? <Link to={props.to} className='btn btn-secondary'>{props.buttonText}</Link>
                            : <button className='btn btn-secondary' onClick={props.onButtonClick}>{props.buttonText}</button>}
                    </div>}
                    {props.breadCrumbItems && <div className="page-title-right">
                        <Breadcrumb className="m-0">
                            <Breadcrumb.Item href="/">UBold</Breadcrumb.Item>

                            {(props['breadCrumbItems'] || []).map((item, index) => {
                                return item.active ? (
                                    <Breadcrumb.Item active key={index}>
                                        {item.label}
                                    </Breadcrumb.Item>
                                ) : (
                                    <Breadcrumb.Item key={index} href={item.path}>
                                        {item.label}
                                    </Breadcrumb.Item>
                                );
                            })}
                        </Breadcrumb>
                    </div>}
                    <h4 className="page-title page-head">{props['title']}</h4>
                </div>
            </Col>
        </Row>
    );
};

export default PageTitle;
