import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from "../redux/store";
import { clearAdminSession, AdminSessionType } from '../redux/actions/commonActions'
import { logoutAdmin } from '../redux/actions/authAdminActions';
import { inactive_admin_time } from '../config';

interface SessionManagerProps {
  children: React.ReactNode;
}

const SessionManager: React.FC<SessionManagerProps> = ({ children }) => {
  const dispatch = useDispatch();
  const adminAuthenticated = useSelector<StoreState, AdminSessionType | null>(
    (state) => state.adminSession
  )?.["isLoggedIn"]!;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    let adminTimer: NodeJS.Timeout;
    const resetTimer = () => {
      clearTimeout(timer);
      clearTimeout(adminTimer);
      let adminInactiveTime:number = inactive_admin_time ? parseInt(inactive_admin_time) : 600000;
      if(adminAuthenticated === true){ 
        adminTimer = setTimeout(() => {
        dispatch(clearAdminSession());
        dispatch(logoutAdmin());
        }, adminInactiveTime);
      }else{
        adminTimer = setTimeout(() => {
        dispatch(clearAdminSession());
        dispatch(logoutAdmin());
        }, adminInactiveTime);
      }
    };

    resetTimer();

    const handleActivity = () => {
      resetTimer();
    };

    document.addEventListener('click', handleActivity);
    document.addEventListener('keypress', handleActivity);
    document.addEventListener('scroll', handleActivity);
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('click', handleActivity);
      document.removeEventListener('keypress', handleActivity);
      document.removeEventListener('scroll', handleActivity);
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
    };
  }, [inactive_admin_time, dispatch]);

  return <>{children}</>;
};

export default React.memo(SessionManager);