import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemDepartmentForm from "../../components/admin/ItemDepartmentForm";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { nanoid } from "@reduxjs/toolkit";
import Modal from "../../components/Modal";
import Option from "../../components/Option";
import  {
  Tbody,
  Thead,
} from "../../components/custom/components/AdminTable";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import {
  ItemDepartmentType,
  deleteItemDepartment,
  fetchDepartment,
} from "../../redux/actions/itemdepartment";
import { ItemCategoryType } from "../../redux/actions/itemcategory";
import { ItemSubcategoryType } from "../../redux/actions/itemsubcategory";
import { AppDispatch, StoreState } from "../../redux/store";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";
import { FormInput } from "../../components/custom/components";
import { PortfolioType } from "../../redux/actions/portfolioActions";

type HandleDelete = (value: ItemDepartmentType) => void;

const ItemDepartment = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
   const navigate = useNavigate();
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const ItemDepartmentList = useSelector<StoreState, Array<ItemDepartmentType>>(
    (state) => state.department
  );
  const ItemCategoryList = useSelector<StoreState, Array<ItemCategoryType>>(
    (state) => state.itemcategory
  );
  const ItemSubcategoryList = useSelector<StoreState, Array<ItemSubcategoryType>>(
    (state) => state.itemsubcategory
  );
  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );
  // const filteredPortfolio = portfolioList.filter((a) => a.businessmodel !== 1);
  const [portfolioId, setportfolioId] = useState<string>("");
  const [portfolioIdError, setportfolioIdError] = useState<string>("");
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<ItemDepartmentType>();
  const [deleteData, setDeleteData] = useState<ItemDepartmentType>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [disableDelete, setDisableDelete] = useState<boolean>(false);
  const [id, setId] = useState<number>();

  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const searchDeptHandler = (dept: ItemDepartmentType, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(dept.department)
      : true || [];
  };

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!portfolioId) {
      toast("Porfolio required", ToastTypes.ERROR);
      setSearchQuery({ search: "" });
      return;
    }
    setSearchQuery({ search: e.target.value });
  };
  const [itemDepartmentData, setItemDepartmentData] = useState<ItemDepartmentType[]>([]);

  const limit = 50;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    if (ItemDepartmentList?.length > 0) {
      setLength(ItemDepartmentList?.length ? ItemDepartmentList?.length : 0);
    }
  }, [ItemDepartmentList]);

    useEffect(()=>{
     navigate("/admin/item_department");
  },[])

 const handleApply = async (offsetVal = offset) => {
    if (ItemDepartmentList?.length > 0) {
      const getData = {
        limit: limit?.toString(),
        offset: offsetVal?.toString(),
      };

      if (
        getData !== undefined &&
        getData.limit !== "" &&
        getData.offset !== "" &&
        getData.offset !== undefined &&
        getData.offset !== "NaN"
      ) {
        const filteredItemDepartmentList: ItemDepartmentType[] =
          ItemDepartmentList?.slice(offset, limit + offset);
        if (filteredItemDepartmentList?.length > 0) {
          setItemDepartmentData([...filteredItemDepartmentList]);
        } else {
          setItemDepartmentData([]);
        }
      }
    }
  };

  useEffect(() => {
    handleApply();
  }, [ItemDepartmentList]);

  useEffect(() => {
    if (portfolioId) {
      dispatch(fetchDepartment(portfolioId));
    }
  }, [portfolioId]);

  const getTableData = () => {
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Department", children: "Department", text: "Department" },
      { id: "Status", children: "Status", text: "Status" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];
    if (portfolioId) {
      itemDepartmentData
      .filter((cr) => searchDeptHandler(cr, searchTerm))
      .forEach((item, i) => {
        const pageNumber = Math.floor(offset / limit) + 1;
        var tBody: Tbody[] = [];

        tBody.push(
          {
            id: nanoid(),
            theadId: "S.No",
            children: limit * pageNumber + i - limit + 1,
            text: (limit * pageNumber + i - limit + 1)?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Department",
            children: item.department,
            text: item.department,
            className: "text-truncate",
          },
          {
            id: nanoid(),
            theadId: "Status",
            children: item.active ? "Active" : !item.active ? "InActive" : "",
            text: item.active ? "Active" : !item.active ? "InActive" : "",
          },
          {
            id: nanoid(),
            theadId: "Action",
            children: "action",
            className: "d-none",
            text: item.depid?.toString(),
          }
        );
        tableBody.push(tBody);
        // })
      });
    }
    return { tbody: tableBody, thead: tableHead };
  };

  const handleAdd = () => {
    if (!portfolioId) toast("Portfolio required", ToastTypes.ERROR);
    else {
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Create',
          event: `${pageName.pathname.replace("\\", " ")} page | Create Item Department Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = ItemDepartmentList.find((c) => c.depid === id);
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page |  ${obj?.depid} Item Department Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handlePfid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setportfolioId(e.target.value);
    setportfolioIdError("");
  };

  const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = ItemDepartmentList.find((c) => c.depid === id);
      setShowDeleteConfirmation(true);
      const itemcategories = ItemCategoryList.filter(
        (tsc) => tsc.depid === id
      );
      const itemSubcategories = ItemSubcategoryList.filter(
        (tsc) => tsc.depid === id
      );
      setDeleteData(obj);
      setDisableDelete((itemcategories?.length || itemSubcategories?.length) ? true : false);
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = ItemDepartmentList.find((c) => c.depid === id);
      setEditData(obj);
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | Item Department - ${obj?.department} | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (value.depid !== undefined) {
      let pfId = portfolioId
      let deptid = value.depid
      dispatch(deleteItemDepartment(pfId, deptid))
        .then(async (text) => {
          dispatch(fetchDepartment(portfolioId));
          toast(text, ToastTypes.SUCCESS);
          if (
            itemDepartmentData !== undefined &&
            itemDepartmentData?.length === 1
          ) {
            let newOffset = offset - limit < 0 ? 0 : offset - limit;
            setOffset(newOffset);
            await handleApply(newOffset);
          } else {
            await handleApply();
          }
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, formType]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " Department"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <ItemDepartmentForm
          pfId={portfolioId}
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
        />
      </Modal>
      <Modal
        headerText={formType + " DEPARTMENT"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            disableDelete
              ? "Department is in use. Deletion not allowed."
              : "Do you really want to delete the records with the Department - " +
                deleteData?.department
          }
          onCancel={handleFormClose}
          onDelete={() => (disableDelete ? {} : handleDelete(deleteData!))}
        />
      </Modal>
      <AdminLayout>
      <div className="row">
          <div className="col-6 hstack mt-4">
            <label htmlFor="">Portfolio :</label>
            <FormInput
              name="Portfolio"
              label=""
              labelClassName="required"
              value={portfolioId}
              onChange={handlePfid}
              placeholder="Portfolio"
              errorText={portfolioIdError}
              containerClass="w-50"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {/* {filteredPortfolio.map((a, i) => { */}
              {portfolioList.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        </div>
        <AdminTable2
          addBtnText="Create New Department"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          search={searchHandler}
          limit={limit}
          setOffset={setOffset}
          offset={offset}
          // onPageNoChange={handleApply}
          range={range}
          pageTitle="Department Management"
          path="/admin/item_department"
          setId={setId}
          {...getTableData()}
        />
      </AdminLayout>
    </>
  );
};
export default ItemDepartment;
