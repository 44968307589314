import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch,useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { FormType, FormTypeList } from "../../config";
import { useDropzone } from 'react-dropzone';
import uploadicon from "../custom/assets/images/file-upload-icon.jpg";
import Modal from "../Modal";
import { AdminLog } from "../../config/common";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { AppDispatch, StoreState } from "../../redux/store";
import * as XLSX from 'xlsx';
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";

interface Props {
  onDrop?: (acceptedFiles: File[]) => void;
  portfolioid: string;
  centerid: string;
  buildingid: string;
  floorid: string;
}
interface FilePreview {
  file: File;
  preview: string;
}
interface DataRow {
  [key: string]: string | number;
}
const UnitUploadIndex = ({
    portfolioid,
    centerid,
    buildingid,
    floorid,
  }: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const token = authAdmin?.['auth-token']!;  
  const config = getAxiosRequestConfig(token);

  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  
  const [data, setData] = useState<DataRow[]>([]);
  const [files, setFiles] = useState<FilePreview[]>([]);
  const [formData, setFormData] = useState<FormData>(new FormData());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHandleClick, setIsHandleClick] = useState(false);




  const maxFiles = 1;
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if(portfolioid===''){
        toast("Portfolio Required", ToastTypes.ERROR);
        return
    }
    else if(centerid===''){
            toast("Center Required", ToastTypes.ERROR);
            return
        } 
        else if(buildingid===''){
            toast("Building Required", ToastTypes.ERROR);
            return
        } 
        else if(floorid===''){
            toast("Floor Required", ToastTypes.ERROR);
            return
        }

    const file = acceptedFiles[0];
    const newFormData = new FormData();
    acceptedFiles.forEach((uploadFile) => {
      newFormData.append('file', uploadFile);
    });
    newFormData.append('portfolioid', portfolioid);
    newFormData.append('centerid', centerid);
    newFormData.append('buildingid', buildingid);
    newFormData.append('floorid', floorid);
    setFormData(newFormData);
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target?.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary',cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: DataRow[] = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: null,
        raw: false
      });
      setData(jsonData);
      setIsModalOpen(true);
    };

    reader.readAsBinaryString(file);

    if (files.length + acceptedFiles.length > maxFiles) {
      alert(`File Already Exists`);
      return;
    }
    
    const filePreviews = acceptedFiles.map(file => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    

    setFiles(prevFiles => [...prevFiles, ...filePreviews]);
    
  }, [files,portfolioid,centerid,buildingid,floorid]);

  const removeFile = (file: File) => {
    setFiles(prevFiles => prevFiles.filter(fp => fp.file !== file));
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ 
    onDrop,
    maxFiles: maxFiles - files.length,
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
    },
    // accept: ['application/csv','application/vnd.ms-excel','application/msexcel,application/x-msexcel','application/x-ms-excel','application/x-excel','application/x-dos_ms_excel','application/xls','application/x-xls','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] 
  });

  useEffect(() => {
    if(isHandleClick === true){
      setIsHandleClick(false)
      if (formData) {
        api.post<APIResponse<{ records: any[] }>>(
            `unitBulkUpload/`,
            formData,
            config
          )
          .then((response) => {
            if (response.status === 200 && response.data.message ) {
              setIsModalOpen(false)
              removeFile(files[0].file);
                toast(response.data.message, ToastTypes.SUCCESS);
                const logProps1 = {
                  action: 'Upload',
                  event: `${pageName.pathname.replace("\\", " ")} page | Apply Unit | Data Status:Success`
                };
                AdminLog(logProps1)
            }
        })
        .catch((error) => {          
                // console.error('Error response:', error.response,error.response.data?.message);
                const errorMessage = error.response.data?.message
                toast(errorMessage, ToastTypes.ERROR);
                const logProps1 = {
                  action: 'Upload',
                  event: `${pageName.pathname.replace("\\", " ")} page | Apply Unit | Data Status:Failure`
                };
                AdminLog(logProps1)
          
        })
        .finally(() => {
            // setLoading(false);
            // handleClear()
        });

          
      }
    }
  }, [isHandleClick, formData]);

  



  return (
    <>
    <div {...getRootProps()} style={{border: '2px dashed #cccccc', padding: '20px', textAlign: 'center', ...(isDragActive ? { borderColor: 'green' } : {}),
    ...(isDragReject ? { borderColor: 'red' } : {})}}>
      <input {...getInputProps()} />
      {files.length === 0 && <div className='uploaddefault'>
        <div className="mb-3">
            <i>
              <img src={uploadicon} alt="upload-icon"></img>
            </i>
        </div>
        <h4>Click to upload.</h4>
      </div>}
      {files.length>0 && 
      <div className='filespreview'>
        <div className="previews">
          {files.map((filePreview, index) => (
            <div key={index} className="preview">
              {/* <img src={filePreview.preview} alt={`Preview ${index}`} /> */}
              <p>{(filePreview.file.size/1000).toFixed(2)} KB</p>
              <p>{filePreview.file.name}</p>
              <a onClick={() => removeFile(filePreview.file)}>Remove File</a>
            </div>
          ))}
        </div>
      </div>
      }
      
    </div>

    <Modal 
    size="xl"
    visible = {isModalOpen}
    headerText={"Unit Preview"}
    onClose={() => setIsModalOpen(false)}
    centered classname="preview-container" 
    >
        <table className='table table-striped table-bordered dt-responsive nowrap no-footer dataTable'>
          <thead>
            <tr>
              {data.length > 0 && Object.values(data[0]).map((head,key) => (
                <th key={key}>{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, index) => (
             row[4]!==''&&row[4]!==null&&row[5]!==null&&row[5]!=='' &&<tr key={index}>
                {Object.values(row).map((cell, cellIndex) => (
                  cell!=='' && cell!==null && 
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
          <div className='up-btn-container'>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>setIsModalOpen(false)}>Close</button>
            <button type="button" className="btn btn-primary waves-effect waves-light me-1" onClick={()=>setIsHandleClick(true)}>Upload</button>
          </div>
      </Modal>
    </>
  );
};

export default UnitUploadIndex;
