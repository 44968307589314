import { NotificationActionsList, NotificationType } from "../actions/notificationActions";
import { Action } from "../actions/types";

const initialValue: Array<NotificationType> = []

export const NotificationReducer = (state: NotificationType[] = initialValue, action: Action) => {

    switch (action.type) {

        case NotificationActionsList.ADD_NOTIFICATION: {

            return [...state, action.data]
        }

        case NotificationActionsList.EDIT_NOTIFICATION: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case NotificationActionsList.FETCH_NOTIFICATION: {

            return action.data

        }

        case NotificationActionsList.DELETE_NOTIFICATION: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}