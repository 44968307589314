import PageTitle from "../../components/custom/components/PageTitle";
import AdminLayout from "../../components/custom/layouts/AdminLayout";

const DeActivateTenant = () => {
    return <AdminLayout>
        <PageTitle
            title='De-Activate Tenant'
        />
        {/* <div className="card">
       <div className="card-body">
           
       </div>
   </div> */}
    </AdminLayout>
}
export default DeActivateTenant;      
