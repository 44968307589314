import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",

}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive | ''

export interface BuildingType {
    id?: number
    portfolioid: string
    centerid: number
    centertype: string
    buildingname: string
    code: string
    active: ActiveTypes | boolean
    createdby?: string
    createdat?: string
    updatedat?: string
}

export enum BuildingActionsList {
    ADD_BUILDING = 'ADD_BUILDING',
    EDIT_BUILDING = 'EDIT_BUILDING',
    FETCH_BUILDING = 'FETCH_BUILDING',
    DELETE_BUILDING = 'DELETE_BUILDING'
}

export interface DeleteBuilding {
    id: number
}

export interface AddBuildingAction {
    type: BuildingActionsList.ADD_BUILDING
    data: BuildingType
}

export interface EditBuildingAction {
    type: BuildingActionsList.EDIT_BUILDING
    data: BuildingType
    id?: number
}

export interface FetchBuildingAction {
    type: BuildingActionsList.FETCH_BUILDING
    data: BuildingType[]

}

export interface DeleteBuildingAction {
    type: BuildingActionsList.DELETE_BUILDING
    data: DeleteBuilding
}

export type BuildingActions = AddBuildingAction | EditBuildingAction | FetchBuildingAction | DeleteBuildingAction

export const addBuilding = (data: BuildingType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<BuildingType>>('building/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddBuildingAction>({
                    type: BuildingActionsList.ADD_BUILDING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Building added')
            } else {
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}

export const editBuilding = (data: BuildingType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<BuildingType>>(`building/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditBuildingAction>({
                    type: BuildingActionsList.EDIT_BUILDING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Building Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchBuilding = (portfolioId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<BuildingType[]>>(`building/${portfolioId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchBuildingAction>({
                    type: BuildingActionsList.FETCH_BUILDING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteBuilding = (id: number, portfolioId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<BuildingType>>(`building/${portfolioId}/${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteBuildingAction>({
                    type: BuildingActionsList.DELETE_BUILDING,
                    data: { id }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Building Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}