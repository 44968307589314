import { BuildingActionsList, BuildingType } from "../actions/buildingActions";
import { Action } from "../actions/types";

const initialValue: Array<BuildingType> = []

export const BuildingReducer = (state: BuildingType[] = initialValue, action: Action) => {

    switch (action.type) {

        case BuildingActionsList.ADD_BUILDING: {

            return [...state, action.data]
        }

        case BuildingActionsList.EDIT_BUILDING: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case BuildingActionsList.FETCH_BUILDING: {

            return action.data

        }

        case BuildingActionsList.DELETE_BUILDING: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}