import { ItemDepartmentActionsList, ItemDepartmentType } from "../actions/itemdepartment";
import { Action } from "../actions/types";

const initialValue: Array<ItemDepartmentType> = []

export const ItemDepartmentReducer = (state: ItemDepartmentType[] = initialValue, action: Action) => {

    switch (action.type) {

        case ItemDepartmentActionsList.ADD_DEPARTMENT: {

            return [...state, action.data]
        }

        case ItemDepartmentActionsList.EDIT_DEPARTMENT: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.depid === action.data.depid)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case ItemDepartmentActionsList.FETCH_DEPARTMENT: {

            return action.data

        }

        case ItemDepartmentActionsList.DELETE_DEPARTMENT: {
            return state.filter(i => i.depid !== action.data.depid)

        }

        default: return state
    }

}