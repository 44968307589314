import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import {
  BuildingType,
  fetchBuilding,
} from "../../redux/actions/buildingActions";
import {
  ActiveDropDown,
  ActiveTypes,
  FloorType,
  addFloor,
  editFloor,
  fetchFloor,
} from "../../redux/actions/floorActions";
import { SiteType } from "../../redux/actions/siteActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { FormInput } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { AdminLog } from "../../config/common";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

interface Props {
  pfId: string;
  formType: FormType;
  editData?: FloorType;
  onSave: () => void;
  showViewForm: boolean;
}

const FloorForm = ({
  formType,
  onSave,
  editData,
  showViewForm,
  pfId,
}: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const siteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  );

  const site = useSelector<StoreState, Array<SiteType>>((state) => state.site);
  const building = useSelector<StoreState, Array<BuildingType>>(
    (state) => state.building
  );

  const [portfolioId, setportfolioId] = useState<string>("");
  const [centerid, setCenterid] = useState<string | null>("");
  const [centeridError, setCenteridError] = useState<string>("");
  const [buildingid, setBuildingid] = useState<string | null>("");
  const [buildingidError, setBuildingidError] = useState<string>("");
  const [centertype, setCentertype] = useState<string>("");
  const [centertypeError, setCentertypeError] = useState<string>("");
  const [floorname, setFloorname] = useState<string>("");
  const [floornameError, setFloornameError] = useState<string>("");
  const [floorcode, setFloorcode] = useState<string>("");
  const [floorcodeError, setFloorcodeError] = useState<string>("");
  const [active, setActive] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [activeError, setActiveError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const filteredSites = useMemo<SiteType[]>(() => {
    if (portfolioId) {
      return site.filter((s) => s.pfid?.toString() === portfolioId);
    } else return site;
  }, [portfolioId, site]);

  const filteredBuildings = useMemo<BuildingType[]>(() => {
    if (centerid) {
      const centerBuilding = building.filter(
        (s) => s.centerid?.toString() === centerid
      );
      if (centerBuilding?.length === 0) setBuildingid(null);
      return centerBuilding;
    } else return building;
  }, [centerid, building]);

  const resetStates = () => {
    setCenterid("");
    setportfolioId("");
    setCenteridError("");
    setCentertype("");
    setCentertypeError("");
    setBuildingid("");
    setBuildingidError("");
    setFloorname("");
    setFloornameError("");
    setFloorcode("");
    setFloorcodeError("");
    setActive(ActiveDropDown.Active);
    setActiveError("");
    setLoading(false);
  };
  const handleCenterid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCenterid(e.target.value);
    setCenteridError("");
    if (e.target.value) {
      let obj = site.find((s) => s.id?.toString() === e.target.value);
      setCentertype(obj?.centertype ? obj?.centertype : "");
    }
  };

  const handleBuildingid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBuildingid(e.target.value);
    setBuildingidError("");
  };

  // const handleCentertype: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setCentertype(e.target.value);
  //   setCentertypeError("");
  // };

  const handleFloorname: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFloorname(e.target.value);
    setFloornameError("");
  };

  const handleActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setActive(e.target.value as ActiveTypes);
    setActiveError("");
  };

  const handleFloorcode: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFloorcode(e.target.value);
    setFloorcodeError("");
  };

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const data: FloorType = {
      portfolioid: portfolioId,
      centerid: parseInt(centerid || ""),
      centertype: centertype,
      buildingid: parseInt(buildingid || ""),
      floorname: floorname,
      floorcode: floorcode,
      active: active,
    };
    if (data.portfolioid === "") {
      toast("Portfolio required", ToastTypes.ERROR);
      error = true;
    }
    if (data.centertype === "") {
      setCentertypeError("Centertype required");
      error = true;
    } else if (data.centertype?.length > 50) {
      setCentertypeError("Centertype should be within 50 characters");
      error = true;
    }

    if (data.active?.toString() === "") {
      setActiveError("Active required");
      error = true;
    }

    if (isNaN(data.centerid)) {
      setCenteridError("Center required");
      error = true;
    }

    if (isNaN(data.buildingid)) {
      setBuildingidError("Building required");
      error = true;
    }

    if (data.floorname === "") {
      setFloornameError("Floor Name required");
      error = true;
    } else if (data.floorname?.length > 50) {
      setFloornameError("Floor Name should be within 50 digits");
      error = true;
    }

    if (data.floorcode === "") {
      setFloorcodeError("Floorcode required");
      error = true;
    } else if (data.floorcode?.length > 15) {
      setFloorcodeError("Floorcode should be within 15 digits");
      error = true;
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addFloor(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
            dispatch(fetchFloor(portfolioId));
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.id !== undefined
      ) {
        setLoading(true);
        dispatch(editFloor(data, editData.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
            dispatch(fetchFloor(portfolioId));
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      setCenterid(editData.centerid?.toString());
      setBuildingid(editData.buildingid?.toString());
      setCentertype(editData.centertype);
      setFloorname(editData.floorname);
      setFloorcode(editData.floorcode);
      setActive(
        editData.active === true
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );
    } else {
      resetStates();
    }
  }, [formType, editData]);

  useEffect(() => {
    if (pfId) {
      setportfolioId(pfId);
      dispatch(fetchBuilding(pfId));
    }
  }, [pfId]);

 

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Center Name"
            label="Center Name"
            labelClassName="required"
            value={centerid || ""}
            onChange={handleCenterid}
            placeholder="Center Name"
            errorText={centeridError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {filteredSites.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.name}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Building Name"
            label="Building Name"
            labelClassName="required"
            value={buildingid || ""}
            onChange={handleBuildingid}
            placeholder="Building Name"
            errorText={buildingidError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {filteredBuildings.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.buildingname}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Floor Name"
            label="Floor Name"
            labelClassName="required"
            value={floorname}
            onChange={handleFloorname}
            placeholder="Floor Name"
            errorText={floornameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Center Type'
                    label='Center Type'
                    labelClassName="required"
                    value={centertype}
                    onChange={handleCentertype}
                    placeholder='Center Type'
                    errorText={centertypeError}
                    containerClass="mb-2"
                    disabled={showViewForm}
                />
            </div> */}
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Floor code"
            label="Floor code"
            labelClassName="required"
            value={floorcode}
            onChange={handleFloorcode}
            placeholder="Floor code"
            errorText={floorcodeError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        {formType !== FormTypeList.ADD && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Status"
              label="Status"
              labelClassName="required"
              value={active}
              onChange={handleActive}
              placeholder="Status"
              errorText={activeError}
              containerClass="mb-2"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {ACTIVE_DROPS.map((a, i) => {
                return (
                  <Option value={a.value?.toString()} key={i}>
                    {a.text}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        )}
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default FloorForm;
