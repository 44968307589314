import { SessionType, AdminSessionType, SessionActionList, AdminSessionActionList, RentType, RentActionsList, RegionType, RegionActionsList, StateType, StateActionsList, CityType, CityActionsList } from "../actions/commonActions";
import { Action } from "../actions/types";

const initialRentValue: Array<RentType> = []
const initialRegionValue: Array<RegionType> = []
const initialStateValue: Array<StateType> = []
const initialCityValue: Array<CityType> = []
const initialSessionState: SessionType = {
    isLoggedIn: false,
};
const initialAdminSessionState: AdminSessionType = {
    isLoggedIn: false,
};

export const SessionReducer = (state=initialSessionState , action: Action) => {

    switch (action.type) {
        case SessionActionList.SET_SESSION: {
            return { ...state, isLoggedIn: true };
        }
        case SessionActionList.CLEAR_SESSION: {
            return { ...state, isLoggedIn: false };
        }
        default: return state;
    }

}

export const AdminSessionReducer = (state=initialAdminSessionState , action: Action) => {

    switch (action.type) {
        case AdminSessionActionList.SET_ADMIN_SESSION: {
            return { ...state, isLoggedIn: true };
        }
        case AdminSessionActionList.CLEAR_ADMIN_SESSION: {
            return { ...state, isLoggedIn: false };
        }
        default: return state;
    }

}

export const RentReducer = (state: RentType[] =initialRentValue , action: Action) => {

    switch (action.type) {

        case RentActionsList.FETCH_RENT: {

            return action.data

        }
        case RentActionsList.CLEAR_RENT: {
            return initialRentValue

        }

        default: return state
    }

}
export const RegionReducer = (state: RegionType[] =initialRegionValue , action: Action) => {

    switch (action.type) {

        case RegionActionsList.FETCH_REGION: {

            return action.data

        }
        case RegionActionsList.CLEAR_REGION: {
            return initialRegionValue

        }

        default: return state
    }

}
export const StateReducer = (state: StateType[] =initialStateValue , action: Action) => {

    switch (action.type) {

        case StateActionsList.FETCH_STATE: {

            return action.data

        }
        case StateActionsList.CLEAR_STATE: {
            return initialStateValue

        }

        default: return state
    }

}
export const CityReducer = (state: CityType[] =initialCityValue , action: Action) => {

    switch (action.type) {

        case CityActionsList.FETCH_CITY: {

            return action.data

        }
        case CityActionsList.CLEAR_CITY: {
            return initialCityValue

        }

        default: return state
    }

}