import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import {
  ActiveDropDown,
  ActiveTypes,
  AdditionalColumnsDropDown,
  // AdditionalColumnsTypes,
  BasedOnDropDown,
  BasedOnTypes,
  NotificationType,
  ResultBasedOnDropDown,
  ResultBasedOnTypes,
  addNotification,
  editNotification,
} from "../../redux/actions/notificationActions";
import { SiteType } from "../../redux/actions/siteActions";
import { TenantCategoryType } from "../../redux/actions/tenantActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { FormInput, MultiSelect } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { TradeCategoryType } from "../../redux/actions/tradeCategoryActions";
import { DropDownOption } from "../commonWidget";

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

const REPORT_DROPS = [
  {
    text: "Daywise sales notification",
    value: "Daywise sales notification",
  },
  {
    text: "Weekwise sales notification",
    value: "Weekwise sales notification",
  },
  {
    text: "Monthwise sales notification",
    value: "Monthwise sales notification",
  },
];

const OCCURENCE_DROPS = [
  {
    text: "Day",
    value: "Day",
  },
  {
    text: "Week",
    value: "Week",
  },
  {
    text: "Month",
    value: "Month",
  },
  {
    text: "Year",
    value: "Year",
  },
];

const DAY_DROPS = [
  {
    text: "Sunday",
    value: "Sunday",
  },
  {
    text: "Monday",
    value: "Monday",
  },
  {
    text: "Tuesday",
    value: "Tuesday",
  },
  {
    text: "Wednesday",
    value: "Wednesday",
  },
  {
    text: "Thursday",
    value: "Thursday",
  },
  {
    text: "Friday",
    value: "Friday",
  },
  {
    text: "Saturday",
    value: "Saturday",
  },
];

const MONTH_DROPS = [
  {
    text: "January",
    value: "January",
  },
  {
    text: "Febraury",
    value: "Febraury",
  },
  {
    text: "March",
    value: "March",
  },
  {
    text: "April",
    value: "April",
  },
  {
    text: "May",
    value: "May",
  },
  {
    text: "June",
    value: "June",
  },
  {
    text: "July",
    value: "July",
  },
  {
    text: "August",
    value: "August",
  },
  {
    text: "September",
    value: "September",
  },
  {
    text: "October",
    value: "October",
  },
  {
    text: "November",
    value: "November",
  },
  {
    text: "December",
    value: "December",
  },
];

const DATE_DROPS = [
  {
    text: "1",
    value: "1",
  },
  {
    text: "2",
    value: "2",
  },
  {
    text: "3",
    value: "3",
  },
  {
    text: "4",
    value: "4",
  },
  {
    text: "5",
    value: "5",
  },
  {
    text: "6",
    value: "6",
  },
  {
    text: "7",
    value: "7",
  },
  {
    text: "8",
    value: "8",
  },
  {
    text: "9",
    value: "9",
  },
  {
    text: "10",
    value: "10",
  },
  {
    text: "11",
    value: "11",
  },
  {
    text: "12",
    value: "12",
  },
  {
    text: "13",
    value: "13",
  },
  {
    text: "14",
    value: "14",
  },
  {
    text: "15",
    value: "15",
  },
  {
    text: "16",
    value: "16",
  },
  {
    text: "17",
    value: "17",
  },
  {
    text: "18",
    value: "18",
  },
  {
    text: "19",
    value: "19",
  },
  {
    text: "20",
    value: "20",
  },
  {
    text: "21",
    value: "21",
  },
  {
    text: "22",
    value: "22",
  },
  {
    text: "23",
    value: "23",
  },
  {
    text: "24",
    value: "24",
  },
  {
    text: "25",
    value: "25",
  },
  {
    text: "26",
    value: "26",
  },
  {
    text: "27",
    value: "27",
  },
  {
    text: "28",
    value: "28",
  },
  {
    text: "29",
    value: "29",
  },
  {
    text: "30",
    value: "30",
  },
  {
    text: "31",
    value: "31",
  },
];

const defaultAdditionalColumns = [
  "Receipt Count",
  "Item Qty",
  "Growth",
  "MTD",
  "Highlight Growth Variation +/- 25",
  "ContractNo",
  "DataCaptureType",
];

interface Props {
  formType: FormType;
  editData?: NotificationType;
  onSave: () => void;
  showViewForm: boolean;
}

const NotificationForm = ({
  formType,
  onSave,
  editData,
  showViewForm,
}: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const siteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  );
  const tenantCategoryList = useSelector<StoreState, Array<TenantCategoryType>>(
    (state) => state.tenant
  );
  const tradeCategoryList = useSelector<StoreState, Array<TradeCategoryType>>(
    (state) => state.tradeCategory
  );

  const [reportType, setReportType] = useState<string>("");
  const [reportTypeError, setReportTypeError] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [siteId, setSiteId] = useState<string>("");
  const [siteIdError, setSiteIdError] = useState<string>("");
  const [basedOn, setBasedOn] = useState<BasedOnTypes>(BasedOnDropDown.Invoice);
  const [basedOnError, setBasedOnError] = useState<string>("");
  const [resultBasedOn, setResultBasedOn] = useState<ResultBasedOnTypes>(
    ResultBasedOnDropDown.AllTenants
  );
  const [resultBasedOnError, setResultBasedOnError] = useState<string>("");
  const [additionalColumns, setAdditionalColumns] = useState<
    AdditionalColumnsDropDown[]
  >([AdditionalColumnsDropDown.ReceiptCount]);
  const [additionalColumnsError, setAdditionalColumnsError] =
    useState<string>("");
  const [occurence, setOccurence] = useState<string>("");
  const [occurenceError, setOccurenceError] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [monthError, setMonthError] = useState<string>("");
  const [week, setWeek] = useState<string>("");
  const [weekError, setWeekError] = useState<string>("");
  const [day, setDay] = useState<string>("");
  const [dayError, setDayError] = useState<string>("");
  const [hour, setHour] = useState<string>("");
  const [hourError, setHourError] = useState<string>("");
  const [minutes, setMinutes] = useState<string>("");
  const [minutesError, setMinutesError] = useState<string>("");
  const [action, setAction] = useState<string>("Email");
  // const [actionError, setActionError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [toList, setToList] = useState<string[]>([]);
  const [toListError, setToListError] = useState<string>("");
  const [tenantLists, setTenantLists] = useState<DropDownOption[]>([]);
  const [tenantListError, setTenantListError] = useState<string>("");
  const [tradeLists, setTradeLists] = useState<DropDownOption[]>([]);
  const [tradeListError, setTradeListError] = useState<string>("");
  const [status, setStatus] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [statusError, setStatusError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const resetStates = () => {
    setReportType("");
    setReportTypeError("");
    setName("");
    setNameError("");
    setSiteId("");
    setSiteIdError("");
    setBasedOn(BasedOnDropDown.Invoice);
    setBasedOnError("");
    setResultBasedOn(ResultBasedOnDropDown.AllTenants);
    setResultBasedOnError("");
    setAdditionalColumns([AdditionalColumnsDropDown.ReceiptCount]);
    setAdditionalColumnsError("");
    setOccurence("");
    setOccurenceError("");
    setMonth("");
    setMonthError("");
    setWeek("");
    setWeekError("");
    setDay("");
    setDayError("");
    setHour("");
    setHourError("");
    setMinutes("");
    setMinutesError("");
    setAction("Email");
    // setActionError("");
    setToList([]);
    setToListError("");
    setTenantLists(
      tenantCategoryList.map((tl) => {
        return {
          value: tl.id ? tl.id : 0,
          label: tl?.name,
          imgUrl: "",
        };
      })
    );
    setTradeLists([]);
    setTenantListError("");
    setTradeListError("");
    setStatus(ActiveDropDown.Active);
    setStatusError("");
    setLoading(false);
  };

  const handleReportType: ChangeEventHandler<HTMLInputElement> = (e) => {
    setReportType(e.target.value);
    setReportTypeError("");
  };

  const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
    setNameError("");
  };

  const handleSiteId: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSiteId(e.target.value);
    setSiteIdError("");
  };

  const handleInvoice: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBasedOn(BasedOnDropDown.Invoice);
    setBasedOnError("");
  };
  const handleInvoiceLessReturn: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBasedOn(BasedOnDropDown.InvoiceLessReturn);
    setBasedOnError("");
  };
  const handleNetSale: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBasedOn(BasedOnDropDown.NetSale);
    setBasedOnError("");
  };

  // const handleSelectedTenants: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setResultBasedOn(ResultBasedOnDropDown.SelectedTenants);
  //   setResultBasedOnError("");
  // };
  const handleAllTenants: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, checked } = e.target;
    if (name === ResultBasedOnDropDown.AllTenants && checked)
      setResultBasedOn(ResultBasedOnDropDown.AllTenants);
    else if (name === ResultBasedOnDropDown.SelectedTenants && checked)
      setResultBasedOn(ResultBasedOnDropDown.SelectedTenants);
    else if (name === ResultBasedOnDropDown.TradeCategory && checked)
      setResultBasedOn(ResultBasedOnDropDown.TradeCategory);

    setResultBasedOnError("");
  };
  // const handleTenantCategory: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setResultBasedOn(ResultBasedOnDropDown.TradeCategory);
  //   setResultBasedOnError("");
  // };

  const handleAdditionalColumns: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, checked } = e.target;
    if (checked)
      setAdditionalColumns([
        ...additionalColumns,
        name as AdditionalColumnsDropDown,
      ]);
    else {
      const updateAdditionalColumns = additionalColumns.filter(
        (ac) => ac !== name
      );
      setAdditionalColumns(updateAdditionalColumns);
    }
    if (additionalColumns?.length > 0) setAdditionalColumnsError("");
  };

  const handleOccurence: ChangeEventHandler<HTMLInputElement> = (e) => {
    setOccurence(e.target.value);
    setOccurenceError("");
  };

  const handleMonth: ChangeEventHandler<HTMLInputElement> = (e) => {
    setMonth(e.target.value);
    setMonthError("");
  };

  const handleWeek: ChangeEventHandler<HTMLInputElement> = (e) => {
    setWeek(e.target.value);
    setWeekError("");
  };

  const handleDay: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDay(e.target.value);
    setDayError("");
  };

  const handleHour: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (parseInt(e.target.value) < 0) return;

    setHour(e.target.value);
    setHourError("");
  };

  const handleMinutes: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === "-") return;
    setMinutes(e.target.value);
    setMinutesError("");
  };

  const handleEmail: ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  useEffect(() => {
    const tl =
      editData?.tenantList !== undefined && editData?.tenantList !== null
        ? editData.tenantList.split(",")
        : [];

    let tenantL = tl
      ?.map((t: any) => {
        return tenantCategoryList.filter(
          (f) => f?.id?.toString() === t?.toString()
        );
      })
      .flat(1);

    let tenantCategoryEditData = tenantL.map((t: any) => {
      return {
        value: t.id ? t.id : 0,
        label: t?.name,
        imgUrl: t.logourl,
      };
    });

    const tcl =
      editData?.tradeList !== undefined && editData?.tradeList !== null
        ? editData.tradeList.split(",")
        : [];

    let tradeL = tcl
      ?.map((t: any) => {
        return tradeCategoryList.filter(
          (f) => f?.id?.toString() === t?.toString()
        );
      })
      .flat(1);

    let tradeCategoryEditData = tradeL.map((t: any) => {
      return {
        value: t.id ? t.id : 0,
        label: t?.name,
        imgUrl: t.logourl,
      };
    });

    if (resultBasedOn === ResultBasedOnDropDown.AllTenants) {
      setTenantLists(
        tenantCategoryList.map((tl) => {
          return {
            value: tl.id ? tl.id : 0,
            label: tl?.name,
            imgUrl: "",
          };
        })
      );
      setTradeLists([]);
    } else if (
      resultBasedOn === ResultBasedOnDropDown.SelectedTenants &&
      formType === FormTypeList.EDIT
    ) {
      setTenantLists(tenantCategoryEditData);
      setTradeLists([]);
    } else if (
      resultBasedOn === ResultBasedOnDropDown.TradeCategory &&
      formType === FormTypeList.EDIT
    ) {
      setTradeLists(tradeCategoryEditData);
      setTenantLists([]);
    } else {
      setTenantLists([]);
      setTradeLists([]);
    }
  }, [resultBasedOn, tenantCategoryList]);

  const handleToList = (value: string) => {
    if (!toList.includes(value)) {
      if (value) {
        setToList((prev) => (prev ? [...prev, value] : [value]));
        setEmail("");
        setEmailError("");
      }
    } else setEmailError("Mail already exists");
    setToListError("");
  };

  const handleRemoveEmail = (value: string) => {
    setToList((prev) => {
      return prev.filter((p) => p !== value);
    });
    // setToListError('')
  };

  // const handleTenantList: ChangeEventHandler<HTMLInputElement> = (e) => {
  //     setTenantList(e.target.value)
  //     setTenantListError('')
  // }

  const handleStatus: ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatus(e.target.value as ActiveTypes);
    setStatusError("");
  };

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const tlist = tenantLists.map((t) => {
      return t.value;
    });
    const trclist = tradeLists.map((t) => {
      return t.value;
    });
    const addColums = additionalColumns.map((c) => {
      return c;
    });

    const data: NotificationType = {
      reportType: reportType,
      name: name,
      siteId: parseInt(siteId),
      basedOn: basedOn,
      resultBasedOn: resultBasedOn,
      additionalColumns: addColums?.toString(),
      occurrence: occurence,
      month: month,
      week: week,
      hour: hour,
      day: day,
      minutes: minutes,
      action: action,
      toList: toList?.toString(),
      tenantList: trclist?.length === 0 ? tlist?.toString() : "",
      tradeList: tlist?.length === 0 ? trclist?.toString() : "",
      status: status,
    };

    if (data.name === "") {
      setNameError("Notification Name required");
      error = true;
    } else if (data.name?.length > 15) {
      setNameError("Notification Name should be within 30 characters");
      error = true;
    }

    if (data.status?.toString() === "") {
      setStatusError("Status required");
      error = true;
    }

    if (data.toList === "") {
      setEmailError("Email required");
      error = true;
    }

    if (data.occurrence === "") {
      setOccurenceError("Occurence required");
      error = true;
    }

    if (isNaN(data.siteId)) {
      setSiteIdError("Center required");
      error = true;
    }
    //  else if ((data.siteId) > 15) {
    //     setSiteIdError('Site should be within 15 digits')
    //     error = true
    // }

    if (
      data.tenantList === "" &&
      (resultBasedOn === ResultBasedOnDropDown.AllTenants ||
        resultBasedOn === ResultBasedOnDropDown.SelectedTenants)
    ) {
      setTenantListError("Tenant required");
      error = true;
    }

    if (
      data.tradeList === "" &&
      resultBasedOn === ResultBasedOnDropDown.TradeCategory
    ) {
      setTradeListError("Trade required");
      error = true;
    }

    if (data.reportType === "") {
      setReportTypeError("Report Type required");
      error = true;
    } else if (data.reportType?.length > 100) {
      setReportTypeError("Report Type should be within 100 digits");
      error = true;
    }
    if (occurence === "Day") {
      if (data.hour === "") {
        setHourError("Hour required");
        error = true;
      }
      if (data.minutes === "") {
        setMinutesError("Minutes required");
        error = true;
      }
    }

    if (occurence === "Week") {
      if (data.week === "") {
        setWeekError("Week Day required");
        error = true;
      }
      if (data.hour === "") {
        setHourError("Hour required");
        error = true;
      }
      if (data.minutes === "") {
        setMinutesError("Minutes required");
        error = true;
      }
    }

    if (occurence === "Month") {
      if (data.day === "") {
        setDayError("Date required");
        error = true;
      }
      if (data.hour === "") {
        setHourError("Hour required");
        error = true;
      }
      if (data.minutes === "") {
        setMinutesError("Minutes required");
        error = true;
      }
    }
    if (occurence === "Year") {
      if (data.month === "") {
        setMonthError("Month required");
        error = true;
      }
      if (data.day === "") {
        setDayError("Date required");
        error = true;
      }
      if (data.hour === "") {
        setHourError("Hour required");
        error = true;
      }
      if (data.minutes === "") {
        setMinutesError("Minutes required");
        error = true;
      }
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addNotification(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.id !== undefined
      ) {
        setLoading(true);
        dispatch(editNotification(data, editData.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      const tl =
        editData?.tenantList !== undefined && editData?.tenantList !== null
          ? editData.tenantList.split(",")
          : [];

      let tenantL = tl
        ?.map((t: any) => {
          return tenantCategoryList.filter(
            (f) => f?.id?.toString() === t?.toString()
          );
        })
        .flat(1);

      let tenantCategoryEditData = tenantL.map((t: any) => {
        return {
          value: t.id ? t.id : 0,
          label: t?.name,
          imgUrl: t.logourl,
        };
      });

      const tcl =
        editData?.tradeList !== undefined && editData?.tradeList !== null
          ? editData.tradeList.split(",")
          : [];

      let tradeL = tcl
        ?.map((t: any) => {
          return tradeCategoryList.filter(
            (f) => f?.id?.toString() === t?.toString()
          );
        })
        .flat(1);

      let tradeCategoryEditData = tradeL.map((t: any) => {
        return {
          value: t.id ? t.id : 0,
          label: t?.name,
          imgUrl: t.logourl,
        };
      });

      const addcol =
        editData?.additionalColumns !== undefined &&
        editData?.additionalColumns !== null
          ? editData.additionalColumns.split(",")
          : [];

      setName(editData.name);
      setReportType(editData.reportType);
      setSiteId(editData.siteId?.toString());
      setBasedOn(editData.basedOn as BasedOnTypes);
      setResultBasedOn(editData.resultBasedOn as ResultBasedOnDropDown);
      setAdditionalColumns(addcol as AdditionalColumnsDropDown[]);
      setOccurence(editData.occurrence);
      setMonth(editData.month);
      setWeek(editData.week);
      setDay(editData.day);
      setHour(editData.hour);
      setMinutes(editData.minutes);
      setAction(editData.action);
      setToList(editData.toList.split(","));
      setTenantLists(tenantCategoryEditData);
      setTradeLists(tradeCategoryEditData);
      setStatus(
        editData.status === true
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );
    } else {
      resetStates();
    }
  }, [formType, editData]);

  return (
    <div className="container">
      <div className="row">
        <div className="hstack gap-3">
          <FormInput
            name="Report Type"
            label="Report Type"
            labelClassName="required"
            value={reportType}
            onChange={handleReportType}
            placeholder="Report Type"
            errorText={reportTypeError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {REPORT_DROPS.map((a, i) => {
              return (
                <Option value={a.value?.toString()} key={i}>
                  {a.text}
                </Option>
              );
            })}
          </FormInput>

          <FormInput
            name="Name"
            label="Schedule Name"
            labelClassName="required"
            value={name}
            onChange={handleName}
            placeholder="Name"
            errorText={nameError}
            containerClass="mb-2"
          />

          <FormInput
            name="Select a center"
            label="Center"
            labelClassName="required"
            value={siteId}
            onChange={handleSiteId}
            placeholder="Center"
            errorText={siteIdError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {siteList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.name}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div>
          <label htmlFor="BasedOn" className="mb-2">
            Sales based on Invoice
          </label>
          <div className="hstack gap-3">
            <FormInput
              name="BasedOn"
              label="Invoice"
              labelClassName="required"
              value={basedOn}
              onChange={handleInvoice}
              containerClass="mb-2"
              type="radio"
              checked={basedOn === BasedOnDropDown.Invoice}
            />
            <FormInput
              name="BasedOn"
              label="Invoice Less Returns"
              labelClassName="required"
              value={basedOn}
              onChange={handleInvoiceLessReturn}
              containerClass="mb-2"
              type="radio"
              checked={basedOn === BasedOnDropDown.InvoiceLessReturn}
            />
            <FormInput
              name="BasedOn"
              label="Net Sale"
              labelClassName="required"
              value={basedOn}
              onChange={handleNetSale}
              containerClass="mb-2"
              type="radio"
              checked={basedOn === BasedOnDropDown.NetSale}
            />
          </div>
        </div>
        <div>
          <label htmlFor="ResultBasedOn" className="mb-2">
            Result based on {resultBasedOn}
          </label>
          <div className="hstack gap-3">
            {[
              ResultBasedOnDropDown.AllTenants,
              ResultBasedOnDropDown.SelectedTenants,
              ResultBasedOnDropDown.TradeCategory,
            ].map((resultbon, index) => (
              <FormInput
                name={resultbon}
                label={resultbon}
                labelClassName="required"
                value={resultbon}
                onChange={handleAllTenants}
                containerClass="mb-2"
                type="radio"
                checked={resultbon === resultBasedOn}
              />
            ))}
          </div>
        </div>
        <div>
          <label htmlFor="AdditionalColumns" className="mb-2">
            Additional Column
          </label>
          <div className="hstack gap-3">
            {defaultAdditionalColumns.map((ac) => (
              <FormInput
                name={ac}
                label={ac}
                labelClassName="required"
                value={ac}
                onChange={handleAdditionalColumns}
                containerClass="mb-2"
                type="checkbox"
                checked={
                  ac === additionalColumns.filter((adc) => adc === ac)[0]
                }
              />
            ))}
          </div>
        </div>

        <div className="hstack gap-1 mt-2 mb-2">
          <FormInput
            name="Occurence"
            label="Every :"
            labelClassName="required"
            value={occurence}
            onChange={handleOccurence}
            placeholder="Day"
            errorText={occurenceError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {OCCURENCE_DROPS.map((a, i) => {
              return (
                <Option value={a.value?.toString()} key={i}>
                  {a.text}
                </Option>
              );
            })}
          </FormInput>
          {occurence === "Year" && (
            <div>
              <FormInput
                name="Month"
                label="Month"
                labelClassName="required"
                value={month}
                onChange={handleMonth}
                placeholder="Month"
                errorText={monthError}
                containerClass="mb-2"
                type="select"
              >
                <option value="">Select</option>
                {MONTH_DROPS.map((a, i) => {
                  return (
                    <Option value={a.value?.toString()} key={i}>
                      {a.text}
                    </Option>
                  );
                })}
              </FormInput>
            </div>
          )}
          {(occurence === "Month" || occurence === "Year") && (
            <div>
              <FormInput
                name="Day"
                label="Date"
                labelClassName="required"
                value={day}
                onChange={handleDay}
                placeholder="Day"
                errorText={dayError}
                containerClass="mb-2"
                type="select"
              >
                <option value="">Select</option>
                {DATE_DROPS.map((a, i) => {
                  return (
                    <Option value={a.value?.toString()} key={i}>
                      {a.text}
                    </Option>
                  );
                })}
              </FormInput>
            </div>
          )}
          {occurence === "Week" && (
            <div>
              <FormInput
                name="Week"
                label="Week Day"
                labelClassName="required"
                value={week}
                onChange={handleWeek}
                placeholder="Week Day"
                errorText={weekError}
                containerClass="mb-2"
                type="select"
              >
                <option value="">Select</option>
                {DAY_DROPS.map((a, i) => {
                  return (
                    <Option value={a.value?.toString()} key={i}>
                      {a.text}
                    </Option>
                  );
                })}
              </FormInput>
            </div>
          )}

          {/* {occurence !== "" &&
                <label htmlFor="Occurence">at</label>} */}
          {occurence !== "" && (
            <div>
              <FormInput
                name="Hour"
                label="Hour"
                labelClassName="required"
                value={hour}
                onChange={handleHour}
                errorText={hourError}
                placeholder="Hour"
                min={0}
                max={9}
                type="number"
                containerClass="mb-2"
              />
            </div>
          )}
          {/* {occurence !== "" && <label htmlFor="Occurence"> : </label>} */}
          {occurence !== "" && (
            <div>
              <FormInput
                name="Minutes"
                label="Minutes"
                labelClassName="required"
                value={minutes}
                onChange={handleMinutes}
                errorText={minutesError}
                placeholder="Minutes"
                type="number"
                min={0}
                max={9}
                containerClass="mb-2"
              />
            </div>
          )}
        </div>
        {resultBasedOn === ResultBasedOnDropDown.SelectedTenants && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            <MultiSelect
              options={tenantCategoryList.map((p) => {
                return {
                  value: p.id ? p.id : 0,
                  label: p?.name,
                };
              })}
              label="Tenant Category"
              selectedOptions={tenantLists}
              setSelectedOptions={setTenantLists}
              placeHolder="Select Tenant"
              filterText=""
              errorText={tenantListError}
              disabled={showViewForm}
            />
          </div>
        )}
        {resultBasedOn === ResultBasedOnDropDown.TradeCategory && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            <MultiSelect
              options={tradeCategoryList.map((tcl) => {
                return {
                  value: tcl.id ? tcl.id : 0,
                  label: tcl?.name,
                };
              })}
              label="Trade Category"
              selectedOptions={tradeLists}
              setSelectedOptions={setTradeLists}
              placeHolder="Select Trade"
              filterText=""
              errorText={tradeListError}
              disabled={showViewForm}
            />
          </div>
        )}

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Status"
            label="Status"
            labelClassName="required"
            value={status}
            onChange={handleStatus}
            placeholder="Status"
            errorText={statusError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {ACTIVE_DROPS.map((a, i) => {
              return (
                <Option value={a.value?.toString()} key={i}>
                  {a.text}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="col-sm-7 hstack gap-2 align-items-middle">
          <FormInput
            name="Email"
            label="Email"
            labelClassName="required"
            value={email}
            onChange={handleEmail}
            placeholder="Email"
            errorText={emailError}
            containerClass="mb-2"
            type="email"
          />
          <button
            className="btn btn-sm btn-success fw-bold fs-5"
            onClick={() => handleToList(email)}
          >
            +
          </button>
        </div>
        <div className="col-7 border ms-1">
          {toList.map((e, i) => {
            return (
              <div className="hstack gap-1 mb-1" key={i}>
                <input type="text" readOnly={true} value={e}></input>
                <button
                  className="btn btn-sm btn-primary fs-5"
                  onClick={() => handleRemoveEmail(e)}
                >
                  -
                </button>
              </div>
            );
          })}
        </div>

        <label htmlFor="" className="fw-bold mb-1">
          Output Format
        </label>
        <div className="hstack gap-2">
          {/* <label>Email</label> */}
          <FormInput
            name="Email"
            label="Email"
            labelClassName="required"
            // value={ }
            // onChange={handleMTD}
            containerClass="mb-2"
            type="checkbox"
          />
        </div>
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default NotificationForm;
