import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import { SiteType, fetchUsersSite } from "../../redux/actions/siteActions";
import { BuildingType, fetchBuilding } from "../../redux/actions/buildingActions";
import { FloorType, fetchFloor } from "../../redux/actions/floorActions";
import { ActiveDropDown, ActiveTypes, UnitType, addUnit, editUnit, fetchUnit, fetchUnitById, 
fetchUnitByCenter, fetchUnitByBuilding, fetchUnitByFloor
} from "../../redux/actions/unitActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { FormInput } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { AdminLog } from "../../config/common";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

interface Props {
  pfId: string;
  formType: FormType;
  editData?: UnitType;
  onSave: () => void;
  showViewForm: boolean;
}

const UnitForm = ({
  formType,
  onSave,
  editData,
  showViewForm,
  pfId,
}: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const siteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  );
  const buildingList = useSelector<StoreState, Array<BuildingType>>(
    (state) => state.building
  );
  const floorList = useSelector<StoreState, Array<FloorType>>(
    (state) => state.floor
  );

    const unitList = useSelector<StoreState, Array<UnitType>>(
      (state) => state.space
    );

  const [portfolioId, setPortfolioId] = useState<string>("");
  const [centerid, setCenterid] = useState<string | null>("");
  const [centeridError, setCenteridError] = useState<string>("");
  const [buildingid, setBuildingid] = useState<string | null>("");
  const [buildingidError, setBuildingidError] = useState<string>("");
  const [floorid, setFloorid] = useState<string | null>("");
  const [flooridError, setFlooridError] = useState<string>("");  
  const [unitname, setUnitname] = useState<string>("");
  const [unitnameError, setUnitnameError] = useState<string>("");
  const [unitno, setUnitno] = useState<string>("");
  const [unitnoError, setUnitnoError] = useState<string>("");
  const [active, setActive] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [activeError, setActiveError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);


  const [unitFull,setUnitFull]= useState<boolean>(false)

  const filteredSites = useMemo<SiteType[]>(() => {
    if (portfolioId) {
      return siteList.filter((s) => s.pfid?.toString() === portfolioId);
    } else return siteList;
  }, [portfolioId, siteList]);

  const filteredBuildings = useMemo<BuildingType[]>(() => {
    if (centerid) {
      const centerBuilding = buildingList.filter(
        (s) => s.centerid?.toString() === centerid
      );
      if (centerBuilding?.length === 0) setBuildingid(null);
      return centerBuilding;
    } else return buildingList;
  }, [centerid, buildingList]);

  const filteredFloors = useMemo<FloorType[]>(() => {
    // if (centerid) {
    //   const centerFloors = floorList.filter(
    //     (s) => s.centerid?.toString() === centerid
    //   );
    //   if (centerFloors?.length === 0) setFloorid(null);
    //   return centerFloors;
    // } else 
    if (buildingid) {
      const buildingFloors = floorList.filter(
        (s) => s.buildingid?.toString() === buildingid
      );
      if (buildingFloors?.length === 0) setFloorid(null);
      return buildingFloors;
    } else return floorList;
  }, [buildingid, floorList]);

  const resetStates = () => {    
    setPortfolioId("");
    setCenterid("");
    setCenteridError("");
    setBuildingid("");
    setBuildingidError("");
    setFloorid("");
    setFlooridError("");
    setUnitname("");
    setUnitnameError("");
    setUnitno("");
    setUnitnoError("");
    setActive(ActiveDropDown.Active);
    setActiveError("");
    setLoading(false);
    setUnitFull(false)
  };
  const handleCenterid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCenterid(e.target.value);
    setCenteridError("");
  };
  const handleBuildingid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBuildingid(e.target.value);
    setBuildingidError("");
  };
  const handleFloorid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFloorid(e.target.value);
    setFlooridError("");
  };
  const handleUnitname: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUnitname(e.target.value);
    setUnitnameError("");
  };
  const handleUnitno: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUnitno(e.target.value);
    setUnitnoError("");
  };
  const handleActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setActive(e.target.value as ActiveTypes);
    setActiveError("");
  };
  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const data: UnitType = {
      portfolioid: portfolioId,
      centerid: parseInt(centerid || ""),
      buildingid: parseInt(buildingid || ""),
      floorid: parseInt(floorid || ""),
      unitname: unitname,
      unitno: unitno,
      active: active,
    };
    if (data.portfolioid === "") {
      toast("Portfolio required", ToastTypes.ERROR);
      error = true;
    }
    if (isNaN(data.centerid)) {
      setCenteridError("Center required");
      error = true;
    }
    if (isNaN(data.buildingid)) {
      setBuildingidError("Building required");
      error = true;
    }
    if (isNaN(data.floorid)) {
      setFlooridError("Floor required");
      error = true;
    }
    if (data.unitname === "") {
      setUnitnameError("Unitname required");
      error = true;
    }else if (data.unitname?.length > 50) {
      setUnitnameError("Unit Name should be within 50 digits");
      error = true;
    }
    if (data.unitno === "") {
      setUnitnoError("Unitno required");
      error = true;
    }else if (data.unitno?.length > 10) {
      setUnitnameError("Unit No should be within 10 digits");
      error = true;
    }
    if (data.active?.toString() === "") {
      setActiveError("Active required");
      error = true;
    }
    if(formType===FormTypeList.ADD){
      if(unitFull===true){
        toast("All units for this center have already been added", ToastTypes.ERROR);
        error=true
      }
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addUnit(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
            dispatch(fetchUnit(portfolioId));
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.id !== undefined
      ) {
        setLoading(true);
        dispatch(editUnit(data, editData.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            onSave();
            dispatch(fetchUnit(portfolioId));
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      setCenterid(editData.centerid?.toString());
      setBuildingid(editData.buildingid?.toString());
      setFloorid(editData.floorid?.toString());
      setUnitname(editData.unitname?.toString());
      setUnitno(editData.unitno?.toString());
      setActive(
        editData.active == 1
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );
    } else {
      resetStates();
    }
  }, [formType, editData]);

  useEffect(() => {
    if (pfId) {
      setPortfolioId(pfId);
      dispatch(fetchUsersSite());
      dispatch(fetchBuilding(pfId));
      dispatch(fetchFloor(pfId))
    }
  }, [pfId]); 

   const fetchRequiredData = async(portfolio:string) =>{
      await dispatch(fetchUnit(portfolio))
      // await dispatch(fetchUsersSite());
      // await dispatch(fetchBuilding(portfolio));
      // await dispatch(fetchFloor(portfolio))
    }


    useEffect(()=>{
      if(portfolioId){
      fetchRequiredData(portfolioId)
      }
    },[portfolioId])


  useEffect(()=>{
    if(unitList?.length>0&&portfolioId&&centerid&&siteList){
        const filteredUnitList = unitList.filter((a)=>a?.centerid?.toString()===centerid?.toString())
        const filteredSiteList = siteList.filter((a)=>a?.id?.toString()===centerid?.toString())

        if(filteredSiteList[0].unitcount!==undefined&&filteredSiteList[0].unitcount?.toString()!==''&&filteredSiteList[0].unitcount?.toString()!=='NaN'){
          if(filteredSiteList[0].unitcount?.toString()===filteredUnitList.length?.toString()){
              setUnitFull(true)
          }else{
            setUnitFull(false)
          }
        } 
  }
  },[unitList,portfolioId,centerid,siteList])

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Center Name"
            label="Center Name"
            labelClassName="required"
            value={centerid || ""}
            onChange={handleCenterid}
            placeholder="Center Name"
            errorText={centeridError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {filteredSites.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.name}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Building Name"
            label="Building Name"
            labelClassName="required"
            value={buildingid || ""}
            onChange={handleBuildingid}
            placeholder="Building Name"
            errorText={buildingidError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {filteredBuildings.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.buildingname}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Floor Name"
            label="Floor Name"
            labelClassName="required"
            value={floorid || ""}
            onChange={handleFloorid}
            placeholder="Floor Name"
            errorText={flooridError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {filteredFloors.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.floorname}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Unit Name"
            label="Unit Name"
            labelClassName="required"
            value={unitname}
            onChange={handleUnitname}
            placeholder="Unit Name"
            errorText={unitnameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Unit No"
            label="Unit No"
            labelClassName="required"
            value={unitno}
            onChange={handleUnitno}
            placeholder="Unit No"
            errorText={unitnoError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        {formType !== FormTypeList.ADD && (
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Status"
              label="Status"
              labelClassName="required"
              value={active}
              onChange={handleActive}
              placeholder="Status"
              errorText={activeError}
              containerClass="mb-2"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {ACTIVE_DROPS.map((a, i) => {
                return (
                  <Option value={a.value?.toString()} key={i}>
                    {a.text}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        )}
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default UnitForm;
