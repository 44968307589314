import { UsersType, UsersActionsList } from "../actions/usersActions";
import { Action } from "../actions/types";

const initialValue: Array<UsersType> = []

export const UsersReducer = (state: UsersType[] =initialValue , action: Action) => {

    switch (action.type) {

        case UsersActionsList.ADD_USERS: {

            return [...state, action.data]
        }

        case UsersActionsList.EDIT_USERS: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => u.uid === action.data.uid)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case UsersActionsList.FETCH_USERS: {

            return action.data

        }

        case UsersActionsList.DELETE_USERS: {
            return state.filter(i => i.uid !== action.data.id)

        }
        case UsersActionsList.CLEAR_USERS: {
            return initialValue

        }
        default: return state
    }

}