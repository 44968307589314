import { nanoid } from "@reduxjs/toolkit";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import SiteForm from "../../components/admin/SiteForm";
import  {
  Tbody,
  Thead,
} from "../../components/custom/components/AdminTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import {
  SiteType,
  deleteSite,
  fetchSite,
} from "../../redux/actions/siteActions";
import { AppDispatch, StoreState } from "../../redux/store";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
import api, { getAxiosRequestConfig } from "../../config/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { CountryType } from "../../redux/actions/countryActions";
import { AdminLog} from "../../config/common"
import { AuthAdminType } from "../../redux/actions/authAdminActions";

type HandleDelete = (value: SiteType) => void;

const Site = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const authAdminToken = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  )?.["auth-token"]!;

  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );
  const SiteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  );

  const countryList = useSelector<StoreState, Array<CountryType>>(
    (state) => state.country
  );

  const [showForm, setShowForm] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [editData, setEditData] = useState<SiteType>();
  const [disableDelete, setDisableDelete] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<SiteType>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [id, setId] = useState<number>();
  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const searchSiteHandler = (site: SiteType, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(site.name)
      : true || [];
  };

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery({ search: e.target.value });
  };

  const [siteData, setSiteData] = useState<SiteType[]>([]);

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    if (SiteList?.length > 0) {
      setLength(SiteList?.length ? SiteList?.length : 0);
    }
  }, [SiteList]);

  const handleApply = async (offsetVal = offset) => {
    if (SiteList?.length > 0) {
      const getData = {
        limit: limit?.toString(),
        offset: offsetVal?.toString(),
      };

      if (
        getData !== undefined &&
        getData.limit !== "" &&
        getData.offset !== "" &&
        getData.offset !== undefined &&
        getData.offset !== "NaN"
      ) {
        const filteredSiteList: SiteType[] = SiteList?.slice(
          offset,
          limit + offset
        );
        if (filteredSiteList?.length > 0) {
          setSiteData([...filteredSiteList]);
        } else {
          setSiteData([]);
        }
      }
    }
  };

  useEffect(() => {
    handleApply();
  }, [SiteList]);

  useEffect(()=>{
     navigate("/admin/center_management");
  },[])

  const getTableData = () => {
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Name", children: "Name", text: "Name" },
      { id: "Code", children: "Code", text: "Code" },
      { id: "Website URL", children: "Website URL", text: "Website URL" },
      { id: "Portfolio", children: "Portfolio", text: "Portfolio" },
      { id: "Center Type", children: "Center Type", text: "Center Type" },
      // { id: 'Site Type', children: 'Site Type', text: 'Site Type' },
      { id: "GLA", children: "GLA", text: "GLA" },
      { id: "UOM", children: "UOM", text: "UOM" },
      { id: "Sales By", children: "Sales By", text: "Sales By" },

      { id: "Country", children: "Country", text: "Country" },
      { id: "Region", children: "Region", text: "Region" },
      { id: "State", children: "State", text: "State" },
      { id: "City", children: "City", text: "City" },
      { id: "Street", children: "Street", text: "Street" },

      { id: "Pincode", children: "Pincode", text: "Pincode" },
      { id: "Logo Path", children: "Logo Path", text: "Logo Path" },
      { id: "Logo URL", children: "Logo URL", text: "Logo URL" },
      { id: "Status", children: "Status", text: "Status" },
      { id: "Live From", children: "Live From", text: "Live From" },

      { id: "Action", children: "Action", text: "Action" },
    ];

    var tableBody: Tbody[][] = [];

    siteData
      .filter((cr) => searchSiteHandler(cr, searchTerm))
      .forEach((item, i) => {
        let index = portfolioList.findIndex((c, i) => {
          return c.id === item.pfid;
        });
        var pname = portfolioList[index]?.name;
        let cindex = countryList.findIndex(
          (a) => a.id?.toString() === item.country?.toString()
        );
        var cname = countryList[cindex]?.countryname;
        const pageNumber = Math.floor(offset / limit) + 1;

        var tBody: Tbody[] = [];
        tBody.push(
          {
            id: nanoid(),
            theadId: "S.No",
            children: limit * pageNumber + i - limit + 1,
            text: (limit * pageNumber + i - limit + 1)?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Name",
            children: item.name,
            text: item.name,
          },
          {
            id: nanoid(),
            theadId: "Code",
            children: item.code,
            text: item.code,
          },
          {
            id: nanoid(),
            theadId: "Website URL",
            children: item.url,
            text: item.url,
          },
          {
            id: nanoid(),
            theadId: "Portfolio",
            children: pname,
            text: pname,
          },
          {
            id: nanoid(),
            theadId: "Center Type",
            children: item.centertype,
            text: item.centertype,
          },
          {
            id: nanoid(),
            theadId: "GLA",
            children: item.gla,
            text: item.gla?.toString(),
          },
          {
            id: nanoid(),
            theadId: "UOM",
            children: item.uom,
            text: item.uom,
          },
          {
            id: nanoid(),
            theadId: "Sales By",
            children: item.salesby,
            text: item.salesby,
          },
          {
            id: nanoid(),
            theadId: "Country",
            children: cname,
            text: cname,
          },
          {
            id: nanoid(),
            theadId: "Region",
            children: item?.siteRegion?.regionname,
            text: item?.siteRegion?.regionname,
          },
          {
            id: nanoid(),
            theadId: "State",
            children: item?.siteState?.statename,
            text: item?.siteState?.statename,
          },
          {
            id: nanoid(),
            theadId: "City",
            children: item?.siteCity?.cityname,
            text: item?.siteCity?.cityname,
          },
          {
            id: nanoid(),
            theadId: "Street",
            children: item.street,
            text: item.street,
          },
          {
            id: nanoid(),
            theadId: "Pincode",
            children: item.pincode,
            text: item.pincode,
          },
          {
            id: nanoid(),
            theadId: "Logo Path",
            children: item.logopath,
            text: item.logopath?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Logo URL",
            children: item.logourl,
            text: item.logourl,
          },
          {
            id: nanoid(),
            theadId: "Status",
            children: item.active ? "Active" : !item.active ? "InActive" : "",
            text: item.active ? "Active" : !item.active ? "InActive" : "",
          },
          {
            id: nanoid(),
            theadId: "Live From",
            children: item.livefrom,
            text: item.livefrom,
          },

          {
            id: nanoid(),
            theadId: "Action",
            children: "action",
            className: "d-none",
            text: item.id?.toString(),
          }
        );
        tableBody.push(tBody);
        // })
      });
    return { tbody: tableBody, thead: tableHead };
  };
  const handleAdd = () => {
    setShowForm(true);
    if(adminAuthendicated){
      const logProps1 = {
        action: 'Create',
        event: `${pageName.pathname.replace("\\", " ")} page | Create Center Clicked`
      };
      AdminLog(logProps1)
    }
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = SiteList.find((c) => c.id === id);
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page | ${obj?.name} Center Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = SiteList.find((c) => c.id === id);
      setEditData(obj);
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | Center - ${obj?.name} | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDeleteConfirmation = async () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = SiteList.find((c) => c.id?.toString() === id?.toString());

      setShowDeleteConfirmation(true);
      const token = authAdminToken;
      const config = getAxiosRequestConfig(token);
      const {
        data: { data },
      } = await api.get(`building/search/${obj?.pfid}/${obj?.id}`, config);
      if (data) {
      }
      setDeleteData(obj);
      setDisableDelete(data && data?.length > 0 ? true : false);
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (value.id !== undefined) {
      dispatch(deleteSite(value.id, value.pfid?.toString()))
        .then(async (text) => {
          dispatch(fetchSite());
          toast(text, ToastTypes.SUCCESS);
          var obj = SiteList.find((c) => c.id === id);
          if(adminAuthendicated){
            const logProps1 = {
              action: 'Delete',
              event: `${pageName.pathname.replace("\\", " ")} page | Company - ${obj?.name} | Delete Clicked`
            };
            AdminLog(logProps1)
          }
          if (siteData !== undefined && siteData?.length === 1) {
            let newOffset = offset - limit < 0 ? 0 : offset - limit;
            setOffset(newOffset);
            await handleApply(newOffset);
          } else {
            await handleApply(); //issue starts from here
          }
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
    setDeleteData(undefined);
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, formType]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " CENTER"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            disableDelete
              ? "Center is in use. Deletion not allowed."
              : "Do you really want to delete the records with the center - " +
                deleteData?.name
          }
          onCancel={handleFormClose}
          onDelete={() => (disableDelete ? {} : handleDelete(deleteData!))}
        />
      </Modal>
      <Modal
        headerText={formType + " CENTER"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <SiteForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
        />
      </Modal>
      <AdminLayout>
        {/* <PageTitle
                title='Site Management'
                buttonText='Create New Site'
                onButtonClick={handleAdd}
            /> */}
        <AdminTable2
          addBtnText="Create New Center"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          search={searchHandler}
          limit={limit}
          setOffset={setOffset}
          offset={offset}
          onPageNoChange={handleApply}
          range={range}
          pageTitle="Center Management"
          path="/admin/center_management"
          // data={data}
          setId={setId}
          {...getTableData()}
        />
        {/* <div className="card mt-3">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-lg-6 col-sm-12">
                            <h5 className="header-title mb-3 float-start"><i className="fe-align-justify me-1 text-primary"></i>Site Management</h5>
                        </div>
                        <div className="col-lg-6 col-sm-12 text-end mb-1">
                            <button type="button" onClick={handleAdd} className={`me-1 btn btn-xs btn-secondary`}>Create New Site</button>
                        </div>
                    </div>
                    <hr className='hr mt-0' />
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle text-center'>
                                        Name
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Code
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Website URL
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Portfolio Name
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Site Type
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        GLA
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        UOM
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Sales By
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Street
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        City
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        State
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Country
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Pincode
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Logo Path
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Logo URL
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Status
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Live From
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Region
                                    </th>
                                    <th className='text-truncate align-middle text-center'>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {SiteList.map((u, i) => {
                                    let index = portfolioList.findIndex((c, i) => {
                                        return c.id === u.pfid
                                    })
                                    var pname = portfolioList[index]?.name
                                    return <SiteBody
                                        sno={i + 1}
                                        name={u.name}
                                        code={u.code}
                                        url={u.url}
                                        pfid={u.pfid}
                                        portfolioName={pname}
                                        sitetype={u.sitetype}
                                        gla={u.gla}
                                        uom={u.uom}
                                        salesby={u.salesby}
                                        street={u.street}
                                        city={u.city}
                                        state={u.state}
                                        country={u.country}
                                        pincode={u.pincode}
                                        logopath={u.logopath}
                                        logourl={u.logourl}
                                        active={u.active}
                                        livefrom={u.livefrom}
                                        region={u.region}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                        onView={() => handleView(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
      </AdminLayout>
    </>
  );
};

// const SiteBody = ({ sno, name, code, url, pfid, sitetype, gla, uom, salesby, street, city, state, country, pincode, logopath, logourl, active, livefrom, region, onEdit, onDelete, onView, portfolioName }: TableBody) => {
//     return <tr>
//         <td className='align-middle text-center'>{sno}</td>
//         <td className='align-middle'>{name}</td>
//         <td className='align-middle'>{code}</td>
//         <td className='align-middle'>{url}</td>
//         <td className='align-middle text-center'>{portfolioName}</td>
//         <td className='align-middle'>{sitetype}</td>
//         <td className='align-middle '>{gla}</td>
//         <td className='align-middle'>{uom}</td>
//         <td className='align-middle'>{salesby}</td>
//         <td className='align-middle'>{street}</td>
//         <td className='align-middle'>{city}</td>
//         <td className='align-middle'>{state}</td>
//         <td className='align-middle'>{country}</td>
//         <td className='align-middle'>{pincode}</td>
//         <td className='align-middle'>{logopath}</td>
//         <td className='align-middle'>{logourl}</td>
//         <td className='align-middle'>{active ? "Active" : "InActive"}</td>
//         <td className='align-middle'>{livefrom}</td>
//         <td className='align-middle'>{region}</td>
//         <td>
//             <div className='hstack gap-1'>
//                 <button className='btn btn-sm btn-secondary' onClick={onEdit}>Edit</button>
//                 <button className='btn btn-sm btn-secondary' onClick={onDelete}>Delete</button>
//                 <button className='btn btn-sm btn-secondary' onClick={onView}>View</button>
//             </div>
//         </td>
//     </tr>
// }

export default Site;
