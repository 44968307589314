import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface BrandType {
    id?: number
    name: string
    url: string
    logopath: string
    logourl: string
    companyid: number
    active: ActiveTypes | boolean
    createdBy?: number | null,
    createdAt?: string,
    updatedAt?: string
    updatedBy?: string
}

export enum BrandActionsList {
    ADD_BRAND = 'ADD_BRAND',
    EDIT_BRAND = 'EDIT_BRAND',
    FETCH_BRAND = 'FETCH_BRAND',
    DELETE_BRAND = 'DELETE_BRAND',
    CLEAR_BRAND = 'CLEAR_BRAND'
}

export interface DeleteBrand {
    id: number
}

export interface AddBrandAction {
    type: BrandActionsList.ADD_BRAND
    data: BrandType
}

export interface EditBrandAction {
    type: BrandActionsList.EDIT_BRAND
    data: BrandType
    id?: number
}

export interface FetchBrandAction {
    type: BrandActionsList.FETCH_BRAND
    data: BrandType[]

}

export interface ClearBrandAction {
    type: BrandActionsList.CLEAR_BRAND
    // data: BrandType[]

}

export interface DeleteBrandAction {
    type: BrandActionsList.DELETE_BRAND
    data: DeleteBrand
}

export type BrandActions = AddBrandAction | EditBrandAction | FetchBrandAction | DeleteBrandAction | ClearBrandAction

export const addBrand = (data: BrandType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        return api.post<APIResponse<BrandType>>('brand/', data, config).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<AddBrandAction>({
                    type: BrandActionsList.ADD_BRAND,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Brand added')
            } else {
                // throw { response }
                throw new Error("Unable to create")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to create'
                : 'Unable to create')
        })
    }
}


export const editBrand = (data: BrandType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<BrandType>>(`brand/${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditBrandAction>({
                    type: BrandActionsList.EDIT_BRAND,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Brand Updated')
            } else {
                throw new Error("Unable to update")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}
export const clearBrand = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return dispatch<ClearBrandAction>({
            type: BrandActionsList.CLEAR_BRAND

        })
    }
}
export const fetchBrand = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<BrandType[]>>('brand/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchBrandAction>({
                    type: BrandActionsList.FETCH_BRAND,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}
export const deleteBrand = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authAdmin?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<BrandType>>(`brand/${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteBrandAction>({
                    type: BrandActionsList.DELETE_BRAND,
                    data: { id }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Brand Deleted')
            } else {
                throw new Error("Unable to delete")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}