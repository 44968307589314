import React, {
  ChangeEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormType, FormTypeList } from "../../config";
import {
  addOutlet,
  deleteOutlet,
  editOutlet,
  outletType,
} from "../../redux/actions/outletActions";
import { useDispatch, useSelector } from "react-redux";
import { BrandCompanyType } from "../../redux/actions/brandCompanyActions";
import { BrandType } from "../../redux/actions/brandActions";
import {
  ActiveTypes,
  PortfolioType,
} from "../../redux/actions/portfolioActions";
import { AppDispatch, StoreState } from "../../redux/store";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { FormInput } from "../custom/components";
import Option from "../Option";
import { CountryType } from "../../redux/actions/countryActions";
import { testNumber, testCode, getYesterday, getRevenueMaxDate, checkDateRangeOverlap, dateranges } from "../../config/functions";
import { BrandCompanyMappingType } from "../../redux/actions/brandCompanyMappingActions";
import AdminTable, { Tbody, Thead } from "../custom/components/AdminTable";
import { nanoid } from "@reduxjs/toolkit";
import Modal from "../Modal";
import { ActiveDropDown } from "../../redux/actions/floorActions";
import DeleteConfirmationForm from "./DeleteConfirmationForm";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  RegionType,
  StateType,
  CityType,
  fetchRegion,
  fetchState,
  fetchCity,
  clearRegion,
  clearState,
  clearCity,
  RentType,
  fetchRent
} from "../../redux/actions/commonActions";
import AdminTable2 from "../custom/components/AdminTable2";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

type HandleDelete = (value: outletType) => void;

interface Props {
  formType: FormType;
  editData?: BrandCompanyMappingType;
  showViewForm: boolean;
}

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

const SHOP_TYPE = [
  {
    text: "Mall",
    value: 1,
  },
  {
    text: "High Street",
    value: 2,
  },
];
const LOCATION_TYPE = [
  {
    text: "Metro City",
    value: 1,
  },
  {
    text: "Non-Metro City",
    value: 2,
  },
];

export interface outletDataType {
  record: outletType[];
  count?: number;
}

const OutletForm = ({ formType, editData, showViewForm }: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const authAdminToken = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  )?.["auth-token"]!;
  const config = getAxiosRequestConfig(authAdminToken);
  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );

  const brandcompanyList = useSelector<StoreState, Array<BrandCompanyType>>(
    (state) => state.brandCompany
  );

  const brandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state.brand
  );

  const countryList = useSelector<StoreState, Array<CountryType>>(
    (state) => state.country
  );
  const regionList = useSelector<StoreState, Array<RegionType>>(
    (state) => state.region
  );
  const StateList = useSelector<StoreState, Array<StateType>>(
    (state) => state.state
  );
  const CityList = useSelector<StoreState, Array<CityType>>(
    (state) => state.city
  );

  const outletList = useSelector<StoreState, Array<any>>(
    (state) => state.outlet
  );
  const brandMapList = useSelector<StoreState, Array<BrandCompanyMappingType>>(
    (state) => state.brandCompanyMapping
  );

  const [portfolioid, setportfolioid] = useState<string>("");
  const [portfolioidError, setportfolioidError] = useState<string>("");
  const [brandid, setbrandid] = useState<string>("");
  const [brandidError, setbrandidError] = useState<string>("");
  const [countryid, setcountryid] = useState<string>("");
  const [countryiderror, setcountryidError] = useState<string>("");
  const [regionid, setregionid] = useState<string>("");
  const [regionidError, setregionidError] = useState<string>("");
  const [stateid, setstateid] = useState<string>("");
  const [stateidError, setstateidError] = useState<string>("");
  const [cityid, setcityid] = useState<string>("");
  const [cityyidError, setcityidError] = useState<string>("");
  const [outlettype, setOutlettype] = useState<string>("");
  const [outletTypeError, setOutletTypeError] = useState<string>("");
  const [locationType, setLocationType] = useState<string>("");
  const [locationTypeError, setLocationTypeError] = useState<string>("");

  const [chargeableArea, setChargeableArea] = useState<string>("");
  const [chargeableAreaError, setChargeableAreaError] = useState<string>("");
  const [carpetArea, setCarpetArea] = useState<string>("");
  const [carpetAreaError, setCarpetAreaError] = useState<string>("");

  const [area, setArea] = useState<string>("");
  const [areaError, setareaError] = useState<string>("");
  const [address1, setaddress1] = useState<string>("");
  const [addressError1, setaddressError1] = useState<string>("");
  const [address2, setaddress2] = useState<string>("");
  const [addressError2, setaddressError2] = useState<string>("");
  const [pincode, setPincode] = useState<string>("");
  const [pincodeError, setPincodeError] = useState<string>("");
  const [outletcode, setoutletcode] = useState<string>("");
  const [outletcodeError, setoutletcodeError] = useState<string>("");
  const [franchise, setFranchise] = useState<boolean>(false);
  const [franchiseError, setFranchiseError] = useState<string>("");
  const [active, setActive] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeError, setActiveError] = useState<string>("");

  const [tradecatid, setTradecatid] = useState<string>("");
  const [tradesubcatid, setTradesubcatid] = useState<string>("");
  const [brandcompanyid, setbrandcompanyid] = useState<string>("");

  const [leaseid, setLeaseid] = useState<string>("");
  const [leaseidError, setLeaseidError] = useState<string>("");

  const [outletData, setOutletData] = useState<outletDataType[]>([]);

  // const [clickCount, setClickCount] = useState<number>(0);

  // const formContainerRef = useRef<HTMLDivElement>(null);

  //search params
  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  //outlet add state data

  const [updateButton, setUpdateButton] = useState<boolean>(false);

  //Outlet Form State
  const [formOutletType, setOutletFormType] = useState<FormType>();
  const [newOutlet, setNewOutlet] = useState<outletType[]>([]);
  const [updateOutlet, setUpdateOutlet] = useState<outletType>();
  const [deleteData, setDeleteData] = useState<outletType>();
  const [disableDelete, setDisableDelete] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [id, setId] = useState<number>();

  const [outletRegionData, setOutletRegionData] = useState<RegionType[]>([]);
  const [outletStateData, setOutletStateData] = useState<StateType[]>([]);
  const [outletCityData, setOutletCityData] = useState<CityType[]>([]);
  //pagination-stuff

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  
  const [rentType, setRentType] = useState<string>("");
  const [rentTypeError, setRentTypeError] = useState<string>("");
  const [maxFromDate, setMaxFromDate] = useState(getYesterday());
  const [fromDate, setFromDate] = useState<string>("");
  const [fromDateError, setFromDateError] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [toDateError, setToDateError] = useState<string>("");
  const [revenueShare, setRevenueShare] = useState<string>("");
  const [revenueShareError, setRevenueShareError] = useState<string>("");
  const [disableUpdate, setDisableUpdate] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchRent())
  }, []);
  const rentList = useSelector<StoreState, Array<RentType>>(
    (state) => state.rent
  );
  useEffect(() => {
    if (outletData?.length > 0) {
      setLength(outletData[0]?.count ? outletData[0]?.count : 0);
    }
  }, [outletData]);

  useEffect(() => {
    if (regionList?.length > 0) {
      regionList.map((a, i) => {
        setOutletRegionData([...outletRegionData, a]);
      });
    }
    if (StateList?.length > 0) {
      StateList.map((a, i) => {
        setOutletStateData([...outletStateData, a]);
      });
    }
    if (CityList?.length > 0) {
      CityList.map((a, i) => {
        setOutletCityData([...outletCityData, a]);
      });
    }
  }, [regionList, StateList, CityList]);

  useEffect(() => {
    const d = new Date(toDate);
    d.setDate(d.getDate() - 1);
    const date = d.getDate()?.toString().padStart(2, "0");
    const month = (d.getMonth() + 1)?.toString().padStart(2, "0");
    const year = d.getFullYear();
    setMaxFromDate([year, month, date].join("-"));
  }, [toDate]);

  const portfolioFilteredByMapping = useMemo(() => {
    if (brandMapList !== undefined && portfolioList !== undefined) {
      const uniquePortfolioObjects = Array.from(
        new Set(
          brandMapList
            .map((obj) => {
              const matchingPortfolio = portfolioList.find(
                (portfolio) => portfolio.id === obj.pfid
              );
              return matchingPortfolio;
            })
            .filter(Boolean)
        )
      );

      return uniquePortfolioObjects;
    } else {
      return [];
    }
  }, [portfolioList, brandMapList]);

  const brandFilteredByPortfolio = useMemo<BrandType[]>(() => {
    if (portfolioid !== "") {
      const brands = brandMapList.filter(
        (a) => a.pfid?.toString() === portfolioid?.toString()
      );
      const filterList = brands
        .map((a) => {
          return brandList.filter(
            (b) => b.id?.toString() === a.brandid?.toString()
          );
        })
        .flat(1);
      return filterList;
    } else return [];
  }, [portfolioid]);

  const filteredOutletList = useMemo<outletType[]>(() => {
    if (formType === FormTypeList.ADD && editData === undefined) {
      return newOutlet;
    } else if (
      formType === FormTypeList.EDIT &&
      editData !== undefined &&
      outletData?.length > 0 &&
      outletData[0].record !== undefined
    ) {
      return outletData[0].record;
    } else {
      return [];
    }
  }, [newOutlet, formType, outletData]);

  /*if (editData?.id !== undefined && formType === FormTypeList.EDIT) {
    const filteredOutletList = outletList[0].filter(
      (a:any) =>
        a.portfolioid === editData?.pfid &&
        a.brandcompanyid === editData.companyid
    );
  } else if (editData === undefined && formType === FormTypeList.ADD) {
    const filteredOutletList = newOutlet;
  }*/

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery({ search: e.target.value });
  };

  const getTableData = () => {
    if (outletData === undefined) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Outlet Code", children: "Outlet Code", text: "Outlet Code" },
      { id: "Country", children: "Country", text: "Country" },
      { id: "Region", children: "Region", text: "Region" },
      { id: "State", children: "State", text: "State" },
      { id: "City", children: "City", text: "City" },
      { id: "Area/Locality", children: "Area/Locality", text: "Area/Locality" },
      { id: "Status", children: "Status", text: "Status" },
    ];
    if (formType !== FormTypeList.ADD) {
      tableHead.push({ id: "Action", children: "Action", text: "Action" });
    }

    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];
    filteredOutletList.forEach((item, i) => {
      let cindex = countryList.findIndex((a) => a.id === item.countryid);
      var cname = countryList[cindex].countryname;
      if (formType !== FormTypeList.ADD) {
        let sindex = outletList[2]
          ? outletList[2].findIndex((a: any) => a.id === item.stateid)
          : undefined;
        let rindex = outletList[1]
          ? outletList[1].findIndex((a: any) => a.id === item.regionid)
          : undefined;
        let cyindex = outletList[3]
          ? outletList[3].findIndex((a: any) => a.id === item.cityid)
          : undefined;
        var sname = sindex !== undefined ? outletList[2][sindex]?.statename : "";
        var rname =
          rindex !== undefined ? outletList[1][rindex]?.regionname : "";
        var cyname =
          cyindex !== undefined ? outletList[3][cyindex]?.cityname : "";
      } else {
        let sindex = outletStateData.findIndex((a) => a.id === item.stateid);
        let rindex = outletRegionData.findIndex((a) => a.id === item.regionid);
        let cyindex = outletCityData.findIndex((a) => a.id === item.cityid);
        // let sindex = StateList.findIndex((a) => a.id === item.stateid);
        // let rindex = regionList.findIndex((a) => a.id === item.regionid);
        // let cyindex = CityList.findIndex((a) => a.id === item.cityid);
        var sname: any =
          sindex !== undefined ? outletStateData[sindex]?.statename : "";
        var rname: any =
          rindex !== undefined ? outletRegionData[rindex]?.regionname : "";
        var cyname: any =
          cyindex !== undefined ? outletCityData[cyindex]?.cityname : "";
      }

      const pageNumber = Math.floor(offset / limit) + 1;

      var tBody: Tbody[] = [];
      tBody.push(
        {
          id: nanoid(),
          theadId: "S.No",
          children: limit * pageNumber + i - limit + 1,
          text: (limit * pageNumber + i - limit + 1)?.toString(),
        },
        {
          id: nanoid(),
          theadId: "Outlet Code",
          children: item.outletcode,
          text: item.outletcode,
        },
        {
          id: nanoid(),
          theadId: "Country",
          children: cname,
          text: cname,
        },
        {
          id: nanoid(),
          theadId: "Region",
          children: rname,
          text: rname,
        },
        {
          id: nanoid(),
          theadId: "State",
          children: sname,
          text: sname,
        },
        {
          id: nanoid(),
          theadId: "City",
          children: cyname,
          text: cyname,
        },
        {
          id: nanoid(),
          theadId: "Area/Locality",
          children: item.area,
          text: item.area,
        },
        {
          id: nanoid(),
          theadId: "Status",
          children: item.active ? "Active" : !item.active ? "InActive" : "",
          text: item.active ? "Active" : !item.active ? "InActive" : "",
        }
      );
      if (formType !== FormTypeList.ADD) {
        tBody.push({
          id: nanoid(),
          theadId: "Action",
          children: "action",
          className: "d-none",
          text: item.id?.toString(),
        });
      }
      tableBody.push(tBody);
      // })
    });
    if (searchTerm?.length > 0) {
      tableBodyFiltered = tableBody.filter((item) =>
        searchCredetialHandler(item, searchTerm)
      );
    } else {
      tableBodyFiltered = tableBody;
    }

    if (outletData !== undefined) {
      return { tbody: tableBodyFiltered, thead: tableHead };
    }
  };

  const resetStates = () => {
    setportfolioidError("");
    setbrandidError("");
    setcountryidError("");
    setregionidError("");
    setstateidError("");
    setcityidError("");
    setArea("");
    setareaError("");
    setaddress1("");
    setaddressError1("");
    setaddress2("");
    setaddressError2("");
    setPincode("");
    setPincodeError("");
    setoutletcode("");
    setoutletcodeError("");
    setLeaseid("");
    setLeaseidError("");
    setCarpetArea("");
    setCarpetAreaError("");
    setChargeableAreaError("");
    setOutlettype("");
    setOutletTypeError("");
    setLocationType("");
    setLocationTypeError("");
    setChargeableArea("");
    setFranchise(false);
    setLoading(false);
    setSearchQuery("");
    setFromDate("");
    setFromDateError("");
    setToDate("");
    setToDateError("");
    setRevenueShare("");
    setRevenueShareError("");
    setRentType("");
    setRentTypeError("");
  };

  useEffect(() => {
    if (portfolioid !== "" && brandid !== "") {
      const obj = brandMapList.filter(
        (a) =>
          a.pfid?.toString() === portfolioid?.toString() &&
          a.brandid?.toString() === brandid?.toString()
      );
      setTradecatid(obj[0].tradecatid?.toString());
      setTradesubcatid(obj[0].tradesubcatid?.toString());
    }
  }, [portfolioid, brandid]);

  useEffect(() => {
    if (portfolioid !== "") {
      const obj = brandcompanyList.filter(
        (a) => a.retailid?.toString() === portfolioid?.toString()
      );
      if (obj[0].id) {
        setbrandcompanyid(obj[0].id?.toString());
      }
    }
  }, [portfolioid]);

  const handlePortfolioid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setportfolioid(e.target.value);
    setportfolioidError("");
  };

  const handleBrandid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setbrandid(e.target.value);
    setbrandidError("");
  };

  const handleCounryid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setregionid("");
    setstateid("");
    setcityid("");
    setcountryid(e.target.value);
    setcountryidError("");
  };

  const handleRegionid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setstateid("");
    setcityid("");
    setregionid(e.target.value);
    setregionidError("");
  };

  const handleStateid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setcityid("");
    setstateid(e.target.value);
    setstateidError("");
  };

  const handleCityid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setcityid(e.target.value);
    setcityidError("");
  };
  const handleRentType: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRentType(e.target.value);
    setRentTypeError("");
  };
  const handleOutletType: ChangeEventHandler<HTMLInputElement> = (e) => {
    setOutlettype(e.target.value);
    setOutletTypeError("");
  };
  const handleLocationType: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLocationType(e.target.value);
    setLocationTypeError("");
  };
  const handlechargeableArea: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;

    setChargeableArea!(e.target.value);
    setChargeableAreaError!("");
  };
  const handleCarpetArea: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;

    setCarpetArea!(e.target.value);
    setCarpetAreaError!("");
  };
  const handleArea: ChangeEventHandler<HTMLInputElement> = (e) => {
    setArea(e.target.value);
    setareaError("");
  };

  const handleAddress1: ChangeEventHandler<HTMLInputElement> = (e) => {
    setaddress1(e.target.value);
    setaddressError1("");
  };

  const handleAddress2: ChangeEventHandler<HTMLInputElement> = (e) => {
    setaddress2(e.target.value);
  };

  const handlePincode: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;
    else if (e.target.value?.length >= 7) return;

    setPincode(e.target.value);
    setPincodeError("");
  };

  const handleOutletcode: ChangeEventHandler<HTMLInputElement> = (e) => {
    setoutletcode(e.target.value);
  };

  const handleLeaseid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLeaseid(e.target.value);
  };

  const handleFranchise: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFranchise!(!franchise);
    setFranchiseError!("");
  };

  const handleActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setActive(e.target.value as ActiveTypes);
    setActiveError("");
  };

  const handleClear = () => {
    resetStates();
  };
  const handleRevenueShare: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRevenueShare(e.target.value);
    setRevenueShareError("");
  };
  const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    /*var today = new Date();
    var d1 = today.getDate();
    var m1 = today.getMonth() + 1;
    var y1 = today.getFullYear();

    const liveFromDate = new Date(value);
    var d2 = liveFromDate.getDate();
    var m2 = liveFromDate.getMonth() + 1;
    var y2 = liveFromDate.getFullYear();

    if (m1 === m2 && d1 < d2) return false;
    else if (m1 < m2) return false;
    else if (y1 < y2) return false;
    else {
      setFromDate(value);
      setFromDateError("");
    }*/
    setFromDate(value);
    setFromDateError("");
  };

  const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    const selectedDate = new Date(e.target.value);
    const fromDateObj = new Date(fromDate);

    if (selectedDate <= fromDateObj) {
      setToDateError('To date must be a future date of from date.');      
      if (formType === FormTypeList.ADD) {
        disableSave === false ? setDisableSave(true) : setDisableSave(false)
      }else{
        disableUpdate === false ? setDisableUpdate(true) : setDisableUpdate(false)
      }
    } else {
      setToDate(e.target.value);
      setToDateError('');
    }
  };

  const searchCredetialHandler = (item: any, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(
          item[1]["text"] +
            " " +
            item[2]["text"] +
            " " +
            item[3]["text"] +
            " " +
            item[4]["text"] +
            " " +
            item[5]["text"] +
            " " +
            item[6]["text"]
        )
      : true || [];
  };
  const checkRevenueDateRange = async (portfolioid:string, outletcode:string, fromDate:string,toDate:string) =>{
    const token1 = authAdminToken;
    const config = getAxiosRequestConfig(token1);
    const dateranges = await api.get(`revenueShareDetails/${portfolioid}/${outletcode}`, config);
    const existingranges:dateranges[] = []
    const datares = dateranges.data.data;
    if(datares?.length>0){      
      for (let d of datares) {
        let dates = {
          fromdate: d.fromdate,
          todate: d.todate
        }
        existingranges.push(dates)
      }
    }    
    const res = checkDateRangeOverlap(existingranges, fromDate, toDate)
    return res;
  }
  const handleSave = async () => {
    let error = false;
    const data: outletType = {
      portfolioid: parseInt(portfolioid),
      brandid: parseInt(brandid),
      countryid: parseInt(countryid),
      regionid: parseInt(regionid),
      stateid: parseInt(stateid),
      cityid: parseInt(cityid),
      brandcompanyid: parseInt(brandcompanyid),
      area: area,
      address1: address1,
      address2: address2,
      pincode: pincode?.toString(),
      outletcode: outletcode,
      outlettype: parseInt(outlettype!),
      outletlocation: parseInt(locationType!),
      carpetarea: parseInt(carpetArea!),
      chargeablearea: parseInt(chargeableArea!),
      franchise: franchise,
      active: active,
      tradecatid: parseInt(tradecatid),
      tradesubcatid: parseInt(tradesubcatid),
      leaseid: leaseid,
      renttype: parseInt(rentType),
      revenueshare: revenueShare,
      fromdate: fromDate,
      todate: toDate
    };

    if (isNaN(data.portfolioid)) {
      setportfolioidError("Portfolio required");
      error = true;
    }

    if (isNaN(data.brandid)) {
      setbrandidError("Brand required");
      error = true;
    }

    if (isNaN(data.countryid)) {
      setcountryidError("Country id required");
      error = true;
    }

    // if (isNaN(data.regionid)) {
    //   setregionidError("Region id required");
    //   error = true;
    // }

    if (isNaN(data.cityid)) {
      setcityidError("City id required");
      error = true;
    }

    if (isNaN(data.stateid)) {
      setstateidError("State id required");
      error = true;
    }
    if (isNaN(data.outlettype)) {
      setOutletTypeError("Outlet Type required");
      error = true;
    }

    if (isNaN(data.outletlocation)) {
      setLocationTypeError("Location Type required");
      error = true;
    }

    if (address1?.length === 0) {
      setaddressError1("Address Required");
      error = true;
    }

    if (pincode?.length === 0) {
      setPincodeError("Pincode required");
      error = true;
    }

    if (outletcode?.length === 0) {
      setoutletcodeError("Outlet code required");
      error = true;
    } else if (outletcode?.length > 10) {
      setoutletcodeError("Outlet code should be within 10 characters");
      error = true;
    } else if (testCode(outletcode)) {
      setoutletcodeError(
        "Outlet code should not allow special characters/space"
      );
      error = true;
    }

    if (leaseid?.length === 0) {
      setLeaseidError("Lease id required");
      error = true;
    } else if (leaseid?.length > 10) {
      setLeaseidError("Lease id should be within 10 characters");
      error = true;
    } else if (testCode(leaseid)) {
      setLeaseidError(
        "Lease id should not allow special characters/space"
      );
      error = true;
    }

    if (area?.length === 0) {
      setareaError("Area/Locality required");
      error = true;
    }

    if (carpetArea === undefined || carpetArea?.length === 0) {
      setCarpetAreaError("Carpet Area Required");
      error = true;
    }
    if (chargeableArea === undefined || chargeableArea?.length === 0) {
      setChargeableAreaError("Chargeble Area Required");
      error = true;
    }
    let dateRangeError = false;
    let ExistingDateRange = '';
    if(revenueShare !=='' && fromDate !=='' && toDate !==''){
      let res = await checkRevenueDateRange(portfolioid, outletcode, fromDate, toDate);
      error = res.status === true ? true : false;
      dateRangeError = res.status === true ? true : false;
      ExistingDateRange = res.status === true ? res.ExistingFrom+ ' - ' +res.ExistingTo : '';
    }
    if (!error) {
      setLoading(true);
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addOutlet(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            setNewOutlet([...newOutlet, data]);
            let reg_obj = regionList.find((a) => a.id === data.regionid);
            reg_obj
              ? setOutletRegionData([...outletRegionData, reg_obj])
              : setOutletRegionData(outletRegionData);
            let state_obj = StateList.find((a) => a.id === data.stateid);
            state_obj
              ? setOutletStateData([...outletStateData, state_obj])
              : setOutletStateData(outletStateData);
            let city_obj = CityList.find((a) => a.id === data.cityid);
            city_obj
              ? setOutletCityData([...outletCityData, city_obj])
              : setOutletCityData(outletCityData);
            resetStates();
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        updateOutlet !== undefined &&
        updateOutlet.id !== undefined
      ) {
        setLoading(true);
        dispatch(editOutlet(data, updateOutlet.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            handleClear();
            handleApply();

            setUpdateButton(false);
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }if(dateRangeError === true){
      toast(`Given Date Range Already Exist with ${ExistingDateRange}`, ToastTypes.ERROR);
    }
  };
  useEffect(() => {
    if (countryid) {
      dispatch(fetchRegion(countryid));
      regionid
        ? dispatch(fetchState(countryid, regionid))
        : dispatch(fetchState(countryid, ""));
      stateid ? dispatch(fetchCity(countryid, stateid)) : dispatch(clearCity());
    } else {
      dispatch(clearRegion());
      setregionid("");
      dispatch(clearState());
      setstateid("");
      dispatch(clearCity());
      setcityid("");
    }
  }, [countryid, regionid, stateid]);

  useEffect(() => {
    if (editData?.id !== undefined && formType === FormTypeList.EDIT) {
      setportfolioid(editData.pfid?.toString());
      setbrandid(editData.brandid?.toString());
      setTradecatid(editData.tradecatid?.toString());
      setTradesubcatid(editData.tradesubcatid?.toString());
      handleApply();
    }
  }, [editData, formType]);

  //outlet form edit and delete

  const handleEdit = () => {
    if (id !== undefined) {
      setUpdateButton(true);
      var obj = filteredOutletList.find((c) => c.id === id);
      setUpdateOutlet(obj);
    }
  };

  const handleDeleteConfirmation = async () => {
    setOutletFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = filteredOutletList.find((a) => a.id === id);
      const portid = obj?.portfolioid;
      const bcid = obj?.brandid;
      const code = obj?.outletcode;
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
      setUpdateButton(false);
      setUpdateOutlet(undefined);

      handleClear();
      //handleApply(); //doesnt have any sort of effect here
      const token = authAdminToken;
      const config = getAxiosRequestConfig(token);
      const {
        data: { data },
      } = await api.get(`outlet/${portid}/${bcid}/${code}`, config);
      setDisableDelete(data && data?.length > 0 ? true : false);
    }
  };

  const handleDelete: HandleDelete = async (value) => {
    if (value.id !== undefined) {
      dispatch(deleteOutlet(value.id, value.portfolioid?.toString()))
        .then(async (text) => {
          toast(text, ToastTypes.SUCCESS);
          if (outletData !== undefined && outletData[0].record?.length === 1) {
            let newOffset = offset - limit < 0 ? 0 : offset - limit;
            setOffset(newOffset);
            await handleApply(newOffset);
          } else {
            await handleApply(); //issue starts from here
          }

          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleApply = async (offsetVal = offset) => {
    if (
      editData !== undefined &&
      editData.pfid !== undefined &&
      editData.brandid !== undefined &&
      formType === FormTypeList.EDIT
    ) {
      const getData = {
        pfid: editData.pfid,
        bcid: editData.brandid,
        limit: limit?.toString(),
        offset: offsetVal?.toString(),
      };

      if (
        getData !== undefined &&
        getData.limit !== "" &&
        getData.offset !== "" &&
        getData.offset !== undefined &&
        getData.offset !== "NaN"
      ) {
        setLoading(true);
        try {
          const apiconfig = getAxiosRequestConfig(authAdminToken);
          const response = await api.post<
            APIResponse<{ records: outletDataType[] }>
          >(`outlet/pagination`, getData, apiconfig);

          if (response.status === 200 && response.data.data) {
            const result = response.data.data.records;
            setOutletData(result);
          } else {
            setOutletData([]);
          }
        } catch (error) {
          // Handle errors
        } finally {
        }
      }
    }
  };

  const handleDeleteFormClose = () => {
    //setOffset(offset);
    //handleApply(); //issue
    setShowDeleteConfirmation(false);
    setId(undefined);
    setOutletFormType(undefined);

    //setOffset(offset); issue here also
  };

  const handleFormClose = () => {
    setUpdateOutlet(undefined);
    setId(undefined);
    setShowDeleteConfirmation(false);
    setNewOutlet([]);
    setOutletFormType(undefined);

    //setOffset(offset-limit);
  };

  useEffect(() => {
    if (
      updateOutlet !== undefined &&
      updateOutlet.id !== undefined &&
      formType === FormTypeList.EDIT
    ) {
      setcountryid(updateOutlet.countryid?.toString());
      setregionid(updateOutlet.regionid?.toString());
      setstateid(updateOutlet.stateid?.toString());
      setcityid(updateOutlet.cityid?.toString());
      setOutlettype(updateOutlet.outlettype?.toString());
      setLocationType(updateOutlet.outletlocation?.toString());
      setArea(updateOutlet.area);
      setaddress1(updateOutlet.address1);
      setaddress2(updateOutlet.address2 ?? "");
      setPincode(updateOutlet.pincode);
      setoutletcode(updateOutlet.outletcode);
      setLeaseid(updateOutlet.leaseid);
      setFranchise(updateOutlet.franchise);
      setActive(
        updateOutlet.active === true
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );
      setTradecatid(updateOutlet.tradecatid?.toString());
      setTradesubcatid(updateOutlet.tradesubcatid?.toString());
      setCarpetArea(updateOutlet.carpetarea?.toString());
      setChargeableArea(updateOutlet.chargeablearea?.toString());
      setFromDate(updateOutlet.fromdate?.toString());
      setToDate(updateOutlet.todate?.toString());
      setRevenueShare(updateOutlet.revenueshare?.toString());
      setRentType(updateOutlet.renttype?.toString());
    }
  }, [updateOutlet, formType]);

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.EDIT) {
      handleEdit();
      handleClear();
    }
  }, [id, formType]);

  useEffect(() => {
    if (id !== undefined && formOutletType === FormTypeList.DELETE) {
      handleDeleteConfirmation();
      handleClear();

      //handleApply(); no use
    }
  }, [id, formOutletType]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

 

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Portfolio Name"
              label="Portfolio Name"
              labelClassName="required"
              value={portfolioid}
              onChange={handlePortfolioid}
              placeholder="Portfolio Name"
              errorText={portfolioidError}
              containerClass="mb-2"
              disabled={showViewForm || (editData && true)}
              type="select"
            >
              <option value="">Select Portfolio</option>
              {portfolioFilteredByMapping.map((a, i) => {
                return (
                  <Option value={a?.id} key={i}>
                    {a?.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Brand"
              label="Brand"
              labelClassName="required"
              value={brandid}
              onChange={handleBrandid}
              placeholder="Brand Name"
              errorText={brandidError}
              containerClass="mb-2"
              disabled={showViewForm || (editData && true)}
              type="select"
            >
              <option value="">Select Brand</option>
              {brandFilteredByPortfolio.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a?.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Country Name"
              label="Country Name"
              labelClassName="required"
              value={countryid}
              onChange={handleCounryid}
              placeholder="Country Name"
              errorText={countryiderror}
              containerClass="mb-2"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select Country</option>
              {countryList.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.countryname}
                  </Option>
                );
              })}
            </FormInput>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Region Name"
              label="Region Name"
              labelClassName="required"
              value={regionid}
              onChange={handleRegionid}
              placeholder="Region Name"
              errorText={regionidError}
              containerClass="mb-2"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select Region</option>
              {regionList.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.regionname}
                  </Option>
                );
              })}
            </FormInput>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="State Name"
              label="State Name"
              labelClassName="required"
              value={stateid}
              onChange={handleStateid}
              placeholder="State Name"
              errorText={stateidError}
              containerClass="mb-2"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select State</option>
              {StateList.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.statename}
                  </Option>
                );
              })}
            </FormInput>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="City Name"
              label="City Name"
              labelClassName="required"
              value={cityid}
              onChange={handleCityid}
              placeholder="City Name"
              errorText={cityyidError}
              containerClass="mb-2"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select City</option>
              {CityList.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.cityname}
                  </Option>
                );
              })}
            </FormInput>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Outlet Type"
              label="Outlet Type"
              labelClassName="required"
              value={outlettype}
              onChange={handleOutletType}
              placeholder="Outlet Type"
              errorText={outletTypeError}
              containerClass="mb-2"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Outlet Type</option>
              {SHOP_TYPE.map((a, i) => {
                return (
                  <Option value={a.value} key={i}>
                    {a.text}
                  </Option>
                );
              })}
            </FormInput>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Location Type"
              label="Location Type"
              labelClassName="required"
              value={locationType}
              onChange={handleLocationType}
              placeholder="Location Type"
              errorText={locationTypeError}
              containerClass="mb-2"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Location Type</option>
              {LOCATION_TYPE.map((a, i) => {
                return (
                  <Option value={a.value} key={i}>
                    {a.text}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name=" Area"
              label=" Area/Locality"
              labelClassName="required"
              value={area}
              onChange={handleArea}
              placeholder="Area/Locality"
              errorText={areaError}
              containerClass="mb-2"
              disabled={showViewForm}
            />
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name=" Address1"
              label=" Address1"
              labelClassName="required"
              value={address1}
              onChange={handleAddress1}
              placeholder="Address"
              errorText={addressError1}
              containerClass="mb-2"
              disabled={showViewForm}
            />
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name=" Address2"
              label=" Address2"
              labelClassName="required"
              value={address2}
              onChange={handleAddress2}
              placeholder="Address"
              errorText={addressError2}
              containerClass="mb-2"
              disabled={showViewForm}
            />
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name=" Pincode"
              label=" Pincode"
              labelClassName="required"
              value={pincode}
              onChange={handlePincode}
              placeholder=" Pincode"
              errorText={pincodeError}
              containerClass="mb-2"
              disabled={showViewForm}
            />
          </div>
          </div>
          <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Outlet Code"
              label="Outlet Code"
              labelClassName="required"
              value={outletcode}
              onChange={handleOutletcode}
              placeholder="Outlet code"
              errorText={outletcodeError}
              containerClass="mb-2"
              disabled={showViewForm || (editData && true)}
            />
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Lease Id"
              label="Lease Id"
              labelClassName="required"
              value={leaseid}
              onChange={handleLeaseid}
              placeholder="Lease Id"
              errorText={leaseidError}
              containerClass="mb-2"
              disabled={showViewForm || (editData && true)}
            />
          </div>
          {/* <div className="row"> */}

          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Carpet Area"
              label="Carpet Area"
              labelClassName="required"
              value={carpetArea}
              onChange={handleCarpetArea}
              placeholder="Carpet Area"
              errorText={carpetAreaError}
              containerClass="mb-2"
              type="text"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Chargeable Area"
              label="Chargeable Area"
              labelClassName="required"
              value={chargeableArea}
              onChange={handlechargeableArea}
              placeholder="Chargeable Area"
              errorText={chargeableAreaError}
              containerClass="mb-2"
              type="text"
            />
          </div>
          </div>
          <div className="row">
          {formType === FormTypeList.EDIT && (
            <div className="col-sm-6 col-md-4 col-lg-3">
              <FormInput
                name="Status"
                label="Status"
                labelClassName="required"
                value={active}
                onChange={handleActive}
                placeholder="Status"
                errorText={activeError}
                containerClass="mb-2"
                disabled={showViewForm}
                type="select"
              >
                <option value="">Select</option>
                {ACTIVE_DROPS.map((a, i) => {
                  return (
                    <Option value={a.value?.toString()} key={i}>
                      {a.text}
                    </Option>
                  );
                })}
              </FormInput>
            </div>
          )}

          <div className="form-check col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Franchise"
              label="Franchise"
              labelClassName="required"
              value={franchise?.toString()}
              onChange={handleFranchise}
              containerClass="mt-4"
              type="checkbox"
              errorText={franchiseError}
              checked={franchise}
            />
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Rent Type"
            label="Rent Type"
            labelClassName=""
            value={rentType}
            onChange={handleRentType}
            placeholder="Rent Type"
            errorText={rentTypeError}
            containerClass="mb-2"
            disabled={showViewForm } //|| (editData && true)
            type="select"
          >
            <option value="">Select Rent Type</option>
            {rentList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a?.renttype}
                </Option>
              );
            })}
          </FormInput>
        </div>
        </div>
        <div className="row revenue-div">
        <div className="col-sm-6 col-md-4 col-lg-3">
            <FormInput
              name="Revenue Share"
              label="Revenue Share"
              labelClassName=""
              value={revenueShare}
              onChange={handleRevenueShare}
              placeholder="Revenue Share"
              errorText={revenueShareError}
              containerClass="mb-2"
              type="text"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
          {/* <FormInput
            name="From Date"
            label="From Date"
            labelClassName=""
            value={fromDate}
            max={today()}
            onChange={handleFromDate}
            placeholder="From Date"
            errorText={fromDateError}
            containerClass="mb-2"
            type="date"
            disabled={showViewForm}
          /> */}
          <FormInput
            name="From Date"
            label="From Date"
            labelClassName=""
            value={fromDate}
            max={maxFromDate}
            onChange={handleFromDate}
            placeholder="From Date"
            errorText={fromDateError}
            containerClass="mb-2"
            type="date"                
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          {/* <FormInput
            name="To Date"
            label="To Date"
            labelClassName=""
            value={toDate}
            max={today()}
            onChange={handleToDate}
            placeholder="To Date"
            errorText={toDateError}
            containerClass="mb-2"
            type="date"
            disabled={showViewForm}
          /> */}
          <FormInput
            name="To Date"
            label="To Date"
            labelClassName=""
            value={toDate}
            max={getRevenueMaxDate()}
            onChange={handleToDate}
            placeholder="To Date"
            errorText={toDateError}
            containerClass="mb-2"
            type="date"
            disabled={showViewForm}
          />
        </div>
        </div>

        {formType === FormTypeList.ADD && (
          <div className="d-flex justify-content-end hstack gap-1 mt-2">
            {!loading ? (
              <button className="btn btn-secondary" onClick={handleSave} disabled={disableSave}>
                ADD
              </button>
            ) : (
              <button className="btn btn-secondary" disabled>
                <i className="fa fa-spinner fa-spin"></i> Adding...
              </button>
            )}
          </div>
        )}

        {updateButton && (
          <div className="d-flex justify-content-end hstack gap-1 mt-2">
            <button className="btn btn-secondary" onClick={handleClear}>
              Clear
            </button>
            {!loading ? (
              <button className="btn btn-secondary" onClick={handleSave} disabled={disableUpdate}>
                Update
              </button>
            ) : (
              <button className="btn btn-secondary" disabled>
                <i className="fa fa-spinner fa-spin"></i> Updating...
              </button>
            )}
          </div>
        )}

        <div className="row">
          <Modal
            headerText={formOutletType + " OUTLET MANAGER"}
            visible={showDeleteConfirmation}
            onClose={handleFormClose}
            size="lg"
            centered
            style={{ background: "#00000082", zIndex: "999999" }}
          >
            <DeleteConfirmationForm
              deleteLabel={
                disableDelete
                  ? "Outlet is in use. Deletion not allowed."
                  : "Do you really want to delete the records with the OutletCode - " +
                    deleteData?.outletcode
              }
              onCancel={handleDeleteFormClose}
              onDelete={() => (disableDelete ? {} : handleDelete(deleteData!))}
            />
          </Modal>

          {formType === FormTypeList.EDIT && (
            <AdminTable2
              addBtnText={""}
              onEdit={handleEdit}
              onDelete={handleDeleteConfirmation}
              search={searchHandler}
              limit={limit}
              setOffset={setOffset}
              offset={offset}
              onPageNoChange={handleApply}
              range={range}
              pageTitle={"OUTLET MANAGEMENT"}
              path={"/admin/outlet_management"}
              setId={setId}
              {...getTableData()}
            />
          )}

          {formType === FormTypeList.ADD && (
            <AdminTable
              addBtnText={""}
              //onEdit={handleEdit}
              //onDelete={handleDeleteConfirmation}
              pageTitle={"OUTLET MANAGEMENT"}
              path={"/admin/outlet_management"}
              //setId={setId}
              {...getTableData()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OutletForm;
