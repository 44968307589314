import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Permission } from "../../../config";
import { AuthAdminType } from "../../../redux/actions/authAdminActions";
import { StoreState } from "../../../redux/store";
import FormInput from "./FormInput";
import * as Feather from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faTrashCan } from "@fortawesome/free-solid-svg-icons";

export interface Thead {
  id?: React.Key;
  children?: React.ReactNode | React.ReactNode[];
  text?: string;
  style?: React.CSSProperties;
  className?: string;
}

export interface Tbody {
  id?: React.Key;
  theadId?: React.Key;
  children?: React.ReactNode | React.ReactNode[];
  text?: string;
  style?: React.CSSProperties;
  className?: string;
}

interface Props {
  data?: string[][];
  thead?: Thead[];
  tbody?: Tbody[][];
  pageTitle: string;
  path: string;
  addBtnText: string;
  onAdd?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onView?: () => void;
  setId?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setRoleId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  From?: string;
  numArrayForRole?: number[];
  onActivate?: any;
  onDeActivate?: () => void;
  search?: ChangeEventHandler<HTMLInputElement>;
  searchRef?: any;
  searchTerm?: any;
  mainContentRef?: any;
  setOffset?: React.Dispatch<React.SetStateAction<number>>;
  limit?: number;
  range?: number[];
  onPageNoChange?: () => void;
  offset: number;
}
const AdminGrid: React.FC<Props> = (props: Props) => {
  const authAdminRole = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  )?.role;

  const {
    mainContentRef,
    searchTerm,
    searchRef,
    pageTitle,
    path,
    onAdd,
    onDelete,
    onEdit,
    onView,
    addBtnText,
    setId,
    From,
    setRoleId,
    numArrayForRole,
    onActivate,
    onDeActivate,
    search,
    setOffset,
    limit,
    range,
    onPageNoChange,
    offset,
  } = props;

  // const head = [props.thead?.map(h => h.text || '') || []]
  const body =
    props.tbody?.map((tr, i) => {
      return (
        props.thead?.map((th, j) => {
          const col = tr.find((r) => r.theadId === th.id);
          return col?.text || "";
        }) || []
      );
    }) || [];

    // console.log(body,'heck')

  const Permissions = useMemo<Permission>(() => {
    let obj = authAdminRole?.find((a) => a.pageurl === path);

    let addAccess = obj?.add!;
    let editAccess = obj?.edit!;
    let viewAccess = obj?.view!;
    let deleteAccess = obj?.delete!;
    if (addAccess || editAccess || viewAccess || deleteAccess) {
      return {
        add: addAccess,
        edit: editAccess,
        view: viewAccess,
        delete: deleteAccess,
      };
    } else
      return {
        add: false,
        edit: false,
        view: false,
        delete: false,
      };
  }, [authAdminRole, path]);

  const lastPageNo = Math.max(...range!);
  const [pageNo, setPageNo] = useState<number>(1);

  const handleView = (value: number) => {
    setId!(value);
    onView!();
  };

  const handleEdit = (value: number) => {
    setId!(value);
    onEdit!();
  };

  const handleActivate = (value: number) => {
    setId!(value);
    onActivate(value);
  };

  const handleDeActivate = (value: number) => {
    setId!(value);
    onDeActivate!();
  };

  const handleDelete = (value: number) => {
    setId!(value);
    onDelete!();
  };

  const handleRoleView = (value: string) => {
    setRoleId!(value);
    onView!();
  };

  const handleRoleEdit = (value: string) => {
    setRoleId!(value);
    onEdit!();
  };

  const handleRoleDelete = (value: string) => {
    setRoleId!(value);
    onDelete!();
  };

  const handleFirstPage = () => {
    setOffset!(0);
    setPageNo(1);
  };
  const handleLastPage = () => {
    setOffset!(lastPageNo * limit! - limit!);

    setPageNo(lastPageNo);
  };

  const handlePrevious = () => {
    setPageNo((prev) => Math.max(prev - 1, 1));
    if (setOffset) {
      setOffset((prevOffset) => Math.max(prevOffset - limit!, 0));
    }
  };

  const handleNext = () => {
    setPageNo((prev) => Math.min(prev + 1, lastPageNo));
    if (setOffset) {
      setOffset((prevOffset) =>
        Math.min(prevOffset + limit!, (lastPageNo - 1) * limit!)
      );
    }
  };

  const handlePageNo = (num: number) => {
    if (num === 1) {
      setOffset!(0);
    } else if (body.length === 0 && offset > 0) {
      setOffset!((prevOffset) => prevOffset - limit!);
    } else {
      setOffset!(num * limit! - limit!);
    }
    setPageNo(num);
  };

  useEffect(() => {
    if (limit && setOffset && body.length > 0) {
      onPageNoChange !== undefined && onPageNoChange!();
      if (pageNo === 1) {
        setOffset!(0);
      } else {
        setOffset!(pageNo * limit! - limit!);
      }
    }
  }, [pageNo, setOffset]);

  useEffect(() => {
    if (limit && setOffset) {
      if (body.length > 0 && offset > 0) {
        setPageNo(Math.floor(offset / limit) + 1);
      } else if (body.length === 0 && offset > 0) {
        setOffset((prevOffset) => prevOffset - limit);
        // setPageNo(Math.floor(offset / limit) - 1);
        setPageNo(Math.floor((offset+limit) / limit));
      }
    } else {
      setPageNo(range !== undefined ? range[0] : 1);
    }
  }, [range, setOffset]);

  useEffect(() => {
    if (offset === 0) {
      // if(pageNo === lastPageNo)
        setPageNo(1)
    }
    // else if(limit && offset > 0){
    //   if(pageNo === lastPageNo)
    //     setPageNo((offset+limit) / limit )
    // }
  }, [offset]);

  return (
    <>
      {/* <div className="text-end mb-3">
                {downloadPDF && <button type="button" onClick={handleDownloadPDF} className={`m-1 btn btn-s btn-blue`}><Feather.Download /> PDF</button>}
                {downloadCSV && <CSVLink data={data} filename={fileName + ".csv"}> <button type="button" className={`m-1 btn btn-s btn-blue`}><Feather.Download /> CSV</button></CSVLink>}
            </div> */}
      <div className="card mt-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h5 className="header-title mb-3 float-start">
                <i className="fe-align-justify me-1 text-primary"></i>
                {pageTitle}
              </h5>
            </div>
            <div className="col-lg-6 col-sm-12 text-end mb-1">
              {Permissions.add && onAdd && (
                <button
                  type="button"
                  onClick={onAdd}
                  className={`me-1 btn btn-xs btn-secondary`}
                >
                  {addBtnText}
                </button>
              )}
            </div>
          </div>
          {search && (
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <FormInput
                  type="text"
                  value={searchTerm}
                  name="search"
                  placeholder="Search..."
                  className="mb-2"
                  itemRef={searchRef}
                  onChange={search}
                  onFocus={() => {
                    mainContentRef?.current?.scrollTo({
                      behavior: "smooth",
                      top: 270,
                    });
                  }}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="table-wrapper">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      {props.thead?.map((item, i) => {
                        return (
                          <th
                            className={`text-truncate ${item.className || ""}`}
                            key={i}
                            style={item.style}
                          >
                            {item.children}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  {From === "role" ? (
                    <tbody>
                      {props.tbody?.map((tr, i) => {
                        let sno = i + 1;
                        return (
                          <tr
                            className={`${
                              numArrayForRole?.includes(sno) ? "" : "d-none"
                            }`}
                            key={i}
                          >
                            {props.thead?.map((th, j) => {
                              const col = tr.find((r) => r.theadId === th.id);
                              return (
                                <>
                                  <td
                                    style={col?.style}
                                    className={col?.className}
                                    key={col?.id || j}
                                  >
                                    {col?.children}
                                  </td>
                                  {col?.children === "action" && (
                                    <td>

                                      <div className="hstack gap-1">
                                        {Permissions.edit && onEdit && (
                                          <button
                                            className="btn btn-sm btn-secondary"
                                            onClick={
                                              From === "role"
                                                ? () =>
                                                    handleRoleEdit(col.text!)
                                                : () =>
                                                    handleEdit(
                                                      parseInt(col.text!)
                                                    )
                                            }
                                          >
                                            <FontAwesomeIcon icon={faEdit} size="lg" title="Edit" />
                                          </button>
                                        )}
                                        {Permissions.delete && onDelete && (
                                          <button
                                            className="btn btn-sm btn-secondary"
                                            onClick={
                                              From === "role"
                                                ? () =>
                                                    handleRoleDelete(col.text!)
                                                : () =>
                                                    handleDelete(
                                                      parseInt(col.text!)
                                                    )
                                            }
                                          >
                                            <FontAwesomeIcon icon={faTrashCan} size="lg" title="Delete" />
                                          </button>
                                        )}
                                        {Permissions.view && onView && (
                                          <button
                                            className="btn btn-sm btn-secondary"
                                            onClick={
                                              From === "role"
                                                ? () =>
                                                    handleRoleView(col.text!)
                                                : () =>
                                                    handleView(
                                                      parseInt(col.text!)
                                                    )
                                            }
                                          >
                                            View
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      {props.tbody?.map((tr, i) => {
                        return (
                          <tr key={i}>
                            {props.thead?.map((th, j) => {
                              const col = tr.find((r) => r.theadId === th.id);
                              const status = tr.find(
                                (r) => r.theadId === "Status"
                              );
                              return (
                                <>
                                  <td
                                    style={col?.style}
                                    className={col?.className}
                                    key={col?.id || j}
                                  >
                                    {col?.children}
                                  </td>
                                  {col?.children === "action" && (
                                    <td>

                                      <div className="hstack gap-1">
                                        {Permissions.edit &&
                                          onActivate &&
                                          status?.children ===
                                            "Deactivated" && (
                                            <button
                                              className="btn btn-sm btn-success"
                                              onClick={() =>
                                                handleActivate(
                                                  parseInt(col.text!)
                                                )
                                              }
                                            >
                                              Reactivate
                                            </button>
                                          )}
                                        {Permissions.edit &&
                                          onActivate &&
                                          (status?.children === "Live" ||
                                            status?.children ===
                                              "Reactivated") && (
                                            <button
                                              className="btn btn-sm btn-danger"
                                              onClick={() =>
                                                handleDeActivate(
                                                  parseInt(col.text!)
                                                )
                                              }
                                            >
                                              Deactivate
                                            </button>
                                          )}
                                        {Permissions.edit && onEdit && (
                                          <button
                                            className="btn btn-sm btn-secondary"
                                            onClick={() =>
                                              handleEdit(parseInt(col.text!))
                                            }
                                          >
                                            <FontAwesomeIcon icon={faEdit} size="lg" title="Edit" />
                                          </button>
                                        )}
                                        {Permissions.delete && onDelete && (
                                          <button
                                            className="btn btn-sm btn-secondary"
                                            onClick={() =>
                                              handleDelete(parseInt(col.text!))
                                            }
                                          >
                                            <FontAwesomeIcon icon={faTrashCan} size="lg" title="Delete" />
                                          </button>
                                        )}
                                        {Permissions.view && onView && (
                                          <button
                                            className="btn btn-sm btn-secondary"
                                            onClick={() =>
                                              handleView(parseInt(col.text!))
                                            }
                                          >
                                            View
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
              {limit && setOffset && body.length > 0 && (
                <div style={{ position: "sticky" }} className="text-center m-2">
                  {/* <tr> */}
                  <nav aria-label="Page navigation example">
                    <ul className="hstack gap-2 justify-content-end m-0 text-black">
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={handleFirstPage}
                          disabled={pageNo === 1}
                        >
                          <i>
                            <Feather.ChevronsLeft size={25} color="#763EEC" />
                          </i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={handlePrevious}
                          disabled={pageNo === 1}
                        >
                          <i>
                            <Feather.ChevronLeft size={25} color="#763EEC" />
                          </i>
                        </button>
                      </li>
                      {range?.map((r, i) => {
                        if (i <= 10) {
                          return (
                            <li
                              key={i}
                              className={
                                pageNo === r
                                  ? "page-selected  page-item paginate_button"
                                  : "page-item"
                              }
                              onClick={() => handlePageNo(r)}
                              value={pageNo}
                            >
                              {" "}
                              <button
                                className={
                                  pageNo === r
                                    ? " page-selected  text-white page-link"
                                    : "page-link"
                                }
                                onClick={() => handlePageNo(r)}
                              >
                                {r}
                              </button>{" "}
                            </li>
                          );
                        } else return <React.Fragment key={i}></React.Fragment>;
                      })}
                      {pageNo > 11 ? (
                        <>
                          ...
                          <span className="page-selected  page-item paginate_button">
                            <button className="page-selected  text-white page-link">
                              {pageNo}
                            </button>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={handleNext}
                          disabled={pageNo === lastPageNo}
                        >
                          <i>
                            <Feather.ChevronRight size={25} color="#763EEC" />
                          </i>
                        </button>
                      </li>
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={handleLastPage}
                          disabled={pageNo === lastPageNo}
                        >
                          <i>
                            <Feather.ChevronsRight size={25} color="#763EEC" />
                          </i>
                        </button>
                      </li>
                    </ul>
                    {pageNo > 0 && (
                      <span className="text-end btn page-link">
                        {pageNo} | {lastPageNo}
                      </span>
                    )}
                  </nav>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminGrid;
