import { LogUserType, LogUserActionsList  } from "../actions/logUserActions";
import { Action } from "../actions/types";

const initialValue: Array<LogUserType> = []

export const LogUserReducer = (state: LogUserType[] =initialValue , action: Action) => {

    switch (action.type) {

        case LogUserActionsList.ADD_LOGUSER: {

            return [...state, action.data]
        }

        case LogUserActionsList.CLEAR_LOGUSER: {
            return initialValue

        }

        default: return state
    }

}