import { nanoid } from "@reduxjs/toolkit";
import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
import RoleForm from "../../components/admin/RoleForm";
import AdminTable, {
  Tbody,
  Thead,
} from "../../components/custom/components/AdminTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import { RoleType, deleteRole } from "../../redux/actions/roleActions";
import { AppDispatch, StoreState } from "../../redux/store";
import { useLocation, useSearchParams } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";
import { RolesType, fetchRoles } from "../../redux/actions/userRoleActions";
// interface TableBody {
//     sno: number
//     role: string
//     pageTitle: string
//     pageUrl: string
//     add: string
//     view: string
//     edit: string
//     deleteAccess: string
//     onEdit: MouseEventHandler<HTMLButtonElement>
//     onDelete: MouseEventHandler<HTMLButtonElement>
//     onView: MouseEventHandler<HTMLButtonElement>
//     numArray: number[]
// }


type HandleDelete = (value: RoleType) => void;

const Role = () => {
  const toast = useToast();

  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const RolesList = useSelector<StoreState, Array<RolesType>>(
    (state) => state.roles
  );
  const RoleList = useSelector<StoreState, Array<RoleType>>(
    (state) => state.role
  );

  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const searchBrandHandler = (item: any, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(item[1]["text"])
      : true || [];
  };

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery({ search: e.target.value });
  };

  const numArray = useMemo<number[]>(() => {
    const array: number[] = [];
    const rl = RoleList?.length;
    const l = rl / 70; //34
    let i = 0;
    for (i = 1; i <= l; i++) {
      let val = 70 * i; //34
      array.push(val);
    }
    return array;
  }, [RoleList]);

  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<RoleType>();
  const [deleteData, setDeleteData] = useState<RoleType>();
  const [roleId, setRoleId] = useState<string>();

  const getTableData = () => {
    if (RoleList === undefined) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      //{ id: "S.No", children: "S.No", text: "S.No" },
      { id: "Name", children: "Name", text: "Name" },
      // { id: "Role", children: "Role", text: "Role" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];

    RoleList.forEach((item, i) => {
      var tBody: Tbody[] = [];

      tBody.push(
        {
          id: nanoid(),
          theadId: "S.No",
          children: i + 1,
          text: (i + 1)?.toString(),
          className: "d-none",
        },
        {
          id: nanoid(),
          theadId: "Name",
          children: item.role,
          text: item.role,
          className: "text-truncate",
        },
        // {
        //   id: nanoid(),
        //   theadId: "Role",
        //   children: item.roleId,
        //   text: item.roleId,
        //   className: "text-truncate",
        // },
        {
          id: nanoid(),
          theadId: "Action",
          children: "action",
          className: "d-none",
          text: item.id?.toString(),
        }
      );
      tableBody.push(tBody);
      // })
    });
    if (searchTerm?.length > 0) {
      tableBodyFiltered = tableBody.filter((item) =>
        searchBrandHandler(item, searchTerm)
      );
    } else {
      tableBodyFiltered = tableBody;
    }
    if (RoleList !== undefined) {
      return { tbody: tableBodyFiltered, thead: tableHead };
    }
  };

  const handleAdd = () => {
    setShowForm(true);
    if(adminAuthendicated){
      const logProps1 = {
        action: 'Create',
        event: `${pageName.pathname.replace("\\", " ")} page | Create Role Clicked`
      };
      AdminLog(logProps1)
    }
  };

  // const handleView: HandleView = (value) => {
  //     setFormType(FormTypeList.VIEW)
  //     setShowForm(true)
  //     setEditData(value)
  //     setShowViewForm(true)
  // }

  // const handleEdit: HandleEdit = (value) => {
  //     setFormType(FormTypeList.EDIT)
  //     setEditData(value)
  //     setShowForm(true)
  // }

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (roleId !== undefined) {
      var obj = RoleList.find((c) => c.id === roleId);
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page |  ${obj?.role} Role Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (roleId !== undefined) {
      var obj = RoleList.find((c) => c.id === roleId);
      setEditData(obj);
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | Role - ${obj?.role} | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  // const handleDelete = () => {
  //     setFormType(FormTypeList.DELETE)
  //     if (id !== undefined) {
  //         dispatch(deleteTenantCategory(id)).then(text => {
  //             toast(text, ToastTypes.SUCCESS)
  //         }).catch(text => {

  //         })
  //     }
  // }

  const handleDelete: HandleDelete = (value) => {
    if (value.role !== undefined) {
      dispatch(deleteRole(value.role))
        .then((text) => {
          toast(text, ToastTypes.SUCCESS);
          var obj = RoleList.find((c) => c.id === roleId);
          if(adminAuthendicated){
            const logProps1 = {
              action: 'Delete',
              event: `${pageName.pathname.replace("\\", " ")} page |  ${obj?.role} Role | Delete Clicked`
            };
            AdminLog(logProps1)
          }
          handleFormClose();
        })
        .catch((text) => {
          toast(text, ToastTypes.ERROR);
        });
    }
  };

  const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);
    if (roleId !== undefined) {
      var obj = RoleList.find((c) => c.id === roleId);
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setRoleId(undefined);
    setShowDeleteConfirmation(false);
    setShowViewForm(false);
  };

  useEffect(() => {
    if (roleId !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (roleId !== undefined && formType === FormTypeList.EDIT)
      handleEdit();
    else if (roleId !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId, formType]);

  useEffect(()=>{
    if(adminAuthendicated){
      dispatch(fetchRoles())
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " ROLE"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <RoleForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
        />
      </Modal>

      <Modal
        headerText={"Delete Role"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="sm"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            "Do you really want to delete the records with the role - " +
            deleteData?.role
          }
          // deleteData={deleteData!}
          onCancel={handleFormClose}
          onDelete={() => handleDelete(deleteData!)}
        />
      </Modal>

      <AdminLayout>
        {/* <PageTitle
                title='Contact'
                buttonText='Create New Contact'
                onButtonClick={handleAdd}
            /> */}
        <AdminTable
          addBtnText="Create New Role"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          pageTitle="Role"
          path="/admin/role"
          // data={data}
          search={searchHandler}
          setRoleId={setRoleId}
          From="role"
          numArrayForRole={numArray}
          {...getTableData()}
        />
        {/* <div className="card mt-3">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-lg-6 col-sm-12">
                            <h5 className="header-title mb-3 float-start"><i className="fe-align-justify me-1 text-primary"></i>Role</h5>
                        </div>
                        <div className="col-lg-6 col-sm-12 text-end mb-1">
                            <button type="button" onClick={handleAdd} className={`me-1 btn btn-xs btn-secondary`}>Create New Role</button>
                        </div>
                    </div>
                    <hr className='hr mt-0' />
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr> */}
        {/* <th className='text-truncate align-middle'>S.No</th> */}
        {/* <th className='text-truncate align-middle'>Role</th> */}
        {/* <th className='text-truncate align-middle'>Page Title</th>
                                    <th className='text-truncate align-middle'>Page Url</th>
                                    <th className='text-truncate align-middle'>ADD</th>
                                    <th className='text-truncate align-middle'>EDIT</th>
                                    <th className='text-truncate align-middle'>VIEW</th>
                                    <th className='text-truncate align-middle'>DELETE</th> */}
        {/* <th className='text-truncate align-middle'>Action</th> */}
        {/* </tr>
                            </thead>
                            <tbody>
                                {RoleList.map((r, i) => {
                                    return <RoleBody
                                        sno={i + 1}
                                        role={r.role}
                                        pageTitle={r.pagetitle}
                                        pageUrl={r.pageurl}
                                        add={(r.add)?.toString()}
                                        edit={(r.edit)?.toString()}
                                        deleteAccess={(r.delete)?.toString()}
                                        view={(r.view)?.toString()}
                                        key={i}
                                        onEdit={() => handleEdit(r)}
                                        onDelete={() => handleDeleteConfirmation(r)}
                                        onView={() => handleView(r)}
                                        numArray={numArray}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
      </AdminLayout>
    </>
  );
};

// const RoleBody = ({ sno, role, pageTitle, pageUrl, add, edit, deleteAccess, view, onView, onEdit, onDelete, numArray }: TableBody) => {
//     return <tr className={`${numArray.includes(sno) ? '' : 'd-none'}`}>
//         {/* <td className='align-middle'>{sno}</td> */}
//         <td className='align-middle text-center'>{role}</td>
//         {/* <td className='align-middle'>{pageTitle}</td>
//         <td className='align-middle'>{pageUrl}</td>
//         <td className='align-middle text-capitalize'>{add}</td>
//         <td className='align-middle text-capitalize'>{edit}</td>
//         <td className='align-middle text-capitalize'>{view}</td>
//         <td className='align-middle text-capitalize'>{deleteAccess}</td> */}
//         <td>
//             <div className={`justify-content-center hstack gap-1 ${numArray.includes(sno) ? '' : 'd-none'}`}>
//                 <button className='btn btn-sm btn-primary' onClick={onEdit}>Edit</button>
//                 <button className='btn btn-sm btn-danger' onClick={onDelete}>Delete</button>
//                 <button className='btn btn-sm btn-secondary' onClick={onView}>View</button>
//             </div>
//         </td>
//     </tr>
// }

export default Role;
