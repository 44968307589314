import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"


export interface ContactType {
    id?: string
    name: string
    // lastName: string
    designation: String
    email: string
    contactNo: number
}

export const ContactSampleData = [
    {
        id: '1',
        name: 'Catharin',
        lastName: 'Prasanna',
        designation: 'Hall',
        email: 'catharinprasanna25@gmail.com',
        contactNo: 9876543210,
    },
    {
        id: '2',
        name: 'Arul',
        lastName: 'Mozhi',
        designation: 'Hall',
        email: 'catharinprasanna25@gmail.com',
        contactNo: 9876543210,
    },

]

export enum ContactActionsList {
    ADD_CONTACT = 'ADD_CONTACT',
    EDIT_CONTACT = 'EDIT_CONTACT',
    FETCH_CONTACT = 'FETCH_CONTACT',
    DELETE_CONTACT = 'DELETE_CONTACT',
    CLEAR_CONTACT = 'CLEAR_CONTACT',
}

export interface DeleteContact {
    id: string
}

export interface AddContactAction {
    type: ContactActionsList.ADD_CONTACT
    data: ContactType
}

export interface EditContactAction {
    type: ContactActionsList.EDIT_CONTACT
    data: ContactType
    id: string
}

export interface FetchContactAction {
    type: ContactActionsList.FETCH_CONTACT

}

export interface DeleteContactAction {
    type: ContactActionsList.DELETE_CONTACT
    data: DeleteContact
}

export interface ClearContactAction {
    type: ContactActionsList.CLEAR_CONTACT
}

export type ContactActions = AddContactAction | EditContactAction | FetchContactAction | DeleteContactAction | ClearContactAction

export const clearContact = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<ClearContactAction>({
            type: ContactActionsList.CLEAR_CONTACT
        })
    }
}
export const addContact = (data: ContactType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddContactAction>({
            type: ContactActionsList.ADD_CONTACT,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editContact = (data: ContactType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditContactAction>({
            type: ContactActionsList.EDIT_CONTACT,
            data: data,
            id: id
        })
    }
}

export const fetchContact = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchContactAction>({
            type: ContactActionsList.FETCH_CONTACT

        })
    }
}

export const deleteContact = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteContactAction>({
            type: ContactActionsList.DELETE_CONTACT,
            data: { id }
        })
    }
}