import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { Dispatch } from "redux";
import { GetState } from "../store";
import { ActiveTypes } from "./brandActions";

export interface BrandCompanyMappingType {
  id?: number;
  pfid: number;
  companyid: number;
  brandid: number;
  tradecatid: number;
  tradesubcatid: number;
  active: ActiveTypes | boolean;
  createdby?: number | null;
  createdat?: string;
  updatedat?: string;
  updatedby?: string;
}

export enum BrandCompanyMappingActionsList {
  ADD_BRAND_MAPPING = "ADD_BRAND_MAPPING",
  EDIT_BRAND_MAPPING = "EDIT_BRAND_MAPPING",
  FETCH_BRAND_MAPPING = "FETCH_BRAND_MAPPING",
  DELETE_BRAND_MAPPING = "DELETE_BRAND_MAPPING",
  CLEAR_BRAND_MAPPING = "CLEAR_BRAND_MAPPING",
}

export interface DELETE_BRAND_MAPPING {
  id: number;
}

export interface AddBrandCompanyMappingAction {
  type: BrandCompanyMappingActionsList.ADD_BRAND_MAPPING;
  data: BrandCompanyMappingType;
}

export interface EditBrandCompanyMappingAction {
  type: BrandCompanyMappingActionsList.EDIT_BRAND_MAPPING;
  data: BrandCompanyMappingType;
  id?: number;
}

export interface DeleteBrandCompanyMappingAction {
  type: BrandCompanyMappingActionsList.DELETE_BRAND_MAPPING;
  data: DELETE_BRAND_MAPPING;
}

export interface FetchBrandCompanyMappingAction {
  type: BrandCompanyMappingActionsList.FETCH_BRAND_MAPPING;
  data: BrandCompanyMappingType[];
}

export interface ClearBrandCompanyMappingAction {
  type: BrandCompanyMappingActionsList.CLEAR_BRAND_MAPPING;
}

export type BrandCompanyMappingActions =
  | AddBrandCompanyMappingAction
  | EditBrandCompanyMappingAction
  | DeleteBrandCompanyMappingAction
  | ClearBrandCompanyMappingAction
  | FetchBrandCompanyMappingAction;

export const addBrandCompanyMapping = (data: BrandCompanyMappingType) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);
    return api
      .post<APIResponse<BrandCompanyMappingType>>("brandmapping/", data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<AddBrandCompanyMappingAction>({
            type: BrandCompanyMappingActionsList.ADD_BRAND_MAPPING,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message
              ? response.data.message
              : "Brand Mapping added"
          );
        } else {
          // throw { response };
          throw new Error("Unable to create")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to create"
            : "Unable to create"
        );
      });
  };
};

export const editBrandCompanyMapping = (
  data: BrandCompanyMappingType,
  id: number
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .put<APIResponse<BrandCompanyMappingType>>(
        `brandmapping/${id}`,
        data,
        config
      )
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<EditBrandCompanyMappingAction>({
            type: BrandCompanyMappingActionsList.EDIT_BRAND_MAPPING,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message
              ? response.data.message
              : "Brand Mapping Updated"
          );
        } else {
          throw new Error("Unable to update")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to update"
            : "Unable to update"
        );
      });
  };
};

export const fetchBrandCompanyMapping = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<BrandCompanyMappingType[]>>("brandmapping/", config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchBrandCompanyMappingAction>({
            type: BrandCompanyMappingActionsList.FETCH_BRAND_MAPPING,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const deleteBrandCompanyMapping = (id: number, portfolioId: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .delete<APIResponse<BrandCompanyMappingType>>(
        `brandmapping/${portfolioId}/${id}`,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch<DeleteBrandCompanyMappingAction>({
            type: BrandCompanyMappingActionsList.DELETE_BRAND_MAPPING,
            data: { id },
          });
          return Promise.resolve(
            response.data.message
              ? response.data.message
              : " Brand Mapping Deleted"
          );
        } else {
          throw new Error("Unable to delete")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to delete"
            : "Unable to delete"
        );
      });
  };
};

export const clearBrandCompanyMapping = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    return dispatch<ClearBrandCompanyMappingAction>({
      type: BrandCompanyMappingActionsList.CLEAR_BRAND_MAPPING,
    });
  };
};
